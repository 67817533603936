import React, { useContext } from "react";
import WidgetStyle from "zrender/widgetStyle";

import { WIDGET } from "constants/editor";
import { returnWidgetByType } from "hooks/Utils/Design/EditorUtils";
import { GetThemeDefault, scaleFontSize } from "hooks/Utils/DesignUtils";
import WidgetObject from "components/Section/Design/Widgets/Widget";

import { WidgetProvider, EditorContext } from "contexts/editor";

import Container from "./container";

const Widget = ({
    id,
    layoutRef,
    widgetSizes,
    editingMenu,
    insideMenu,
    type,
    disabled,
    selected,
    editorSelected,
    onItemSelect,
    droppingItem,
    config,
    onChange,
}) => {
    const { deviceType, theme } = useContext(EditorContext);

    const data = WidgetObject.ParseData(config?.data, deviceType);
    const rawStyle = WidgetObject.ParseStyle(config?.style, deviceType);
    const isMenu = type === WIDGET.TYPE.TVMENU;
    const isContainer = type === WIDGET.TYPE.CONTAINER;

    const style = type ? new WidgetStyle(type, theme) : null;
    if (style) {
        style.fill(rawStyle);
    }

    const usingDriveRender = false;

    if (isContainer || isMenu) {
        if (
            config?.data?.grid &&
            (config.data.grid?.cols != config?.layout?.cols || config.data.grid?.rows != config?.layout?.maxRows)
        ) {
            if (config?.widgets?.length) {
                config.widgets = config.widgets.map((widget) => {
                    if (widget?.layout) {
                        const xDiff = config.data.grid?.cols / config?.layout?.cols;
                        const yDiff = config.data.grid?.rows / config?.layout?.maxRows;
                        widget.layout.x = Math.round(widget.layout.x / xDiff);
                        widget.layout.y = Math.round(widget.layout.y / yDiff);
                        widget.layout.w = Math.round(widget.layout.w / xDiff);
                        widget.layout.h = Math.round(widget.layout.h / yDiff);
                    }
                    return widget;
                });
            }
            delete config.data.grid;
        }
        // If is container, we need to pass the layout to the container
        const containerDisabled = disabled || (isContainer && !selected) || (isMenu && !editingMenu);
        const isDroppable = !disabled || (isMenu && !editingMenu);

        return (
            <Container
                parentLayoutRef={layoutRef}
                deviceType={deviceType}
                isMenu={isMenu}
                cols={config?.layout?.cols}
                rows={config?.layout?.maxRows}
                margin={config?.layout?.margin}
                gap={config?.layout?.gap}
                rowHeight={config?.layout?.rowHeight}
                readOnly={containerDisabled}
                droppable={isDroppable}
                editorSelected={editorSelected}
                onItemSelect={onItemSelect}
                droppingItem={droppingItem}
                widgets={config?.widgets}
                style={style?.export()}
                rawStyle={rawStyle}
                onChange={(widgets) => {
                    if (onChange) {
                        onChange({
                            ...config,
                            widgets,
                        });
                    }
                }}
            />
        );
    }

    return (
        <WidgetProvider
            value={{
                id,
                type,
                style: rawStyle,
                config: {
                    style,
                    data,
                    actions: config?.actions,
                },
                widgetSizes,
            }}
        >
            <WidgetObject.Zone
                style={
                    WidgetObject.ImplementNewLibrary(type)
                        ? null
                        : {
                              background: usingDriveRender
                                  ? "transparent"
                                  : style?.bgColor || GetThemeDefault({ theme, id: "bgColor" }),
                              color: style?.fgColor ? style.fgColor : GetThemeDefault({ theme, id: "fgColor" }),
                              borderWidth: style?.borderWidth ? `${scaleFontSize(style.borderWidth)}px` : "",
                              borderColor: style?.borderColor ? style.borderColor : "transparent",
                              borderRadius: style?.radius ? `${style.radius}rem` : "",
                          }
                }
            >
                {returnWidgetByType(type, {
                    insideMenu,
                    isOnDrag: false,
                    isOnResize: false,
                    widgets: null,
                    setWarning: (widgetID, warn) => {
                        console.log("TEST WIDGET WARNING", { widgetID, warn });
                    },
                })}
            </WidgetObject.Zone>
        </WidgetProvider>
    );
};

export default Widget;
