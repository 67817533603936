import React from "react";
import { useDispatch, useSelector } from "react-redux";
import UseIcon from "../../useIcon";
import ReactTooltip from "components/ReactTooltip";
import sanitizeHtml from "sanitize-html";
import { openModal, setModalContent, setLoadingModalContent } from "../../../actions/uiActions";
import { changeGlobalAction } from "../../../actions/globalActions";
import EditRoomGroupModal from "../../../hooks/GraphqlCalls/Hotel/Rooms/modals/EditRoomGroupModal";
import { useTranslation } from "react-i18next";

function UseRoomGroupCard({ data, parentSectionName }) {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const { headerText, roomGroup, roomName, roomId } = data;
    const { langStrings } = useSelector((state) => state.ui);
    const sectionName = `${parentSectionName ? parentSectionName + "-" : ""}room-group`;
    const getToolTip = () => {
        let tooltipText = "";
        roomGroup.map((group) => {
            tooltipText += `${group}<br>`;
            return null;
        });
        return sanitizeHtml(tooltipText);
    };

    const editGroup = () => {
        const roomType = parentSectionName.toLowerCase() === "common-zones" ? "COMMONZONE" : "GUESTROOM";
        const modal = EditRoomGroupModal({ roomName, roomId, type: roomType });
        dispatch(setLoadingModalContent(true));
        dispatch(
            changeGlobalAction({
                actionName: "room-group-assign",
                itemsAffected: roomId,
            })
        );
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    return (
        <div className={`dc-card-container bg-white text-blue-400  grid grid-cols-3`}>
            <div className="col-span-2 first-capital">{t(headerText)}</div>
            <div className=" text-right">
                {parentSectionName === "common-zones" ? (
                    <UseIcon icon={{ name: "Commonzones_large" }} adjust="text-4xl"></UseIcon>
                ) : (
                    <UseIcon icon={{ name: "room" }} adjust="text-4xl"></UseIcon>
                )}
            </div>
            <div className="col-span-3 first-capital text-lg font-bold mt-5 flex">
                {Array.isArray(roomGroup) && roomGroup.length > 0 ? (
                    <>
                        <div
                            className=" inline overflow-ellipsis whitespace-nowrap"
                            style={{ maxWidth: "75%", overflow: "hidden" }}
                        >
                            {`${roomGroup[0]}`}
                        </div>
                        {roomGroup.length > 1 ? (
                            <>
                                <span
                                    className="inline-block font-normal text-base cursor-default ml-2 mt-0.5"
                                    data-tip={getToolTip()}
                                >{` +${roomGroup.length - 1}`}</span>
                                <ReactTooltip
                                    place="bottom"
                                    type="light"
                                    offset={{ top: -8, left: -8 }}
                                    html={true}
                                    border={true}
                                    borderColor="#D3DAE1"
                                />
                            </>
                        ) : null}
                    </>
                ) : (
                    <span onClick={editGroup} className="cursor-pointer">
                        {t("assign")}
                    </span>
                )}
                <UseIcon
                    id={`${sectionName}-edit`}
                    icon={{ name: "edit" }}
                    adjust="inline-block ml-4 text-1xl font-normal"
                    onclickCallback={editGroup}
                ></UseIcon>
            </div>
        </div>
    );
}

export default UseRoomGroupCard;
