import React, { useRef } from "react";

import GridLayout from "./layout";

const Container = ({
    cols,
    rows,
    margin,
    gap,
    rowHeight,
    readOnly,
    isMenu,
    editorSelected,
    onItemSelect,
    widgets,
    style,
    onChange,
    droppingItem,
    parentLayoutRef,
    deviceType,
    droppable,
}) => {
    const ref = useRef(null);

    return (
        <div
            className="h-full w-full"
            style={{
                backgroundColor: style?.bgColor || undefined,
                color: style?.fgColor || undefined,
                borderColor: style?.borderColor || undefined,
                borderWidth: style?.borderWidth ? style?.borderWidth + "px" : undefined,
                borderRadius: style?.radius ? style?.radius + "rem" : undefined,
            }}
        >
            <GridLayout
                ref={ref}
                deviceType={deviceType}
                isMenu={isMenu}
                isContainer={true}
                parentLayoutRef={parentLayoutRef}
                editorSelected={editorSelected}
                onItemSelect={onItemSelect}
                readOnly={readOnly}
                droppable={droppable}
                widgets={widgets}
                onChange={onChange}
                droppingItem={droppingItem}
                cols={cols}
                rows={rows}
                margin={margin}
                gap={gap}
                scrollX={false}
                scrollY={false}
                rowHeight={rowHeight}
                autoSize={false}
                draggableHandle={".container-widget-label"}
            />
        </div>
    );
};

export default Container;
