import React from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { useDuplicatePropertyGroup } from "../GroupsProperties/useListPropertyGroups";
import Loading from "components/Loading";

const ModalDuplicatePropertyGroup = ({ close, refetch, name, id }) => {
    const { t } = useTranslation();

    const { duplicatePropertyGroup, loadinDuplicatePropertyGroup } = useDuplicatePropertyGroup({
        close,
        refetch,
    });

    const handleClose = () => {
        close();
    };

    const handleSave = () => {
        duplicatePropertyGroup({ variables: { id: id } });
    };

    return (
        <Modal
            footer={
                !loadinDuplicatePropertyGroup ? (
                    <>
                        <Button design="blue-outline" id="modal-button-cancel" onClick={handleClose}>
                            {t("cancel")}
                        </Button>
                        <Button design="blue" id="modal-button-save" onClick={handleSave}>
                            {t("duplicate")}
                        </Button>
                    </>
                ) : null
            }
            className="w-4/12 p-10"
            title={`${name} - ${t("duplicate")}`}
        >
            {loadinDuplicatePropertyGroup ? (
                <Loading />
            ) : (
                <div>
                    <span>{t("Are you sure you want to duplicate this group of properties?")}</span>
                </div>
            )}
        </Modal>
    );
};

export const useModalDuplicatePropertyGroup = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            open(<ModalDuplicatePropertyGroup {...props} close={close} />);
        },
    };
};

export default useModalDuplicatePropertyGroup;
