import React, { useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";

//Utils
import Parser from "hooks/Utils/Parser";
import image_error from "../assets/images/icons/image.svg";

import { changeActionValues } from "../actions/globalActions";
import { useTranslation } from "react-i18next";

import Icon from "components/Icon";

const UseCheckOptions = ({
    dataOptions,
    setDataOptions,
    name,
    oneSelected,
    onChange,
    categoryCheck,
    searching,
    fixed,
    editOptions,
    noMaxHeight,
    parentSectionName,
    adjustContainerOption,
    useOptionID = true,
}) => {
    const { t } = useTranslation();
    let lastOptCategory;
    const RESULTS_INCREMENT = 10;
    const [resultsSize, setResultsSize] = useState(50);
    const [actionsComponent, setActionsComponent] = useState({ visible: false, option: null, editing: false });
    //Actions
    const dispatch = useDispatch();

    useLayoutEffect(() => {}, []);

    const changeSelection = (e) => {
        const temCopy = dataOptions;

        temCopy.map((item) =>
            String(item.id) === String(e.currentTarget.dataset.id)
                ? (item.selected = oneSelected && item.selected === true ? item.selected : !item.selected)
                : oneSelected
                ? (item.selected = false)
                : null
        );
        setDataOptions(temCopy);

        const selectedIds = temCopy.map((item) => (item.selected ? item.id : null));
        dispatch(
            changeActionValues({
                [name]: selectedIds.filter((item) => item !== null),
            })
        );

        if (onChange) {
            onChange(e.currentTarget.dataset.id);
        }
    };

    const selectCategory = (categoryName, catChecked) => {
        let temCopy = [];
        if (catChecked) {
            temCopy = dataOptions.map((item) =>
                item.category === categoryName ? { ...item, selected: false } : { ...item }
            );
        } else {
            temCopy = dataOptions.map((item) =>
                item.category === categoryName && !item.disabled ? { ...item, selected: true } : { ...item }
            );
        }

        setDataOptions(temCopy);
        const selectedIds = temCopy.map((item) => (item.selected ? item.id : null));
        dispatch(
            changeActionValues({
                [name]: selectedIds.filter((item) => item !== null),
            })
        );

        if (onChange) {
            onChange(categoryName);
        }
    };

    const addOptGroup = (option, index) => {
        let response = [];
        if (option.category) {
            const catOptions = dataOptions.filter(
                (dataOption) => dataOption.category === option.category && !dataOption.disabled
            );
            const catOptionsSelected = dataOptions.filter(
                (dataOption) => dataOption.category === option.category && dataOption.selected
            );
            //check if all options of the category are checked
            const catChecked = catOptions.length === catOptionsSelected.length;

            if (option.category !== lastOptCategory) {
                response.push(
                    <div key={index} className={index === 0 ? "" : "border-t-2 border-gray-200 pt-4"}>
                        <div className="block relative mb-4">
                            <p className="font-bold text-gray-900 first-capital mb-4">
                                {categoryCheck ? (
                                    <input
                                        id={`group-${option.category}`}
                                        type="checkbox"
                                        data-category={option.category}
                                        className={`absolute top-0 left-0 w-5 h-5 checkbox cursor-pointer z-50 `}
                                        onChange={(e) => selectCategory(option.category, catChecked)}
                                        checked={catChecked}
                                    ></input>
                                ) : null}
                                <span
                                    id={`group-${option.category.replaceAll(" ", "-")}-span`}
                                    className={`flex items-center space-x-2 ${
                                        categoryCheck ? "ml-8 cursor-pointer" : "cursor-default"
                                    }`}
                                    onClick={
                                        categoryCheck ? (e) => selectCategory(option.category, catChecked) : () => {}
                                    }
                                >
                                    <div>{option.category}</div>
                                    {option.categoryWarning ? (
                                        <Icon type="warning" size="2xl" tooltip={option.categoryWarning} />
                                    ) : null}
                                    {option.categoryInfo ? (
                                        <Icon type="info" size="2xl" tooltip={option.categoryInfo} />
                                    ) : null}
                                </span>
                            </p>
                        </div>
                    </div>
                );
            }
            lastOptCategory = option.category;
        }
        return response;
    };
    const scroll = () => {
        const increment = resultsSize + RESULTS_INCREMENT;
        setResultsSize(increment < dataOptions.length ? increment : dataOptions.length);
    };

    const handleEditName = (e) => {
        let actionsComponentClone = { ...actionsComponent };
        let dataOptionsClone = [...dataOptions];
        if (actionsComponentClone.option.name === actionsComponentClone.option.id) {
            actionsComponentClone.option.id = e.target.value;
        }
        actionsComponentClone.option.name = e.target.value;
        dataOptionsClone.filter((dataOptionClone) => dataOptionClone.id === actionsComponentClone.option.id)[0] =
            actionsComponentClone.option;
        setActionsComponent(actionsComponentClone);
        setDataOptions(dataOptionsClone);
    };

    const onImageError = (option, e, conteinerErrorIconId) => {
        if (option.iconError) {
            e.target.classList.add("hidden");
            if (conteinerErrorIconId) {
                const conteinerErrorIcon = document.querySelector(`#${conteinerErrorIconId}`);
                if (conteinerErrorIcon) {
                    conteinerErrorIcon.classList.remove("hidden");
                    conteinerErrorIcon.innerHTML = `<span class="text-xl text-gray-500 icon ${option.iconError}"></span>`;
                }
            }
        } else {
            e.target.src = option.imgError ? option.imgError : image_error;
        }
    };

    return (
        <div
            className={`${fixed ? "fixed w-104" : ""}  bg-white rounded shadow-lg border border-gray-200 ${
                !noMaxHeight ? `max-h-18 overflow-y-auto overflow-x-hidden` : ``
            } p-2`}
            onScroll={() => scroll()}
        >
            {dataOptions
                ? dataOptions.map((option, index) =>
                      option.visible && (index < resultsSize || searching) ? (
                          <div key={index}>
                              {addOptGroup(option, index)}
                              <div key={index} className="w-full h-auto">
                                  <div className="block relative">
                                      {!actionsComponent.editing && editOptions ? (
                                          <div className="absolute right-0">
                                              <span
                                                  id={`${parentSectionName}-item-${index}-actions`}
                                                  className="cursor-pointer icon icon-row-actions mt-1"
                                                  onClick={() =>
                                                      setActionsComponent({
                                                          option: option,
                                                          visible: !actionsComponent.visible,
                                                      })
                                                  }
                                              ></span>
                                              {actionsComponent &&
                                              actionsComponent.visible &&
                                              actionsComponent.option.id === option.id ? (
                                                  <div
                                                      id={`actions-container-${name}`}
                                                      className="t-actions-container"
                                                      onClick={() =>
                                                          setActionsComponent({ ...actionsComponent, visible: false })
                                                      }
                                                      style={{ right: "0px", left: "auto", width: "max-content" }}
                                                  >
                                                      <div className="t-actions-background">
                                                          <div id="action">
                                                              <span
                                                                  id={`${parentSectionName}-item-${index}-action-edit-name`}
                                                                  className="t-action-container first-capital"
                                                                  onClick={(e) => {
                                                                      e.stopPropagation();
                                                                      setActionsComponent({
                                                                          ...actionsComponent,
                                                                          editing: true,
                                                                      });
                                                                  }}
                                                              >
                                                                  {t("edit-name")}
                                                              </span>
                                                          </div>
                                                      </div>
                                                  </div>
                                              ) : null}
                                          </div>
                                      ) : null}
                                      <div
                                          tabIndex={0}
                                          className={`pb-4 truncate ${
                                              !actionsComponent.editing && editOptions ? `mr-2` : ``
                                          } ${!oneSelected ? "ml-8" : "ml-2"} first-capital  ${
                                              option.disabled
                                                  ? "text-gray-600"
                                                  : "hover:text-zafiro-400  cursor-pointer"
                                          } flex ${
                                              adjustContainerOption
                                                  ? adjustContainerOption
                                                  : "items-center justify-start"
                                          }  `}
                                          data-id={option.id}
                                          id={
                                              option?.id && useOptionID
                                                  ? `check-option-${option.id}`
                                                  : `check-option-${index}`
                                          }
                                          onKeyPress={
                                              actionsComponent.editing || option.disabled
                                                  ? () => {}
                                                  : (e) => (e.key === "Enter" ? changeSelection(e) : null)
                                          }
                                          onClick={
                                              actionsComponent.editing || option.disabled
                                                  ? () => {}
                                                  : (e) => changeSelection(e)
                                          }
                                      >
                                          {actionsComponent.editing && actionsComponent.option.id === option.id ? (
                                              <input
                                                  className="w-full bg-gray-200 rounded pl-3 p-2"
                                                  value={actionsComponent.option.name}
                                                  placeholder={t("edit-name")}
                                                  data-id={option.id}
                                                  onChange={(e) => handleEditName(e)}
                                                  onKeyPress={(e) => (e.key === "Enter" ? changeSelection(e) : null)}
                                                  onBlur={(e) => {
                                                      changeSelection(e);
                                                  }}
                                              />
                                          ) : (
                                              <>
                                                  {option.imgSrc && (
                                                      <>
                                                          <img
                                                              alt=""
                                                              className={`${
                                                                  option.imgAdjust
                                                                      ? option.imgAdjust
                                                                      : "border border-gray-300 bg-gray-200 w-14 h-10 rounded mr-4"
                                                              }`}
                                                              src={option.imgSrc}
                                                              onError={(e) => {
                                                                  onImageError(
                                                                      option,
                                                                      e,
                                                                      `icon_error_image_${index}_${
                                                                          option.id ? option.id : option.name
                                                                      }`
                                                                  );
                                                              }}
                                                          />
                                                          <div
                                                              className="hidden border border-gray-300 bg-gray-200 w-14 h-10 rounded mr-4 flex justify-center items-center"
                                                              id={`icon_error_image_${index}_${
                                                                  option.id ? option.id : option.name
                                                              }`}
                                                          ></div>
                                                      </>
                                                  )}
                                                  {option.iconName && (
                                                      <span
                                                          className={`icon icon-${option.iconName} text-2xl mr-1`}
                                                      ></span>
                                                  )}
                                                  {Parser(option.name)}{" "}
                                                  {option.number ? (
                                                      <span className=" text-sm text-gray-800">
                                                          {" "}
                                                          &nbsp; {option.number}
                                                      </span>
                                                  ) : null}
                                                  {option.description ? (
                                                      <div className="pt-3 text-base text-gray-500 whitespace-pre-wrap">
                                                          {option.description}
                                                      </div>
                                                  ) : null}
                                              </>
                                          )}
                                      </div>
                                      {!oneSelected ? (
                                          <>
                                              <input
                                                  id={`option-${index}`}
                                                  type="checkbox"
                                                  data-id={option.id}
                                                  className={`absolute top-0 left-0 w-5 h-5 checkbox z-50 ${
                                                      option.disabled ? "cursor-default" : "cursor-pointer"
                                                  }`}
                                                  onChange={option.disabled ? () => {} : (e) => changeSelection(e)}
                                                  checked={option.selected}
                                                  disabled={option.disabled}
                                              ></input>
                                              <span
                                                  id={`checkbox-background-${index}`}
                                                  className={`absolute top-0 left-0 w-5 h-5 bg-gray-200 hover:bg-gray-300 `}
                                              ></span>
                                          </>
                                      ) : null}
                                  </div>
                              </div>
                          </div>
                      ) : null
                  )
                : null}
            {searching &&
            dataOptions &&
            dataOptions.length > 0 &&
            dataOptions.filter((option) => !option.visible).length === dataOptions.length ? (
                <div className="first-capital" id="no-result-message">
                    {t("no-results-found")}
                </div>
            ) : null}
        </div>
    );
};

export default UseCheckOptions;
