import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { gql } from "apollo-boost";

import useLocalState from "hooks/useLocalState";
import { Session } from "hooks/Utils/Session";
import { safeJsonParse, safeJsonStringify } from "hooks/Utils/Utils";
import { APPEARANCE_DEFAULT } from "hooks/Utils/AppearancesUtils";
import { TRANSLATE_SCREEN_TYPE_TITLE, getLandingContentQuery } from "hooks/Utils/Design/EditorUtils";
import { getGQLClient } from "hooks/useQuery";
import { SCREEN, DEVICE, getWidgets } from "constants/editor";

import { Header, HEADER_DESIGN } from "sections/playground";
import { ScreenEditor, EDITING_MENU } from "components/Editor";
import Icon from "components/Icon";
import Switch from "components/Switch";
import Select from "components/Select";
import Button from "components/Button";
import Loading from "components/Loading";

const Editor = () => {
    const editorRef = useRef(null);

    const { t } = useTranslation();

    const lang = "en";

    const availableScreens = [
        {
            id: "new",
            name: "Free design",
        },
        {
            id: "testing-3873",
            name: "New home screen",
            design: "Juane Dev",
            project: "DC Zaragoza",
            environment: "Testing",
            conn: {
                id: 3873,
                design: 937,
                token: TOKEN_TESTING_DCZARAGOZA,
            },
        },
        {
            id: "testing-7551",
            name: "Portal",
            design: "juan.decristofaro",
            project: "NH Barcelona",
            environment: "Testing",
            conn: {
                id: 7551,
                design: 1684,
                token: TOKEN_TESTING_NHBARCELONA,
            },
        },
        {
            id: "testing-535",
            name: "Screen",
            design: "Apariencias test",
            project: "NH Barcelona",
            environment: "Testing",
            conn: {
                id: 535,
                design: 627,
                token: TOKEN_TESTING_NHBARCELONA,
            },
        },
        {
            id: "testing-3947",
            name: "OKU Ibiza",
            design: "Import design from 3.8.3",
            project: "NH Barcelona",
            environment: "Testing",
            conn: {
                id: 3947,
                design: 953,
                token: TOKEN_TESTING_NHBARCELONA,
            },
        },
        {
            id: "beta-4637",
            name: "Urban Paradise TV",
            design: "Demo Paradise",
            project: "ES Bangkok",
            environment: "Beta",
            customHost: "https://beta.zafiro.link",
            conn: {
                id: 4637,
                design: 1421,
                token: TOKEN_BETA_URBAN,
                url: "https://beta.zafiro.link/manager-graphql",
            },
        },
        {
            id: "beta-4756",
            name: "Resort ES Hotels Home",
            design: "Resort ES Hotels",
            project: "ES Bangkok",
            environment: "Beta",
            customHost: "https://beta.zafiro.link",
            conn: {
                id: 4756,
                design: 1429,
                token: TOKEN_BETA_URBAN,
                url: "https://beta.zafiro.link/manager-graphql",
            },
        },
        {
            id: "beta-4696",
            name: "Home",
            design: "Urban Paradise",
            project: "ES Bangkok",
            environment: "Beta",
            customHost: "https://beta.zafiro.link",
            conn: {
                id: 4696,
                design: 1428,
                token: TOKEN_BETA_URBAN,
                url: "https://beta.zafiro.link/manager-graphql",
            },
        },
    ];

    const groupedScreens = availableScreens?.reduce((acc, screen) => {
        const scr = {
            value: screen?.id,
            label:
                screen?.id === "new" ? (
                    <Icon type="appearances" text={screen?.name} alt={screen?.name} />
                ) : (
                    <div>
                        <Icon
                            type="content"
                            size={1.2}
                            text={
                                <div className="leading-none">
                                    {screen?.name}
                                    {screen?.design ? (
                                        <div className="font-normal text-xs text-gray-800 py-1">{screen?.design}</div>
                                    ) : null}
                                </div>
                            }
                        />
                    </div>
                ),
            selectedLabel: (
                <div className="leading-none">
                    {screen?.name}
                    {screen?.project ? <div className="text-gray-700 text-xs">{screen.project}</div> : null}
                </div>
            ),
        };
        if (!screen?.project) {
            acc.push(scr);
        } else {
            const groupID = (screen?.environment + "-" + screen?.project).replace(/\s/g, "_");
            const group = acc.find((group) => group?.id === groupID);
            if (!group) {
                acc.push({
                    id: groupID,
                    label: (
                        <div className="text-gray-900 border-b border-t border-gray-300 py-2 leading-none flex justify-between items-start">
                            {screen?.project}
                            <div className="font-normal text-gray-600 text-xs">{screen?.environment}</div>
                        </div>
                    ),
                    options: [scr],
                });
            } else {
                group.options.push(scr);
            }
        }
        return acc;
    }, []);

    const [editorConfig, setEditorConfig] = useLocalState("editor-config", {
        theme: "default",
        screen: "new",
        randomBackground: null,
        screenType: null,
        deviceType: null,
        showGrid: true,
    });

    const [screenData, setScreenData] = useState(null);

    const randomBackground = editorConfig?.randomBackground;
    const setRandomBackground = (randomBackground) => {
        setEditorConfig((config) => ({ ...config, randomBackground }));
    };
    const selectedScreen = availableScreens?.find((screen) => screen?.id === editorConfig?.screen);
    const DAS_URL = selectedScreen?.customHost ? selectedScreen?.customHost + "/das" : Session.getDasUrl();
    const setScreen = (id) => {
        setEditorConfig((config) => ({ ...config, screen: id }));
    };
    const appearance = THEME_OPTIONS?.find((theme) => theme?.id === editorConfig?.theme)?.data;
    const setAppearance = (id) => {
        setEditorConfig((config) => ({ ...config, theme: id }));
    };

    const [hash, setHash] = useState(null);
    const [layout, setLayout] = useState(null);
    const [config, setConfig] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);

    const [droppingItem, setDroppingItem] = useState(null);
    const [editorReady, setEditorReady] = useState(false);
    const [editing, setEditing] = useState(null);

    const theme = {
        name: appearance?.name,
        themeReady: true,
        default: {
            rgbaBG: appearance?.colors?.defaultBackground,
            rgbaFG: appearance?.colors?.defaultForeground,
        },
        focus: {
            rgbaBG: appearance?.colors?.focusBackground,
            rgbaFG: appearance?.colors?.focusForeground,
        },
        active: {
            rgbaBG: appearance?.colors?.activeBackground,
            rgbaFG: appearance?.colors?.activeForeground,
        },
        focusBorderSize: appearance?.colors?.focusBorderSize,
        headerStyle: {
            font: appearance?.fontStyles?.heading?.name,
            size: appearance?.fontStyles?.heading?.size,
            textAlign: appearance?.fontStyles?.heading?.alignment,
            bold: appearance?.fontStyles?.heading?.bold,
            italic: appearance?.fontStyles?.heading?.italic,
            underline: appearance?.fontStyles?.heading?.underline,
        },
        paragraphStyle: {
            font: appearance?.fontStyles?.paragraph?.name,
            size: appearance?.fontStyles?.paragraph?.size,
            textAlign: appearance?.fontStyles?.paragraph?.alignment,
            bold: appearance?.fontStyles?.paragraph?.bold,
            italic: appearance?.fontStyles?.paragraph?.italic,
            underline: appearance?.fontStyles?.paragraph,
        },
        logos: null,
        logoSelected: null,
        logoSelectedId: null,
    };

    const makeDeviceOption = (value) => {
        const keyName = Object.keys(DEVICE.TYPE).find((key) => DEVICE.TYPE[key] === value);
        const name = DEVICE.TYPE[keyName];
        return {
            value: value,
            label: name,
        };
    };

    const getDeviceOptions = (screenType) => {
        let types = [];
        switch (screenType) {
            case SCREEN.TYPE.GENERAL:
                types = [DEVICE.TYPE.TV, DEVICE.TYPE.MOBILE];
                break;
            case SCREEN.TYPE.TV:
            case SCREEN.TYPE.WELCOME:
            case SCREEN.TYPE.CAST:
            case SCREEN.TYPE.CCINSTRUCTION:
            case SCREEN.TYPE.EXPRESSCHECKOUT:
                types = [DEVICE.TYPE.TV];
                break;
            case SCREEN.TYPE.MOBILE:
                types = [DEVICE.TYPE.MOBILE];
                break;
            case SCREEN.TYPE.CCPAIRINGSUCCESSFUL:
            case SCREEN.TYPE.PORTAL:
                types = [DEVICE.TYPE.DESKTOP, DEVICE.TYPE.MOBILE];
                break;
            default:
        }
        return types?.map(makeDeviceOption);
    };

    const makeScreenOption = (value) => {
        const keyName = Object.keys(SCREEN.TYPE).find((key) => SCREEN.TYPE[key] === value);
        const name = t(TRANSLATE_SCREEN_TYPE_TITLE[keyName]);
        const isHome = [SCREEN.TYPE.TV, SCREEN.TYPE.MOBILE].includes(value);
        return {
            value: value,
            label: isHome ? `${name} (${value})` : name,
        };
    };

    const typeOptions = Object.values(SCREEN.TYPE)
        ?.filter((type) => ![SCREEN.TYPE.SALES, SCREEN.TYPE.SALESMOBILE, SCREEN.TYPE.WELCOME].includes(type))
        ?.map(makeScreenOption);

    const screenID = screenData?.id;
    const isNew = screenID === "new";
    const screenType = isNew ? editorConfig?.screenType : screenData?.type;
    const deviceType = isNew ? editorConfig?.deviceType : screenData?.content?.devices?.[0]?.type;
    const deviceOptions = screenType ? getDeviceOptions(screenType) : null;
    const availableWidgets = getWidgets({ deviceType, screenType, tvmenu: editing === EDITING_MENU });
    const storageKey = `editor-widgets-${screenID === "new" ? `${screenID}-${screenType}` : screenID}`;
    const storageKeyConfig = `${storageKey}-config`;
    const draftStorage = screenID ? safeJsonParse(localStorage.getItem(storageKey)) : null;
    const draftStorageConfig = screenID ? safeJsonParse(localStorage.getItem(storageKeyConfig)) : null;

    useEffect(() => {
        setScreenData(null);
        if (selectedScreen?.id === "new") {
            setScreenData({ id: selectedScreen?.id, version: 2 });
        } else {
            const id = selectedScreen?.conn?.id;
            const design = selectedScreen?.conn?.design;
            const token = selectedScreen?.conn?.token;
            const url = selectedScreen?.conn?.url;
            getLanding(lang, id, design, token, url, setScreenData);
        }
    }, [selectedScreen?.id]);

    useEffect(() => {
        let elements = [];
        if (appearance?.fontStyles) {
            Object.keys(appearance?.fontStyles).forEach((key) => {
                const style = appearance?.fontStyles?.[key];
                if (style?.font?.externalUrl) {
                    const link = document.createElement("link");
                    link.rel = "stylesheet";
                    link.href = style?.font?.externalUrl;
                    elements.push(link);
                }
                //TODO load font assets
            });
        }
        elements.forEach((element) => {
            document.head.appendChild(element);
        });
        return () => {
            elements.forEach((element) => {
                document.head.removeChild(element);
            });
        };
    }, [appearance]);

    return (
        <div className="h-full grid bg-white" style={{ gridTemplateRows: "auto auto 1fr" }}>
            <Header title="Content Editor" design={HEADER_DESIGN.COMPACT} />
            <div className="p-5 flex items-center justify-between">
                <div className="flex items-center space-x-5 whitespace-no-wrap">
                    <div className="flex items-center space-x-2">
                        <div>Theme:</div>
                        <Select
                            id="content-theme"
                            value={editorConfig?.theme}
                            options={THEME_OPTIONS?.map((theme) => ({
                                value: theme?.id,
                                label: theme?.name,
                            }))}
                            onChange={setAppearance}
                            required={true}
                        />
                    </div>
                    <div className="flex items-center space-x-2">
                        <div>Screen:</div>
                        <Select
                            id="content-screen"
                            value={editorConfig?.screen}
                            options={groupedScreens}
                            onChange={setScreen}
                            required={true}
                        />
                    </div>
                    {screenType || isNew ? (
                        <div className="flex items-center space-x-2">
                            <div>Type:</div>
                            {screenType && !isNew ? (
                                <div className="font-bold">{t(TRANSLATE_SCREEN_TYPE_TITLE[screenType])}</div>
                            ) : (
                                <Select
                                    id="screen-type"
                                    value={screenType}
                                    options={typeOptions}
                                    onChange={(screen) => {
                                        if (isNew) {
                                            setEditorConfig((config) => ({
                                                ...config,
                                                screenType: screen,
                                                deviceType: getDeviceOptions(screen)?.[0]?.value,
                                            }));
                                        }
                                    }}
                                    required={true}
                                />
                            )}
                        </div>
                    ) : null}
                    {screenType ? (
                        <div className="flex items-center space-x-2">
                            <div>Device:</div>
                            {!isNew && deviceType && deviceOptions?.length <= 1 ? (
                                <div className="font-bold">{deviceType}</div>
                            ) : (
                                <Select
                                    id="device-type"
                                    value={deviceType}
                                    options={deviceOptions}
                                    onChange={(device) => {
                                        if (isNew) {
                                            setEditorConfig((config) => ({
                                                ...config,
                                                deviceType: device,
                                            }));
                                        }
                                    }}
                                    required={true}
                                />
                            )}
                        </div>
                    ) : null}

                    {deviceType ? (
                        <Switch
                            label="Random background"
                            labelPosition="left"
                            checked={!!randomBackground}
                            onChange={({ checked }) =>
                                setRandomBackground(checked ? `${BG_IMAGE_TEST}?random=${Math.random()}` : null)
                            }
                        />
                    ) : null}

                    {deviceType && (hasChanges || screenID !== "new") ? (
                        <>
                            <Button
                                id="save-changes"
                                design="blue-outline"
                                disabled={!hasChanges}
                                onClick={() => {
                                    localStorage.setItem(storageKey, safeJsonStringify(layout || []));
                                    localStorage.setItem(storageKeyConfig, safeJsonStringify(config || []));
                                    setHasChanges(false);
                                }}
                            >
                                Save changes
                            </Button>
                            {screenID !== "new" ? (
                                <Button
                                    id="clear-storage"
                                    design="link"
                                    onClick={() => {
                                        localStorage.removeItem(storageKey);
                                        localStorage.removeItem(storageKeyConfig);
                                        document.location.reload();
                                    }}
                                >
                                    Clear all changes
                                </Button>
                            ) : null}
                        </>
                    ) : null}
                </div>
            </div>
            <div className="flex">
                {deviceType && !editorReady ? (
                    <Loading adjust="absolute" />
                ) : (
                    <div className="px-5 w-2/12">
                        <div className="grid grid-cols-2 gap-2">
                            {availableWidgets?.length
                                ? availableWidgets.map((widget) => {
                                      const item = {
                                          type: widget?.type,
                                          actions: widget?.actions,
                                          data: widget?.data,
                                          style: widget?.style,
                                          layout: widget?.layout?.[deviceType],
                                      };
                                      return (
                                          <div
                                              key={widget?.type}
                                              className="border p-3 flex flex-col justify-center items-center space-y-1 bg-white cursor-move overflow-hidden"
                                              draggable={true}
                                              onDragStartCapture={(e) => {
                                                  setDroppingItem(item);
                                                  if (e?.dataTransfer) {
                                                      e.dataTransfer.setDragImage(e.target, 0, 0);
                                                      e.dataTransfer.setData("text/plain", "");
                                                  }
                                              }}
                                              onDragEndCapture={() => {
                                                  setDroppingItem(null);
                                              }}
                                          >
                                              <Icon type={widget?.icon} size="3xl" />
                                              <div className="text-xs truncate">{t(widget?.name)}</div>
                                          </div>
                                      );
                                  })
                                : null}
                        </div>
                    </div>
                )}
                {screenType && deviceType ? (
                    <div
                        className={classNames({
                            "bg-gray-200": true,
                            "w-full": !editorReady,
                            "w-10/12": editorReady,
                        })}
                    >
                        <ScreenEditor
                            ref={editorRef}
                            key={`${storageKey}-${editorConfig?.theme}`}
                            readOnly={false}
                            showGrid={editorConfig?.showGrid}
                            screenData={screenData}
                            screenType={screenType}
                            deviceType={deviceType}
                            customDAS={DAS_URL}
                            backgroundImage={randomBackground}
                            widgets={draftStorage}
                            config={draftStorageConfig}
                            lang={lang}
                            theme={theme}
                            droppingItem={droppingItem}
                            onReadyChange={(ready) => setEditorReady(ready)}
                            onEditingChange={(editing) => {
                                setDroppingItem(null);
                                setEditing(editing);
                            }}
                            onShowGridChange={(showGrid) => setEditorConfig((config) => ({ ...config, showGrid }))}
                            onChange={({ widgets: layout, config, hash: newHash }) => {
                                console.log("TEST onChange");
                                const changed = hash !== newHash;
                                setHasChanges(changed);
                                if (changed) {
                                    console.log("TEST CHANGED");
                                    setLayout(layout);
                                    setConfig(config);
                                    setHash(newHash);
                                }
                            }}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

const getLanding = (lang, id, design, token, url, onSuccess) => {
    const query = getLandingContentQuery({ landingid: id, designID: design, lang });
    const client = getGQLClient({
        token: token ? `Bearer ${token}` : undefined,
        serviceURL: url,
    });
    if (client) {
        client
            .query({ query: gql`{${query}}` })
            .then((response) => {
                if (onSuccess) {
                    onSuccess(response?.data?.content?.results?.[0]);
                }
            })
            .catch((err) => {
                toast.error(err?.message || err || "Error loading landing content");
            });
    }
};

const BG_IMAGE_TEST = "https://picsum.photos/1280/720";

const TOKEN_TESTING_NHBARCELONA =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJTZXJ2aWNlTmFtZSI6InNydi1tYW5hZ2VyIiwiVXNlciI6eyJUeXBlIjoiem1zIiwiUmVmIjoidXNlci1hNTIwNWIwZS1mOTFmLTQ4YmItYTQ3ZS1iODk5ZDUxMzVjYWEiLCJMYW5nUmVmIjoiZXMifSwiTG9jYXRpb24iOnsiQ2hhaW5SZWYiOiJjaGFpbi0wMDAwLTAwMDAtMDAwMC0wMDAxIiwiUHJvamVjdFJlZiI6InByb2plY3QtMDAwMC0wMDAwLTAwMDAtMDAwMSJ9LCJpYXQiOjE3MzI3OTE1NjR9.9dCA3sTaTCNXFl_IO48wtwvyHNSXPg3v3PGr3ZJZdmw";

const TOKEN_TESTING_DCZARAGOZA =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXJ2aWNlTmFtZSI6InNydi1tYW5hZ2VyIiwidXNlciI6eyJ0eXBlIjoiem1zIiwicmVmIjoidXNlci1hNTIwNWIwZS1mOTFmLTQ4YmItYTQ3ZS1iODk5ZDUxMzVjYWEiLCJsYW5nUmVmIjoiZXMifSwibG9jYXRpb24iOnsiY2hhaW5SZWYiOiJjaGFpbi0wMDAwLTAwMDAtMDAwMC0wMDAxIiwicHJvamVjdFJlZiI6InByb2plY3QtMDAwMC0wMDAwLTAwMDAtMDAwNyJ9fQ.KFFXe61n5vWPZFTO2QAsyHiKC3KtEyXxo3GM0uD1z3w";

const TOKEN_BETA_URBAN =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJTZXJ2aWNlTmFtZSI6InNydi1tYW5hZ2VyIiwiVXNlciI6eyJUeXBlIjoiem1zIiwiUmVmIjoic3VwZXJ1c2VyLWQwZjQxMDEzLTdmMjItNGE4NS1hOTAwLWFlNjhjYTZhMzAzOCIsIkxhbmdSZWYiOiJlcyJ9LCJMb2NhdGlvbiI6eyJDaGFpblJlZiI6ImNoYWluLTAwMDAtMDAwMC0wMDAwLTAwMDkiLCJQcm9qZWN0UmVmIjoicHJvamVjdC0wMDAwLTAwMDAtMDAwMC0wMTQxIn0sImlhdCI6MTczMjY0MjY1N30.JB1ga-qEgkpNEJbTlYdLbNKV18Fo6RF54npKqMgYL2Y";

const MOCKUP_THEMES = {
    data: {
        themes: {
            __typename: "Themes",
            results: [
                {
                    __typename: "resultsTheme",
                    config: {
                        __typename: "themeConfig",
                        colors: {
                            __typename: "Colors",
                            accent: "",
                            accentContrast: "",
                            activeBackground: "rgba(255,255,255,1)",
                            activeForeground: "rgba(0,0,0,1)",
                            defaultBackground: "rgba(255,255,255,0.2)",
                            defaultForeground: "rgba(255,255,255,1)",
                            focusBackground: "rgba(255,255,255,0.6)",
                            focusBorderSize: 5,
                            focusForeground: "rgba(255,255,255,1)",
                            login: "",
                        },
                        fontStyles: {
                            __typename: "fontStyles",
                            heading: {
                                __typename: "fontStyle",
                                alignment: "left",
                                bold: true,
                                italic: false,
                                name: "Proxima Nova (copy)",
                                size: 2.93,
                                underline: false,
                            },
                            paragraph: {
                                __typename: "fontStyle",
                                alignment: "left",
                                bold: false,
                                italic: false,
                                name: "Proxima Nova (copy)",
                                size: 1.9,
                                underline: false,
                            },
                        },
                    },
                    id: 403,
                    name: "Urban Paradise",
                    themeLogos: [
                        {
                            __typename: "themeLogo",
                            id: 248,
                            imageRef:
                                "asset-78fc5b97-3744-4d3c-b82a-5414b6093b1c-636861696e2d303030302d303030302d303030302d30303039",
                            themeLogoCustoms: [],
                            type: "GENERIC",
                            version: "BW_LIGHT_HORIZONTAL",
                        },
                        {
                            __typename: "themeLogo",
                            id: 249,
                            imageRef:
                                "asset-e90d0bf8-5d74-449d-809f-e024ee381aa3-636861696e2d303030302d303030302d303030302d30303039",
                            themeLogoCustoms: [],
                            type: "GENERIC",
                            version: "BW_DARK_HORIZONTAL",
                        },
                    ],
                    type: "CORPORATE",
                },
            ],
        },
    },
};

const THEME_OPTIONS = [
    {
        id: "default",
        name: "Default",
        data: APPEARANCE_DEFAULT,
    },
    {
        id: "dark",
        name: "Dark",
        data: {
            colors: {
                defaultBackground: "#333",
                defaultForeground: "#eee",
                activeBackground: "blue",
                activeForeground: "pink",
                focusBackground: "lime",
                focusForeground: "purple",
                focusBorderSize: 10,
                accent: "orange",
                accentContrast: "cyan",
                login: "olive",
            },
            fontStyles: {
                heading: {
                    name: "Mystery Quest",
                    size: 2,
                    bold: false,
                    italic: false,
                    underline: true,
                    alignment: "left",
                    font: {
                        externalUrl: "https://fonts.googleapis.com/css2?family=Mystery+Quest&display=swap",
                    },
                },
                paragraph: {
                    name: "Kode Mono",
                    size: 1.2,
                    bold: false,
                    italic: false,
                    underline: true,
                    alignment: "left",
                    font: {
                        externalUrl:
                            "https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&family=Mystery+Quest&display=swap",
                    },
                },
            },
        },
    },
    {
        id: "vintage",
        name: "Vintage",
        data: {
            colors: {
                defaultBackground: "#cda",
                defaultForeground: "#943",
                activeBackground: "#deb",
                activeForeground: "#a54",
                focusBackground: "#9a6",
                focusForeground: "#eee",
                focusBorderSize: 2,
                accent: "orange",
                accentContrast: "cyan",
                login: "olive",
            },
            fontStyles: {
                heading: {
                    name: "Ballet",
                    size: 2.5,
                    bold: false,
                    italic: false,
                    underline: true,
                    alignment: "left",
                    font: {
                        externalUrl:
                            "https://fonts.googleapis.com/css2?family=Ballet:opsz@16..72&family=Kode+Mono:wght@400..700&family=Mystery+Quest&family=UnifrakturMaguntia&display=swap",
                    },
                },
                paragraph: {
                    name: "UnifrakturMaguntia",
                    size: 1.5,
                    bold: false,
                    italic: false,
                    underline: true,
                    alignment: "left",
                    font: {
                        externalUrl:
                            "https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&family=Mystery+Quest&family=UnifrakturMaguntia&display=swap",
                    },
                },
            },
        },
    },
    {
        id: "custom",
        name: "Mockup theme",
        data: MOCKUP_THEMES.data?.themes?.results?.[0]?.config,
    },
];

export default Editor;
