import React, { useState, useEffect } from "react";
//Components
import UseIcon from "./useIcon";
import { useNavigate as useHistory } from "react-router-dom";

//Utils
import ReactTooltip from "components/ReactTooltip";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "hooks/Utils/Utils";

const MapItem = ({ data, active }) => {
    const [showToolTip, setShowTooltip] = useState(false);
    const { t } = useTranslation();

    //Data
    const { name, number, isRoom, status, warnings, isCommonZone } = data;
    const history = useHistory();
    //Styles
    const iconStatus = {
        name: "guest",
        color: "link",
        itemType: "map",
    };

    useEffect(() => {
        const nameItem = document.getElementById(`name-map-item-${name.replace(/\s/g, "")}`);
        if (nameItem) {
            setShowTooltip(nameItem.offsetWidth < nameItem.scrollWidth);
        }
        // eslint-disable-next-line
    }, []);

    const redirectToRoomDetail = () => {
        if (!Object.keys(data).includes("floors") && !Object.keys(data).includes("rooms")) {
            if (isRoom) {
                history(`/hotel/rooms/room/detail/${data.id}`);
            } else {
                history(`/hotel/zones/zone/detail/${data.id}`);
            }
        }
    };

    const isWarning = (warning) => {
        if (warning.level < 2) {
            return true;
        }

        return false;
    };

    const isRoomOrCommonZone = isRoom || isCommonZone;

    return (
        <div
            data-tip
            data-for={`${name}_complete_tooltip`}
            onClick={redirectToRoomDetail}
            className={`map-item-container table map-item-${active ? "active" : ""} ${
                isRoomOrCommonZone ? "py-2" : ""
            }`}
        >
            <div
                className={`cursor block overflow-hidden whitespace-no-wrap overflow-ellipsis ${
                    isRoomOrCommonZone ? "w-7/12 leading-4" : "w-11/12"
                }`}
            >
                <a href="#" onClick={(e) => e.preventDefault()}>
                    <div id={`name-map-item-${name.replace(/\s/g, "")}`} className="truncate">
                        {name}
                    </div>

                    {isRoomOrCommonZone ? (
                        <div
                            id={`name-map-item-${name.replace(/\s/g, "")}`}
                            className="text-sm inline-block w-full truncate"
                        >
                            {number}
                        </div>
                    ) : null}
                </a>
                {showToolTip === true ? (
                    <ReactTooltip
                        id={`${name}_complete_tooltip`}
                        delayShow={0}
                        type={"light"}
                        borderColor={"#D3DAE1"}
                        border={true}
                        multiline={true}
                    >
                        {name}
                    </ReactTooltip>
                ) : null}
            </div>
            <div className={`field-icon-map ${warnings ? " right-4 " : ""}`}>
                {status ? <UseIcon icon={iconStatus} adjust={`map-item-guest ${warnings ? " pr-1 " : ""}`} /> : null}
                {(warnings && typeof warnings === "boolean") ||
                (warnings && warnings.filter((warn) => warn != null).length > 0) ? (
                    <>
                        <a data-tip data-for={`${name}_tooltip`} href={() => false}>
                            <div className="text-2xl mt-2">
                                <span className={`icon icon-warning ${active ? "" : " text-orange-100"}`}></span>
                                <span
                                    className={`icon icon-warning2 -ml-1em  ${
                                        active ? "text-zafiro-600" : "text-white"
                                    }`}
                                ></span>
                            </div>
                        </a>
                        {typeof warnings != "boolean" ? (
                            <ReactTooltip
                                id={`${name}_tooltip`}
                                type="light"
                                backgroundColor={"#C47373"}
                                arrowColor={"#C47373"}
                            >
                                {warnings.map((warning, index) => {
                                    let warningText = "";
                                    if (warning.info === "low-signal-level") {
                                        warningText = t("low-signal-level_");
                                    } else {
                                        warningText = warning.info;
                                    }

                                    return isWarning(warning) ? (
                                        <span key={index} className="text-white">
                                            {capitalizeFirst(warningText) + " "}
                                        </span>
                                    ) : (
                                        ""
                                    );
                                })}
                            </ReactTooltip>
                        ) : null}
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default MapItem;
