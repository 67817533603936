import React, { useEffect } from "react";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Utils
import { toast } from "react-toastify";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { setServerError, setCustomServerError, setTableResults } from "../../../../actions/tableActions";
import { parseBoolean, POLLINTERVAL_15S } from "../../../Utils/Utils";
import { Session } from "hooks/Utils/Session";
import Warning from "components/Warning";

const NSStatus = (params) => {
    const { langStrings } = useSelector((state) => state.ui);
    const dispatch = useDispatch();
    const GET_NSSTATUS = gql`
        {
            nsStatus {
                results {
                    status
                    lastActivityTime
                }
            }
        }
    `;
    const [executeQuery, { data, loading, error }] = useLazyQuery(GET_NSSTATUS, {
        pollInterval: POLLINTERVAL_15S,
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (!loading && !error && data && data.nsStatus && !data.nsStatus.results.status) {
            if (params && params.clearTableResults) dispatch(setTableResults([]));
            if (params && params.showGenericNotification) toast.error(langStrings["non-response-server"]);
            dispatch(setServerError(true));
            dispatch(
                setCustomServerError({
                    enabled: params && params.customServerError ? params.customServerError : false,
                    lastUpdated: data.nsStatus.results.lastActivityTime,
                })
            );
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        executeQuery();
        return () => {
            dispatch(setServerError(false));
        };
        // eslint-disable-next-line
    }, []);
};
export const networkServerErrorDiv = (customServerError, t, isMonitoring) => {
    const superUser = Session.isSuperUser();

    const lastUpdatedTimeDate =
        customServerError && customServerError.lastUpdated ? new window.ZDate(customServerError.lastUpdated) : null;
    const lastUpdated = lastUpdatedTimeDate
        ? `${lastUpdatedTimeDate.toLocaleString()} ${lastUpdatedTimeDate.getTimezone()}`
        : "";

    return isMonitoring ? (
        <Warning
            id="server-error"
            title={t("server-unresponsive")}
            collapsible={false}
            className="mt-5"
            icon="alert-red"
            design="black"
        />
    ) : (
        <Warning
            id="warning-test-B"
            title={t("server-unresponsive")}
            collapsible={true}
            className="mt-5"
            icon="alert-red"
            design="black"
        >
            {t("server-unresponsive-last-updated").replace("%0", lastUpdated)}
            {superUser ? null : (
                <div className="mt-2 serverErrorExtended">
                    <span className=" ">{t("server-unresponsive-check")}</span> {t("server-unresponsive-contact")}{" "}
                    <a className="underline" href="https://support.zafiro.tv" target="_blank" rel="noopener noreferrer">
                        https://support.zafiro.tv
                    </a>
                </div>
            )}
        </Warning>
    );
};

export default NSStatus;
