import pkg from "../../../package.json";

import React, { createContext, useMemo, useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Session } from "hooks/Utils/Session";
import { setLanguage } from "actions/uiActions";

import { useLoginProject, useLoginCorporate, useLoginZafiroV3, useAdvicedUpdate } from "hooks/Data/useUser";
import useSecrets from "hooks/secrets";

import Loading from "components/Loading";
import { ModalsContainer } from "components/Modal";
import { WARNING_TYPES } from "components/Section/Warning";

import { UserNetworkProvider } from "contexts/NetworkWarnings/UserNetwork";
import { ValidateNetworkProvider } from "contexts/NetworkWarnings/ValidateNetwork";

import { Context as EnvironmentContext } from "contexts/Global/env";
import { useAuthentication } from "contexts/Global/auth";

export const GlobalContext = createContext({
    ...EnvironmentContext,

    token: null,
    tokens: null,
    isLogged: false,
    isImpersonating: false,
    isChainLogged: false,
    isProjectLogged: false,
    isProjectReady: false,

    user: null,
    chain: null,
    project: null,
    projects: null,

    loginUser: () => {},
    logoutUser: () => {},
    logoutChain: () => {},
    logoutProject: () => {},
    loginV3: () => {},

    history: null,
    searchHistory: () => {},

    loading: false,

    setLoading: (loading) => {},

    lang: null, // Manager language (iso-639-1 code)
    setLang: (isoCode) => {},

    openModal: (modal) => {},
    closeModal: () => {},

    warnings: [],
    isVersionUpdated: false,
    onVersionUpdated: (value) => {},
    userAdvicedUpdate: false,
    setReleaseNotesAsRead: (value) => {},

    highlightComponent: false,
});

const GlobalProvider = ({ children, onNewToken }) => {
    const { t, i18n } = useTranslation();

    const location = useLocation();
    const [history, setHistory] = useState([]);

    const defaultLang = i18n?.language?.split("-")[0];

    // Session stored data
    const storedReleaseNotesRead = Session.isLastUpdateRead();

    // Hooks
    const dispatch = useDispatch();

    const { update: markReleaseNotesAsRead } = useAdvicedUpdate();

    const { highlightComponent } = useSecrets({ enabled: EnvironmentContext?.environment !== "production" });

    const {
        token,
        updateLanguage,
        loading: loadingAuth,
        user,
        chain,
        project,
        permissions,
        languages,
        defaultLanguage,
        isZafiroV3,
        paths,
    } = useAuthentication();

    const loginUser = (data, token) => {
        user.login({
            ...data?.user,
            token,
        });
        chain.login({
            ...data?.chain,
            token: data?.chain?.ref ? token : null,
            projects: data?.projects,
        });
    };

    const impersonateUser = (data, token) => {
        user.impersonate({
            ...data?.user,
            token,
        });
        if (data?.chain) {
            chain.login({
                ...data?.chain,
                token,
                projects: data?.projects,
            });
        }
    };

    const stopImpersonation = () => {
        user.stopImpersonation();
        logoutChain();
    };

    const storeLoginProject = (ref, token) => {
        if (!ref) {
            toast.error(t("Project ref is empty"));
            return;
        }
        const data = chain.projects?.find((project) => project.ref === ref);
        if (data) {
            project.login(
                {
                    ...data,
                    oldmanagerurl: data.managerURL,
                    oldmanagerusers: data.managerUsers,
                    timezone: data.timeZone,
                    zmobileurl: data.zMobileURL,
                },
                token
            );
        } else {
            toast.error(t("Project not found"));
        }
    };

    const storeLoginChain = (data, token) => {
        if (data?.user) {
            user.login(
                {
                    ...user?.info,
                    ...data?.user,
                },
                user?.token
            );
        }
        if (chain.login) {
            chain.login({
                ...data?.chain,
                token,
                projects: data?.projects,
            });
        }
    };

    const isLogged = !!token;
    const isImpersonating = !!user.impersonating;
    const isChainLogged = !!chain.token;
    const isProjectLogged = !!project.token;
    const isProjectReady = !!project?.ready;

    // States
    const [areReleaseNotesRead, setAreReleaseNotesRead] = useState(storedReleaseNotesRead);
    const [isVersionUpdated, setIsVersionUpdated] = useState(false);

    const [loading, setLoading] = useState(false);
    const [lang, setLang] = useState(defaultLang);
    const [modals, setModals] = useState([]);

    const { warnings, hash: warningsHash } = useWarnings({ permissions });

    const loginParams = {
        chainID: chain?.info?.id,
        chainRef: chain?.info?.ref,
        lang,
        onSuccess: (data) => {
            if (data?.token) {
                storeLoginProject(data?.projectRef, data?.token);
            }
        },
        onError: (error) => {
            toast.error(error);
        },
    };
    const { login: loginProject } = useLoginProject(loginParams);
    const { login: loginCorporate } = useLoginCorporate(loginParams);
    const { login: loginZafiroV3 } = useLoginZafiroV3({
        lang,
        onSuccess: (data) => {
            if (data?.isEnabled) {
                const v3Config = {
                    url: data.url,
                    users: data.users,
                    tokenCloud: data.token,
                };
                Session.setSessionProp("oldManagerConfig", JSON.stringify(v3Config));
                window.open("/#/ZAFIRO_TV", "_blank").focus();
            }
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    const loginV3 = () => {
        loginZafiroV3({
            url: project?.info?.oldmanagerurl,
            users: project?.info?.oldmanagerusers,
        });
    };

    // Update the release notes status
    const setReleaseNotesAsRead = useCallback(
        (value) => {
            if (!user.info?.superUser && areReleaseNotesRead !== value) {
                setAreReleaseNotesRead(value);
                // Mark user as notified in database only if not impersonating
                if (user.info?.ref && !isImpersonating) {
                    markReleaseNotesAsRead({ ref: user.info?.ref, value });
                }
            }
        },
        [user.info?.superUser, areReleaseNotesRead, user.info?.ref, isImpersonating]
    );

    // Callback to update the version update status and notify the user
    const onVersionUpdated = (value) => {
        setIsVersionUpdated(value);
        if (value) {
            toast.success(t("System update completed"));
            setReleaseNotesAsRead(false);
        }
    };

    const changeLanguage = (isoCode) => {
        if (lang && isoCode) {
            setLang(isoCode);
            // Update the language in the token
            updateLanguage({ variables: { lang: isoCode } });
        }
    };

    const logoutProject = () => {
        project.logout();
    };
    const logoutChain = () => {
        chain.logout();
        logoutProject();
    };
    const logoutUser = () => {
        user.logout();
        logoutChain();
    };
    const closeAllModals = () => setModals([]);
    const contextData = useMemo(
        () => ({
            ...EnvironmentContext,

            highlightComponent,

            permissions,
            languages,
            defaultLanguage,
            paths,
            token,
            tokens: {
                user: user.token,
                chain: chain.token,
                project: project.token,
            },
            isLogged,
            isImpersonating,
            isChainLogged,
            isProjectLogged,
            isProjectReady,
            isZafiroV3,
            user: user.info,
            chain: chain.info,
            project: project.info,
            projects: chain.projects,
            loginUser,
            impersonateUser,
            loginChain: storeLoginChain,
            loginProject: storeLoginProject,
            logoutProject,
            logoutChain,
            logoutUser,
            stopImpersonation,
            loginV3,

            history,
            searchHistory: (search) => {
                if (history?.length) {
                    // Search for the last route in the history and return it if found
                    const lastPath = search?.lastPath;
                    if (lastPath) {
                        return history
                            ?.map((item) => item?.pathname + (item?.search || ""))
                            .reverse()
                            .find((item) => item === lastPath || item?.startsWith(lastPath + "?"));
                    }
                }
                return null;
            },

            loading: loading || loadingAuth,
            setLoading,

            lang,
            setLang: changeLanguage,

            openModal: (modal) => {
                if (modal) {
                    setModals((modals) => [...modals, modal]);
                }
            },
            closeModal: () => {
                setModals((modals) => {
                    if (modals?.length) {
                        return modals.slice(0, modals.length - 1);
                    }
                    return [];
                });
            },
            closeAllModals,
            warnings: warnings?.length ? warnings : null,
            isVersionUpdated,
            onVersionUpdated,
            userAdvicedUpdate: areReleaseNotesRead,
            setReleaseNotesAsRead,
        }),
        [
            history,
            loading,
            lang,
            permissions,
            languages,
            defaultLanguage,
            paths,
            highlightComponent,
            token,
            user.token,
            chain.token,
            project.token,
            isLogged,
            isImpersonating,
            isChainLogged,
            isProjectLogged,
            isProjectReady,
            isVersionUpdated,
            warningsHash,
            areReleaseNotesRead,
            setReleaseNotesAsRead,
            user.info,
            chain.info,
            chain.projects,
            project.info,
        ]
    );

    useEffect(() => {
        if (onNewToken) {
            onNewToken(token);
        }
    }, [token]);

    useEffect(() => {
        if (isProjectLogged && isZafiroV3) {
            // When is old manager, switch to another project
            const nextProject = chain?.projects ? chain.projects?.find((p) => p?.ref != project?.info?.ref) : null;
            if (nextProject) {
                const isCorporate = nextProject?.ref === "CORPORATE";
                if (isCorporate) {
                    loginCorporate({ userRef: user?.info?.ref });
                } else {
                    loginProject({ projectRef: nextProject?.ref, userRef: user?.info?.ref });
                }
            }
            // Login to Zafiro V3 and open the popup
            loginV3();
        }
    }, [isProjectLogged, isZafiroV3]);

    useEffect(() => {
        // Store release notes as read in current session
        Session.setLastUpdateRead(areReleaseNotesRead);
    }, [areReleaseNotesRead]);

    useEffect(() => {
        // This is a temporary fix, while there are still implementations of Session.getLang() to read the language
        //OPTIMIZE Remove this when all implementations are updated
        Session.setLang(lang);

        // This is a temporary fix, while there are still implementation of redux state.ui.lang to read the language
        //OPTIMIZE Remove this when all implementations are updated
        dispatch(setLanguage(lang));

        // Update the language in the i18n instance
        i18n.changeLanguage(lang);
    }, [lang]);

    useEffect(() => {
        if (
            history.length > 1 &&
            location.pathname === history[history.length - 2]?.pathname &&
            location.search === history[history.length - 2]?.search
        ) {
            // Back detected, remove the last location from the history
            setHistory((prevHistory) => prevHistory.slice(0, prevHistory.length - 1));
        } else {
            // Add the current location to the history
            setHistory((prevHistory) => [...prevHistory, location]);
        }
    }, [location]);

    return (
        <GlobalContext.Provider value={contextData}>
            <ValidateNetworkProvider>
                <UserNetworkProvider>
                    {children}
                    {loading ? <Loading adjust="absolute" /> : null}
                    <ModalsContainer
                        modals={modals}
                        onClose={() => {
                            setModals((modals) => {
                                if (modals?.length > 0) {
                                    return modals.slice(0, modals.length - 1);
                                }
                                return [];
                            });
                        }}
                    />
                </UserNetworkProvider>
            </ValidateNetworkProvider>
        </GlobalContext.Provider>
    );
};

const getWarningID = (type) => `warning-${type}-${pkg?.version}`;

const useWarnings = ({ permissions }) => {
    const versionWarnings = pkg?.updateInfo?.display ? [{ type: WARNING_TYPES.UPDATE_INFO.type }] : [];
    const userWarnings = Session.getWarnings() || [];
    const allWarnings = [].concat(userWarnings || [], versionWarnings || []);

    const storedClosedWarnings = Session.getWarningsClosed();
    const storedCollapsedWarnings = Session.getWarningsCollapsed();

    const [closedWarnings, setClosedWarnings] = useState(storedClosedWarnings);
    const [collapsedWarnings, setCollapsedWarnings] = useState(storedCollapsedWarnings);

    // Filter warnings that are not expired and the user has the necessary permissions
    const availableWarnings = allWarnings
        .map((w) => {
            if (w) {
                if (!w?.id) {
                    w.id = getWarningID(w?.type);
                }
                w.collapsed = !!collapsedWarnings?.[w.id];
            }
            return w;
        })
        .filter((w) => {
            if (!w) {
                return false;
            }

            if (WARNING_TYPES[w.type]) {
                if (WARNING_TYPES[w.type].deadlineDate) {
                    // Check if the warning has expired
                    if (new Date() >= WARNING_TYPES[w.type].deadlineDate) {
                        return false;
                    }
                }
                if (WARNING_TYPES[w.type].check) {
                    if (
                        w.type !== WARNING_TYPES.SYSTEM_UPDATE.type &&
                        allWarnings?.some((w) => w.type === WARNING_TYPES.SYSTEM_UPDATE.type)
                    ) {
                        // If the user has the system update warning, don't show other warnings
                        return false;
                    }
                    // Check if the user has the necessary permissions to see the warning
                    if (w?.type && WARNING_TYPES?.[w.type]?.check && !WARNING_TYPES[w.type].check(permissions)) {
                        return false;
                    }
                }
            }

            // If the warning is closed, don't show it
            return w.id && !closedWarnings?.[w.id];
        });
    // Hash the available warnings to avoid unnecessary re-renders
    const availableWarningsHash = JSON.stringify(availableWarnings);

    useEffect(() => {
        const handleStorageChange = (event) => {
            switch (event?.detail?.key) {
                case "WARNINGS_CLOSED":
                    setClosedWarnings(Session.getWarningsClosed());
                    break;
                case "WARNINGS_COLLAPSED":
                    setCollapsedWarnings(Session.getWarningsCollapsed());
                    break;
                default:
            }
        };
        window.addEventListener("storageChange", handleStorageChange);
        return () => {
            window.removeEventListener("storageChange", handleStorageChange);
        };
    }, []);

    return {
        warnings: availableWarnings,
        hash: availableWarningsHash,
    };
};

export default GlobalProvider;
