import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//Components
import UseButton from "../../../../useButton";
import EmptyWidget from "./CommonOptions/EmptyWidget";
import UseRadio from "../../../../useRadio";
//Actions
import { setWidgetToolbarVisible } from "../../../../../actions/designActions";
import { setExecuteQuery } from "../../../../../actions/sectionActions";

//Utils
import { useTranslation } from "react-i18next";
import { Session } from "../../../../../hooks/Utils/Session";
import ReactHlsPlayer from "react-hls-player";
import Toolbar from "./CommonOptions/Toolbar";
import Settings from "./CommonOptions/Settings";
import Widget from "../Widget";
import BrowserMediaLibraryModal from "../../../../../hooks/GraphqlCalls/MediaLibrary/modals/BrowserMediaLibraryModal";
import { EditorContext, WidgetContext } from "../../../../../contexts/editor";
import { changeGlobalAction } from "../../../../../actions/globalActions";
import { setModalContent, openModal } from "../../../../../actions/uiActions";
import { WELCOME_SEQUENCE } from "constants/editor";

const VideoToolbar = ({ deviceSpecific }) => {
    const { deviceType, itemSelected } = useContext(EditorContext);
    const { type, id: widgetID } = useContext(WidgetContext);
    const sectionName = `toolbar`;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { lang } = useParams();

    //Store data
    const { permissions } = useSelector((state) => state.ui);

    const permissionLibraryVideos = permissions?.design?.libraryVideos ? true : false;

    const { gridItems, widgetToolbarVisible, cScreenData } = useSelector((state) => state.design);
    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;

    //States
    const [videoUrl, setVideoUrl] = useState(null);
    const [posterUrl, setPosterUrl] = useState(null);
    const [autoplay, setAutoplay] = useState(false);
    const [repeat, setRepeat] = useState(false);
    const [urlVideoValid, setUrlVideoValid] = useState(false);
    const [firstClick, setFirstClick] = useState(false);
    const [videoInputSelected, setVideoInputSelected] = useState("libraryVideo");
    const [isAlibraryVideo, setIsALibraryVideo] = useState(false);
    useEffect(() => {
        if (widgetToolbarVisible === "video-settings" && widgetID === itemSelected) {
            reloadFields();
        }
        // eslint-disable-next-line
    }, [widgetToolbarVisible]);

    useEffect(() => {
        if (widgetID === itemSelected) {
            reloadFields();
        }
        // eslint-disable-next-line
    }, [gridItems]);

    useEffect(() => {
        if (!permissionLibraryVideos) {
            setVideoInputSelected("externalVideo");
        }
    }, [permissionLibraryVideos]);

    const reloadFields = () => {
        const [gItem] = gridItems.filter(({ i }) => i === widgetID);
        const videoInput = document.querySelector(`#video_input_${widgetID}`);

        const updateVideoInput = (url = "") => {
            if (videoInput) videoInput.value = url;
        };

        if (gItem?.data?.video?.[0]) {
            const { externalUrl, libraryRef } = gItem.data.video[0];

            if (externalUrl) {
                setVideoUrl(externalUrl);
                updateVideoInput(externalUrl);
                setFirstClick(true);
                setUrlVideoValid(true);
                setIsALibraryVideo(false);
                setVideoInputSelected("externalVideo");
            } else if (libraryRef && permissionLibraryVideos) {
                const posterSrc = Session.getDasUrl(`thumb/${libraryRef}-${lang}?w=633&h=336`);
                const videoSrc = Session.getDasUrl(`stream/${libraryRef}-${lang}_hd.m3u8`);

                updateVideoInput();
                setVideoUrl(videoSrc);
                setPosterUrl(posterSrc);
                setIsALibraryVideo(true);
                setFirstClick(false);
                setUrlVideoValid(false);
                setVideoInputSelected("libraryVideo");
            } else {
                setVideoUrl(null);
                setPosterUrl(null);
            }
        }

        if (gItem?.data) {
            setAutoplay(gItem.data.autoplay);
            setRepeat(gItem.data.repeat);
        }
    };

    useEffect(() => {
        if (document.querySelector(`#video_input_${widgetID}`) && isAlibraryVideo === false) {
            document.querySelector(`#video_input_${widgetID}`).value = videoUrl || "";
        }
        // eslint-disable-next-line
    }, [videoUrl]);

    //Actions

    const updateVideoUrl = () => {
        const gItem = gridItems.filter((gridItem) => gridItem.i === widgetID)[0];

        let newData = gItem.data || {};

        let inputUrl = document.querySelector(`#video_input_${widgetID}`).value;

        inputUrl = inputUrl.replace("youtu.be", "www.youtube.com/embed");
        inputUrl = inputUrl.replace("watch?v=", "embed/");

        if (gItem && gItem.data && gItem.data.libraryRef) {
            delete newData.libraryRef;
            delete newData.externalUrl;
        }

        newData.video = [
            {
                externalUrl: inputUrl,
            },
        ];

        dispatch(
            setExecuteQuery(
                Widget.UpdateData({
                    widget,
                    value: newData,
                })
            )
        );
    };

    const updateVideoOption = (option, value) => {
        const widget = gridItems.filter((gridItem) => gridItem.i === widgetID)[0];

        let newData = widget.data;
        newData[option] = value;

        dispatch(setExecuteQuery(Widget.UpdateData({ widget, value: newData })));
    };

    const validateURLVideo = (url) => {
        const regexYoutube =
            /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w]+\?v=|embed\/|v\/)?)([\w]+)(\S+)?$/;
        const isAValidYoutubeURL = regexYoutube.test(url);
        const regexMP4 = /(http(s?):\/\/)([^\s(["<,>/]*)(\/)[^\s[",><]*(.mp4)(\?[^\s[",><]*)?/g;
        const isAValidMP4URL = regexMP4.test(url);

        if (isAValidYoutubeURL || isAValidMP4URL) {
            setVideoUrl(url);
            setPosterUrl(null);
            setUrlVideoValid(true);
        } else {
            setUrlVideoValid(false);
        }
    };

    const openModalLibrary = () => {
        const modal = BrowserMediaLibraryModal(t, {
            title: "select-a-video",
            text: "videos-of-media-library",
            bodyAdjust: "w-10/12",
            textAdjust: "",
            executeSectionQuery: true,
            buttonSelectedAssetName: "choose",
            dataMediaLibrary: {
                selected_ref: null,
                select_multiple: false,
                lang: lang,
                type: "video",
                search_input: true,
            },
        });
        setTimeout(() => {
            dispatch(
                changeGlobalAction({
                    actionName: "browser-media-library",
                })
            );
        }, 1000);
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    return (
        <>
            <div
                id={`${sectionName}-settings`}
                className={`icon icon-settings-editor cursor-pointer p-2 ${
                    widgetToolbarVisible === "video-settings" ? "bg-gray-200" : ""
                }`}
                onClick={(e) =>
                    dispatch(
                        setWidgetToolbarVisible(widgetToolbarVisible === "video-settings" ? null : "video-settings")
                    )
                }
            ></div>
            {widgetToolbarVisible === "video-settings" ? (
                <div
                    id={`widgetOptionContainer_${widgetID}`}
                    className={`widgetOptionContainer invisible p-0`}
                    style={{ width: "32rem" }}
                >
                    <div className="border-b border-gray-200 p-8 ">
                        <div className="first-capital mb-8 font-bold">{t("add-video")}</div>
                        <div className="relative mb-4 h-48">
                            {videoUrl ? (
                                <>
                                    {videoUrl && videoUrl.indexOf("youtube") > -1 ? (
                                        <iframe
                                            id={`video_${widgetID}`}
                                            title="video"
                                            className="h-full w-full"
                                            src={videoUrl}
                                        ></iframe>
                                    ) : (
                                        <ReactHlsPlayer
                                            id={`video_${widgetID}`}
                                            poster={posterUrl}
                                            src={videoUrl}
                                            controls={true}
                                            autoPlay={false}
                                            loop={true}
                                            className={`h-full w-full`}
                                        ></ReactHlsPlayer>
                                    )}
                                </>
                            ) : (
                                <EmptyWidget type={type}></EmptyWidget>
                            )}
                        </div>

                        {permissionLibraryVideos ? (
                            <>
                                <div
                                    className="first-capital mb-4 cursor-pointer"
                                    onClick={(e) => setVideoInputSelected("libraryVideo")}
                                >
                                    <UseRadio selected={videoInputSelected} value={"libraryVideo"} />
                                    <div className="first-capital mb-2 ml-6 font-bold">{t("media-library")}:</div>
                                </div>
                                <UseButton
                                    buttonName={"choose-a-media-file"}
                                    buttonColor={"btn-white"}
                                    disabled={videoInputSelected !== "libraryVideo"}
                                    adjust="w-full mb-4"
                                    action={() => {
                                        openModalLibrary();
                                    }}
                                />
                            </>
                        ) : null}

                        <div
                            className="first-capital mb-4 cursor-pointer"
                            onClick={(e) => {
                                setVideoInputSelected("externalVideo");
                            }}
                        >
                            {permissionLibraryVideos ? (
                                <>
                                    <UseRadio selected={videoInputSelected} value={"externalVideo"} />
                                    <div className="first-capital mb-2 ml-6 font-bold">
                                        {t("link")} (URL - {t("https only")}):
                                    </div>
                                </>
                            ) : (
                                <div className="first-capital mb-2 ml-0 font-bold">
                                    {t("link")} (URL - {t("https only")}):
                                </div>
                            )}
                        </div>
                        <div className="first-capital mb-4">{t("only YouTube and MP4 videos are supported")}</div>
                        <div className="table w-full">
                            <input
                                type="text"
                                id={`video_input_${widgetID}`}
                                disabled={videoInputSelected === "libraryVideo"}
                                onChange={(e) => {
                                    validateURLVideo(e.target.value);
                                }}
                                className="rounded mb-6 w-full bg-gray-200 px-4 py-2 "
                            />
                            <UseButton
                                buttonName={firstClick ? "update" : "insert"}
                                buttonColor={"btn-blue"}
                                disabled={urlVideoValid === false || videoInputSelected === "libraryVideo"}
                                adjust="inline-flex float-right"
                                action={() => {
                                    updateVideoUrl();
                                    setFirstClick(true);
                                }}
                                id={`${firstClick ? "update" : "insert"}-button`}
                            />
                        </div>
                    </div>{" "}
                    {deviceType !== "Mobile" ? (
                        <div className="p-8 ">
                            <div className="first-capital mb-8 font-bold">{t("video-options")}</div>
                            <div className="my-4 flex justify-between">
                                {(cScreenData &&
                                    cScreenData.welcome &&
                                    cScreenData.data.contentType === WELCOME_SEQUENCE.WELCOME_VIDEO) ||
                                (cScreenData && cScreenData.screenType === "CCINSTRUCTION") ? (
                                    <div className="first-capital ">{t("video-play-automatically")}</div>
                                ) : (
                                    <>
                                        <div className="first-capital ">{t("play-automatic")}</div>
                                        <label className="switch ml-2 cursor-pointer">
                                            <input
                                                type="checkbox"
                                                checked={autoplay}
                                                onChange={() => updateVideoOption("autoplay", !autoplay)}
                                            ></input>
                                            <span className="slider round"></span>
                                        </label>
                                    </>
                                )}
                            </div>
                            {cScreenData && cScreenData.screenType !== "CCINSTRUCTION" ? (
                                <div className="my-4 flex justify-between">
                                    <div className="first-capital ">{t("continuos-repetition")}</div>
                                    <label className="switch ml-2 cursor-pointer">
                                        <input
                                            type="checkbox"
                                            checked={repeat}
                                            onChange={() => updateVideoOption("repeat", !repeat)}
                                        ></input>
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            ) : null}
            {cScreenData &&
            cScreenData.welcome &&
            cScreenData.data.contentType === WELCOME_SEQUENCE.WELCOME_VIDEO ? null : (
                <>
                    <Toolbar type="style">
                        <Settings.Border t={t} noRadius={true} />
                    </Toolbar>
                    {/* <Toolbar type="clone" /> */}
                    <Toolbar type="delete" />
                </>
            )}
        </>
    );
};

export default VideoToolbar;
