import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

//Components
import AddImage from "./CommonOptions/AddImage";
import UseButton from "../../../../useButton";
import ScreenMargins from "./OptionsDetails/ScreenMargins";
import UseSelectPreviewScreen from "../../../../Inputs/useSelectPreviewScreen";
import ReactTooltip from "components/ReactTooltip";

//Actions
import { setExecuteQuery } from "actions/sectionActions";
import {
    setWidgetToolbarVisible,
    setColorPickerReference,
    setEditingMenu,
    setItemSelected,
    setDesignToolbarVisible,
    setHiddenMenuVisible,
    setOnResizeWindowMenu,
} from "actions/designActions";

//Utils
import { rgbaToHex, hexToRgbA } from "hooks/Utils/DesignUtils";
import { SCREEN, WELCOME_SEQUENCE } from "constants/editor";
import { EDITOR_MODE } from "hooks/Utils/Design/EditorUtils";
import OkToContinueAligment from "./OkToContinueAligment";
import { translate, capitalizeFirst } from "hooks/Utils/Utils";

import { useAuth } from "hooks/Session/auth";
import { GlobalContext } from "contexts/Global";
import { EditorContext } from "contexts/editor";

const ScreenToolbar = ({ deviceType, width }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { type: screenType } = useParams();
    const { editorSize } = useContext(EditorContext);
    //Store
    const { langStrings, warningsMinimized } = useSelector((state) => state.ui);
    const {
        editingMenu,
        itemSelected,
        widgetToolbarVisible,
        cScreenData,
        cDevice,
        typeMenu,
        hiddenMenuVisible,
        previewMenuIdSelected,
        editorMode,
    } = useSelector((state) => state.design);
    const { warnings: uiWarnings } = useContext(GlobalContext);

    const { isImpersonating } = useAuth();

    const style = cScreenData ? cScreenData.contentStyle : null;
    const margin = style && style.margin ? style.margin : { w: 0, h: 0 };
    const isTV = screenType === "TV";
    const isGeneralTV = screenType === "GENERAL" && deviceType === "TV" ? true : false;
    const isGeneral = screenType === "GENERAL";

    //states
    const [playingBgMusic, setPlayingBgMusic] = useState(false);
    const [musicUrl, setMusicUrl] = useState(false);
    const [isAValidUrlMusic, setIsValidUrlMusic] = useState(false);
    const [toolTipTitleHM, setTooltipTitleHM] = useState("show-menu");
    const [showHomeMenu, setShowHomeMenu] = useState(false);
    const [isUsingHomeBg, setIsUsingHomeBg] = useState(false);

    const timeoutRef = useRef(null);

    const handleChangeShowHomeMenu = () => {
        updateShowHomeMenu(!showHomeMenu);
        dispatch(setOnResizeWindowMenu(true));

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            dispatch(setOnResizeWindowMenu(false));
        }, 1000);
    };

    useEffect(() => {
        return () => {
            if (timeoutRef) {
                clearTimeout(timeoutRef);
            }
        };
    }, [showHomeMenu]);

    useEffect(() => {
        if (hiddenMenuVisible) {
            setTooltipTitleHM("hide-menu");
        } else {
            setTooltipTitleHM("show-menu");
        }
        // eslint-disable-next-line
    }, [hiddenMenuVisible]);

    useEffect(() => {
        const checkUrl = validateMusicUrl(musicUrl);
        setIsValidUrlMusic(checkUrl);
        // eslint-disable-next-line
    }, [musicUrl]);

    useEffect(() => {
        setInputValues();
        // eslint-disable-next-line
    }, [widgetToolbarVisible, style]);

    useEffect(() => {
        if (style) {
            if (style.showHomeMenu) {
                setShowHomeMenu(true);
            } else {
                setShowHomeMenu(false);
            }
        }
    }, [style, previewMenuIdSelected]);

    useEffect(() => {
        if (style) {
            if (style.useHomeBackground) {
                setIsUsingHomeBg(true);
            } else {
                setIsUsingHomeBg(false);
            }
        }
    }, [style, previewMenuIdSelected]);

    const setInputValues = () => {
        if (style) {
            if (widgetToolbarVisible === "screen-settings") {
                let musicInput = document.querySelector("#input_bgmusic");
                const mUrl = style.music && style.music.externalUrl ? style.music.externalUrl : "";
                if (musicInput) {
                    musicInput.value = mUrl;
                }
                setMusicUrl(mUrl);
            }
            if (widgetToolbarVisible === "screen-style") {
                const opacityInput = document.querySelector("#input_opacity");
                opacityInput.value = style.opacity || 100;

                if (style && style.bgColor) {
                    let colorOpacityInput = document.querySelector("#input_color-opacity");
                    colorOpacityInput.value = style.bgColor.replace(/^.*,(.+)\)/, "$1") * 100 || 100;

                    let colorInput = document.querySelector("#input_color");
                    colorInput.value = rgbaToHex(style.bgColor);
                }
            }
        }
    };

    useEffect(() => {
        if (style) {
            if (widgetToolbarVisible === "screen-style") {
                if (style && style.bgColor) {
                    let colorOpacityInput = document.querySelector("#input_color-opacity");
                    colorOpacityInput.value = style.bgColor.replace(/^.*,(.+)\)/, "$1") * 100;

                    let colorInput = document.querySelector("#input_color");
                    colorInput.value = rgbaToHex(style.bgColor);
                } else {
                    let colorOpacityInput = document.querySelector("#input_color-opacity");
                    colorOpacityInput.value = "";
                    let colorInput = document.querySelector("#input_color");
                    colorInput.value = "";
                }
            }
        }
        // eslint-disable-next-line
    }, [style && style.bgColor]);

    //Functions

    const updateMusic = () => {
        const val = document.querySelector(`#input_bgmusic`).value;
        setMusicUrl(val);
        const contentStyle = JSON.parse(JSON.stringify(style));
        contentStyle.music = { externalUrl: val };

        if (!val) {
            contentStyle.music = null;
        } else if (!isAValidUrlMusic) {
            return;
        }
        dispatch(
            setExecuteQuery({
                action: "updateContentStyle",
                params: {
                    contentStyle: contentStyle,
                },
            })
        );
    };

    const updateOpacity = () => {
        const val = document.querySelector(`#input_opacity`).value;

        const contentStyle = JSON.parse(JSON.stringify(style));
        contentStyle.opacity = parseInt(val);

        dispatch(
            setExecuteQuery({
                action: "updateContentStyle",
                params: {
                    contentStyle: contentStyle,
                },
            })
        );
    };

    const updateColor = () => {
        let hexaColor = document.querySelector(`#input_color`).value;
        const colorOpacity = document.querySelector(`#input_color-opacity`).value;

        if (
            ((hexaColor.length !== 6 && !/^#[0-9a-fA-F]{6}$/i.test(hexaColor)) ||
                (hexaColor.length === 6 && !/^[0-9a-fA-F]{6}$/i.test(hexaColor))) &&
            hexaColor !== ""
        ) {
            toast.error(langStrings["input-error"]);
            return;
        }
        if (hexaColor !== "" && !hexaColor.includes("#")) {
            hexaColor = `#${hexaColor}`;
            document.querySelector(`#input_color`).value = hexaColor;
        }

        const contentStyle = JSON.parse(JSON.stringify(style));
        if (hexaColor !== "") {
            contentStyle.bgColor = hexToRgbA(hexaColor, colorOpacity ? colorOpacity : 100);
        } else {
            contentStyle.bgColor = null;
        }

        dispatch(
            setExecuteQuery({
                action: "updateContentStyle",
                params: {
                    contentStyle: contentStyle,
                },
            })
        );
    };

    const updateColorOpacity = () => {
        const hexaColor = document.querySelector(`#input_color`).value;
        const colorOpacity = document.querySelector(`#input_color-opacity`).value;

        const contentStyle = JSON.parse(JSON.stringify(style));
        if (hexaColor !== "") {
            contentStyle.bgColor = hexToRgbA(hexaColor, colorOpacity ? colorOpacity : 100);
        } else {
            contentStyle.bgColor = null;
        }

        dispatch(
            setExecuteQuery({
                action: "updateContentStyle",
                params: {
                    contentStyle: contentStyle,
                },
            })
        );
    };

    const stopMusic = () => {
        document.querySelector("#bgMusicObject").pause();
        setPlayingBgMusic(false);
    };
    const playMusic = () => {
        document.querySelector("#bgMusicObject").play();
        setPlayingBgMusic(true);
    };

    const validateMusicUrl = (url) => {
        const regex = /(http(s?):\/\/)([^\s(["<,>/]*)(\/)[^\s[",><]*(.mp3|.ogg|.m4a|.wav)(\?[^\s[",><]*)?/g;
        const isAValidUrl = regex.test(url);
        if (!isAValidUrl) {
            return false;
        }
        return true;
    };

    const updateShowHomeMenu = (value) => {
        const contentStyle = JSON.parse(JSON.stringify(cScreenData.contentStyle));
        contentStyle.showHomeMenu = value;
        setShowHomeMenu(value);
        dispatch(
            setExecuteQuery({
                action: "updateContentStyle",
                params: {
                    contentStyle: contentStyle,
                },
            })
        );
    };

    return (
        <div className="m-8 contents">
            <div
                id={`toolbar_bg`}
                onClick={(e) => e.stopPropagation()}
                onDoubleClick={(e) => e.stopPropagation()}
                className={`${
                    itemSelected === "bg" && editingMenu !== null && editingMenu === false ? "" : "hidden"
                } widgetToolbarContainer absolute right-0`}
            >
                <div id="background" className="flex">
                    <div
                        className={`icon icon-settings-editor cursor-pointer p-2 ${
                            widgetToolbarVisible === "screen-settings" ? "toolbarOptionSelected" : ""
                        }`}
                        onClick={(e) =>
                            dispatch(
                                setWidgetToolbarVisible(
                                    widgetToolbarVisible === "screen-settings" ? null : "screen-settings"
                                )
                            )
                        }
                    ></div>
                    <div
                        className={`icon icon-color cursor-pointer p-2 ${
                            widgetToolbarVisible === "screen-style" ? "toolbarOptionSelected" : ""
                        }
                        ${isUsingHomeBg && isGeneralTV ? " text-gray-500" : ""}
                        `}
                        onClick={(e) => {
                            if (isUsingHomeBg && isGeneralTV) return;
                            dispatch(
                                setWidgetToolbarVisible(widgetToolbarVisible === "screen-style" ? null : "screen-style")
                            );
                        }}
                    ></div>
                </div>
                {widgetToolbarVisible === "screen-settings" ? (
                    <div
                        id={`widgetOptionContainer_bg`}
                        className={`widgetOptionContainer invisible p-0`}
                        style={{ width: "32rem" }}
                    >
                        {cScreenData.data.__typename === SCREEN.LTYPE.WELCOME_CONTENT &&
                        (cScreenData.data.contentType === WELCOME_SEQUENCE.WELCOME_VIDEO ||
                            cScreenData.data.contentType === WELCOME_SEQUENCE.WELCOME_LANG) ? null : (
                            <ScreenMargins deviceType={deviceType} deviceSpecific={true} />
                        )}

                        {cScreenData.data.__typename === SCREEN.LTYPE.WELCOME_CONTENT &&
                        cScreenData.data.contentType === WELCOME_SEQUENCE.WELCOME_VIDEO ? null : (
                            <AddImage isBackground={true} />
                        )}

                        {cScreenData.data.__typename === SCREEN.LTYPE.WELCOME_CONTENT &&
                        (cScreenData.data.contentType === WELCOME_SEQUENCE.WELCOME_VIDEO ||
                            cScreenData.data.contentType === WELCOME_SEQUENCE.WELCOME_WIDGETS) ? (
                            <OkToContinueAligment />
                        ) : null}

                        {isGeneralTV ? (
                            <div className="border-b border-gray-200 p-8">
                                <div className="first-capital mb-6 font-bold">{translate("menu", langStrings)}</div>
                                <div className={`first-capital mt-6 flex w-full cursor-pointer`}>
                                    <div className=" first-capital inline-block  w-11/12 text-gray-900 ">
                                        {capitalizeFirst(translate("show-home-menu", langStrings))}
                                        <i
                                            className="icon icon-info cursor-help ml-2 align-middle text-3xl text-gray-700"
                                            data-for="icon-eye"
                                            data-tip={t("menu-display-depends-configuration")}
                                        />
                                        <ReactTooltip
                                            arrowColor="text-gray-200"
                                            border={true}
                                            borderColor="text-gray-200"
                                            className="font-normal"
                                            delayShow={250}
                                            id="icon-eye"
                                            type="light"
                                        />
                                    </div>
                                    <div className=" w-1/12 ">
                                        <label className="switch cursor-pointer ">
                                            <input
                                                type="checkbox"
                                                checked={showHomeMenu}
                                                onChange={() => {
                                                    handleChangeShowHomeMenu();
                                                }}
                                            ></input>
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {cDevice.type !== "Mobile" &&
                        screenType !== SCREEN.TYPE.PORTAL &&
                        !(
                            cScreenData.data.__typename === SCREEN.LTYPE.WELCOME_CONTENT &&
                            cScreenData.data.contentType === WELCOME_SEQUENCE.WELCOME_VIDEO
                        ) ? (
                            <div className="border-b border-gray-200 p-8">
                                <div className="first-capital mb-8 font-bold">
                                    {translate("add-music", langStrings)}
                                </div>
                                <div className="table w-full">
                                    <div className="first-capital mb-4">
                                        {translate("link", langStrings)} (URL - {translate("https-only", langStrings)}):
                                    </div>

                                    <input
                                        type="text"
                                        id={`input_bgmusic`}
                                        className={` ${musicUrl && isAValidUrlMusic ? "float-left w-5/6" : "w-full"} 
                                        ${
                                            musicUrl && !isAValidUrlMusic
                                                ? " border-red-100 mb-2 "
                                                : " border-transparent mb-4"
                                        }
                                        rounded bg-gray-200 px-4 border  py-2`}
                                        onChange={(e) => {
                                            const checkUrl = validateMusicUrl(e.target.value);
                                            setIsValidUrlMusic(checkUrl);
                                        }}
                                    />
                                    {musicUrl && !isAValidUrlMusic ? (
                                        <span className=" block w-full text-red-100 ">{t("incorrect-url")}</span>
                                    ) : null}
                                    {musicUrl && isAValidUrlMusic ? (
                                        <>
                                            <audio
                                                onEnded={(e) => setPlayingBgMusic(false)}
                                                id="bgMusicObject"
                                                className="hidden"
                                                src={musicUrl}
                                            />
                                            {playingBgMusic ? (
                                                <div
                                                    className="icon icon-stop float-left w-1/6 text-center text-4xl text-blue-200"
                                                    style={{ fontSize: "2.25rem" }}
                                                    onClick={(e) => stopMusic()}
                                                ></div>
                                            ) : (
                                                <div
                                                    className="icon icon-play2 float-left w-1/6 text-center text-blue-200"
                                                    style={{ fontSize: "2.25rem" }}
                                                    onClick={(e) => playMusic()}
                                                ></div>
                                            )}
                                        </>
                                    ) : null}
                                    <UseButton
                                        buttonName={musicUrl ? "update" : "insert"}
                                        id={`${musicUrl ? "update" : "insert"}-music-button`}
                                        buttonColor={"btn-blue"}
                                        adjust="inline-flex float-right"
                                        action={updateMusic}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                ) : null}

                {widgetToolbarVisible === "screen-style" ? (
                    <>
                        <div
                            id={`widgetOptionContainer_bg`}
                            onClick={(e) => dispatch(setColorPickerReference(null))}
                            className={`widgetOptionContainer w-max invisible p-0`}
                            style={{ width: "36rem" }}
                        >
                            <div className="border-b border-gray-200 p-8">
                                <div className="first-capital mb-6 font-bold">
                                    {translate("color-and-opacity", langStrings)}
                                </div>

                                <div className="flex ">
                                    <div className="first-capital mt-2 w-4/12">
                                        {translate("background-opacity", langStrings)}
                                    </div>
                                    <div className="mb-2 w-4/12 ">
                                        <input
                                            type="number"
                                            step="1"
                                            min="0"
                                            max="100"
                                            id={`input_opacity`}
                                            className={`rounded h-10 bg-gray-200 px-4 py-2 `}
                                            onBlur={(e) => updateOpacity()}
                                            onKeyUp={(e) => (e.keyCode === 13 ? updateOpacity() : null)}
                                        />
                                        <span className="ml-1 mt-2">%</span>
                                    </div>
                                </div>
                                <div className="flex ">
                                    <div className="first-capital mt-2 w-4/12">
                                        {translate("background-color", langStrings)}
                                    </div>
                                    <div className="flex w-8/12">
                                        <div
                                            id="screenBgColor"
                                            onClick={(e) => {
                                                dispatch(
                                                    setColorPickerReference({
                                                        id: "screenBgColor",
                                                        color: style?.bgColor || "",
                                                    })
                                                );
                                                e.stopPropagation();
                                            }}
                                            className={`border mr-3 h-10 w-10 cursor-pointer rounded-md border-gray-200${
                                                style && style.bgColor ? "" : "icon icon-no-color"
                                            }`}
                                            style={{
                                                backgroundColor: `${style?.bgColor || ""}`,
                                                fontSize: `${style && style.bgColor ? "" : "2.3rem"}`,
                                            }}
                                        ></div>
                                        <div className="mr-3 w-5/12 ">
                                            <input
                                                type="text"
                                                id={`input_color`}
                                                className={`rounded h-10 w-full bg-gray-200 px-4 py-2 `}
                                                onBlur={(e) => updateColor()}
                                                placeholder="#000000"
                                                onKeyUp={(e) => (e.keyCode === 13 ? updateColor() : null)}
                                            />
                                        </div>
                                        <div className="w-5/12  ">
                                            <input
                                                type="number"
                                                step="1"
                                                min="0"
                                                max="100"
                                                id={`input_color-opacity`}
                                                className={`rounded h-10 bg-gray-200 px-4 py-2 `}
                                                disabled={style && style.bgColor ? false : true}
                                                onBlur={(e) => updateColorOpacity()}
                                                onKeyUp={(e) => (e.keyCode === 13 ? updateColorOpacity() : null)}
                                            />
                                            <span className="ml-1 mt-2">%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
            {isTV && editorMode === EDITOR_MODE.EDITION ? (
                editingMenu === false ? (
                    <div
                        className=" text-zafiro-600 absolute left-0 top-0 -mt-10 cursor-pointer items-center"
                        onClick={(e) => {
                            dispatch(setEditingMenu(true));
                            dispatch(setItemSelected(null));
                            e.stopPropagation();
                        }}
                        onDoubleClick={(e) => {
                            e.stopPropagation();
                        }}
                        style={{ display: "flex" }}
                    >
                        <div className="icon icon-settings mr-2 text-xl font-bold"></div>
                        <div className="first-capital font-bold">{translate("edit-menu", langStrings)}</div>
                    </div>
                ) : (
                    <div className=" absolute left-0 top-0 -mt-10 flex items-center">
                        <div className="first-capital mr-5 font-bold text-gray-600">
                            {translate("editing-menu", langStrings)}
                        </div>
                        <div
                            className="first-capital text-zafiro-600 cursor-pointer font-bold"
                            onClick={(e) => {
                                dispatch(setEditingMenu(false));
                                e.stopPropagation();
                            }}
                        >
                            {translate("finalize-changes", langStrings)}
                        </div>
                    </div>
                )
            ) : null}
            {isGeneral ? (
                <div
                    className={` flex  ${
                        isGeneralTV ? "absolute justify-center " : "z-505 fixed justify-end pt-1 "
                    } -mt-10 `}
                    style={
                        isGeneralTV
                            ? {
                                  top: "0rem",
                                  right: "0rem",
                              }
                            : {
                                  top:
                                      isImpersonating && uiWarnings
                                          ? warningsMinimized
                                              ? "14.8rem"
                                              : "17.8rem"
                                          : isImpersonating && !uiWarnings
                                          ? "12.8rem"
                                          : uiWarnings
                                          ? warningsMinimized
                                              ? "11.8rem"
                                              : "14.8rem"
                                          : "9.9rem",
                                  left: "50%",
                                  marginLeft: `-${editorSize && editorSize.width / 2 - 30.5}px`,
                                  width: `${editorSize && editorSize.width + "px"}`,
                                  height: "4rem",
                                  pointerEvents: "none",
                                  opacity: 0,
                              }
                    }
                    onClick={(e) => {
                        e.stopPropagation();
                        dispatch(setItemSelected(null));
                        dispatch(setDesignToolbarVisible(null));
                    }}
                    onDoubleClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <div className={`${isGeneralTV ? "" : "mt-4"}`}>
                        {capitalizeFirst(
                            translate(isGeneralTV ? "preview-menu-and-background" : "preview-background", langStrings)
                        )}
                    </div>
                    <div className={`${isGeneralTV ? "-mt-2" : "mt-2"} ml-5 w-64 `}>
                        <UseSelectPreviewScreen
                            enabled={true}
                            selectData={{
                                id: "preview-menu-and-background-option-selected",
                                adjustActiveValueContainer: "-mt-1",
                                inputActiveTextColor: "text-zafiro-600",
                                containerOptionsStyles: { maxHeight: "11rem" },
                                arrowIconSize: "text-xs",
                                inputActiveBgColor: "bg-white",
                                inputBgColor: "bg-white",
                                inputHeight: "h-8",
                                containerOptionsAdjust: "z-500 overflow-y-scroll",
                                placeHolder: translate("select-home", langStrings),
                                containerSelecOptionStyle: "pl-6",
                            }}
                        />
                    </div>
                </div>
            ) : null}

            {isGeneralTV && typeMenu === "hidden-menu" && showHomeMenu ? (
                <>
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            dispatch(setHiddenMenuVisible(!hiddenMenuVisible));
                        }}
                        onDoubleClick={(e) => {
                            e.stopPropagation();
                        }}
                        data-tip
                        data-for={"hidden-menu-tooltip"}
                        className={` bg-zafiro-600 z-505 rounded-t absolute left-0 top-0 -mt-4 flex h-4 w-32 cursor-pointer justify-center text-white ${
                            hiddenMenuVisible === false ? "icon-arrow-down" : "icon-arrow-up"
                        } `}
                    ></div>
                    <ReactTooltip
                        id="hidden-menu-tooltip"
                        place="bottom"
                        type="light"
                        offset={{ top: -8, left: -8 }}
                        html={true}
                        border={true}
                        borderColor="#D3DAE1"
                    >
                        {capitalizeFirst(translate(toolTipTitleHM, langStrings))}
                    </ReactTooltip>
                </>
            ) : null}

            <div
                id={`toolbar_bg_tag`}
                className={`${
                    (itemSelected === "bg" && editingMenu !== null && editingMenu === false) ||
                    margin.h < 10 ||
                    margin.w < 10
                        ? ""
                        : "hidden"
                } bg-zafiro-400 first-capital z-200 absolute top-0 -ml-10 flex cursor-pointer items-center px-1 py-4 text-base text-white`}
                style={{
                    left: deviceType === "Mobile" || screenType === SCREEN.TYPE.PORTAL ? "5px" : 0,
                    borderRadius: "0 .5rem .5rem 0",
                }}
            >
                <div className="first-capital">{t("screen")}</div>
                {deviceType === "Mobile" || screenType === SCREEN.TYPE.PORTAL ? null : (
                    <div className="icon icon-music pl-4"></div>
                )}
            </div>
        </div>
    );
};

export default ScreenToolbar;
