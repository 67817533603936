//REACT
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//Utils
import { toast } from "react-toastify";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//ACTIONS
import { setDashboardCards, cleanDashboardCards } from "../../../../actions/uiActions";
import { setRefreshContentData, setSectionContent } from "../../../../actions/sectionActions";
import { parseBoolean } from "../../../Utils/Utils";

const RoomDetailRoomInfo = ({ roomId, guestAccess, roomNumber, sectionName, roomType }) => {
    const dispatch = useDispatch();
    const { refreshData } = useSelector((state) => state.sectionContent);
    const { langStrings } = useSelector((state) => state.ui);

    const GET_ROOM_INFO = gql`{
        hotelRooms: hotelRooms(filter: {roomID: ${roomId}, roomType: ${roomType}}) {
            results{id roomNumber name status stayFrom stayTo lastStay warnings guests{ id name } group roomCode}
        }
    }`;

    const [executeQuery, { data, loading, error, refetch }] = useLazyQuery(GET_ROOM_INFO, {
        pollInterval: 60000,
        fetchPolicy: "network-only",
    });

    const arrangeDashboardData = (hotelroom) => {
        const roomDashboards = [];
        // room Stay card
        if (guestAccess) {
            roomDashboards.push({
                color: "bg-blue-300",
                headerText: "last-stay",
                occupied: parseBoolean(hotelroom.status),
                stayFrom: new window.ZDate(hotelroom.stayFrom),
                stayTo: hotelroom.stayTo ? new window.ZDate(hotelroom.stayTo) : null,
                stayId: hotelroom.guests.length > 0 ? hotelroom.guests[0].id : null,
                guestId: hotelroom.guests.length > 0 ? hotelroom.guests[0].id : null,
                lastStay: new window.ZDate(hotelroom.lastStay),
                type: "stay-card",
                visible: false,
            });
        }
        //room Code card
        roomDashboards.push({
            color: "bg-blue-200",
            headerText: sectionName === "common-zones" ? "common-zone-code" : "room-code",
            roomName: hotelroom.name ? hotelroom.name : "",
            roomId: hotelroom.id ? hotelroom.id : null,
            roomCode: hotelroom.roomCode,
            roomNumber: hotelroom.roomNumber,
            type: "roomcode-card",
            visible: true,
        });
        // room Group card
        roomDashboards.push({
            color: "bg-blue-200",
            headerText: sectionName === "common-zones" ? "common-zone-group" : "room-group",
            roomName: hotelroom.name ? hotelroom.name : "",
            roomId: hotelroom.id ? hotelroom.id : null,
            roomGroup: hotelroom.group,
            type: "roomgroup-card",
            visible: true,
        });
        // room Warnings card
        roomDashboards.push({
            color: "bg-blue-100",
            headerText: "device-status",
            warnings: hotelroom.warnings,
            type: "warning-card",
            visible: true,
        });
        // room available content card
        roomDashboards.push({
            color: "bg-blue-100",
            headerText: "available-content",
            type: "available-content-card",
            visible: true,
        });
        return roomDashboards;
    };

    useEffect(() => {
        if (!loading && !error && data && data.hotelRooms && data.hotelRooms.results) {
            const dashboardCards = arrangeDashboardData(data.hotelRooms.results[0]);
            dispatch(
                setSectionContent({
                    room: {
                        id: data.hotelRooms.results[0].id,
                        name: data.hotelRooms.results[0].name,
                        number: data.hotelRooms.results[0].roomNumber,
                        alarms: data.hotelRooms.results[0].alarms,
                    },
                })
            );
            dispatch(setDashboardCards(dashboardCards));
            dispatch(setRefreshContentData(false));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (refreshData && refetch) {
            refetch();
        }
        // eslint-disable-next-line
    }, [refreshData]);
    useEffect(() => {
        if (error) {
            toast.error(langStrings["mutation-error"]);
        }
        // eslint-disable-next-line
    }, [error]);

    useEffect(() => {
        dispatch(cleanDashboardCards());
        executeQuery();
        // eslint-disable-next-line
    }, []);
};

export default RoomDetailRoomInfo;
