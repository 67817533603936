import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "hooks/Session/auth";

import UseSectionHeader from "../../useSectionHeader";
import UpdateDesign from "../../../hooks/GraphqlCalls/Design/UpdateDesign";

import Warning from "components/Warning";
import Table from "components/ZafiroTable";
import { useDesigns } from "hooks/GraphqlCalls/Design/useDesigns";
import Loading from "components/Loading";
import { useModal } from "components/Modal";
import Button from "components/Button";
import { AddNewDesign } from "./ModalComponents/AddDesigns";

const Designs = () => {
    const { t } = useTranslation();
    const { open } = useModal();
    const { chain } = useAuth();
    const { hasChainModule, isCorporate } = useAuth();
    const { designsItems, itemsLoading, getDesign, getChain, refetch } = useDesigns();

    const cols = ["design", "author", "last-update", "creator", isCorporate ? "properties" : "apply", "actions"];
    const headers = {
        design: { title: t("design"), sortable: true, width: "25%" },
        author: { title: t("author"), sortable: false, width: "15%" },
        "last-update": { title: t("last-update"), sortable: true, width: "20%" },
        creator: { title: t("creator"), sortable: false, width: "20%" },
        ...(isCorporate
            ? { properties: { title: t("properties"), sortable: false, width: "25%" } }
            : { apply: { title: "", sortable: false, width: "10%" } }),
        actions: { title: "", sortable: false, width: "15%" },
    };

    useEffect(() => {
        getDesign();
        if (!chain?.name) {
            getChain();
        }
    }, [chain?.name]);

    return (
        <>
            <UpdateDesign />
            <UseSectionHeader title={["list-of", "designs"]} value={"demo"} />
            {!hasChainModule && !isCorporate ? (
                <Warning id="warning-chain-designs">
                    {t("You will not be able to perform any actions on Global management designs")}
                    <br />
                    {t(
                        "If you wish to make full use of all the options available please request a quotation for Zafiro Chain from our Account Service department"
                    )}
                </Warning>
            ) : null}
            <div className="bg-white p-6">
                {!itemsLoading ? (
                    <Table
                        id="designs-table"
                        cols={cols}
                        paginate={true}
                        showCount={true}
                        rows={designsItems}
                        header={headers}
                        search={true}
                        topRightCorner={
                            <Button
                                id="add-design"
                                onClick={() => open(<AddNewDesign refetch={refetch} />)}
                                className="btn btn-blue"
                            >
                                {t("add-design")}
                            </Button>
                        }
                        {...(isCorporate && {
                            filters: [
                                {
                                    id: "creator-type",
                                    title: t("design type"),
                                    options: [
                                        { id: "all", label: `-- ${t("all")} --`, value: "" },
                                        { id: "global", label: t("global-designs"), value: "CORPORATE" },
                                        { id: "local", label: t("property-designs"), value: "LOCAL" },
                                    ],
                                    value: "CORPORATE",
                                    allowUnselect: false,
                                    onFilter: (value, row) => value.includes(row?.filterType),
                                },
                            ],
                        })}
                    />
                ) : (
                    <Loading adjust="section-loading" style={{ height: "50vh" }} />
                )}
            </div>
        </>
    );
};

export default Designs;
