import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "components/Inputs/Checkbox";
import Icon from "components/Icon";

const DeviceContentSelector = ({ devices, availableContents, setSelectedOptions, selectedOptions }) => {
    const { t } = useTranslation();

    const handleCheckboxChange = (deviceId, option) => {
        setSelectedOptions((prev) => ({
            ...prev,
            [deviceId]: {
                ...prev[deviceId],
                [option]: !prev[deviceId]?.[option],
            },
        }));
    };

    const hasUnselectedDevice = () => {
        return (devices?.results || []).some((device) => {
            const options = selectedOptions[device.roomTVID] || {};
            return !options.signage && !options.tvChannels && !options.castScreen;
        });
    };

    return (
        <div>
            {devices && devices.results.length > 0 ? (
                devices.results.map((device) => {
                    const name = device.roomTVName || "";

                    return (
                        <div key={device.roomTVID} className="mt-4">
                            <div className="text-lg bg-gray-200 mb-4 pr-4">
                                <div className="ml-2 mb">{name || ""}</div>
                            </div>
                            <div className="flex flex-col mt-2">
                                {availableContents.includes("SIGNAGESEQUENCES") && (
                                    <label className="mb-2">
                                        <Checkbox
                                            id={`${device.roomTVID}-checkbox-signage`}
                                            checked={selectedOptions[device.roomTVID]?.signage || false}
                                            label={t("Signage")}
                                            onChange={() => handleCheckboxChange(device.roomTVID, "signage")}
                                        />
                                    </label>
                                )}
                                {availableContents.includes("TVCHANNELS") && (
                                    <label className="mb-2">
                                        <Checkbox
                                            id={`${device.roomTVID}-checkbox-tvChannels`}
                                            checked={selectedOptions[device.roomTVID]?.tvChannels || false}
                                            label={t("TV Channels")}
                                            onChange={() => handleCheckboxChange(device.roomTVID, "tvChannels")}
                                        />
                                    </label>
                                )}
                                {availableContents.includes("CASTINSTRUCTIONSCREEN") && (
                                    <label className="mb-2">
                                        <Checkbox
                                            id={`${device.roomTVID}-checkbox-castScreen`}
                                            checked={selectedOptions[device.roomTVID]?.castScreen || false}
                                            label={t("Cast instruction screen")}
                                            onChange={() => handleCheckboxChange(device.roomTVID, "castScreen")}
                                        />
                                    </label>
                                )}
                            </div>
                        </div>
                    );
                })
            ) : (
                <div className="mt-6 text-center">{t("No devices are available in this common zone.")}</div>
            )}

            {hasUnselectedDevice() && (
                <div className="mt-6 flex items-center">
                    <Icon type="warning" size="2xl" className="mr-4" />
                    <span>
                        {t(
                            "It is necessary to select one of the options in order to display content on the TV location."
                        )}
                    </span>
                </div>
            )}
        </div>
    );
};

export default DeviceContentSelector;
