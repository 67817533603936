import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "assets/fonts/fontAwesome/css/all.min.css";
import "assets/fonts/fontAwesome/css/brands.min.css";
import "assets/fonts/fontAwesome/css/fontawesome.min.css";
import "assets/fonts/fontAwesome/css/regular.min.css";
import "assets/fonts/fontAwesome/css/solid.min.css";

import EmptyWidget from "components/Section/Design/Widgets/WidgetToolbars/CommonOptions/EmptyWidget";

import { EditorContext, WidgetContext } from "contexts/editor";

const WidgetTVMenuNav = ({ insideMenu, menuStatusStyle }) => {
    const { itemSelected } = useContext(EditorContext);
    const { id: widgetID, type } = useContext(WidgetContext);

    const [ready, setReady] = useState(!!window.Render);

    const [statusStyle, setStatusStyle] = useState("defaultColor");
    const { widgetToolbarVisibleTab: tab, gridItems } = useSelector((state) => state.design);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;

    if (widget && widgetID) {
        widget.type = "TVMENUNAV";
        widget.ID = widgetID;
        widget.insideMenu = insideMenu === true ? true : false;
    }

    const getStatusStyle = (tab) => {
        let statusStyle = "";
        switch (tab) {
            case "regular":
            case "defaultColor":
                statusStyle = "defaultColor";
                break;
            case "activeColor":
                statusStyle = "activeColor";
                break;
            case "focusColor":
                statusStyle = "focusColor";
                break;
            default:
                break;
        }
        return statusStyle;
    };

    useEffect(() => {
        if (widgetID === itemSelected) {
            setStatusStyle(getStatusStyle(tab));
        }
        // eslint-disable-next-line
    }, [tab]);

    useEffect(() => {
        setReady(!!window?.Render?.menuColorsAndAnimations && !!widget);
    }, [!!window?.Render?.menuColorsAndAnimations, !!widget]);

    if (!ready) {
        return <EmptyWidget type={type} />;
    }

    return (
        <div
            dangerouslySetInnerHTML={{
                width: "100%",
                height: "100%",
                __html: widget
                    ? window.Render.widget(widget, null, {
                          statusStyle: menuStatusStyle ? menuStatusStyle : statusStyle ? statusStyle : null,
                          insideMenu: insideMenu === true ? true : false,
                          fromManager: true,
                      }).outerHTML
                    : null,
            }}
        ></div>
    );
};

export default WidgetTVMenuNav;
