import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UseSelectWithSearch from "components/useSelectWithSearch";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { useSelector } from "react-redux";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import { useDispatch } from "react-redux";
import { changeActionValues } from "actions/globalActions";
import { ARRANGE_GUEST_INFORMATION_DATA } from "components/Section/Customers/Precheckin/ReservationDetails/utils";

const InputGroupsRooms = ({ index, numberOfRooms, guests, rooms }) => {
    const { t } = useTranslation();
    const prefixId = "modal-checkin";
    const roomInputName = `${prefixId}-room-select-${index}`;
    const guestInputName = `${prefixId}-guest-name-select-${index}`;
    const { values } = useSelector((state) => state.action);
    const dispatch = useDispatch();

    const filterGuests = (currentIndex) => {
        if (values) {
            const availableGuests = [];
            const selectedIDs = [];
            const selectedPerUser = [];
            let hasSelectedUsers = false;
            numberOfRooms.forEach((_, index) => {
                if (index != currentIndex) {
                    const selectedUsers = values[`${prefixId}-guest-name-select-${index}`];
                    if (selectedUsers) {
                        hasSelectedUsers = true;
                        selectedIDs.push(...selectedUsers);
                    }
                } else {
                    const selectedUsers = values[`${prefixId}-guest-name-select-${index}`];
                    if (selectedUsers) {
                        selectedPerUser.push(...selectedUsers);
                    }
                }
            });

            availableGuests.push(
                ...guests.map((val) => {
                    if (val.id === values[`${prefixId}-main-guest-select`][0]) {
                        hasSelectedUsers = true;
                        return { ...val, selected: true, disabled: true, blocked: true };
                    } else if (selectedIDs.includes(val.id)) {
                        return { ...val, disabled: true };
                    } else if (selectedPerUser.includes(val.id)) {
                        return { ...val, selected: true };
                    } else {
                        return { ...val, selected: false };
                    }
                })
            );
            if (hasSelectedUsers) {
                return availableGuests;
            } else {
                return guests;
            }
        } else {
            return guests;
        }
    };

    const filterRooms = (currentIndex) => {
        const availableRooms = [];
        const selectedIDs = [];
        const selectedPerUser = [];
        let hasSelectedRooms = false;
        numberOfRooms.forEach((_, index) => {
            if (index != currentIndex) {
                const selectedRooms = values[`${prefixId}-room-select-${index}`];
                if (selectedRooms) {
                    hasSelectedRooms = true;
                    selectedIDs.push(...selectedRooms);
                }
            } else {
                const selectedRooms = values[`${prefixId}-room-select-${index}`];
                if (selectedRooms) {
                    selectedPerUser.push(...selectedRooms);
                }
            }
        });
        availableRooms.push(
            ...rooms.map((val) => {
                if (selectedIDs.includes(val.id)) {
                    return { ...val, disabled: true };
                }
                if (selectedPerUser.includes(val.id)) {
                    return { ...val, selected: true };
                } else {
                    return { ...val };
                }
            })
        );
        if (hasSelectedRooms) {
            return availableRooms;
        } else {
            return rooms;
        }
    };

    const replaceMainID = (newMainID, previousMainID) => {
        return filterGuests(index).map((val) => {
            if (val.id == previousMainID) {
                return { ...val, selected: false, disabled: false };
            }
            if (val.id == newMainID) {
                return { ...val, selected: true, disabled: true };
            }
            return { ...val };
        });
    };

    const [guestsInRoom, setGuestsInRoom] = useState([]);
    const [roomList, setRoomList] = useState([]);
    const [previousMainID, setPreviousMainID] = useState(values[`${prefixId}-main-guest-select`][0]);

    useEffect(() => {
        setGuestsInRoom(() => {
            return filterGuests(index).map((val) => {
                if (val.room == index + 1) {
                    return { ...val, selected: true };
                } else {
                    return { ...val };
                }
            });
        });
        dispatch(
            changeActionValues({
                [guestInputName]: filterGuests(index)
                    .map((val) => {
                        if (val.room == index + 1) {
                            return { ...val, selected: true };
                        } else {
                            return { ...val };
                        }
                    })
                    .filter((val) => val?.selected)
                    .map((val) => val.id),
            })
        );
    }, []);

    useEffect(() => {
        if (rooms) {
            setRoomList(filterRooms(index));
        }
    }, [rooms]);

    useEffect(() => {
        setGuestsInRoom(filterGuests(index));
        setRoomList(filterRooms(index));
    }, [values]);

    useEffect(() => {
        if (values[`${prefixId}-main-guest-select`][0] != previousMainID) {
            setGuestsInRoom(replaceMainID(values[`${prefixId}-main-guest-select`][0], previousMainID));
            setPreviousMainID(values[`${prefixId}-main-guest-select`][0]);
            dispatch(
                changeActionValues({
                    [guestInputName]: replaceMainID(values[`${prefixId}-main-guest-select`][0], previousMainID)
                        .filter((val) => val?.selected)
                        .map((val) => val.id),
                })
            );
        }
    }, [values[`${prefixId}-main-guest-select`][0]]);

    return (
        <div className=" w-full flex flex-col mt-6">
            <div className=" mb-4 font-bold">{`${capitalizeFirst(t("room"))} ${index + 1}`}</div>
            <div className=" w-full flex">
                <div className=" w-1/2 mr-2">
                    <div className=" font-bold mb-2 text-gray-800 text-sm">{t("guest-name")}*</div>
                    <UseSelectWithSearch
                        data={{
                            id: guestInputName,
                            name: guestInputName,
                            optionData: guestsInRoom,
                            selectPlaceHolder: t("select-an-option"),
                            oneSelected: false,
                            noSelect: true,
                            noShowSelectedOptionsInDropDownSection: false,
                            nonInitialOptionData: true,
                        }}
                    />
                </div>
                <div className=" w-1/2 ml-2 ">
                    <div className=" font-bold mb-2 text-gray-800 text-sm">{t("room-number")}*</div>
                    <UseSelectWithSearch
                        data={{
                            id: roomInputName,
                            name: roomInputName,
                            optionData: roomList,
                            selectPlaceHolder: t("select-an-option"),
                            oneSelected: true,
                            noSelect: true,
                            hideSearch: false,
                            selectedTextAdjust: "hidden",
                            noShowSelectedOptionsInDropDownSection: false,
                            nonInitialOptionData: true,
                            customNoDataMessage: "no-rooms-available",
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

const UseModalCheckin = () => {
    const { t } = useTranslation();
    const prefixId = "modal-checkin";

    const [guests, setGuests] = useState([]);
    const [roomList, setRoomList] = useState([]);
    const [numberOfRooms, setNumberOfRooms] = useState([]);
    const { data } = useSelector((state) => state.ui.modalContent.inputs[1]);
    const { values } = useSelector((state) => state.action);
    const dispatch = useDispatch();
    const roomsQuery = gql`
        {
            data: rooms(filter: { free: true, type: GUESTROOM }) {
                info {
                    count
                }
                results {
                    id
                    name
                }
            }
        }
    `;

    const [getOptionsQuery, { data: rooms }] = useLazyQuery(roomsQuery, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        setGuests(() => {
            let registredGuests = ARRANGE_GUEST_INFORMATION_DATA(data?.stayBookings);
            registredGuests = registredGuests.map((guest) => {
                return {
                    id: guest.id,
                    name: guest.name,
                    room: guest.stayBookingRoomIndex,
                    main: guest.guestRole === "MAIN",
                };
            });
            if (registredGuests.length < data?.stayBookings?.numberOfGuests) {
                const finalGuests = [...registredGuests];
                for (let index = registredGuests.length; index < data?.stayBookings?.numberOfGuests; index++) {
                    finalGuests.push({
                        id: `guest-${index + 1 - registredGuests.length}`,
                        name: `${t("guest")} ${index + 1}`,
                    });
                }
                return finalGuests;
            }

            if (registredGuests.length > data?.stayBookings?.numberOfGuests) {
                return registredGuests.slice(0, data?.stayBookings?.numberOfGuests);
            }
            return registredGuests;
        });
        getOptionsQuery();
        dispatch(
            changeActionValues({
                [`${prefixId}-main-guest-select`]: data?.stayBookings?.guests
                    ?.map((guest) => {
                        return {
                            id: guest.id,
                            name: guest.name,
                            room: guest.stayBookingRoomIndex,
                            main: guest.guestRole === "MAIN",
                        };
                    })
                    .filter((val) => val?.main)
                    .map((val) => val.id),
            })
        );
        setNumberOfRooms(new Array(data?.stayBookings?.numberOfRooms).fill(0));
    }, []);

    useEffect(() => {
        if (rooms) {
            setRoomList(rooms?.data?.results);
        }
    }, [rooms]);

    return (
        <div>
            <div className=" w-full ">
                <div className=" w-1/2">
                    <div className=" font-bold mb-4">{t("select-the-main-guest")}*</div>
                    <UseSelectWithSearch
                        data={{
                            id: `${prefixId}-main-guest-select`,
                            name: `${prefixId}-main-guest-select`,
                            optionData: guests.map((val) => {
                                if (values[`${prefixId}-main-guest-select`][0] === val.id) {
                                    return { ...val, selected: true };
                                } else {
                                    return { ...val };
                                }
                            }),
                            optionsAdjust: "mt-11",
                            selectPlaceHolder: t("select-an-option"),
                            oneSelected: true,
                            noSelect: true,
                            hideSearch: true,
                            selectedTextAdjust: "hidden",
                            nonInitialOptionData: true,
                        }}
                    />
                </div>
            </div>
            <div className=" w-full">
                {numberOfRooms.map((_, index) => {
                    return (
                        <InputGroupsRooms
                            index={index}
                            numberOfRooms={numberOfRooms}
                            guests={guests}
                            rooms={roomList}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default UseModalCheckin;
