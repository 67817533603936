import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import EmptyWidget from "components/Section/Design/Widgets/WidgetToolbars/CommonOptions/EmptyWidget";

import { WidgetContext } from "contexts/editor";

const WidgetAlarm = ({ insideMenu, menuStatusStyle }) => {
    const [ready, setReady] = useState(!!window.Render);

    const { id: widgetID, type, style, config, widgetSizes } = useContext(WidgetContext);

    const { widgetToolbarVisibleTab: tab, gridItems, itemSelected } = useSelector((state) => state.design);
    const [alarmConfigured, setAlarmConfigured] = useState(tab === "regular" ? false : true);

    let reduxWidget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    if (reduxWidget) {
        reduxWidget.type = "ALARM";
        reduxWidget.insideMenu = insideMenu === true;
    }

    const newWidget = {
        i: widgetID,
        type,
        widgetStyle: style,
        data: config?.data,
        actions: config?.actions,
        insideMenu: insideMenu === true,
        widgetSizes,
    };
    const widget = reduxWidget || newWidget;

    useEffect(() => {
        if (widgetID === itemSelected) {
            setAlarmConfigured(tab === "regular" ? false : true);
        } else {
            setAlarmConfigured(false);
        }
        // eslint-disable-next-line
    }, [tab, itemSelected]);

    useEffect(() => {
        setReady(!!window?.Render?.menuColorsAndAnimations && !!widget);
    }, [!!window?.Render?.menuColorsAndAnimations, !!widget]);

    if (!ready) {
        return <EmptyWidget type={type} />;
    }

    return (
        <div
            dangerouslySetInnerHTML={{
                width: "100%",
                height: "100%",
                __html: widget
                    ? window.Render.widget(widget, null, {
                          alarm: { configured: alarmConfigured, date: null },
                          insideMenu: insideMenu === true ? true : false,
                          statusStyle: menuStatusStyle ? menuStatusStyle : null,
                      }).outerHTML
                    : null,
            }}
        ></div>
    );
};

export default WidgetAlarm;
