import React, { useEffect } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";
import { useMutation } from "react-apollo";
import Button from "components/Button";
import Loading from "components/Loading";
import Icon from "components/Icon";
import { UseContentTree } from "../Advertising/graphql/useSignages";

export const ApplyModal = ({ id, name, refetch, designsLocalAssigned }) => {
    const { t } = useTranslation();
    const { close } = useModal();
    const { applyDesign } = useApplyDesign();

    const { getContentTree, contentTree, loading: loadingContentTree } = UseContentTree();

    useEffect(() => {
        getContentTree({ variables: { designID: designsLocalAssigned?.[0]?.id } });
    }, []);

    const contents = contentTree?.screens?.dir?.contents || [];
    const hasSignagesLinked = contents.reduce((acc, curr) => {
        if (curr.linkedSignages.length > 0) {
            acc = true;
        }
        return acc;
    }, false);

    const loading = loadingContentTree;

    return (
        <Modal
            title={`${t("apply-design")} - ${name}`}
            className="p-10 w-1/3"
            footer={
                <div className="flex justify-end space-x-2">
                    <Button id="cancel" design="white" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        id="apply"
                        design="blue"
                        onClick={() => {
                            applyDesign(id);
                            close();
                            if (refetch) {
                                setTimeout(() => refetch(), 1200);
                            }
                        }}
                    >
                        {t("apply")}
                    </Button>
                </div>
            }
        >
            {loading ? (
                <Loading></Loading>
            ) : (
                <>
                    <p className="mt-6">{t("design-apply-instructions")}</p>
                    {hasSignagesLinked && (
                        <div className="mt-4 flex gap-2 items-center">
                            <Icon type={"error"}></Icon>
                            <p>{t("please-note-that-some-screens-are-linked-to-a-signage-if-the-design-is-apply")}</p>
                        </div>
                    )}
                </>
            )}
        </Modal>
    );
};

export const useApplyDesign = () => {
    const APPLY_DESIGN = gql`
        mutation updateDesign($ID: Int64!, $isAssigned: Boolean!, $currentDate: DateTime!) {
            updateDesign(ID: $ID, isAssigned: $isAssigned, currentDate: $currentDate) {
                error
                id
                ok
            }
        }
    `;
    const [applyDesignMutation] = useMutation(APPLY_DESIGN);

    const applyDesign = (id) => {
        applyDesignMutation({
            variables: {
                ID: id,
                isAssigned: true,
                currentDate: new Date()?.toISOString(),
            },
        });
    };

    return { applyDesign };
};
