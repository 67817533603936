import React from "react";
import Button from "components/Button";
import Icon from "components/Icon";
import { UseModalAddFilters } from "./modals/useModalAddFilters";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import classNames from "classnames";

const AddFiltersCard = ({ data, loading, getData }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { open: openModalAddFilters } = UseModalAddFilters();
    const { isDefault } = data?.[0] || {};
    const roomGroupsTpCommonZone = data?.[0]?.destinationInfo?.roomGroupsTpCommonZone || [];
    const roomTvsTpCommonZone = data?.[0]?.destinationInfo?.roomTvsTpCommonZone || [];
    const roomsTpCommonZone = data?.[0]?.destinationInfo?.roomsTpCommonZone || [];
    const numberOfFilters = roomGroupsTpCommonZone?.length + roomTvsTpCommonZone?.length + roomsTpCommonZone?.length;

    return (
        <div className=" w-full flex flex-col gap-4 bg-white p-4 rounded min-h-full">
            <div className=" flex justify-between">
                <p className=" font-bold text-2xl">{t("add-filters")}</p>
                <Button
                    id={"edit-sequence-filters"}
                    onClick={() => {
                        openModalAddFilters({ id, getSignage: getData, filters: data?.[0]?.destinationInfo });
                    }}
                    disabled={loading || isDefault}
                >
                    <Icon
                        tooltip={isDefault ? t("the-default-sequence-cannot-be-filtered") : ""}
                        className={classNames({ "text-gray-700": isDefault })}
                        size={1.2}
                        type={"edit"}
                    ></Icon>
                </Button>
            </div>

            <p className={classNames({ "text-gray-700": isDefault })}>{t("set-up-the-filters")}</p>

            {numberOfFilters > 0 ? (
                <p className=" text-green-500">{t("filters-x", { count: numberOfFilters })}</p>
            ) : (
                <p className=" text-gray-700">{t("no-filters-yet")}</p>
            )}
        </div>
    );
};

export default AddFiltersCard;
