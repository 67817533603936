import { ENVIRONMENT_VARS } from "contexts/Global/env";
import { safeJsonParse } from "hooks/Utils/Utils";
import { SessionKibana } from "hooks/Utils/SessionKibana";
import pkg from "../../../package.json";

export const MANAGER_PREFIX = (() => {
    const majorVersion = parseInt(pkg?.version?.split(".")?.[0], 10);
    return `${pkg?.name}-v${majorVersion}`;
})();

// Gestion de cookies de session
export const Session = {
    init: () => {
        // Check if the version has changed
        if (Session.getVersion() !== pkg?.version) {
            // Clear cache
            if (caches !== undefined) {
                caches.keys().then((names) => {
                    names.forEach((name) => {
                        caches.delete(name);
                    });
                });
            }
            // Set new version
            Session.setVersion(pkg?.version);
            document.location.reload();
        }
    },

    getVersion: () => Session.getSessionProp("VERSION"),
    setVersion: (version) => Session.setSessionProp("VERSION", version),

    getLang: () => Session.getSessionProp("LANG"),
    setLang: (lang) => Session.setSessionProp("LANG", lang),

    getAuth: () => safeJsonParse(Session.getSessionProp("AUTH")),
    setAuth: (props) => Session.setSessionProp("AUTH", props),

    getLoggedUser: () => Session.getAuth()?.user,
    setLoggedUser: (props) => {
        if (!props) {
            Session.setAuth(null);
            // Remove kibana session (if opened)
            SessionKibana.close();
            return;
        }
        return Session.setAuth({
            ...Session.getAuth(),
            user: props,
        });
    },

    getImpersonated: () => Session.getAuth()?.impersonated,
    setImpersonated: (props) => {
        return Session.setAuth({
            ...Session.getAuth(),
            impersonated: props,
        });
    },

    getPaths: () => Session.getAuth()?.paths,
    setPaths: (props) => {
        return Session.setAuth({
            ...Session.getAuth(),
            paths: props,
        });
    },

    getUser: () => {
        const storedUser = Session.getLoggedUser();
        const storedImpersonated = Session.getImpersonated();
        return storedImpersonated || storedUser;
    },
    setUser: (props) => {
        const storedImpersonated = Session.getImpersonated();
        if (storedImpersonated) {
            Session.setImpersonated(props);
            return;
        }
        Session.setLoggedUser(props);
    },

    getChain: () => Session.getAuth()?.chain,
    getProjects: () => Session.getAuth()?.chain?.projects,
    setChain: (props) => {
        const auth = Session.getAuth();
        if (!auth?.user) {
            Session.setAuth(null);
            return;
        }
        return Session.setAuth({
            ...auth,
            chain: props,
        });
    },

    getProject: () => Session.getAuth()?.project,
    setProject: (props) => {
        return Session.setAuth({
            ...Session.getAuth(),
            project: props,
        });
    },

    isESChain: (chainID) => {
        const currentChainID = parseInt(chainID ?? Session.getChain()?.id ?? -1);
        const ZafiroChainID = parseInt(ENVIRONMENT_VARS?.ZAFIRO_CHAIN_ID || -2);
        return currentChainID === ZafiroChainID;
    },
    isSuperUser: () => Session.getUser()?.superUser,
    isCorporate: () => Session.getProject()?.ref === "CORPORATE",
    hasCorporate: () => Session.isSuperUser() || Session.getUser()?.corporate,
    hasChainModule: () => Session.getProject()?.hasChainModule,

    // User advised update means that the user has already seen the update completed message
    isLastUpdateRead: () => Session.getUser()?.userAdvicedUpdate,
    setLastUpdateRead: (read) => {
        const user = Session.getUser();
        if (user) {
            user.userAdvicedUpdate = read;
            Session.setUser(user);
        }
    },

    getWarnings: () => Session.getUser()?.warnings,
    setWarnings: (props) => {
        const user = Session.getUser();
        if (user) {
            user.warnings = props;
            Session.setUser(user);
        }
    },

    setTOSAnswer: (accepted) => {
        Session.setSessionProp("setPolicyToAccepted", accepted);
    },
    removeTOSAnswer: () => {
        Session.removeSessionProp("setPolicyToAccepted");
    },

    getCurrentPath: () => Session.getSessionProp("LOCATION_CURRENT"),
    getPreviousPath: () => Session.getSessionProp("LOCATION_PREVIOUS"),
    setCurrentPath: (path) => {
        const current = Session.getCurrentPath();
        if (current != path) {
            Session.setSessionProp("LOCATION_PREVIOUS", current);
            Session.setSessionProp("LOCATION_CURRENT", path);
        }
    },

    getWarningsClosed: () => safeJsonParse(Session.getSessionProp("WARNINGS_CLOSED")),
    setWarningsClosed: (props) => Session.setSessionProp("WARNINGS_CLOSED", props),
    setWarningClosed: (id, value = true) => {
        const set = JSON.stringify({ ...Session.getWarningsClosed(), [id]: value });
        Session.setWarningsClosed(set);
    },
    getWarningsCollapsed: () => safeJsonParse(Session.getSessionProp("WARNINGS_COLLAPSED")),
    setWarningsCollapsed: (props) => Session.setSessionProp("WARNINGS_COLLAPSED", props),
    setWarningCollapsed: (id, value = true) => {
        const set = JSON.stringify({ ...Session.getWarningsClosed(), [id]: value });
        Session.setWarningsClosed(set);
    },

    userGuiding: (products, sections) => {
        const user = Session.getUser();
        const isCorporate = Session.isCorporate();
        const hasCorporate = Session.hasCorporate();
        const userRef = user?.ref;
        const userEmail = user?.email;
        const userFullname = user?.fullname;

        const userguidingLimit = (prop, values, limit = 256) => {
            if (!values) {
                return null;
            }
            let result = {};
            let currentProp = prop;
            let propIndex = 1;
            if (values) {
                values.forEach((value) => {
                    if (value) {
                        const currentVal = result[currentProp];
                        const newValue = !currentVal ? value : `${currentVal}, ${value}`;
                        if (newValue.length <= limit) {
                            result[currentProp] = newValue;
                        } else {
                            currentProp = `${prop}${++propIndex}`;
                            result[currentProp] = value;
                        }
                    }
                });
            }
            return result;
        };

        const userID = userRef || "anonymous";
        const data = {
            email: userEmail || "unknown email",
            name: userFullname || "Unknown name",
            globalMode: isCorporate,
            hasGlobalOption: hasCorporate,
            lang: Session.getLang() || "en",
            ...userguidingLimit("products", products),
            ...userguidingLimit("sections", sections),
        };
        if (window && window.userGuiding) {
            window.userGuiding.identify(userID, data);
        }
    },

    // URL getters
    getApiUrl: (path) => getManagerPath("api", path),
    getUploadUrl: (path) => getManagerPath("upload", path),
    getDasUrl: (path) => getManagerPath("das", path),
    getPrinterUrl: (path) => getManagerPath("printer", path, true),
    getDriveUrl: (path) => getManagerPath("drive", path),
    getVendureUrl: (path) => getManagerPath("vendure", path),
    getStaffAppUrl: (path) => getManagerSubdomain("staff", path),
    getCastUrl: (path) => getManagerSubdomain("cast", path),

    develMode: () => Boolean(process.env.REACT_APP.DEVELOPMENT == "true"),

    setSessionProp: (property, val) => {
        if (typeof val === "object") {
            val = JSON.stringify(val);
        }
        localStorage.setItem(`${MANAGER_PREFIX}-${property}`, val);

        const event = new CustomEvent("storageChange", { detail: { key: `${property}`, value: val } });
        if (event && window) {
            window.dispatchEvent(event);
        }
    },
    getSessionProp: (property) => {
        return localStorage.getItem(`${MANAGER_PREFIX}-${property}`);
    },
    removeSessionProp: (property) => {
        localStorage.removeItem(`${MANAGER_PREFIX}-${property}`);
    },
};

const getManagerPath = (type, path, useLocationHost = false) => {
    const id = type?.toUpperCase();
    const force = process.env.REACT_APP[`FORCE_${id}`];
    let url = force || process.env.REACT_APP[`DEFAULT_${id}_PATH`] || `/${id}`;
    if (path) {
        url += "/" + path;
    }
    if (useLocationHost && !force) {
        const { host, protocol } = window.location;
        url = `${protocol}//${host}${url}`;
    }
    return cleanURL(url);
};

const getManagerSubdomain = (type, path) => {
    const id = type?.toUpperCase();
    const force = process.env.REACT_APP[`FORCE_${id}`];
    let url = force || subdomain(process.env.REACT_APP[`DEFAULT_${id}_SUBDOMAIN`] || id);
    if (!Session.develMode()) {
        url = url.replace(".link", ".mobi");
    }
    if (path) {
        url += "/" + path;
    }
    return cleanURL(url);
};

const cleanURL = (url) => {
    return url.replace(/([^:]\/)\/+/g, "$1");
};

const subdomain = (type, url = null) => {
    if (!url) {
        url = window.location.href;
    }
    const urlObj = new URL(url);
    const parts = urlObj.hostname.split(".");
    if (parts[0] === "manager") {
        parts.shift();
    }
    parts.unshift(type);
    urlObj.hostname = parts.join(".");
    urlObj.pathname = "";
    urlObj.hash = "";
    return urlObj.toString();
};
