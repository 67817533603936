import React, { useEffect } from "react";
import { gql } from "apollo-boost";
import Button from "components/Button";
import Icon from "components/Icon";
import Modal, { useModal } from "components/Modal";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import { UseContentTree } from "../Advertising/graphql/useSignages";
import Loading from "components/Loading";

export const DeleteDesign = ({ designName, id, refetch }) => {
    const { close } = useModal();
    const { t } = useTranslation();
    const { deleteDesign } = useDeleteDesign();
    const { getContentTree, contentTree, loading: loadingContentTree } = UseContentTree();

    useEffect(() => {
        getContentTree({ variables: { designID: id } });
    }, []);

    const contents = contentTree?.screens?.dir?.contents || [];
    const hasSignagesLinked = contents.reduce((acc, curr) => {
        if (curr.linkedSignages.length > 0) {
            acc = true;
        }
        return acc;
    }, false);

    const loading = loadingContentTree;

    return (
        <Modal
            title={`${designName} - ${t("delete-design")}`}
            className="p-10 w-1/3"
            footer={
                <div className="flex justify-end gap-4">
                    <Button id="cancel" design="white" onClick={close} className="font-bold">
                        {t("cancel")}
                    </Button>
                    <Button
                        id="delete-design"
                        design="red"
                        disabled={loading}
                        onClick={() => {
                            deleteDesign({
                                variables: { id },
                            }).then((res) => {
                                if (res.data.deleteDesign.ok) {
                                    setTimeout(() => refetch(), 500);
                                }
                            });
                            close();
                        }}
                    >
                        {t("delete")}
                    </Button>
                </div>
            }
        >
            {loading ? (
                <Loading></Loading>
            ) : (
                <>
                    <p>{t("delete-design-confirm")}</p>
                    {hasSignagesLinked && (
                        <div className="mt-4 flex gap-2 items-center">
                            <Icon type={"error"}></Icon>
                            <p>{t("please-note-that-some-screens-are-linked-to-a-signage-if-the-design-is-deleted")}</p>
                        </div>
                    )}
                </>
            )}
        </Modal>
    );
};

const useDeleteDesign = () => {
    const DELETE_DESIGN = gql`
        mutation DeleteDesign($id: Int64!) {
            deleteDesign(id: $id) {
                error
                ok
            }
        }
    `;
    const [deleteDesign] = useMutation(DELETE_DESIGN);

    return { deleteDesign };
};
