import React, { useEffect } from "react";
import UseSectionHeader from "../../../useSectionHeader";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Icon from "components/Icon";
import { NetworkMonitorCards } from "./NetworkMonitorCards";
import Table from "components/ZafiroTable";
import { useNetworkMonitor } from "hooks/GraphqlCalls/Hotel/Monitoring/useNetworkMonitor";
import Loading from "components/Loading";
import { Session } from "hooks/Utils/Session";
import { ZabbixError } from "./ZabbixError";
import { useNetworkMonitorCard } from "hooks/GraphqlCalls/Hotel/Monitoring/useNetworkMonitorCard";

export const NetworkMonitor = () => {
    const { t } = useTranslation();
    const { rows, executeNetworkMonitorDevices, getZabbixStatus, statusData, statusLoading } = useNetworkMonitor();
    const { summary, loading } = useNetworkMonitorCard();
    //const superUser = Session.isSuperUser();

    useEffect(() => {
        executeNetworkMonitorDevices();
        getZabbixStatus();
    }, []);

    return (
        <>
            <UseSectionHeader title={t("network-monitor")} navToPreviousPage={true} />
            <main>
                {/* COMENTADO HASTA INTEGRACIÓN EN FUTURAS FASES */}
                {/* <div className="flex justify-end">
                    {superUser && (
                        <Button
                            id="network-monitor-configuration"
                            design="blue-outline"
                            className="font-bold flex items-center"
                        >
                            <Icon type="superuser" size="2xl" />
                            <p>{t("zafiro wireless gateway configuration")}</p>
                        </Button>
                    )}
                </div> */}
                {rows?.length !== 0 && (
                    <section className="bg-white p-6 rounded mt-4">
                        <NetworkMonitorCards monitoring={false} summary={summary} loading={loading} />
                    </section>
                )}
                {!statusData?.getNetworkMonitorZabbixStatus?.isAvailable && !statusLoading && <ZabbixError />}
                <section className="bg-white p-6 rounded mt-4">
                    {!statusLoading ? (
                        <Table
                            id="network-monitor-table"
                            search={true}
                            paginate={true}
                            showCount={true}
                            cols={["name", "ip", "type", "status", "warnings", "critical"]}
                            rows={rows}
                            customTexts={{
                                noTableData: t("no devices added yet"),
                            }}
                            header={{
                                name: { title: t("name"), sortable: true },
                                ip: { title: t("ip"), sortable: true },
                                type: { title: t("type"), sortable: true },
                                status: { title: t("status"), sortable: true },
                                warnings: { title: t("warnings"), sortable: true },
                                critical: { title: t(""), sortable: false },
                            }}
                            filters={[
                                {
                                    id: "device-type",
                                    title: t("device-type"),
                                    multiple: true,
                                    options: [
                                        { value: "Router", label: t("Router") },
                                        { value: "Switch", label: t("Switch") },
                                        { value: "AP", label: t("AP") },
                                    ],
                                    onFilter: (value, row) => value.includes(row.type),
                                },
                                {
                                    id: "warnings",
                                    title: t("warnings"),
                                    multiple: true,
                                    options: [
                                        {
                                            value: 3,
                                            label: t("critical"),
                                        },
                                        {
                                            value: 2,
                                            label: t("average"),
                                        },
                                        {
                                            value: 1,
                                            label: t("informational"),
                                        },
                                        {
                                            value: 0,
                                            label: t("not-classified"),
                                        },
                                    ],
                                    onFilter: (value, row) =>
                                        row?.warningPerDevice?.some((warning) => value.includes(warning.level)),
                                },
                                {
                                    id: "status",
                                    title: t("status"),
                                    multiple: true,
                                    options: [
                                        {
                                            value: "offline",
                                            label: t("offline"),
                                        },
                                        {
                                            value: "online",
                                            label: t("online"),
                                        },
                                        {
                                            value: "unknown",
                                            label: t("unknown"),
                                        },
                                    ],
                                    onFilter: (value, row) => value.includes(row.deviceStatus?.toLowerCase()),
                                },
                                {
                                    id: "criticality",
                                    title: t("criticality"),
                                    allowUnselect: false,
                                    options: [
                                        { value: "", label: `-- ${t("all")} -- ` },
                                        { value: true, label: t("critical") },
                                        { value: false, label: t("not-critical") },
                                    ],
                                    onFilter: (value, row) => value === row.criticalDevice,
                                },
                            ]}
                        />
                    ) : (
                        <Loading adjust="section-loading" style={{ height: "50vh" }} />
                    )}
                </section>
            </main>
        </>
    );
};
