import React from "react";
import DateAgo from "hooks/Utils/DataCalc/DateAgo";
import { DEVICE_FUNCTIONS, DEVICE_STATUS, DEVICE_TYPES } from "hooks/Utils/Hotel/Monitor/ListDevicesUtils";
import Icon from "components/Icon";
import Button from "components/Button";
import { TRANSLATE_SCREEN_TYPE_TITLE } from "hooks/Utils/Design/EditorUtils";
import { useTranslation } from "react-i18next";
import Modal, { useModal } from "components/Modal";
import Table from "components/ZafiroTable";
import { getAvailableActions, renderIcon, sortByFunction } from "../../utils/monitorUtils";
import Loading from "components/Loading";
import { ExpandedInfo } from "components/Section/Hotel/Monitor/MonitorComponents/MonitorDevicesComponents";

export const Devices = (props, t, permissions, showPreviewCell = true) => {
    const { device, inputGroupData, open, close, refetch, tvInputs, contents } = props;
    const {
        name,
        mac,
        roomID,
        function: deviceFunction,
        roomTVName,
        roomNumber,
        warning,
        lastActivity,
        lastActivityDate,
        commercialName,
        floorID,
        ip,
        type,
        ref,
        roomType,
        location,
        roomTVID,
    } = device;

    const hasCast = permissions?.product?.cast;

    let lastActivityText = "";
    let lastActivityDateDisplayText = t("never");

    if (lastActivity) {
        if (lastActivity.includes("sending-content")) {
            lastActivityText = t(lastActivityText);
        } else if (lastActivity.includes("instructions page")) {
            lastActivityText = t(TRANSLATE_SCREEN_TYPE_TITLE["CCINSTRUCTION"]);
        } else if (lastActivity.includes("unauthorized")) {
            lastActivityText = t("unauthorized");
        } else if (lastActivity.includes("Zafiro: unassigned")) {
            lastActivityText = t("unassigned");
        } else if (lastActivity !== undefined && lastActivity !== "undefined") {
            lastActivityText = lastActivity;
        }
    }
    if (lastActivityDate && lastActivityDate !== "" && !Number.isNaN(new Date(lastActivityDate).getTime())) {
        const _lastDisplay = DateAgo(new window.ZDate(lastActivityDate));
        lastActivityDateDisplayText =
            _lastDisplay[Object.keys(_lastDisplay)[0]] === "NaN"
                ? t("never")
                : t("last-activity-date", {
                      val: _lastDisplay[Object.keys(_lastDisplay)[0]],
                      measure: t(Object.keys(_lastDisplay)[0]),
                  });
    }
    lastActivityText += ` (${lastActivityDateDisplayText})`;

    const previewCell = {
        name: "preview",
        value: t("{{value, capitalize}}", { value: t("preview") }),
        translate: false,
        link: `/hotel/zones/zone/preview/${device.roomTVID}/${ref ? ref : ""}`,
        target: "_blank",
        external: true,
        cellContent: "link",
        specialWidth: "w-3/12",
    };
    const tvPreviewCell = {
        name: "preview",
        value: t("{{value, capitalize}}", { value: t("preview") }),
        translate: false,
        link: `/hotel/zones/zone/preview/tv`,
        target: "_blank",
        external: true,
        noWidthFullCell: true,
        cellContent: "link",
        specialWidth: "w-3/12",
    };

    return {
        floorID,
        rowTitleValue: roomType === "COMMONZONE" ? roomTVName : null,
        "device-model": commercialName,
        name: (
            <div className="flex flex-col" sortvalue={name} alt={`${mac} ${name} ${ip} ${ref}`}>
                <p>{roomType === "COMMONZONE" ? type : name}</p>
                {hasCast || (DEVICE_TYPES.googleTV.value !== type && DEVICE_FUNCTIONS.tv !== deviceFunction) ? (
                    <p className="text-gray-700">
                        {t("mac")}: {mac?.toUpperCase()}
                    </p>
                ) : null}
            </div>
        ),
        function: (
            <div
                sortvalue={sortByFunction(deviceFunction)}
                alt={sortByFunction(deviceFunction)}
                className="flex items-center gap-2"
            >
                {renderIcon(deviceFunction)}
            </div>
        ),
        room: (
            <div className="flex flex-col" sortvalue={roomNumber} alt={`${roomNumber} ${roomTVName}`}>
                {roomNumber !== 0 ? (
                    <LocationButton
                        roomType={roomType}
                        roomID={roomID}
                        roomNumber={roomNumber}
                        location={location}
                        permissions={permissions}
                    />
                ) : null}
                <p className="text-gray-700">{roomTVName}</p>
            </div>
        ),
        status: (
            <div className="flex items-center gap-2" sortvalue={warning?.info} alt={warning?.info}>
                {Object.keys(DEVICE_STATUS).map((status, index) => {
                    if (warning?.info === DEVICE_STATUS[status].value) {
                        let colorClass = "";
                        switch (warning?.level) {
                            case 1:
                                colorClass = "text-red-100";
                                break;
                            case 2:
                                colorClass = "text-orange-100";
                                break;
                            case 3:
                                colorClass = "text-gray-500";
                                break;
                            case 4:
                                colorClass = "text-green-500";
                                break;
                            default:
                                colorClass = DEVICE_STATUS[status]?.icon?.color?.class;
                        }

                        return (
                            <div className="flex items-center gap-2">
                                {warning?.info === "running-task" ? (
                                    <Loading adjust="inline" zoom={20} />
                                ) : (
                                    <Icon type={DEVICE_STATUS[status]?.icon?.name} className={colorClass} size="xl" />
                                )}
                                <p key={index} className={DEVICE_STATUS[status].labelAdjust}>
                                    {t(DEVICE_STATUS[status].label)}
                                </p>
                            </div>
                        );
                    }
                })}
            </div>
        ),
        lastActivityDate: (
            <div className="flex flex-col" sortvalue={lastActivityText}>
                <p>{lastActivityText?.length > 40 ? `${lastActivityText.substring(0, 35)}...` : lastActivityText}</p>
            </div>
        ),
        preview: (
            <div className="flex flex-col" sortvalue={device}>
                {device.type !== "tv" && showPreviewCell ? <Preview previewCell={previewCell} /> : tvPreviewCell}
            </div>
        ),
        rowConfig: {
            expanded: () => {
                return (
                    <ExpandedInfo
                        device={device}
                        inputGroupData={inputGroupData}
                        deviceLocation={roomType}
                        roomName={name}
                        contents={contents}
                        roomTVID={roomTVID}
                    />
                );
            },

            actions: getAvailableActions(device, t, open, refetch, hasCast, tvInputs, inputGroupData),
        },
    };
};

const Preview = ({ previewCell }) => {
    const { t } = useTranslation();

    const handleClick = (event) => {
        event.stopPropagation();
    };

    return (
        <Button
            id="preview"
            className="font-bold text-zafiro-700"
            href={previewCell?.link}
            onClick={handleClick}
            target={previewCell?.target || "_blank"}
        >
            {t("preview")}
        </Button>
    );
};

export const TVInput = ({ inputGroupData, tvInputID, type }) => {
    const { t } = useTranslation();
    const { open, close } = useModal();

    if (type !== DEVICE_TYPES.googleTV.value && type !== DEVICE_TYPES.chromecast.value) {
        return (
            <div className="flex items-center gap-2">
                <p>{t("input-group")}:</p>
                <Button
                    id={`input-group-${tvInputID}`}
                    className="t-link cursor-pointer font-bold"
                    onClick={() =>
                        open(
                            <Modal
                                title={inputGroupData?.name}
                                className="w-1/2 p-10"
                                footer={
                                    <Button design="blue" id="modal-button-close" onClick={close}>
                                        {t("close")}
                                    </Button>
                                }
                            >
                                <p className="my-8">
                                    {t("inputs-of", {
                                        name: inputGroupData?.name,
                                    })}
                                    :
                                </p>
                                <Table
                                    id={`input-group-${inputGroupData?.id}`}
                                    header={{
                                        name: { title: t("name"), width: "33%" },
                                        inputs: { title: t("inputs"), width: "33%" },
                                        "connected-device": { title: t("connected-device"), width: "33%" },
                                    }}
                                    cols={["name", "inputs", "connected-device"]}
                                    rows={inputGroupData?.inputs?.map((input) => ({
                                        name: input?.name,
                                        inputs: input?.input,
                                        "connected-device": input?.deviceType ? input?.deviceType : "-",
                                    }))}
                                />
                            </Modal>
                        )
                    }
                >
                    {inputGroupData?.name || ``}
                </Button>
            </div>
        );
    }
};

export const DeviceFunction = ({ deviceFunction }) => {
    return (
        <>
            {DEVICE_FUNCTIONS[deviceFunction].icons.map((icon, index) => (
                <div key={index} className={`p-2 ${icon?.color?.class} rounded flex items-center justify-center`}>
                    <Icon type={icon.name} size="2xl" className="text-white font-bold" />
                </div>
            ))}
        </>
    );
};

export const LocationButton = ({ roomType, roomID, roomNumber, location, permissions }) => {
    const getLink = () => {
        if (roomType === "COMMONZONE") {
            return `/hotel/zones/zone/detail/${roomID}`;
        }
        return `/hotel/rooms/room/detail/${roomID}`;
    };

    const getIcon = () => {
        if (roomType === "COMMONZONE") {
            return "icon-commonzones";
        }
        return "icon-rooms";
    };

    return (
        <Button
            id={`room-location-${roomID}`}
            to={permissions?.hotel?.roomList && getLink()}
            className={permissions?.hotel?.roomList ? `t-link cursor-pointer font-bold` : null}
            tooltip={`<div class="flex items-center justify-center gap-1 font-semibold"><span class="${getIcon()} text-xl"></span><span>${roomNumber}</span></div>`}
            tooltipType={"dangerous-html-tooltip"}
        >
            {location}
        </Button>
    );
};
