import React, { useLayoutEffect, useContext } from "react";
import MenuSideBar from "./MenuSideBar";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showMainMenu } from "../../actions/uiActions";

import Sitemap from "../../sitemap";
import { usePermissions } from "hooks/map";
import { UseSectionMap } from "./utils";
import { GlobalContext } from "contexts/Global";

export default function ShopSidebar() {
    const { id, productId } = useParams();
    const dispatch = useDispatch();

    const { paths } = useContext(GlobalContext);
    const { checkPath } = usePermissions();
    const { getItems } = UseSectionMap(checkPath);

    useLayoutEffect(() => {
        dispatch(showMainMenu(false));
        return () => {
            dispatch(showMainMenu(true));
        };
        // eslint-disable-next-line
    }, []);

    let menuItems = getItems("sales", Sitemap.sales, paths);
    if (menuItems) {
        menuItems.map((item) => {
            item.to = item.to.replace(":id", id).replace(":productId", productId);
            if (item.redirectOptions) {
                item.redirectOptions = item.redirectOptions.map((option) =>
                    option.replace(":id", id).replace(":productId", productId)
                );
            }
            return item;
        });
    }

    return (
        <>
            <MenuSideBar menuItems={menuItems} />
        </>
    );
}
