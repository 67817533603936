import { gql } from "apollo-boost";
import { navToPrevious } from "components/Section/Hotel/Settings/FloorWings";
import { TopologyContext } from "contexts/Topology";
import { useContext } from "react";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const useEditRoom = (roomTvs, lengthWhileEditing, newRoomsTVs) => {
    const { t } = useTranslation();
    const { tvs, location, tvsLengthWhileEditing, setLoadingRefresh } = useContext(TopologyContext);
    const tvsNumber = newRoomsTVs ? newRoomsTVs?.length : tvs;
    const locationsName = newRoomsTVs ? newRoomsTVs?.filter((tv) => tv?.name) : location;
    const lengthBeforeEditing = lengthWhileEditing ? lengthWhileEditing : tvsLengthWhileEditing;
    const filteredLocations = locationsName?.map((loc) => {
        const { __typename, id, cec, ...rest } = loc;
        return rest;
    });

    const EDIT_ROOM = gql`
        mutation EditRoom ($id: Int64!, $name: String!, $number: Int!, $pmsNumber: String){
            updateRoom(id: $id, name: $name, number: $number, pmsNumber: $pmsNumber){
                error
                ok
            }
            ${Array.from({ length: Math.max(tvsNumber, roomTvs?.length) }, (_, i) => {
                if (i < lengthBeforeEditing && i >= tvsNumber) {
                    return `deleteRoomTV${i + 1}: deleteRoomTV(roomTVID: ${roomTvs[lengthBeforeEditing - i - 1]?.id}){
                        error
                        ok
                    }
                    `;
                } else if (i < lengthBeforeEditing && i < tvsNumber && locationsName[i]?.id) {
                    return `updateRoomTV${i + 1}: updateRoomTV(roomTvID: ${locationsName[i]?.id}, location: {name: "${
                        filteredLocations[i]?.name
                    }", CEC: ${filteredLocations[i]?.CEC || filteredLocations[i]?.cec || false}}){
                                error
                                ok
                            }
                            `;
                } else if (i >= lengthBeforeEditing && i < tvsNumber) {
                    return `createRoomTV${i + 1}: createRoomTV(roomID: $id, location: {name: "${
                        filteredLocations[i]?.name
                    }", CEC: ${filteredLocations[i]?.CEC || filteredLocations[i]?.cec || false}}){
                                error
                                ok
                            }
                            `;
                } else {
                    return "";
                }
            })}
        }
    `;

    const [editRoomQuery] = useMutation(EDIT_ROOM);

    const editRoom = (id, name, number, pmsNumber) => {
        editRoomQuery({
            variables: {
                id,
                name,
                number,
                pmsNumber,
            },
        })
            .then((response) => {
                if (response.data.updateRoom.ok) {
                    toast.success(t("operation-successful"));
                    navToPrevious();
                    if (setLoadingRefresh) {
                        setLoadingRefresh(true);
                    }
                }
                return response;
            })
            .catch((err) => {
                const errorMessage = err?.message.split(":").pop().trim();
                toast.error(t(errorMessage));
            });
    };

    return { editRoom };
};
