import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { vw2px } from "../../../../hooks/Utils/DesignUtils";
import { EditorContext } from "../../../../contexts/editor";

const TabBar = () => {
    const { editorSize } = useContext(EditorContext);
    const { t } = useTranslation();
    const { permissions } = useSelector((state) => state.ui);

    const size = (s) => vw2px(s, editorSize?.width);

    const hasDigitalKeys = permissions?.guests?.digitalKey;
    const hasPrecheckin = permissions?.guests?.preCheckin;

    const bgColor = "rgb(251, 251, 251)";
    const fgColor = "rgba(50,50,50,0.6)";
    const fgActiveColor = "rgb(94, 120, 154)";
    const bgAccent = "rgb(94, 120, 154)";
    const fcAccentContrast = "white";

    const gridCols = hasPrecheckin ? "grid-cols-4" : "grid-cols-3";
    const iconFontSize = `${size(6)}px`;
    const gridFontSize = `${size(4)}px`;

    const renderTab = (icon, label, isActive = false) => (
        <div style={isActive ? { color: fgActiveColor } : {}}>
            <i className={`icon ${icon} block py-1`} style={{ fontSize: iconFontSize }}></i>
            <div className="first-capital">{t(label)}</div>
        </div>
    );

    return (
        <>
            {hasDigitalKeys && (
                <div className="relative w-full">
                    <div
                        className="absolute rounded-full shadow-md box-content leading-none z-200"
                        style={{
                            padding: `${size(4)}px`,
                            width: `${size(8)}px`,
                            height: `${size(8)}px`,
                            fontSize: `${size(8)}px`,
                            color: fcAccentContrast,
                            background: bgAccent,
                            top: "-7rem",
                            right: "1rem",
                        }}
                    >
                        <i className="icon icon-key"></i>
                    </div>
                </div>
            )}
            <div
                className="text-center w-full h-26 p-2 border-t border-gray-200"
                style={{
                    backgroundColor: bgColor,
                    color: fgColor,
                }}
            >
                <div className={`grid ${gridCols} place-content-center h-full`} style={{ fontSize: gridFontSize }}>
                    {renderTab("icon-hotel-solid", "property", true)}
                    {hasPrecheckin && renderTab("icon-rooms", "stays")}
                    {renderTab("icon-notifications", "notifications")}
                    {renderTab("icon-user-regular", "profile")}
                </div>
            </div>
        </>
    );
};

export default TabBar;
