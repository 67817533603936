import { gql } from "apollo-boost";

export const ADD_SEQUENCE = gql`
    mutation createSignage($name: String!, $duration: String!, $language: String!) {
        createSignage(name: $name, contentDefaultFilesDuration: $duration, contentLanguageRef: $language) {
            error
            id
            ok
        }
    }
`;

export const UPDATE_SEQUENCE = gql`
    mutation updateSignage($id: Int64!, $name: String, $contentLanguageRef: String) {
        updateSignage(id: $id, name: $name, contentLanguageRef: $contentLanguageRef) {
            error
            id
            ok
        }
    }
`;

export const DELETE_SEQUENCES = gql`
    mutation deleteSignages($ids: [Int64]!) {
        deleteSignages(ids: $ids) {
            error
            id
            ok
        }
    }
`;

export const DUPLICATE_SIGNAGE = gql`
    mutation DuplicateSignages($ids: [Int64!]!) {
        duplicateSiganges(ids: $ids) {
            error
            id
            ok
        }
    }
`;

export const SET_AS_UNAVAILABLE = gql`
    mutation setSignagesAsUnavailable($ids: [Int64]!) {
        setSignagesAsUnavailable(ids: $ids) {
            ok
            error
        }
    }
`;

export const SET_AS_AVAILABLE = gql`
    mutation setSignagesAsAvailable($ids: [Int64]!) {
        setSignagesAsAvailable(ids: $ids) {
            ok
            error
        }
    }
`;

export const SET_AS_DEFAULT = gql`
    mutation setSignageAsDefault($id: Int64!) {
        setSignageAsDefault(id: $id) {
            ok
            error
        }
    }
`;

export const UPDATE_SCHEDULE_SEQUENCE = gql`
    mutation UpdateScheduleSequence(
        $id: Int64!
        $timeRanges: [SignageTimeRangeSET]
        $scheduleStart: Date
        $scheduleEnd: Date
        $scheduleDailyType: SignageScheduleDailyType
        $scheduleDaysOfWeek: [Int]
        $scheduleDaysType: SignageScheduleDaysType
        $destination: destinationSET
    ) {
        updateSignage(
            id: $id
            timeRanges: $timeRanges
            scheduleStart: $scheduleStart
            scheduleEnd: $scheduleEnd
            scheduleDailyType: $scheduleDailyType
            scheduleDaysOfWeek: $scheduleDaysOfWeek
            scheduleDaysType: $scheduleDaysType
            destination: $destination
        ) {
            error
            ok
            id
            __typename
        }
    }
`;

export const GENERATE_CREATE_SIGNAGE_CONTENT_MUTATION = (contentArray) => {
    const mutationString =
        contentArray.length > 0
            ? contentArray
                  .map(
                      (content, index) => `
                content${index}: createSignageContent(
                    signageID: ${content.signageID},
                    type: ${content.type},
                    ref: "${content.ref}",
                    duration: "${content.duration}"
                ) {
                    error
                    id
                    ok
                }
            `
                  )
                  .join("\n")
            : `fakeContent: createSignageContent(
                signageID: 0,
                type: ASSET,
                ref: "fakeRef",
                duration: "0s"
            ) {
                error
                id
                ok
            }`;

    return gql`
        mutation {
            ${mutationString}
        }
    `;
};

export const DELETE_SIGNAGE_CONTENTS = gql`
    mutation deleteSignageContents($signageID: Int64!, $ids: [Int64!]!) {
        deleteSignageContents(signageID: $signageID, ids: $ids) {
            ok
            error
            id
        }
    }
`;

export const UPDATE_SIGNAGE_CONTENT_POSITION = gql`
    mutation SetContentOrderInSignage($signageID: Int64!, $id: Int64!, $pos: Int!) {
        SetContentOrderInSignage(signageID: $signageID, id: $id, pos: $pos) {
            error
            id
            ok
        }
    }
`;

export const UPDATE_SIGNAGE_CONTENT_DURATION = gql`
    mutation UpdateSignageContentDuration($duration: String!, $id: Int64!, $signageID: Int64!) {
        updateSignageContent(duration: $duration, id: $id, signageID: $signageID) {
            ok
            error
            id
        }
    }
`;

export const UPDATA_SIGNAGE_CONTENT = gql`
    mutation updateSignageContent(
        $id: Int64!
        $signageID: Int64!
        $type: SignageContentType!
        $ref: String!
        $duration: String!
    ) {
        updateSignageContent(id: $id, signageID: $signageID, type: $type, ref: $ref, duration: $duration) {
            error
            id
            ok
        }
    }
`;
