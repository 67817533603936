import React, { useEffect, useState, useContext, useParams } from "react";
import { POLLINTERVAL_15S } from "hooks/Utils/Utils";
import { useLazyQuery } from "react-apollo";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useModal } from "components/Modal";
import { gql } from "apollo-boost";
import { arrangeData } from "../utils/monitorUtils";

export const useListDeviceCZ = (id) => {
    const { open, close } = useModal();
    const [devices, setDevices] = useState(null);
    const { permissions } = useSelector((state) => state.ui);
    const { currentPage, perPage } = useSelector((state) => state.table);
    const castEnabled = permissions?.product?.cast ? true : false;

    const { t } = useTranslation();

    const GET_DATA = gql`
        {
            devices: devices (page:${currentPage}, size:${perPage}, filter:{roomID:${id}} ){
                info {
                    count
                    page
                    size
                }
                results {
                    name
                    commercialName
                    function
                    mac
                    ref
                    type
                    wifiSignal
                    firmwareVersion
                    model
                    kind
                    warning{
                        info
                        level
                        roomID
                        actionInfo
                    }
                    location
                    roomType
                    roomID
                    roomTVID
                    roomTVName
                    roomNumber
                    lastActivity
                    lastActivityDate
                    tvInputID
                    ip
                    instructions
                }
            }
            tvInputs: tvInputs{
                results {
                    id
                    name
                    isDefault
                    inputs {
                        name
                        input
                        deviceType
                    }
                }
            }
            rooms(filter: { type: COMMONZONE }) {
                results {
                    name
                    roomTVs {
                        id
                        tvInputID
                        contents
                    }
                }
            }
        }
    `;

    //useQueries&useMutations
    const [executeQuery, { data, loading, error, refetch }] = useLazyQuery(GET_DATA, {
        pollInterval: POLLINTERVAL_15S,
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        executeQuery();
    }, []);

    useEffect(() => {
        if (!loading && !error && data?.devices?.results) {
            const tableDevices = arrangeData(data, t, castEnabled, permissions, open, close, refetch);
            setDevices(tableDevices);
        }
    }, [data, loading, error]);

    return { devices };
};
