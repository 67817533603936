import { gql } from "apollo-boost";

export const GET_HOTEL_ROOMS_STATIC = (orderBy) => gql`
    query hotelRoomStatic(
        $page: Int
        $roomGroupID: [Int64]
        $status: String
        $search: String
        $floorID: [Int64]
        $roomType: RoomType!
        $size: Int
    ) {
        hotelRooms(
            layout: {
                page: $page,
                size: $size,
                ${orderBy ? orderBy : `orderBy: { field: "room", criteria: "ASC" }`}
            }
            filter: {
                search: $search
                status: $status
                roomGroupID: $roomGroupID
                floorID: $floorID
                roomType: $roomType
            }
        ) {
            info {
                count
            }
            results {
                id
                roomNumber
                name
                group
                guests {
                    id
                    name
                }
                status
                stayFrom
                stayTo
                lastStay
                floorID
                warnings
            }
        }
}

`;

export const GET_FILTERS_DATA = gql`
    {
        hotelMap: installationMap {
            info {
                count
            }
            results {
                id
                name
                floors {
                    id
                    name
                }
            }
        }
        roomGroups: roomGroups {
            info {
                count
            }
            results {
                id
                name
            }
        }
    }
`;

export const updateRoomGroupsForRoom = gql`
    mutation updateRoomGroupsForRoom($room: Int64!, $roomGroups: [Int64]) {
        updateRoomGroupsForRoom(room: $room, roomGroups: $roomGroups) {
            error
            id
            ok
        }
    }
`;

export const addRoomsToRoomGroups = gql`
    mutation addRoomsToRoomGroups($rooms: [Int64], $roomGroups: [Int64]) {
        addRoomsToRoomGroups(rooms: $rooms, roomGroups: $roomGroups) {
            error
            id
            ok
        }
    }
`;

export const GET_CZ_LIST = gql`
    {
        rooms(filter: { type: COMMONZONE }) {
            results {
                name
                number
                roomTVs {
                    id
                    tvInputID
                    name
                    contents
                }
                floorID
                floorName
                id
                pmsNumber
                residents
                roomGroups {
                    id
                    name
                }
                warnings {
                    actionInfo
                    date
                    function
                    info
                    level
                    mac
                    model
                    priority
                    roomID
                    state
                }
                wingID
                wingName
            }
        }
    }
`;

export const GET_CZ_GROUPS = gql`
    {
        roomGroups(filter: { type: COMMONZONE }) {
            results {
                id
                name
                rooms {
                    id
                    name
                }
                type
            }
        }
    }
`;

export const GET_FLOORS = gql`
    {
        floors: installationMap {
            results {
                id
                name
                floors {
                    id
                    name
                }
            }
        }
    }
`;
