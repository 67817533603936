import React, { forwardRef } from "react";

import { DEVICE } from "constants/editor";

const Overlay = forwardRef(({ device, marginTop, marginBottom, grid, editingMenu, style }, ref) => {
    const colWidth = device?.colWidth;
    const rowHeight = device?.rowHeight;
    const borderWidth = device?.border;
    const scrollBarWidth = device?.scrollbar;
    const deviceHeight = device?.editor?.height;
    const isMobile = device?.id === DEVICE.TYPE.MOBILE;

    const gridBase = { w: colWidth, h: rowHeight };
    const gridBold = { w: colWidth * 8, h: rowHeight * 8 };
    const gridContainerStyles = {
        bottom: marginBottom + borderWidth + "px",
        left: borderWidth + "px",
        right: scrollBarWidth + borderWidth + "px",
        top: marginTop + borderWidth + "px",
        zIndex: editingMenu ? 350 : undefined,
    };
    const gridCoversStyles = {
        height: deviceHeight ? `${deviceHeight}px` : "auto",
        ...style,
    };
    const gridBaseStyles = {
        backgroundSize: `${gridBase?.w}px ${gridBase?.h}px`,
        backgroundPosition: "0 0",
    };
    const gridBoldStyles = {
        backgroundPosition: isMobile ? `${colWidth * 2}px ${marginTop}px` : `0px ${colWidth * 2}px`,
        backgroundSize: `${gridBold?.w}px ${gridBold?.h}px`,
    };

    return (
        <div ref={ref} className="absolute pointer-events-none overflow-hidden" style={gridContainerStyles}>
            <div
                className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none cover-bg-overlay"
                style={gridCoversStyles}
            ></div>
            {grid ? (
                <>
                    <div
                        className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none cover-grid"
                        style={{ ...gridCoversStyles, ...gridBaseStyles }}
                    ></div>
                    <div
                        className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none cover-grid-bold"
                        style={{ ...gridCoversStyles, ...gridBoldStyles }}
                    ></div>
                </>
            ) : null}
        </div>
    );
});

export default Overlay;
