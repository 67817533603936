import { newRoute, newRedirect } from "routes/utils";

import GeneralSettings from "components/Section/Services/Sales/GeneralSettings";
import Shop from "components/Section/Services/Sales/Shop";
import ShopSidebar from "components/Sidebar/ShopSidebar";
import ShopSettings from "components/Section/Services/Sales/ShopSettings";
import ShopIntegrations from "components/Section/Services/Sales/ShopIntegrations";
import Products from "components/Section/Services/Sales/Products";
import Labels from "components/Section/Services/Sales/Labels";
import ProductSettings from "components/Section/Services/Sales/ProductSettings";
import ShopCategories from "components/Section/Services/Sales/ShopCategories";
import Extras from "components/Section/Services/Sales/components/ShopSettings/ProductSettings/Extras/Extras";
import ExtrasTranslate from "components/Section/Services/Sales/components/ShopSettings/ProductSettings/Extras/ExtrasTranslate";
import ShopAssignedUsers from "components/Section/Services/Sales/ShopAssignedUsers";
import GeneralSettingsAutomaticMessagesEdition from "components/Section/Services/Sales/GeneralSettingsAutomaticMessagesEdition";
import Channels from "components/Section/Services/tvChannels/channels/Channels";
import ChannelEdition from "components/Section/Services/tvChannels/channels/ChannelEdition";
import BulkChannels from "components/Section/Services/tvChannels/channels/BulkChannels";
import GroupChannels from "components/Section/Services/tvChannels/channels/GroupChannels";
import GroupChannelsEdit from "components/Section/Services/tvChannels/channels/GroupChannelsEdit";
import Grids from "components/Section/Services/tvChannels/channels/Grids";
import GridsEdition from "components/Section/Services/tvChannels/channels/GridsEdition";
import ChannelsGeneralSettings from "components/Section/Services/tvChannels/channels/ChannelsGeneralSettings";
import CorporateChannels from "components/Section/Services/tvChannels/channels/CorporateChannels";
import CorporateChannelEdition from "components/Section/Services/tvChannels/channels/CorporateChannelEdition";
import PresetChannels from "components/Section/Services/tvChannels/channels/PresetChannels";
import OrderSummary from "components/Section/Services/Sales/OrderSummary";
import OrderPreview from "components/Section/Customers/Guest/GuesOrders.js/OrderPreview";
import EditShopCategoriesParent from "components/Section/Services/Sales/EditShopCategoriesParent";
import OrderMonitorContainer from "components/Section/Services/Sales/orders/monitor/OrderMonitorContainer";
import ChannelsImport from "components/Section/Services/tvChannels/channels/ChannelsImport";
import MoviesGeneralSettings from "components/Section/Services/Movies/MoviesGeneralSettings";

import WatchedMoviesList from "sections/services/movies/WatchedMoviesList";

const sectionID = "services";

const route = newRoute(sectionID);
const redirect = newRedirect(sectionID);

const checkServices = ({ permissions }) => permissions?.services;
const checkSales = ({ permissions }) => permissions?.services?.productSALES;
const checkOrders = ({ permissions }) => permissions?.services?.shopsOrders;
const checkShopManager = ({ permissions }) => permissions?.services?.shopsManager;
const checkConfig = ({ permissions }) => permissions?.services?.shopsGeneralconfig;
const checkChannels = ({ permissions }) => permissions?.services?.channelList;
const checkCorporateChannels = ({ permissions }) => permissions?.services?.corporateChannelsInGlobalManagement;
const checkGrids = ({ permissions }) => permissions?.services?.channelGrids;
const checkPredefinedChannels = ({ permissions }) => permissions?.services?.predefinedChannels;
const checkChannelGroups = ({ permissions }) => permissions?.services?.channelGroups;
const checkChannelsSettings = ({ permissions }) => permissions?.services?.channelSettings;
const checkMoviesWatched = ({ permissions }) => permissions?.services?.movieSettings;
const checkMoviesSettings = ({ permissions }) => {
    const freeToguest = permissions?.movies?.freeToguest;
    const adultContent = permissions?.movies?.adult;
    return permissions?.services?.movieList && (!freeToguest || adultContent);
};

const routes = [
    route("/", { initial: true, redirect: redirect("/sales/shop"), check: checkServices }),

    // Sales
    route("/sales", { redirect: redirect("/sales/shop") }),
    // Shops
    route("/sales/shop", { section: Shop, check: checkShopManager }),
    // > Categories
    route("/sales/shop/categories/:id", { section: ShopCategories, check: checkShopManager, sidebar: ShopSidebar }),
    route("/sales/shop/categories/:id/edit-category/:idCat", {
        section: EditShopCategoriesParent,
        check: checkShopManager,
        sidebar: ShopSidebar,
    }),
    // > Products
    route("/sales/shop/products/:id", { section: Products, check: checkShopManager, sidebar: ShopSidebar }),
    route("/sales/shop/:id/product/:productId", {
        section: ProductSettings,
        check: checkShopManager,
        sidebar: ShopSidebar,
    }),
    route("/sales/shop/:id/product/:productId/extras", {
        section: Extras,
        check: checkShopManager,
        sidebar: ShopSidebar,
    }),
    route("/sales/shop/:id/product/:productId/extras/translate", {
        section: ExtrasTranslate,
        check: checkShopManager,
        sidebar: ShopSidebar,
    }),
    // > Labels
    route("/sales/shop/labels/:id", {
        section: Labels,
        check: checkShopManager,
        sidebar: ShopSidebar,
    }),
    // > Shop settings
    route("/sales/shop/settings/:id/*", { section: ShopSettings, check: checkShopManager, sidebar: ShopSidebar }),
    // > Integration settings
    route("/sales/shop/integrations/:id", { section: ShopIntegrations, check: checkShopManager, sidebar: ShopSidebar }),
    // > Assigned users
    route("/sales/shop/assigned-users/:id", {
        section: ShopAssignedUsers,
        check: checkShopManager && checkOrders,
        sidebar: ShopSidebar,
    }),
    // Orders
    route("/sales/order-summary", { section: OrderSummary, check: checkOrders }),
    route("/sales/order-monitor/:shopId", { section: OrderMonitorContainer, check: checkOrders, fullscreen: true }),
    route("/sales/order-monitor/preview/:id", { section: OrderPreview, check: checkSales, fullscreen: true }),
    // General settings
    route("/sales/general-settings", { section: GeneralSettings, check: checkConfig }),
    route("/sales/general-settings/automatic-messages/:type", {
        section: GeneralSettingsAutomaticMessagesEdition,
        check: checkConfig,
    }),

    // TV Channels
    route("/tv-channels", { redirect: redirect("/tv-channels/channels") }),
    // Grids
    route("/tv-channels/grids", { section: Grids, check: checkGrids }),
    route("/tv-channels/grids/:gridId", { section: GridsEdition, check: checkGrids }),
    route("/tv-channels/grids/:gridId/group/:groupId", { section: GroupChannelsEdit, check: checkGrids }),
    // Channels
    route("/tv-channels/channels", { section: Channels, check: checkChannels }),
    route("/tv-channels/channels/import", { section: ChannelsImport, check: checkChannels, fullscreen: true }),
    route("/tv-channels/channel/:id", { section: ChannelEdition, check: checkChannels }),
    route("/tv-channels/channels/add-bulk", { section: BulkChannels, check: checkChannels }),
    // Groups
    route("/tv-channels/groups", { section: GroupChannels, check: checkChannelGroups }),
    route("/tv-channels/groups/:groupId", { section: GroupChannelsEdit, check: checkChannelGroups }),
    route("/tv-channels/groups/:groupId/edit-channel/:id", { section: ChannelEdition, check: checkChannelGroups }),
    // General settings
    route("/tv-channels/general-settings", { section: ChannelsGeneralSettings, check: checkChannelsSettings }),
    // Corporate Channels
    route("/tv-channels/corporate-channels", { section: CorporateChannels, check: checkCorporateChannels }),
    route("/tv-channels/corporate-channels/:id", { section: CorporateChannelEdition, check: checkCorporateChannels }),
    // Preset channels
    route("/tv-channels/preset-channels", { section: PresetChannels, check: checkPredefinedChannels }),
    route("/tv-channels/preset-channels/bulk-channels", { section: PresetChannels, check: checkPredefinedChannels }),

    // Movies
    // Watched mobie list
    route("/movies/watched-list", { section: WatchedMoviesList, check: checkMoviesWatched }),
    // General settings
    route("/movies/general-settings", { section: MoviesGeneralSettings, check: checkMoviesSettings }),
];

export default routes;
