import React from "react";
import { useDispatch, useSelector } from "react-redux";
import UseIcon from "../../useIcon";
import { changeGlobalAction } from "../../../actions/globalActions";
import { openModal, setModalContent } from "../../../actions/uiActions";
import ChangeRoomCodeModal from "../../../hooks/GraphqlCalls/Hotel/Rooms/modals/ChangeRoomCodeModal";
import { useTranslation } from "react-i18next";

function UseRoomCodeCard({ data }) {
    const { headerText, roomName, roomId, roomDeviceId, roomCode, roomNumber } = data;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const changeRoomCode = () => {
        const modal = ChangeRoomCodeModal(roomName, roomId, roomDeviceId, roomCode, roomNumber);
        dispatch(
            changeGlobalAction({
                actionName: "chromecast-change-pairing",
                itemsAffected: roomId,
                values: {
                    roomid: roomId,
                    mac: roomDeviceId,
                },
            })
        );
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };
    return (
        <div className={`dc-card-container bg-white text-blue-400  grid grid-cols-3`}>
            <div className="col-span-2 first-capital">{t(headerText)}</div>
            <div className=" text-right">
                <UseIcon icon={{ name: "pairing" }} adjust="text-4xl"></UseIcon>
            </div>
            <div className="col-span-3 first-capital text-lg font-bold mt-5 flex">
                <div
                    id="room-code"
                    className=" inline overflow-ellipsis whitespace-nowrap"
                    style={{ maxWidth: "75%", overflow: "hidden" }}
                >
                    {`${roomCode ? roomCode : ""}`}
                </div>
            </div>
        </div>
    );
}

export default UseRoomCodeCard;
