import React, { useState, useEffect, forwardRef, createRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import HTMLReactParser from "html-react-parser";
import md5 from "md5";

import { safeJsonParse, safeJsonStringify } from "hooks/Utils/Utils";

import { SCREEN, DEVICE, WIDGET, WIDGET_TOOLBAR, CONTENT_SCREEN_TOOLBAR } from "constants/editor";
import useDevice, { DEVICES } from "components/Editor/devices";

import Icon from "components/Icon";
import Switch from "components/Switch";
import Button from "components/Button";
import Warning from "components/Warning";

import { EditorProvider } from "contexts/editor";

import GridLayout from "./layout";
import Overlay from "./overlay";
import Item from "./item";
import Toolbar, { Settings } from "./toolbar";
import ErrorInfo from "components/ErrorInfo";
import WelcomeOKToContinue from "components/Section/Design/WelcomeOKToContinue";

export const EDITING_SCREEN = "screen";
export const EDITING_MENU = "menu";
export const EDITING_TOPBAR = "topbar";
export const EDITING_ROOMS = "rooms";

const Editor = forwardRef(
    (
        {
            screenType,
            deviceType,
            customDevice,
            customDAS,
            showGrid: initialShowGrid,
            onShowGridChange,
            widgets: initialWidgets,
            config: initialConfig,
            backgroundImage: forceBackgroundImage,
            theme,
            lang,
            readOnly,
            droppingItem,
            onReadyChange,
            onChange,
            onEditingChange,
        },
        ref
    ) => {
        const { t } = useTranslation();

        const [mode, setMode] = useState(EDITING_SCREEN);
        const [selected, setSelected] = useState(false);

        const permissions = {
            guests: {
                digitalKey: true,
            },
        };

        const evaluateWidgets = (widgets) => {
            const widgetTopBar = widgets?.length ? widgets.find((widget) => widget?.type === WIDGET.TYPE.TOPBAR) : null;
            const widgetRooms = widgets?.length ? widgets.find((widget) => widget?.type === WIDGET.TYPE.ROOMS) : null;
            const allWidgets = widgets?.length ? widgets : [];
            let updateWidgets = false;

            if (ensureWidgetTopBar) {
                if (!widgetTopBar) {
                    // Add widget topbar
                    allWidgets.push({
                        id: "topbar",
                        type: WIDGET.TYPE.TOPBAR,
                        actions: null,
                        data: null,
                        style: null,
                        layout: {
                            w: device?.cols,
                            h: (2 / defaultDevice?.rows) * device?.rows,
                            x: 0,
                            y: 0,
                            isDraggable: false,
                            isResizable: false,
                            outOfGrid: true,
                        },
                    });
                    updateWidgets = true;
                } else {
                    // Ensure widget topbar layout
                    widgetTopBar.layout = {
                        w: device?.cols,
                        h: (2 / defaultDevice?.rows) * device?.rows,
                        x: 0,
                        y: 0,
                        isDraggable: false,
                        isResizable: false,
                        outOfGrid: true,
                    };
                }
            } else if (widgetTopBar) {
                // Remove widget topbar
                allWidgets.splice(allWidgets.indexOf(widgetTopBar), 1);
                updateWidgets = true;
            }

            // Widget ROOMS is only available in mobile landing screens
            if (ensureWidgetRooms) {
                if (!widgetRooms) {
                    // Add widget rooms
                    allWidgets.push({
                        id: "rooms",
                        type: WIDGET.TYPE.ROOMS,
                        actions: null,
                        data: null,
                        style: null,
                        layout: {
                            w: device?.cols,
                            h: (2 / defaultDevice?.rows) * device?.rows,
                            x: 0,
                            y: 0,
                            isDraggable: false,
                            isResizable: false,
                            outOfGrid: true,
                        },
                    });
                    updateWidgets = true;
                } else {
                    // Ensure widget rooms layout
                    widgetRooms.layout = {
                        w: device?.cols,
                        h: (2 / defaultDevice?.rows) * device?.rows,
                        x: 0,
                        y: 0,
                        isDraggable: false,
                        isResizable: false,
                        outOfGrid: true,
                    };
                }
            } else if (widgetRooms) {
                // Remove widget rooms
                allWidgets.splice(allWidgets.indexOf(widgetRooms), 1);
                updateWidgets = true;
            }
            // Widget WIFILOGIN is only available in portal landing screens
            if (ensureWidgetWiFiLogin) {
                const widgetWiFiLogin = widgets?.length
                    ? widgets.find((widget) => widget?.type === WIDGET.TYPE.WIFILOGIN)
                    : null;
                if (!widgetWiFiLogin) {
                    // Add widget WiFiLogin
                    allWidgets.push({
                        id: "wifi-login",
                        type: WIDGET.TYPE.WIFILOGIN,
                        actions: null,
                        data: null,
                        style: null,
                        layout: {
                            w: 24,
                            h: 24,
                            x: 20,
                            y: 10,
                            isResizable: true,
                            isDraggable: true,
                        },
                    });
                    updateWidgets = true;
                } else {
                    // Ensure widget WiFiLogin layout
                    widgetWiFiLogin.layout = {
                        ...widgetWiFiLogin.layout,
                        w: widgetWiFiLogin?.layout?.w || 24,
                        h: widgetWiFiLogin?.layout?.h || 24,
                        x: widgetWiFiLogin?.layout?.x ?? 20,
                        y: widgetWiFiLogin?.layout?.y ?? 10,
                        isResizable: true,
                        isDraggable: true,
                    };
                }
            } else {
                const widgetWiFiLogin = widgets?.length
                    ? widgets.find((widget) => widget?.type === WIDGET.TYPE.WIFILOGIN)
                    : null;
                if (widgetWiFiLogin) {
                    // Remove widget WiFiLogin
                    allWidgets.splice(allWidgets.indexOf(widgetWiFiLogin), 1);
                    updateWidgets = true;
                }
            }

            // Widget TVMENU is only available in TV landing screens
            if (ensureWidgetTVMenu) {
                const widgetMenu = widgets?.length
                    ? widgets.find((widget) => widget?.type === WIDGET.TYPE.TVMENU)
                    : null;
                if (!widgetMenu) {
                    // Add widget menu
                    allWidgets.push({
                        id: "menu",
                        type: WIDGET.TYPE.TVMENU,
                        actions: null,
                        data: null,
                        style: null,
                        layout: {
                            w: device?.cols,
                            h: (6 / defaultDevice?.rows) * device?.rows,
                            x: 0,
                            y: 0,
                            isResizable: true,
                            isDraggable: true,
                            allowOverlap: true,
                        },
                    });
                    updateWidgets = true;
                } else {
                    // Ensure widget menu layout
                    widgetMenu.layout = {
                        w: widgetMenu?.layout?.w || device?.cols,
                        h: widgetMenu?.layout?.h || (6 / defaultDevice?.rows) * device?.rows,
                        x: widgetMenu?.layout?.x ?? 0,
                        y: widgetMenu?.layout?.y ?? 0,
                        isResizable: true,
                        isDraggable: true,
                        allowOverlap: true,
                    };
                }
            } else {
                const widgetMenu = widgets?.length
                    ? widgets.find((widget) => widget?.type === WIDGET.TYPE.TVMENU)
                    : null;
                if (widgetMenu) {
                    // Remove widget menu
                    allWidgets.splice(allWidgets.indexOf(widgetMenu), 1);
                    updateWidgets = true;
                }
            }
            //

            if (updateWidgets) {
                return allWidgets;
            }

            return widgets;
        };

        const ensureWidgetTVMenu = screenType === SCREEN.TYPE.TV;
        const ensureWidgetWiFiLogin = screenType === SCREEN.TYPE.PORTAL;

        const ensureWidgetTopBar =
            deviceType === DEVICE.TYPE.MOBILE &&
            (screenType === SCREEN.TYPE.MOBILE || screenType === SCREEN.TYPE.GENERAL);
        const ensureWidgetRooms = screenType === SCREEN.TYPE.MOBILE;
        const showAppTabBar = ensureWidgetTopBar;
        const showAppKey = showAppTabBar && permissions?.guests?.digitalKey;
        const showPressOKToContinue = [SCREEN.TYPE.WELCOME_WIDGETS, SCREEN.TYPE.WELCOME_VIDEO].includes(screenType);

        const [area, setArea] = useState(null);

        const [areaRef, setAreaRef] = useState(null);
        const [widgets, setWidgets] = useState(null);
        const [config, setConfig] = useState(null);
        const [containerRef, setContainerRef] = useState(null);
        const [scrollAreaRef, setScrollAreaRef] = useState(null);
        const [editorRef, setEditorRef] = useState(null);
        const [gridRef, setGridRef] = useState(null);
        const [dragging, setDragging] = useState(null);

        const draftHash = md5(safeJsonStringify(widgets || []) + "::" + safeJsonStringify(config || {}) || "[]");

        const backgroundColor = config?.style?.bgColor;
        const bgImage = config?.style?.images?.[0];
        const backgroundImage =
            forceBackgroundImage ||
            (bgImage?.libraryRef ? customDAS + "/" + bgImage?.libraryRef : bgImage?.externalUrl);
        const backgroundOpacity =
            (!config?.style?.opacity && String(config?.style?.opacity) !== "0" ? 100 : config?.style?.opacity) / 100;

        const widgetRooms = widgets?.length ? widgets.find((widget) => widget?.type === WIDGET.TYPE.ROOMS) : null;
        const widgetTopBar = widgets?.length ? widgets.find((widget) => widget?.type === WIDGET.TYPE.TOPBAR) : null;

        const [showGrid, setShowGrid] = useState();

        const showOverlay = showGrid || dragging;

        const defaultDevice = { ...DEVICES.find((d) => d.id === deviceType) };
        const device = useDevice({ editorRef, deviceType, customDevice, area, readOnly });

        const topBarHeight = ensureWidgetTopBar
            ? (2 / defaultDevice?.rows) * (device?.rows * device?.rowHeight + device?.gap?.y * (device?.rows - 1))
            : 0;
        const roomsHeight = ensureWidgetRooms
            ? (2 / defaultDevice?.rows) * (device?.rows * device?.rowHeight + device?.gap?.y * (device?.rows - 1))
            : 0;
        const tabBarHeight = showAppTabBar ? topBarHeight : 0;
        const marginTop = topBarHeight + roomsHeight;
        const marginBottom = tabBarHeight;

        const [scrollEnabled, setScrollEnabled] = useState(false);

        const scrollAreaClass = classNames({
            screenScrollable: true,
            "overflow-x-hidden overflow-y-scroll": scrollEnabled,
            "border-gray-400": !(selected && mode === EDITING_SCREEN),
            "border-zafiro-400": selected && mode === EDITING_SCREEN,
        });

        const onEditorResize = (e) => {
            for (let entry of e) {
                setArea((current) => {
                    const areaWidth = area?.width;
                    const currentWidth = entry?.contentRect?.width;
                    if (currentWidth && currentWidth !== areaWidth && device?.ratio) {
                        return {
                            width: Math.min(currentWidth, window.innerWidth * 0.9),
                            height: Math.min(currentWidth / device?.ratio, window.innerHeight * 0.75),
                        };
                    }
                    return current;
                });
            }
        };

        useImperativeHandle(ref, () => ({
            getSize: (w, h) => {
                return [(w || 0) * (device?.colWidth || 0), (h || 0) * (device?.rowHeight || 0)];
            },
        }));

        const ready = device?.width > 0 && device?.height > 0;

        useEffect(() => {
            console.log("TEST LOAD Editor");
        }, []);

        useEffect(() => {
            if (onShowGridChange) {
                onShowGridChange(showGrid);
            }
        }, [showGrid]);

        useEffect(() => {
            if (onEditingChange) {
                onEditingChange(mode);
            }
        }, [mode]);

        useEffect(() => {
            if (ready && onReadyChange) {
                onReadyChange(ready);
            }
        }, [ready]);

        useEffect(() => {
            if (!droppingItem) {
                setDragging(false);
            }
        }, [droppingItem]);

        useEffect(() => {
            // Reset the editor when the device changes
            setAreaRef(createRef());
            setContainerRef(createRef());
            setScrollAreaRef(createRef());
            setEditorRef(createRef());
            setGridRef(createRef());
            setWidgets(evaluateWidgets(initialWidgets));
            setConfig(initialConfig);
            setMode(EDITING_SCREEN);
            setSelected(!readOnly);
            setShowGrid(!readOnly && initialShowGrid !== false);
        }, [screenType, deviceType, device?.cols, device?.rows, readOnly]);

        useEffect(() => {
            console.log("TEST useEffect draftHash");
            if (onChange && !readOnly) {
                onChange({ widgets, config, hash: draftHash });
            }
        }, [draftHash]);

        useEffect(() => {
            const onScroll = () => {
                if (scrollAreaRef?.current && gridRef?.current) {
                    gridRef.current.scrollTo(scrollAreaRef.current.scrollLeft, scrollAreaRef.current.scrollTop);
                }
            };
            if (scrollAreaRef?.current && gridRef?.current) {
                scrollAreaRef.current.addEventListener("scroll", onScroll);
            }
            return () => {
                if (scrollAreaRef?.current) {
                    scrollAreaRef.current.removeEventListener("scroll", onScroll);
                }
            };
        }, [scrollAreaRef?.current, gridRef?.current]);

        useEffect(() => {
            const observer = new ResizeObserver(onEditorResize);
            if (areaRef?.current) {
                observer.observe(areaRef.current);
            }
            return () => {
                if (observer) {
                    observer.disconnect();
                }
            };
        }, [areaRef?.current]);

        const onWindowResize = () => {
            setArea((current) => {
                if (current?.height) {
                    return {
                        ...current,
                        height: Math.min(current.height, window.innerHeight * 0.7),
                    };
                }
                return current;
            });
        };

        useEffect(() => {
            window.addEventListener("resize", onWindowResize);
            return () => window.removeEventListener("resize", onWindowResize);
        }, []);

        const title = mode === EDITING_MENU ? t("menu") : t("screen");

        const screenToolbar = [
            ...CONTENT_SCREEN_TOOLBAR,
            WIDGET_TOOLBAR.DEBUG, //TODO remove this
        ];

        const widgetMenu = widgets?.length ? widgets.find((widget) => widget?.type === WIDGET.TYPE.TVMENU) : null;
        const blurMainScreen =
            mode === EDITING_MENU && widgetMenu?.data?.menuType === "hidden-menu" && widgetMenu?.data?.blurMainScreen;

        return (
            <EditorProvider
                value={{
                    deviceType,
                    lang,
                    editorSize: {
                        width: device?.width,
                        height: device?.height,
                        my: 0,
                        mx: 0,
                        cols: device?.cols,
                        rows: device?.rows,
                    },
                    itemSelected: null,
                    customDAS,
                    screenType,
                    theme,
                }}
            >
                <div
                    ref={areaRef}
                    className={classNames({
                        "zafiro-editor px-5": true,
                        "read-only": readOnly,
                    })}
                >
                    {!device?.loading && !readOnly ? (
                        <div className="top-bar" style={{ width: device?.width + "px" }}>
                            <Switch
                                label={t("Show grid")}
                                labelPosition="left"
                                checked={showGrid}
                                onChange={({ checked }) => setShowGrid(checked)}
                            />
                            {screenType === SCREEN.TYPE.TV ? (
                                <>
                                    {mode === EDITING_MENU ? (
                                        <div className="flex items-center space-x-5">
                                            <span className="text-gray-700 font-medium">{t("editing-menu")}</span>
                                            <Button
                                                design="link"
                                                id="edit-menu"
                                                onClick={() => setMode(EDITING_SCREEN)}
                                            >
                                                {t("finalize-changes")}
                                            </Button>
                                        </div>
                                    ) : (
                                        <Button
                                            design="link"
                                            id="edit-menu"
                                            onClick={() => {
                                                setMode(EDITING_MENU);
                                                setSelected(true);
                                            }}
                                        >
                                            <Icon type="settings" size={1.4} text={t("edit-menu")} />
                                        </Button>
                                    )}
                                </>
                            ) : null}
                        </div>
                    ) : null}
                    <div
                        ref={containerRef}
                        className="screen"
                        style={{
                            width: device?.width + "px",
                            height: device?.height + "px",
                            background: "none",
                        }}
                    >
                        <div
                            ref={scrollAreaRef}
                            id="scroll-area"
                            className={scrollAreaClass}
                            style={{
                                height: device?.height + "px",
                                borderWidth: `${device?.border}px`,
                                backgroundColor,
                            }}
                            onClick={
                                readOnly
                                    ? null
                                    : () => {
                                          if (mode !== EDITING_MENU) {
                                              setMode(EDITING_SCREEN);
                                              setSelected(true);
                                          }
                                      }
                            }
                        >
                            {!device?.loading ? (
                                <>
                                    {selected && !readOnly && mode === EDITING_SCREEN ? (
                                        <div
                                            className="select-none text-white text-center bg-zafiro-400 z-200 py-1 px-3 rounded-tl rounded-tr"
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                transformOrigin: "0 0",
                                                transform:
                                                    "rotate(90deg) rotate(180deg) translateX(-100%) translateY(-100%)",
                                            }}
                                        >
                                            {title}
                                        </div>
                                    ) : null}
                                    <Overlay
                                        device={device}
                                        marginTop={marginTop}
                                        marginBottom={marginBottom}
                                        style={{
                                            backgroundColor: "transparent",
                                            backgroundImage: backgroundImage ? `url('${backgroundImage}')` : null,
                                            backgroundSize: "cover",
                                            opacity: backgroundOpacity,
                                            filter: blurMainScreen ? "blur(2px)" : null,
                                        }}
                                    />
                                    {showOverlay ? (
                                        <Overlay
                                            ref={gridRef}
                                            grid={showGrid}
                                            device={device}
                                            marginTop={marginTop}
                                            marginBottom={marginBottom}
                                            editingMenu={mode === EDITING_MENU}
                                        />
                                    ) : null}
                                    {ensureWidgetTopBar ? (
                                        <div
                                            id="top-bar-widget"
                                            className={classNames({
                                                "react-grid-item absolute shadow bg-white": true,
                                                selected: mode === EDITING_TOPBAR,
                                            })}
                                            style={{
                                                top: `${device?.border}px`,
                                                left: `${device?.border}px`,
                                                right: `${device?.scrollbar + device?.border}px`,
                                                bottom: "auto",
                                                height: topBarHeight + "px",
                                                cursor: readOnly ? "default" : "pointer",
                                                zIndex: 260,
                                            }}
                                            onClick={
                                                readOnly
                                                    ? null
                                                    : (e) => {
                                                          if (e) {
                                                              e.stopPropagation();
                                                          }
                                                          setMode(EDITING_TOPBAR);
                                                          setSelected(true);
                                                      }
                                            }
                                        >
                                            <Item
                                                widget={widgetTopBar}
                                                layoutRef={editorRef}
                                                selected={mode === EDITING_TOPBAR}
                                            />
                                        </div>
                                    ) : null}
                                    {ensureWidgetRooms ? (
                                        <div
                                            id="rooms-widget"
                                            className={classNames({
                                                "react-grid-item absolute shadow bg-white": true,
                                                selected: mode === EDITING_ROOMS,
                                            })}
                                            style={{
                                                top: `${device?.border + topBarHeight}px`,
                                                left: `${device?.border}px`,
                                                right: `${device?.scrollbar + device?.border}px`,
                                                bottom: "auto",
                                                height: roomsHeight + "px",
                                                cursor: readOnly ? "default" : "pointer",
                                                zIndex: 250,
                                            }}
                                            onClick={
                                                readOnly
                                                    ? null
                                                    : (e) => {
                                                          if (e) {
                                                              e.stopPropagation();
                                                          }
                                                          setMode(EDITING_ROOMS);
                                                          setSelected(true);
                                                      }
                                            }
                                        >
                                            <Item
                                                widget={widgetRooms}
                                                layoutRef={editorRef}
                                                selected={mode === EDITING_ROOMS}
                                            />
                                        </div>
                                    ) : null}
                                </>
                            ) : null}

                            <GridLayout
                                ref={editorRef}
                                readOnly={readOnly}
                                disabled={mode === EDITING_MENU}
                                blur={blurMainScreen}
                                deviceType={deviceType}
                                widgets={widgets}
                                onChange={setWidgets}
                                editorSelected={selected}
                                onItemSelect={
                                    mode !== EDITING_MENU
                                        ? () => {
                                              setMode(EDITING_SCREEN);
                                              setSelected(false);
                                          }
                                        : null
                                }
                                onDraggingChange={setDragging}
                                droppingItem={droppingItem}
                                onScrollableChange={(enabled) => setScrollEnabled(enabled)}
                                style={{
                                    marginTop: marginTop + "px",
                                    marginBottom: marginBottom * 2 + "px",
                                    [scrollEnabled ? "minHeight" : "height"]: `${
                                        device?.height - device?.border * 2 - marginTop
                                    }px`,
                                }}
                                cols={device?.cols}
                                rows={device?.rows}
                                margin={device?.margin}
                                gap={device?.gap}
                                scrollX={device?.scrollX}
                                scrollY={device?.scrollY}
                                rowHeight={device?.rowHeight}
                                autoSize={true}
                                draggableHandle={".widget-label"}
                                scrollable={device?.scrollbar}
                            />
                            {!device?.loading && !readOnly ? (
                                <Toolbar
                                    visible={selected && mode === EDITING_SCREEN}
                                    targetRef={null}
                                    layoutRef={editorRef}
                                    menu={
                                        screenToolbar?.length
                                            ? screenToolbar.map((type) => ({
                                                  type,
                                                  content: (
                                                      <Settings
                                                          id={type}
                                                          data={config}
                                                          onChange={(config) => {
                                                              setConfig(config);
                                                          }}
                                                      />
                                                  ),
                                              }))
                                            : []
                                    }
                                />
                            ) : null}
                        </div>
                        {!device?.loading ? (
                            <>
                                {showAppKey ? (
                                    <div
                                        className="decoration absolute p-4 rounded-full box-content text-white bg-zafiro-400 shadow-md leading-none"
                                        style={{
                                            right: `${device?.scrollbar + device?.border + 5}px`,
                                            bottom: `calc(${marginBottom}px + 1rem)`,
                                        }}
                                    >
                                        <Icon type="key" size={1.8} />
                                    </div>
                                ) : null}
                                {showAppTabBar ? (
                                    <div
                                        className="decoration absolute pt-3 pb-2 bg-white shadow border border-gray-300 text-center text-gray-700 text-sm"
                                        style={{
                                            bottom: `${device?.border}px`,
                                            left: `${device?.border}px`,
                                            right: `${device?.scrollbar + device?.border}px`,
                                            top: "auto",
                                            height: tabBarHeight + "px",
                                        }}
                                    >
                                        <div className="grid grid-cols-3 place-content-center h-full">
                                            <div className="space-y-1 text-zafiro-400">
                                                <Icon type="hotel-solid" size={1.5} />
                                                <div className="first-capital">{t("property")}</div>
                                            </div>
                                            <div className="space-y-1">
                                                <Icon type="notifications" size={1.5} />
                                                <div className="first-capital">{t("notifications")}</div>
                                            </div>
                                            <div className="space-y-1">
                                                <Icon type="user-regular" size={1.5} />
                                                <div className="first-capital">{t("profile")}</div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                {showPressOKToContinue ? (
                                    <div
                                        className="decoration absolute bottom-0 right-0"
                                        style={{
                                            bottom: `${device?.border + 5}px`,
                                            right: `${device?.scrollbar + device?.border + 5}px`,
                                        }}
                                    >
                                        <WelcomeOKToContinue />
                                    </div>
                                ) : null}
                            </>
                        ) : null}
                    </div>
                </div>
            </EditorProvider>
        );
    }
);

export const ScreenEditor = forwardRef((props, ref) => {
    const { t } = useTranslation();

    let editorProps = { ...props };
    delete editorProps?.leftComponent;

    const [ready, setReady] = useState(false);
    const [data, setData] = useState(null);
    const [currentWidgets, setCurrentWidgets] = useState(null);
    const [currentConfig, setCurrentConfig] = useState(null);

    const screenData = editorProps?.screenData;
    delete editorProps.screenData;

    const isOldVersion = data?.version < 2;

    editorProps.readOnly = props?.readOnly ?? isOldVersion;
    editorProps.showGrid = props?.showGrid ?? (isOldVersion ? false : undefined);
    editorProps.screenType = props?.screenType ?? data?.screenType;
    editorProps.deviceType = props?.deviceType ?? data?.deviceType;
    editorProps.customDAS = props?.customDAS || "/das";
    editorProps.customDevice = props?.customDevice ?? (isOldVersion ? data?.customDevice : null);
    editorProps.widgets = currentWidgets;
    editorProps.config = currentConfig;
    editorProps.onChange = ({ widgets, config, hash }) => {
        setCurrentWidgets(safeJsonParse(safeJsonStringify(widgets || []) || "[]"));
        setCurrentConfig(config);
        if (props.onChange) {
            props.onChange({ widgets, config, hash });
        }
    };

    useEffect(() => {
        const content = screenData?.content;
        const dbContentDevice = content?.devices?.[0]?.type;
        const dbContentStyle = safeJsonParse(content?.contentStyle);
        const currentDevice = content?.devices?.find((device) => device?.type === dbContentDevice);

        const contentID = content?.id || screenData?.contentId || screenData?.id;
        const contentType = screenData?.contentType || screenData?.type;

        setData((data) => ({
            ...data,
            id: contentID,
            version: screenData?.version || (contentID ? 1 : 2),
            deviceType: dbContentDevice,
            screenType: contentType,
            customDevice:
                currentDevice?.grid || dbContentStyle?.margin || dbContentStyle?.itemsMargin
                    ? {
                          cols: currentDevice?.grid?.cols,
                          rows: currentDevice?.grid?.rows,
                          margin: contentType === SCREEN.TYPE.TV ? null : dbContentStyle?.margin,
                          itemsMargin: dbContentStyle?.itemsMargin,
                      }
                    : null,
        }));

        if (!props?.widgets) {
            const widgets = content?.contentWidgets?.map((widget) =>
                dbWidget2Widget(widget, safeJsonParse(content?.pos)?.[dbContentDevice])
            );
            setCurrentWidgets(widgets || []);
        } else {
            setCurrentWidgets(props?.widgets);
        }
        console.log("TEST content", content);
        if (!props?.config) {
            setCurrentConfig({
                //pos: safeJsonParse(content?.pos)?.[dbContentDevice],
                style: dbContentStyle,
            });
        } else {
            setCurrentConfig(props?.config);
        }
        setReady(true);
    }, [screenData?.id]);

    if (!editorProps.deviceType) {
        return <ErrorInfo>{editorProps}</ErrorInfo>;
    }

    const readOnlyReason =
        props?.readOnlyReason ||
        (isOldVersion
            ? t(
                  "This screen was designed in a previous version of the editor. It must be <strong>duplicated</strong> to be able to edit it."
              )
            : null);

    return (
        <>
            {editorProps.readOnly && readOnlyReason ? (
                <div className="px-5 bg-gray-100 border-gray-200 text-gray-900 shadow-sm mb-2">
                    <Warning id="old-screen-warning">{HTMLReactParser(readOnlyReason)}</Warning>
                </div>
            ) : null}
            <div className="grid grid-flow-col">
                {ready ? props?.leftComponent : null}
                {ready ? <Editor ref={ref} {...editorProps} /> : null}
            </div>
        </>
    );
});

const dbWidget2Widget = (widget, positions) => {
    const data = safeJsonParse(widget?.data);
    const actions = safeJsonParse(widget?.actions);
    const style = safeJsonParse(widget?.widgetStyle);
    const id = widget?.id || widget?.ID;
    const pos = positions?.find((pos) => pos?.ID === id);
    const widgetPositions = safeJsonParse(widget?.pos);
    return {
        id,
        type: widget?.type,
        actions,
        data,
        style,
        widgets: widget?.widgets || widget?.contentWidgets?.map((widget) => dbWidget2Widget(widget, widgetPositions)),
        layout: {
            w: pos?.W,
            h: pos?.H,
            x: pos?.X,
            y: pos?.Y,
        },
    };
};

export default Editor;
