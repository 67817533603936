import Button from "components/Button";
import Icon from "components/Icon";
import ReactTooltip from "components/ReactTooltip";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AssignToGroupModal } from "../Rooms/models/HotelRoomsModel";
import Modal, { useModal } from "components/Modal";
import { useLazyQuery } from "react-apollo";
import { GET_CZ_GROUPS, GET_CZ_LIST, GET_FLOORS } from "../utils/roomsListQueries";

export const useListCZ = () => {
    const [rows, setRows] = useState([]);
    const [filters, setFilters] = useState([]);

    const { t } = useTranslation();
    const { open } = useModal();

    const [executeCZListQuery, { data, error, loading, refetch }] = useLazyQuery(GET_CZ_LIST, {
        fetchPolicy: "network-only",
    });

    const [executeCZGroupsQuery, { data: commonZonesGroupsData, loading: commonZonesLoading }] = useLazyQuery(
        GET_CZ_GROUPS,
        { fetchPolicy: "network-only" }
    );

    const [executeFloorsQuery, { data: floorsData, loading: floorsLoading }] = useLazyQuery(GET_FLOORS, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        executeCZListQuery();
        executeCZGroupsQuery();
        executeFloorsQuery();
    }, []);

    useEffect(() => {
        if (
            data &&
            !loading &&
            !error &&
            commonZonesGroupsData &&
            !commonZonesLoading &&
            floorsData &&
            !floorsLoading
        ) {
            const filterGroup = commonZonesGroupsData?.roomGroups?.results?.map((group) => ({
                id: group.id,
                value: group.name,
                label: group.name,
            }));
            const filterFloors = floorsData?.floors?.results?.map((floor) => {
                const { id, name, floors: results } = floor;
                return {
                    id,
                    label: name,
                    options: results?.map((floor) => ({
                        id: floor.id,
                        label: floor.name,
                        value: floor.id,
                    })),
                };
            });

            const commonZones = getTableRows(data?.rooms?.results, t, refetch, filterGroup, open);
            setRows(commonZones);
            setFilters([filterFloors, filterGroup]);
        }
    }, [data, loading, error, floorsData]);

    return {
        rows,
        loading,
        filters,
        refetch,
    };
};

const getTableRows = (data, t, refetch, filterGroup, open) => {
    return data.map((row) => {
        return {
            id: row.id,
            floorFilter: row?.floorID,
            roomName: row.name,
            groupFilter: row?.roomGroups?.map((group) => group?.name),
            name: (
                <div className="flex flex-col" alt={`${row?.name} ${row?.number}`}>
                    <Button id={row?.id} to={`/hotel/zones/zone/detail/${row?.id}`} design="link">
                        {row?.name}
                    </Button>
                    <span className="text-gray-900">{row?.number}</span>
                </div>
            ),
            group: getGroups(row?.roomGroups),
            "device-notices": row?.warnings > 0 && (
                <div className="flex items-center gap-3" sortvalue={row?.warnings}>
                    <Icon type="warning" />
                    <span>
                        {row?.warnings?.length} {row?.warnings?.length > 1 ? t("warnings") : t("warning")}
                    </span>
                </div>
            ),
            content: row?.roomTVs?.map((t) => getIcon(t?.contents)),
            rowConfig: {
                actions: [
                    {
                        id: "view-detail",
                        label: t("view-detail"),
                        onClick: () => {
                            window.location.href = `#/hotel/zones/zone/detail/${row?.id}`;
                        },
                    },
                    {
                        id: "assign-to-group",
                        label: t("assign-to-group"),
                        onClick: () =>
                            open(
                                <Modal
                                    id="add-to-group"
                                    title={
                                        <h1 className="text-2xl font-bold">
                                            {row?.name} - {t("assign-to-group")}
                                        </h1>
                                    }
                                    minWidth="30rem"
                                    className="w-1/3 p-10"
                                >
                                    <AssignToGroupModal
                                        t={t}
                                        filterGroup={filterGroup}
                                        refetch={refetch}
                                        roomsId={row?.id}
                                        groupsSelected={row?.roomGroups?.map((group) => group?.name)}
                                    />
                                </Modal>
                            ),
                    },
                ],
            },
        };
    });
};

const getGroups = (groups) => {
    if (groups.length > 0) {
        return (
            <div className="flex items-center gap-1">
                <span>{groups[0]?.name}</span>
                <span
                    data-tip={
                        groups.length > 1
                            ? groups
                                  .slice(1, groups.length)
                                  .map((group) => group.name)
                                  .join("\n")
                            : ""
                    }
                    data-for={"groups.length"}
                    className="text-blue-300"
                >
                    {groups.length > 1 ? `+${groups.length - 1}` : ""}
                    <ReactTooltip id={"groups.length"} place="top" type="light" effect="solid" />
                </span>
            </div>
        );
    }
};

const getIcon = (content) => {
    const iconTypes = {
        TVCHANNELS: { type: "channel", className: "text-teal-500", borderColor: "border-teal-500" },
        SIGNAGESEQUENCES: { type: "advertising", className: "text-purple-900", borderColor: "border-purple-900" },
        CASTINSTRUCTIONSCREEN: { type: "wificast", className: "text-zafiro-400", borderColor: "border-zafiro-400" },
    };

    return content?.map((c) => {
        const icon = iconTypes[c];
        if (!icon) return null;

        return (
            <div
                className={`flex items-center justify-center p-1 w-10 border-2 ${icon.borderColor} rounded-lg`}
                key={c}
            >
                <Icon type={icon.type} className={`${icon.className} font-bold`} size="3xl" />
            </div>
        );
    });
};
