import React, { useEffect } from "react";
import { gql } from "apollo-boost";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import Icon from "components/Icon";
import Loading from "components/Loading";
import { UseContentTree } from "../Advertising/graphql/useSignages";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";

export const Unapply = ({ designName, id, refetch }) => {
    const { close } = useModal();
    const { t } = useTranslation();
    const { unapplyDesign } = useUnApplyDesign();

    const { getContentTree, contentTree, loading: loadingContentTree } = UseContentTree();

    useEffect(() => {
        getContentTree({ variables: { designID: id } });
    }, []);

    const contents = contentTree?.screens?.dir?.contents || [];
    const hasSignagesLinked = contents.reduce((acc, curr) => {
        if (curr.linkedSignages.length > 0) {
            acc = true;
        }
        return acc;
    }, false);

    const loading = loadingContentTree;

    return (
        <Modal
            title={`${designName} - ${t("unapply-design")}`}
            className="p-10 w-1/3"
            footer={
                <div className="flex justify-end gap-4">
                    <Button id="cancel" design="white" onClick={close} className="font-bold">
                        {t("cancel")}
                    </Button>
                    <Button
                        id="duplicate-design"
                        design="blue"
                        onClick={() => {
                            unapplyDesign({
                                variables: {
                                    designID: id,
                                    currentDate: new Date()?.toISOString(),
                                    isAssigned: false,
                                },
                            }).then((res) => {
                                if (res.data.updateDesign.ok) {
                                    setTimeout(() => refetch(), 500);
                                }
                            });
                            close();
                        }}
                    >
                        {t("unapply-design")}
                    </Button>
                </div>
            }
        >
            {loading ? (
                <Loading></Loading>
            ) : (
                <>
                    <p>{t("unapply-design-confirm")}</p>
                    {hasSignagesLinked && (
                        <div className="mt-4 flex gap-2 items-center">
                            <Icon type={"error"}></Icon>
                            <p>{t("please-note-that-some-screens-are-linked-to-a-signage-if-the-design-is-unapply")}</p>
                        </div>
                    )}
                </>
            )}
        </Modal>
    );
};

const useUnApplyDesign = () => {
    const UNAPPLY_DESIGN = gql`
        mutation UnapplyDesign($designID: Int64!, $currentDate: DateTime!, $isAssigned: Boolean!) {
            updateDesign(ID: $designID, currentDate: $currentDate, isAssigned: $isAssigned) {
                error
                ok
            }
        }
    `;
    const [unapplyDesign] = useMutation(UNAPPLY_DESIGN);

    return { unapplyDesign };
};
// "2024-11-27T09:44:41Z"
