import { gql } from "apollo-boost";

export const GET_DATA = (wifiEnabled, orderBy) => gql`
    query devices($page: Int, $search: String, $model: [String], $deviceFunction: [DeviceFunction], $deviceStatus: [String], $deviceLocation: [Int64], $size: Int, $roomType: RoomType) {
        devices(
            size: $size
            page: $page
            filter: { unAuthorized: true, search: $search, model: $model, floorIDs: $deviceLocation, function: $deviceFunction, status: $deviceStatus, roomType: $roomType }
            ${orderBy}
        ) {
            info {
                count
                page,
                size
            }
            results {
                name
                function
                mac
                ref
                type
                wifiSignal
                firmwareVersion
                commercialName
                model
                kind
                floorID
                warning {
                    info
                    level
                    roomID
                    actionInfo
                }
                location
                roomID
                roomTVID
                roomTVName
                roomType
                roomNumber
                lastActivity
                lastActivityDate
                tvInputID
                ip
            }
        }
        tvInputs: tvInputs {
            results {
                id
                name
                isDefault
                tvsAssigned
                inputs {
                    icon
                    name
                    pos
                    input
                    deviceType
                }
            }
        }
        warnings: devices(size: 999) {
            info {
                count
            }
            results {
                function
                warning {
                    level
                    info
                }
            }
        }
        ${
            wifiEnabled
                ? `wifiLicenses: wifiLicenses {
                used
                available
            }`
                : ""
        }
    }`;

export const GET_CARDS_INFO = (wifiEnabled) => gql`
    { 
        warnings: devices(size: 999) {
            info {
                count
            }
            results {
                function
                warning {
                    level
                    info
                }
            }
        }
        ${
            wifiEnabled
                ? `wifiLicenses: wifiLicenses {
                used
                available
            }`
                : ""
        }
    }
`;

export const GET_FILTERS_DATA = gql`
    {
        rooms: rooms {
            results {
                wingID
                floorID
                floorName
                wingName
                number
            }
        }
        floors: installationMap {
            results {
                id
                name
                floors {
                    id
                    name
                }
            }
        }
        deviceFuntions: deviceFunctionsList {
            unspecified
            chromecast
            tv
            tvchromecast
        }
        deviceModels: monitoringModelTypes {
            results {
                model
                type
            }
        }
    }
`;

export const AUTHORIZE_ALL = gql`
    mutation {
        authorizeDevice(all: true) {
            ok
            error
        }
    }
`;

export const SYNC_CHROMECAST = gql`
    mutation {
        syncChromecast {
            ok
            error
        }
    }
`;

export const ROOMS_WITHOUT_DEVICE = gql`
    query roomtvsWithoutDeviceByFunction($kind: DeviceKind!, $function: DeviceFunction!, $roomType: RoomType) {
        data: roomtvsWithoutDeviceByFunction(kind: $kind, function: $function, roomType: $roomType) {
            roomID
            roomName
            roomNumber
            roomTVID
            roomTVName
        }
    }
`;

export const UNASSIGN_DEVICE = gql`
    mutation unassignDevice($ref: [String]!) {
        unassignDevice(ref: $ref) {
            ok
            error
        }
    }
`;

export const MOVE_ROOM = gql`
    mutation updateDeviceRoomTV($ref: String!, $roomtvId: Int64!, $oldroomId: Int64!) {
        updateDeviceRoomTV(ref: $ref, roomtvId: $roomtvId, oldroomId: $oldroomId) {
            ok
            error
        }
    }
`;

export const EDIT_DEVICE_NAME = gql`
    mutation editDeviceName($ref: String!, $name: String!) {
        updateDeviceName(ref: $ref, name: $name) {
            ok
            error
        }
    }
`;

export const RESTART_DEVICE = gql`
    mutation restartDevice($ref: [String]!) {
        chromecastReboot(ref: $ref) {
            ok
            error
        }
    }
`;

export const STATUS = gql`
    mutation hideStatus($ref: [String!], $showWarnings: Boolean!) {
        updateDeviceShowWarnings(ref: $ref, showWarnings: $showWarnings) {
            ok
            error
        }
    }
`;

export const FACTORY_RESET = gql`
    mutation chromecastFactoryReset($ref: [String!]) {
        chromecastFactoryReset(ref: $ref) {
            ok
            error
        }
    }
`;

export const DESAUTHORIZE_DEVICE = gql`
    mutation unauthorizeDevice($ref: [String]!) {
        unauthorizeDevice(ref: $ref) {
            ok
            error
        }
    }
`;

export const AUTHORIZE_DEVICE = gql`
    mutation authorizeDevice(
        $ref: String!
        $mac: String!
        $type: String!
        $model: String
        $name: String
        $function: DeviceFunction
        $roomtvId: Int64
        $assignCC: Boolean
    ) {
        authorizeDevice(
            all: false
            device: {
                ref: $ref
                mac: $mac
                type: $type
                model: $model
                name: $name
                function: $function
                roomtvId: $roomtvId
                assignCC: $assignCC
            }
        ) {
            ok
            error
        }
    }
`;

export const DELETE_DEVICE = gql`
    mutation DeleteDevice($ref: String!) {
        deleteUnauthorizedDevice(ref: $ref) {
            ok
            error
        }
    }
`;
export const ASSIGN_DEVICE = gql`
    mutation assignDevice($ref: String!, $roomtvId: Int64!, $function: DeviceFunction!) {
        assignDevice(ref: $ref, roomtvId: $roomtvId, function: $function) {
            ok
            error
        }
    }
`;

export const DETACH_FROM_PROPERTY = gql`
    mutation detachFromProperty($ref: String!) {
        detachDevice(ref: $ref) {
            error
            ok
        }
    }
`;

export const TURN_OFF_ON = gql`
    mutation powerOff($ref: String!, $power: TvActionsEnum!) {
        sendActionToTV(ref: $ref, action: $power) {
            ok
            error
        }
    }
`;

export const RELOAD_INTERFACE = gql`
    mutation reloadInterface($ref: String!, $kind: DeviceKind!) {
        reloadDeviceInterface(ref: $ref, kind: $kind) {
            ok
            error
        }
    }
`;

export const UNINSTALL_TV = gql`
    mutation uninstallTV($ref: [String!]) {
        uninstallTV(ref: $ref) {
            ok
            error
        }
    }
`;

export const CHANGE_DEVICE_FUNCTION = gql`
    mutation changeDeviceFunction($ref: String!, $function: DeviceFunction!) {
        updateDeviceFunction(ref: $ref, function: $function) {
            error
            ok
            id
        }
    }
`;

export const PAIRING_REMOTE_CONTROL = gql`
    mutation pairingRemoteControl($ref: [String!]) {
        chromecastOpenBluetooth(ref: $ref) {
            ok
            error
        }
    }
`;

export const LAUNCH_SETTINGS_SCREEN = gql`
    mutation launchSettingsScreen($ref: [String!]) {
        chromecastOpenSettings(ref: $ref) {
            ok
            error
        }
    }
`;

export const LAUNCH_SETTINGS_SYSTEM_SCREEN = gql`
    mutation launchSystemSettingsScreen($ref: [String!]) {
        chromecastOpenSystemSettings(ref: $ref) {
            ok
            error
        }
    }
`;

export const GET_AVAILABLE_FUNCTIONS = gql`
    query deviceAvailableFunctions($roomTvID: Int64!, $function: DeviceFunction!) {
        deviceAvailableFunctions(roomTvID: $roomTvID, function: $function) {
            chromecast
            tv
            tvchromecast
        }
    }
`;

export const CHANGE_INPUT_GROUP = gql`
    mutation assignTVInput($roomTvIDs: [Int64]!, $tvInputID: Int64!) {
        assignTVInput(roomTvIDs: $roomTvIDs, tvInputID: $tvInputID, replace: false) {
            ok
            error
        }
    }
`;
