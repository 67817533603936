import { gql } from "apollo-boost";

export const GET_WIFI_SETTING = gql`
    {
        ccpairing: chromecastSettings(kind: "") {
            results {
                cec
                enabled
                pairingMode
                philips
                socket
            }
        }
        castwifi: wifiSettings(kind: "Cast") {
            results {
                auth
                enabled
                hidden
                name
                password
            }
        }
        ccdailyreboot: chromecastsDailyReboot(kind: "") {
            results {
                enable
                rebootTime
            }
        }
        ccemailnotify: chromecastSettings {
            results {
                enabled
                emailNotify
                cec
                socket
                philips
                pairingMode
            }
        }
        cclowsignal: chromecastSettings {
            results {
                lowSignalMessage
            }
        }
    }
`;

export const getMutationQuery = (query) => {
    switch (query) {
        case "ccdailyreboot":
            return gql`
                mutation CCDailyReboot($check: Boolean!, $time: String!) {
                    setCCDailyReboot(enable: $check, rebootTime: $time) {
                        error
                        ok
                    }
                }
            `;
        case "reportccstatus":
            return gql`
                mutation ChromecastSettings($check: Boolean!) {
                    setChromecastSettings(emailNotify: $check) {
                        ok
                        error
                    }
                }
            `;
        case "permanentsocket":
            return gql`
                mutation ChromecastSettings($check: Boolean!) {
                    setChromecastSettings(socket: $check) {
                        ok
                        error
                    }
                }
            `;
        case "cclowsignal":
            return gql`
                mutation ChromecastSettings($check: Boolean!) {
                    setChromecastSettings(lowSignalMessage: $check) {
                        ok
                        error
                    }
                }
            `;
        case "castwifi":
            return gql`
                mutation setWifiSettings($name: String, $password: String, $auth: String) {
                    setWifiSettings(kind: "Cast", enabled: false, name: $name, password: $password, auth: $auth) {
                        error
                        ok
                    }
                }
            `;
        default:
            return gql`
                mutation {
                    setWifiSettings(kind: "Hotel", enabled: false, name: "dafault", password: "dafault") {
                        error
                        ok
                    }
                }
            `;
    }
};

export const WIFI_SETTINGS = gql`
    {
        hotelwifi: wifiSettings(kind: "Hotel") {
            results {
                name
                password
                enabled
            }
        }
    }
`;

export const WIFI_LICENSES = gql`
    {
        wifiLicenses: wifiLicenses {
            used
            available
        }
    }
`;

export const setWifiSettings = gql`
    mutation WifiSettings($name: String!, $password: String!) {
        setWifiSettings(kind: "Hotel", name: $name, password: $password, hidden: false, auth: "") {
            error
            ok
        }
    }
`;
