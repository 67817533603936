import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import TextInput from "components/TextInput";
import Select from "components/Select";
import { ProjectBadge } from "components/Section/Settings/PropertyGroups";
import Button from "components/Button";
import { toast } from "react-toastify";
import { useCreateGroupProperty, useUpdatePropertyGroup } from "../GroupsProperties/useListPropertyGroups";
import Loading from "components/Loading";
import { useGetHasLinkedSignages } from "../GroupsProperties/useListPropertyGroups";
import Icon from "components/Icon";

const ModalAddEditGroup = ({ properties, close, refetch, isEdit, idsPropertiesSelected = [], name = "", id, refs }) => {
    const { t } = useTranslation();
    const [groupName, setGroupName] = useState(name);
    const [propertiesSelected, setPropertiesSelected] = useState(idsPropertiesSelected);
    const [validations, setValidations] = useState({ groupName: true, propertiesSelected: true });

    const { createGroupProperty, loadingCreateGroupProperty } = useCreateGroupProperty({
        close,
        refetch,
    });

    const { updatePropertyGroup, loadingUpdatePropertyGroup } = useUpdatePropertyGroup({
        close,
        refetch: () => {
            refetch(true);
        },
    });

    const optionsProperties = properties.map((property) => ({
        value: property.id,
        label: <ProjectBadge alt={property.name} project={property} className="inline" />,
        selectedLabel: <ProjectBadge project={property} className="inline" />,
    }));

    const handleClose = () => {
        close();
    };

    const handleSave = () => {
        const newValidations = {
            groupName: groupName.trim() !== "",
            propertiesSelected: propertiesSelected.length > 0,
        };

        setValidations(newValidations);

        const hasErrors = !newValidations.groupName || !newValidations.propertiesSelected;

        if (hasErrors) {
            toast.error(t("input error"));
            return;
        }

        if (isEdit) {
            updatePropertyGroup({
                variables: { projects: propertiesSelected, name: groupName, replace: true, id: id },
            });
        } else {
            createGroupProperty({ variables: { projects: propertiesSelected, name: groupName } });
        }
    };

    const { hasLinkedSignages, loading } = useGetHasLinkedSignages({ refs: refs });

    return (
        <Modal
            footer={
                !loadingCreateGroupProperty && !loadingUpdatePropertyGroup && !loading ? (
                    <>
                        <Button design="blue-outline" id="modal-button-cancel" onClick={handleClose}>
                            {t("cancel")}
                        </Button>
                        <Button design="blue" id="modal-button-save" onClick={handleSave}>
                            {t("save")}
                        </Button>
                    </>
                ) : null
            }
            className="w-4/12 p-10"
            title={isEdit ? `${name} - ${t("edit-group")}` : t("add-new-group")}
        >
            {loadingCreateGroupProperty || loadingUpdatePropertyGroup || loading ? (
                <Loading />
            ) : (
                <>
                    <div className="w-2/4 mb-5">
                        <label className="block mb-2">{t("group-name")}:</label>
                        <TextInput
                            value={groupName}
                            onChange={setGroupName}
                            placeholder={t("group-name")}
                            error={!validations.groupName ? true : false}
                        />
                    </div>
                    <div className="w-3/4">
                        <label className="block mb-2">{t("properties")}:</label>
                        <Select
                            id="dropdown-properties-add-new-group"
                            allowUnselect
                            options={optionsProperties}
                            designClass={{
                                validation: !validations.propertiesSelected
                                    ? "border border-red-100"
                                    : "border border-transparent",
                            }}
                            placeholder={t("properties")}
                            showSelectedItems
                            multiple
                            search
                            labelColor="bg-teal-500"
                            value={propertiesSelected}
                            onChange={(values) => setPropertiesSelected(values || [])}
                        />
                    </div>
                    <div>
                        {isEdit && hasLinkedSignages ? (
                            <div className=" flex items-start mt-2">
                                <Icon size={1.5} type="warning" className=" mr-2" />
                                <div>{t("properties-using-global-design-screens-unlinked")}</div>
                            </div>
                        ) : null}
                    </div>
                </>
            )}
        </Modal>
    );
};

export const useModalAddEditGroup = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            open(<ModalAddEditGroup {...props} close={close} />);
        },
    };
};

export default useModalAddEditGroup;
