import React, { useState, useEffect } from "react";
import UseSectionHeader from "../../../useSectionHeader";
import { useHotelMap, useRoom } from "hooks/GraphqlCalls/Hotel/useTopologyMap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Session } from "hooks/Utils/Session";
import TextInput from "components/TextInput";
import NumberInput from "components/NumberInput";
import { TVsPerRoom } from "./TopologyComponents/FloorsDetail";
import Button from "components/Button";
import { toast } from "react-toastify";

export const AddCommonZone = () => {
    const COMMONZONE = "COMMONZONE";
    const [name, setName] = useState("");
    const [nameValidation, setNameValidation] = useState("");
    const [tvsPerRoomValidation, setTvsPerRoomValidation] = useState("");
    const [numberValidation, setNumberValidation] = useState("");
    const [number, setNumber] = useState(1);
    const [tvsPerRoom, setTvsPerRoom] = useState([{ name: "", CEC: true }]);

    const { createRoom } = useRoom();
    const { results: installationMap } = useHotelMap();
    const { t } = useTranslation();
    const { id } = useParams();

    const prevLocation = JSON.parse(Session.getPreviousPath())?.pathname;
    const wing = installationMap?.find((wing) => wing?.floors?.find((floor) => parseInt(floor.id) === parseInt(id)));
    const floor = wing?.floors?.find((floor) => parseInt(floor.id) === parseInt(id));

    const wingName = wing?.name;
    const floorName = floor?.name;
    const lastRoom =
        floor?.rooms?.[floor?.rooms?.length - 1]?.number + 1 ||
        Number(`${installationMap?.length}${wing?.floors?.length}01`);

    const handleSave = () => {
        const newCZ = {
            floorID: id,
            floors: [
                {
                    numbersOfRooms: 1,
                    starterRoom: number,
                    tvsPerRoom: tvsPerRoom,
                    name: name,
                    type: COMMONZONE,
                },
            ],
        };
        if (validate(newCZ)) {
            createRoom(newCZ);
        }
    };
    console.log(tvsPerRoomValidation);
    useEffect(() => {
        if (installationMap) {
            setNumber(lastRoom);
        }
    }, [installationMap]);

    const validate = (newCommonZone) => {
        const { floors } = newCommonZone;

        let valid = true;
        if (floors?.[0]?.starterRoom > 99999) {
            setNumberValidation("border border-red-100");
            toast.error(t("The number must be a maximum of 5 digits. Please enter a valid number"));
            valid = false;
        }
        if (!floors?.[0]?.name) {
            setNameValidation("border border-red-100");
            toast.error(t("input error"));
            valid = false;
        }
        if (!floors?.[0]?.starterRoom) {
            setNumberValidation("border border-red-100");
            toast.error(t("input error"));
            valid = false;
        }
        if (floors?.[0]?.tvsPerRoom?.length > 0) {
            //eslint-disable-next-line
            floors?.[0]?.tvsPerRoom?.forEach((roomTv, roomIndex) => {
                if (!roomTv?.name) {
                    setTvsPerRoomValidation((prevState) => ({
                        ...prevState,
                        [roomIndex]: true,
                    }));
                    toast.error(t("input error"));
                    valid = false;
                } else {
                    const duplicateRoomNames =
                        floors?.[0]?.tvsPerRoom.filter(
                            (r, i) => floors?.[0]?.tvsPerRoom.findIndex((room) => room.name === r.name) !== i
                        ).length > 0;

                    if (duplicateRoomNames) {
                        setTvsPerRoomValidation((prevState) => ({
                            ...prevState,
                            [roomIndex]: true,
                        }));
                        toast.error(t("TV location names in a room must be unique"));
                        valid = false;
                    } else {
                        setTvsPerRoomValidation((prevState) => ({
                            ...prevState,
                            [roomIndex]: false,
                        }));
                    }
                }
            });
        }
        return valid;
    };
    return (
        <>
            <UseSectionHeader
                title={["add-common-zone", "(", wingName, ",", floorName, ")"]}
                navToPreviousPage={true}
            />
            <div className="bg-white p-6">
                <div className="flex gap-3 p-8">
                    <div className="flex flex-col">
                        <label className="fieldLabel">{t("common-zone-name")}</label>
                        <TextInput
                            placeholder={t("name")}
                            onChange={(value) => setName(value)}
                            className={nameValidation}
                        />
                    </div>
                    <div className="flex flex-col">
                        <label className="fieldLabel">{t("common-zone-number")}</label>
                        <NumberInput
                            min={1}
                            value={number || 1}
                            onChange={(value) => setNumber(value)}
                            className={numberValidation}
                        />
                    </div>
                    <TVsPerRoom
                        value={tvsPerRoom}
                        onChange={setTvsPerRoom}
                        label={t("tvs-per-common-zone")}
                        disabled={!number > 0}
                        validateLocation={(index) =>
                            tvsPerRoomValidation?.[index] || tvsPerRoomValidation?.[id]?.[index]?.validation
                        }
                    />
                </div>
                <div className="flex gap-3 mt-10 justify-end">
                    <Button id="cancel-add-common-zone" className="btn btn-white" to={`${prevLocation}`}>
                        {t("cancel")}
                    </Button>
                    <Button id="save-add-common-zone" className="btn btn-blue" onClick={handleSave}>
                        {t("save")}
                    </Button>
                </div>
            </div>
        </>
    );
};
