import React, { useEffect, useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { UseListSignages, useGetFiltersDestinations } from "../graphql/useSignages";

const ModalFilters = (props) => {
    const { t } = useTranslation();
    const { getFilterDestinations } = useGetFiltersDestinations();
    const [selected, setSelected] = useState();
    const { roomGroupTpCommonZone = [], roomTvsTpCommonZone = [], roomsTpCommonZone = [] } = props?.filters || {};
    const filters = props?.filters
        ? Object.entries(props?.filters).reduce((acc, curr) => {
              if (typeof curr[1] == "object" && curr[1]?.length > 0) {
                  acc = [...acc, ...curr[1].map((val) => ({ name: val.name, id: val.id, type: curr[0] }))];
              }
              return acc;
          }, [])
        : [];

    useEffect(() => {
        if (filters.length > 0 && !selected) {
            setSelected(filters[0].id);
        }
    }, [filters]);

    useEffect(() => {
        getFilterDestinations({
            variables: { filters: { roomGroupTpCommonZone: [], roomTvsTpCommonZone: [], roomsTpCommonZone: [] } },
        });
    }, [selected]);

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={`${props.name} - ${t("filters")}`}
            footer={
                <>
                    <Button design="blue" id="modal-button-close" onClick={handleCloseClick}>
                        {t("close")}
                    </Button>
                </>
            }
            className={"w-8/12 p-10"}
        >
            <div className=" grid grid-cols-5 gap-2">
                <div className=" col-span-3">
                    <p
                        dangerouslySetInnerHTML={{
                            __html: t("sequence-has-the-following-filters", { name: `<strong>${props.name}</strong>` }),
                        }}
                    ></p>
                    <p className="mt-4 font-bold">
                        {t("filters")}
                        <span className=" font-normal pl-2">({t("filters-x", { count: filters.length })})</span>
                    </p>
                    <div className=" mt-2 flex gap-2">
                        {filters.map((val) => {
                            return (
                                <p
                                    key={val.name + "-" + val.id}
                                    className={`${
                                        val?.id == selected ? "bg-blue-200 text-white" : "bg-gray-300 cursor-pointer"
                                    } py-1 px-2 w-fit-content rounded-md`}
                                    onClick={() => {
                                        if (val.id != selected) {
                                            setSelected(val.id);
                                        }
                                    }}
                                >
                                    {val.name}
                                </p>
                            );
                        })}
                    </div>
                </div>
                <div className=" col-span-2">
                    <div className=" text-gray-500 rounded-md flex justify-between mb-2">
                        <span>{t("results-of-the-selected-filter")}:</span>
                        <span>{t("x rows_other", { count: 12 })}</span>
                    </div>
                    <div className="flex flex-col border p-2 min-h-40 overflow-y-auto"></div>
                </div>
            </div>
        </Modal>
    );
};

export const UseModalFilters = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalFilters {...newProps} />);
        },
    };
};
