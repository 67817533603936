import React, { useEffect, useState } from "react";
//Components
import UseSectionHeader from "../../useSectionHeader";
//Utils
import { DashboardCards } from "../DasboardCards/DashboardCards";
import { useHotelMap } from "hooks/GraphqlCalls/Hotel/useTopologyMap";
import Search from "components/TextInput/Search";
import { useTranslation } from "react-i18next";
import UseTitle from "components/useTitle";
import { Session } from "hooks/Utils/Session";
import MapItem from "components/MapItem";
import Loading from "components/Loading";
import { FilteredRooms } from "./Settings/TopologyMapView";

const InstallationMap = () => {
    const [activeWing, setActiveWing] = useState(null);
    const [activeFloor, setActiveFloor] = useState(null);

    const { results: installationMap, dashboardCardsData, filters, setFilters, loading } = useHotelMap();

    useEffect(() => {
        setActiveWing(installationMap?.[0] ? installationMap[0]?.id : null);
        setActiveFloor(installationMap?.[0]?.floors?.[0] ? installationMap?.[0]?.floors?.[0]?.id : null);

        if (!Session.getSessionProp("map-active-floor") && installationMap?.[0]?.floors?.[0]) {
            Session.setSessionProp("map-active-floor", installationMap?.[0]?.floors?.[0]?.id);
        }
        if (!Session.getSessionProp("map-active-wing") && installationMap?.[0]) {
            Session.setSessionProp("map-active-wing", installationMap?.[0]?.id);
        }
    }, [installationMap]);

    return (
        <>
            <UseSectionHeader title={["map-of-installation"]} />
            <DashboardCards data={dashboardCardsData} />
            <div className="main-container">
                {!loading ? (
                    <>
                        <div className="t-filters-row w-1/5">
                            <Search onChange={(value) => setFilters(value)} />
                        </div>
                        <div className="mt-8">
                            <WingsMap
                                installationMap={installationMap}
                                setActiveWing={setActiveWing}
                                activeWing={activeWing}
                                setActiveFloor={setActiveFloor}
                                filters={filters}
                            />
                            <FloorsMap
                                installationMap={installationMap}
                                setActiveFloor={setActiveFloor}
                                activeFloor={activeFloor}
                                activeWing={activeWing}
                                filters={filters}
                            />
                            <RoomMap
                                installationMap={installationMap}
                                activeFloor={activeFloor}
                                activeWing={activeWing}
                                filters={filters}
                            />
                            {filters && <FilteredRooms installationMap={installationMap} view={"map"} />}
                        </div>
                    </>
                ) : (
                    <Loading adjust="section-container" style={{ height: "50vh" }} />
                )}
            </div>
        </>
    );
};

export default InstallationMap;

const WingsMap = ({ installationMap, setActiveFloor, setActiveWing, activeWing, filters }) => {
    const { t } = useTranslation();

    const handleClickWing = (e) => {
        setActiveWing(Number(e.currentTarget.dataset.id));
        Session.setSessionProp("map-active-wing", Number(e.currentTarget.dataset.id));

        let firstAvailableFloor = "";
        // eslint-disable-next-line
        installationMap.map((wing) => {
            if (wing.id === activeWing) {
                firstAvailableFloor = wing.floors.length > 0 ? wing.floors[0].id : null;
            }
        });
        setActiveFloor(firstAvailableFloor);
        Session.setSessionProp("map-active-floor", firstAvailableFloor);
    };

    return installationMap?.length > 0 && !filters ? (
        <>
            <div>
                <div className="flex">
                    <div>
                        <UseTitle text={"wings"} adjust="text-gray-900 font-bold text-lg pb-2" />
                    </div>
                    <span id="wings-number" className="ml-2 text-gray-800">
                        ({installationMap?.length})
                    </span>
                </div>
            </div>
            {installationMap?.map((wing) => (
                <div className="inline-flex" key={wing.id} data-id={wing.id} onClick={handleClickWing}>
                    <MapItem data={wing} active={activeWing === wing.id ? true : false} />
                </div>
            ))}
        </>
    ) : installationMap && !filters ? (
        <div className="first-capital"> {t("no-hotel-map")}</div>
    ) : null;
};

const FloorsMap = ({ installationMap, setActiveFloor, activeFloor, activeWing, filters }) => {
    const handleClickFloor = (e) => {
        setActiveFloor(Number(e.currentTarget.dataset.id));
        Session.setSessionProp("map-active-floor", Number(e.currentTarget.dataset.id));
    };

    return (
        /* Get Floors... */
        installationMap?.length > 0 && !filters
            ? installationMap?.map((wing, index) =>
                  wing.id === activeWing ? (
                      <div key={index}>
                          <div>
                              <div className="flex mt-6">
                                  <div>
                                      <UseTitle text={"floors"} adjust="text-gray-900 font-bold text-lg pb-2" />
                                  </div>
                                  <span id="floor-number" className="ml-2 text-gray-800">
                                      ({wing?.floors?.length})
                                  </span>
                              </div>
                          </div>
                          {wing?.floors?.map((floor) => (
                              <div className="inline-flex" key={floor.id} data-id={floor.id} onClick={handleClickFloor}>
                                  <MapItem data={floor} active={activeFloor === floor.id ? true : false} />
                              </div>
                          ))}
                      </div>
                  ) : null
              )
            : null
    );
};

const RoomMap = ({ installationMap, activeFloor, activeWing, filters }) => {
    const { t } = useTranslation();
    const GUESTROOM = "GUESTROOM";
    const COMMONZONE = "COMMONZONE";
    return (
        /* Get Rooms... */
        installationMap?.length > 0 && !filters
            ? installationMap?.map((wing) =>
                  wing.id === activeWing
                      ? wing.floors.map((floor, index) =>
                            floor.id === activeFloor ? (
                                <div key={index} className="mt-4 border border-gray-300 p-4 rounded">
                                    <div className="flex">
                                        <div>
                                            <UseTitle
                                                text={"common-zones"}
                                                adjust="text-gray-900 font-bold text-lg pb-2"
                                            />
                                        </div>
                                        <span id="rooms-number" className="ml-2 text-gray-800">
                                            ({floor.rooms.filter((room) => room.type === COMMONZONE)?.length || 0})
                                        </span>
                                    </div>
                                    {floor.rooms.filter((room) => room.type === COMMONZONE).length > 0 ? (
                                        floor.rooms
                                            .filter((room) => room.type === COMMONZONE)
                                            .map((room) => (
                                                <div className="inline-flex" key={room.id} data-id={room.id}>
                                                    <div className="">
                                                        <MapItem data={room} />
                                                    </div>
                                                </div>
                                            ))
                                    ) : (
                                        <p className="text-gray-700">
                                            {t("no-topology-item-created", {
                                                rooms: t("common-zones"),
                                            })}
                                        </p>
                                    )}
                                    <div className="flex mt-6">
                                        <div>
                                            <UseTitle text={"rooms"} adjust="text-gray-900 font-bold text-lg pb-2" />
                                        </div>
                                        <span id="rooms-number" className="ml-2 text-gray-800">
                                            ({floor.rooms.filter((room) => room.type === GUESTROOM)?.length || 0})
                                        </span>
                                    </div>
                                    {floor.rooms.filter((room) => room.type === GUESTROOM).length > 0 ? (
                                        floor.rooms
                                            .filter((room) => room.type === GUESTROOM)
                                            .map((room) => (
                                                <div className="inline-flex" key={room.id} data-id={room.id}>
                                                    <div className="">
                                                        <MapItem data={room} />
                                                    </div>
                                                </div>
                                            ))
                                    ) : (
                                        <p className="text-gray-700">
                                            {t("no-topology-item-created", {
                                                rooms: t("rooms"),
                                            })}
                                        </p>
                                    )}
                                </div>
                            ) : null
                        )
                      : null
              )
            : null
    );
};
