import React, { useEffect, useState } from "react";
import Button from "components/Button";
import { useAuth } from "hooks/Session/auth";
import { Session } from "hooks/Utils/Session";
import { tmpGetChain } from "hooks/Utils/Utils";
import { useLazyQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { Duplicate } from "components/Section/Design/ModalComponents/Duplicate";
import { useModal } from "components/Modal";
import { DeleteDesign } from "components/Section/Design/ModalComponents/DeleteDesign";
import { Unapply } from "components/Section/Design/ModalComponents/UnApply";
import { CHAIN, GET_DESIGNS_INFO } from "./utils/designsQueries";
import { ApplyColumn } from "components/Section/Design/ModalComponents/ApplyColumn";
import { EditDesigns } from "components/Section/Design/ModalComponents/EditDesigns";

export const useDesigns = () => {
    const { t } = useTranslation();
    const { open } = useModal();
    const [designsItems, setDesignsItems] = useState([]);
    const { isCorporate } = useAuth();

    const [getDesign, { data: designs, loading: designLoading, refetch }] = useLazyQuery(GET_DESIGNS_INFO, {
        pollInterval: 60000,
    });

    const [getChain, { data: chainData, loading: chainLoading }] = useLazyQuery(CHAIN, {
        pollInterval: 60000,
    });

    useEffect(() => {
        if (designs && !designLoading) {
            Session.setSessionProp("designList", JSON.stringify(designs?.designs?.results));
            const brands = designs?.brands?.results;
            const designsItems = getDesignModels(designs, t, open, refetch, isCorporate);
            setDesignsItems(designsItems);

            if (brands) {
                Session.setSessionProp("brands", brands);
            }
        }
    }, [designs, chainData]);

    return { designsItems, itemsLoading: designLoading || chainLoading, getDesign, getChain, chainData, refetch };
};

const getDesignModels = (designs, t, open, refetch, isCorporate) => {
    const designList = designs?.designs?.results;
    const designsLocalAssigned = designs?.designsLocalAssigned?.results;

    return designList.map((design) => designModel(design, designsLocalAssigned, t, open, refetch, isCorporate));
};

const designModel = (design, designsLocalAssigned, t, open, refetch, isCorporate) => {
    const { creatorProject, visibleAll, lastUpdatedTime, id, name, type, visibleAllProject, lastUpdatedUserInfo } =
        design;

    const chain = <div className="px-2 py-1 bg-purple-600 rounded text-white">{tmpGetChain()?.name}</div>;

    const projectName = (
        <div className="px-2 py-1 bg-teal-500 rounded text-white">
            {creatorProject?.name || Session.getProject()?.name || ""}
        </div>
    );
    const isVisibleAll = visibleAll ? chain : "";

    const lastUpdatedTimeDate = new window.ZDate(lastUpdatedTime)?.toLocaleString() || "";

    const designName = (
        <div alt={name}>
            <Button id={`design-${id}`} className="t-link font-bold" to={`/design/${id}`}>
                {name}
            </Button>
        </div>
    );
    const properties = creatorProject?.name ? (
        <div className="px-2 py-1 bg-teal-500 rounded text-white">{creatorProject?.name}</div>
    ) : visibleAllProject?.name ? (
        <div
            className={`px-2 py-1 bg-${visibleAllProject?.type === "BRAND" ? "orange" : "teal"}-500 rounded text-white`}
        >
            {visibleAllProject?.name}
        </div>
    ) : (
        isVisibleAll
    );

    return {
        id,
        design: designName,
        author: lastUpdatedUserInfo?.name || "",
        "last-update": lastUpdatedTimeDate || "",
        creator: type === "LOCAL" ? projectName : chain,
        properties,
        apply: <ApplyColumn refetch={refetch} design={design} designsLocalAssigned={designsLocalAssigned} />,
        filterType: type,
        rowConfig: {
            actions: getActions(design, t, open, refetch, isCorporate),
        },
    };
};

const getActions = (design, t, open, refetch, isCorporate) => {
    const { isAssigned, type, id, name } = design;

    const editDesign = {
        id: `edit-design`,
        label: t("edit-design"),
        onClick: () => open(<EditDesigns design={design} refetch={refetch} />),
    };

    const editScreen = {
        id: `edit-screen-design`,
        label: t("edit-screen"),
        onClick: () => (window.location.href = `#/design/${id}/`),
    };

    const duplicateDesign = {
        id: `duplicate-design`,
        label: t("duplicate-design"),
        onClick: () => open(<Duplicate designName={name} id={id} refetch={refetch} />),
    };

    const deleteDesign = {
        id: `delete-design`,
        label: t("delete-design"),
        onClick: () => open(<DeleteDesign designName={name} id={id} refetch={refetch} />),
    };

    const unassignDesign = {
        id: `unapply-design`,
        label: t("unapply-design"),
        onClick: () => open(<Unapply designName={name} id={id} refetch={refetch} />),
    };

    const viewScreens = {
        id: `view-screen-design`,
        label: t("see-screens"),
        onClick: () => (window.location.href = `#/design/${id}/`),
    };

    const actionsInGlobal = [editDesign, editScreen, duplicateDesign, deleteDesign];
    const actionsWhenAssigned = [...actionsInGlobal, unassignDesign];
    const actionsWhenDesignCreatedInGlobal = [viewScreens, duplicateDesign];
    const actionsWhenDesignsCreatedInLocal = [...actionsInGlobal];

    if (isAssigned) {
        return actionsWhenAssigned;
    }
    if (isCorporate) {
        return actionsInGlobal;
    }
    if (type === "LOCAL") {
        return actionsWhenDesignsCreatedInLocal;
    } else {
        return actionsWhenDesignCreatedInGlobal;
    }
};
