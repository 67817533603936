import React, { useEffect, useState } from "react";
import Modal, { useModal } from "components/Modal";
import { capitalizeFirst, formatSeconds } from "hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import Button from "components/Button";
import { formatDate } from "hooks/Utils/Services/OrdersUtils";
import ReactTooltip from "components/ReactTooltip";
import SessionDate from "date";

export const DisplayTimes = ({
    movieCharge,
    onClickDisplayTimes,
    onCloseDisplayTimes,
    displayTimesWidth,
    showTimesIcon,
    formatDate,
}) => {
    const { t } = useTranslation();
    const { open, close } = useModal();

    const GET_MOVIE_VISUALIZATIONS = gql`{
        movieVisualizations(movieId: ${movieCharge.extId},stayGuestRoomId:${movieCharge.stayGuestRoomId},  orderBy:{field:"startTime" criteria:"DESC"})
        {
            results{
                startTime
                percentageReached
                visualizationTime
            }
        }
    }`;

    const [getMovieVisualizations, { loading, data }] = useLazyQuery(GET_MOVIE_VISUALIZATIONS, {
        fetchPolicy: "network-only",
    });
    useEffect(() => {
        if (data?.movieVisualizations?.results) {
            showDisplayModal(data?.movieVisualizations?.results);
        }
    }, [data]);

    const handleClose = () => {
        close();
        onCloseDisplayTimes && onCloseDisplayTimes();
    };

    const showDisplayModal = (displayTimes) => {
        onClickDisplayTimes && onClickDisplayTimes();
        open(
            <Modal
                id="display-times-modal"
                title={
                    <h1 className="text-2xl font-bold">
                        <span
                            onClick={() => onClickDisplayTimes && handleClose()}
                            className={`${
                                onClickDisplayTimes ? "icon icon-chevron-left cursor-pointer text-xl pr-2" : ""
                            }`}
                        ></span>
                        {capitalizeFirst(t("display-times"))}
                    </h1>
                }
                className=" p-10"
                minWidth={displayTimesWidth || "32vw"}
                onKeyPress={{ Enter: close }}
            >
                <div className="mt-4">
                    <div className="font-bold">{`${t("movie")}: ${movieCharge?.detailInfo?.name}`}</div>
                    <div className="font-bold mt-4">{`${capitalizeFirst(t("room"))}: ${movieCharge?.roomNumber}`}</div>
                    <div className="mt-4">{`${t("display-times-text")}`}</div>
                </div>
                <div className="font-bold border-b border-gray-200 w-full mt-6 pb-2 flex">
                    <div className="w-4/12">{t("date-and-time")}</div>
                    <div className="w-3/12">{t("time")}</div>
                    <div className="w-5/12">
                        {t("max-reached")}{" "}
                        <span
                            id={"max-reached-icon"}
                            className="icon icon-info text-2xl cursor-help"
                            data-tip
                            data-for={`max-reached-Tooltip`}
                        ></span>
                    </div>
                    <ReactTooltip id={`max-reached-Tooltip`}>{t("max-reached-tooltip")}</ReactTooltip>
                </div>
                <div
                    style={{ minHeight: showTimesIcon ? "35.5vh" : "22vh", maxHeight: "36vh" }}
                    className="overflow-scroll"
                >
                    {displayTimes?.length > 0 ? (
                        displayTimes.map((movieTime) => {
                            return (
                                <div className="border-b border-gray-200 my-4 pb-2 w-full flex">
                                    <div className="w-4/12" style={{ alignContent: "center" }}>
                                        <div className="table">
                                            <div>{formatDate(SessionDate(movieTime.startTime), "time")}</div>
                                            <div>{formatDate(SessionDate(movieTime.startTime), "date")}</div>
                                        </div>
                                    </div>
                                    <div className="w-3/12" style={{ alignContent: "center" }}>
                                        {formatSeconds(movieTime.visualizationTime)}
                                    </div>
                                    <div
                                        className="w-5/12"
                                        style={{ alignContent: "center" }}
                                    >{`${movieTime.percentageReached} %`}</div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="h-full" style={{ height: "22vh", alignContent: "center", textAlign: "center" }}>
                            {t("no data available")}
                        </div>
                    )}
                </div>
                <footer className="flex justify-end gap-4 mt-12">
                    <Button id="cancel" className="btn btn-blue" onClick={() => close()}>
                        {t("close")}
                    </Button>
                </footer>
            </Modal>
        );
    };

    return (
        <div
            id={`display-times-btn-${movieCharge.id}`}
            className="link clickable font-bold"
            onClick={() => getMovieVisualizations()}
        >
            {t("display-times")}
            {showTimesIcon && <span className="icon icon-chevron-right ml-3"></span>}
        </div>
    );
};
