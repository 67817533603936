import { useContext, useEffect, useState } from "react";
//API
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import Casts from "./models/Casts";
import { NetworkSettingsContext } from "contexts/NetworkSettings";
import { GET_WIFI_SETTING, getMutationQuery } from "./utils/networkQueries";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const useCastConfig = () => {
    const { t } = useTranslation();
    const [networks, setNetworks] = useState([]);
    const { setCastSettings, castSettings } = useContext(NetworkSettingsContext);
    const keyWithSaveTrue = Object.keys(castSettings).find((key) => castSettings[key].save === true);
    const keyWithoutSave = Object.keys(castSettings).find((key) => castSettings[key].auth);

    //API Call
    const [executeQuery, { data, loading, error, called }] = useLazyQuery(GET_WIFI_SETTING, {
        pollInterval: 0,
        fetchPolicy: "network-only",
    });

    const [executeMutation] = useMutation(getMutationQuery(keyWithSaveTrue || keyWithoutSave), {
        onError(err) {
            console.log(err);
            toast.error(t("mutation-error"));
        },
    });

    const arrangeData = () => {
        let tempArray = [];
        // eslint-disable-next-line
        Object.keys(data).forEach((element) => {
            tempArray[element] = { ...data[element].results };
        });
        return Casts(tempArray);
    };

    useEffect(() => {
        if (!loading && !error && data) {
            const networksData = arrangeData();
            setNetworks(networksData);
        }
    }, [data]);

    useEffect(() => {
        if (networks) {
            return networks?.forEach((network) => {
                if (network?.id === "ccdailyreboot") {
                    setCastSettings((prev) => ({
                        ...prev,
                        [network.id]: {
                            check: network?.fields[0]?.value,
                            time: network?.fields[1]?.activeValue,
                        },
                    }));
                } else {
                    setCastSettings((prev) => ({
                        ...prev,
                        [network.id]: {
                            check: network?.fields[0]?.value,
                        },
                    }));
                }
            });
        }
    }, [networks]);

    useEffect(() => {
        if (castSettings[keyWithSaveTrue]?.save || castSettings[keyWithoutSave]?.auth) {
            executeMutation({
                variables: {
                    check: castSettings[keyWithSaveTrue]?.check,
                    time: castSettings[keyWithSaveTrue]?.time,
                    auth: castSettings[keyWithoutSave]?.auth,
                },
            })
                .then((response) => {
                    const { data } = response;
                    const { setWifiSettings, setChromecastSettings, setCCDailyReboot } = data;
                    if (setWifiSettings?.ok || setChromecastSettings?.ok || setCCDailyReboot?.ok) {
                        toast.success(t("operation-successful"));
                    } else if (setWifiSettings?.error || setChromecastSettings?.error || setCCDailyReboot?.error) {
                        toast.error(t("mutation-error"));
                    }
                    if (castSettings[keyWithSaveTrue]?.save) {
                        castSettings[keyWithSaveTrue].save = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast.error(t("mutation-error"));
                });
        }
    }, [castSettings[keyWithSaveTrue]?.save, castSettings[keyWithoutSave]?.auth]);

    return { networks, ready: !loading && called, executeQuery };
};

export function getTimeZone() {
    let offset = new window.ZDate().getTimezoneOffset(),
        o = Math.abs(offset);
    return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
}
