import Icon from "components/Icon";
import Loading from "components/Loading";
import React from "react";
import { useTranslation } from "react-i18next";

export const NetworkMonitorCards = ({ monitoring, summary, loading }) => {
    const { t } = useTranslation();
    const { criticalDevices, eventWarnings, statusDevices, monitoredDevices } = summary;
    console.log(summary);
    return !loading ? (
        <div className={`flex items-center rounded ${monitoring && "border border-gray-300"}`}>
            <div className="border-r border-gray-300 p-4 my-2 flex gap-4 w-1/4 justify-between">
                <Icon type="critical-device" size="6xl" className="text-zafiro-800" />
                <div className="">
                    <p className="text-zafiro-900 font-bold text-5xl text-right">
                        {criticalDevices} / {monitoredDevices}
                    </p>
                    <p className="text-lg text-zafiro-900">{t("critical-devices")}</p>
                </div>
            </div>
            <div className="border-r border-gray-300 p-4 my-2 flex gap-4 justify-between">
                <p className="font-bold text-zafiro-900 text-2xl">{t("status")}</p>
                <div className="flex gap-6">
                    {statusDevices?.map((status) => (
                        <div key={status?.status} className="flex flex-col">
                            <p className="text-zafiro-900 font-bold text-5xl text-right">{status?.devices}</p>
                            <div className="flex gap-2 items-center">
                                <Icon type={status?.icon} size="2xl" className={status?.color} />
                                <p className="text-lg text-zafiro-900">{status?.status}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="p-4 my-2 flex gap-4 justify-between w-2/4">
                <p className="font-bold text-zafiro-900 text-2xl">{t("warnings")}</p>
                {eventWarnings?.map((warning, index) => (
                    <div key={index}>
                        <p className="text-zafiro-900 font-bold text-5xl text-right">{warning?.events}</p>
                        <div className="flex items-center gap-2">
                            <Icon type={warning?.icon} size="2xl" className={warning?.color} />
                            <p className="text-zafiro-900 text-lg">{warning?.label}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    ) : (
        <Loading className="mb-8" />
    );
};
