import React, { createContext, useMemo, useState } from "react";

const FilterCCsContext = createContext({});

export const FilterCCsProvider = ({ children }) => {
    const [model, setModel] = useState([]);
    const [ownership, setOwnership] = useState([]);

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState(null);
    const [deviceModel, setDeviceModel] = useState(null);
    const [deviceFunction, setDeviceFunction] = useState(null);
    const [deviceStatus, setDeviceStatus] = useState(null);
    const [deviceLocation, setDeviceLocation] = useState(null);
    const [size, setSize] = useState(6);
    const [roomType, setRoomType] = useState(null);

    const contextValue = useMemo(
        () => ({
            model,
            setModel,
            ownership,
            setOwnership,
            page,
            setPage,
            search,
            setSearch,
            sort,
            setSort,
            deviceModel,
            setDeviceModel,
            deviceFunction,
            setDeviceFunction,
            deviceStatus,
            setDeviceStatus,
            deviceLocation,
            setDeviceLocation,
            size,
            setSize,
            roomType,
            setRoomType,
        }),
        [
            model,
            ownership,
            page,
            search,
            sort,
            deviceModel,
            deviceFunction,
            deviceStatus,
            deviceLocation,
            size,
            roomType,
        ]
    );

    return <FilterCCsContext.Provider value={contextValue}>{children}</FilterCCsContext.Provider>;
};

export default FilterCCsContext;
