import React from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import Icon from "components/Icon";
import Table from "components/ZafiroTable";
import { useDuplicateSignage } from "../graphql/useSignages";
import { toast } from "react-toastify";
import Loading from "components/Loading";

const ModalDuplicateSequence = ({ batch, batchList, id, name, close, getSignages }) => {
    const { t } = useTranslation();

    const { duplicateSignage, loadingDuplicateSignage } = useDuplicateSignage({
        onCompleted: () => {
            getSignages();
            close();
            toast.success(t("operation-successful"));
        },
    });

    const handleSaveClick = () => {
        const ids = batch ? batchList.map((item) => item.id) : [id];
        duplicateSignage({ variables: { ids } });
    };

    const modalTitle = batch ? t("duplicate") : `${name} - ${t("duplicate")}`;
    const confirmationMessage = batch
        ? t("are-you-sure-you-want-to-duplicate-the-following-sequences")
        : t("are-you-sure-you-want-to-duplicate-this-sequence");

    const footer = (
        <>
            <Button design="blue-outline" id="modal-button-cancel" onClick={close} disabled={loadingDuplicateSignage}>
                {t("cancel")}
            </Button>
            <Button design="blue" id="modal-button-save" onClick={handleSaveClick} disabled={loadingDuplicateSignage}>
                {t("duplicate")}
            </Button>
        </>
    );

    return (
        <Modal title={modalTitle} footer={footer} className="w-5/12 p-10">
            {loadingDuplicateSignage ? (
                <Loading />
            ) : (
                <div className="flex flex-col gap-2">
                    <p>{confirmationMessage}</p>
                    <div className="flex gap-2">
                        <Icon size="2xl" type="info" />
                        <p>{t("please-note-that-only-the-content-will-be-duplicated")}</p>
                    </div>
                    {batch && (
                        <Table
                            id="signage-duplicate-batch-table"
                            cols={["name"]}
                            className="border border-gray-100 p-2"
                            maxHeight="max-h-30"
                            header={{ name: { title: "" } }}
                            rows={batchList}
                        />
                    )}
                </div>
            )}
        </Modal>
    );
};

export const UseModalDuplicateSequence = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            open(<ModalDuplicateSequence {...props} close={close} />);
        },
    };
};
