import { gql } from "apollo-boost";

export const MUTATION_CREATE_GROUP = gql`
    mutation CreateBrand($projects: [Int64]!, $name: String!) {
        createBrand(projects: $projects, name: $name) {
            error
            id
            ok
            __typename
        }
    }
`;

export const DELETE_PROPERTY_GROUP = gql`
    mutation DeleteBrand($id: [Int64]!) {
        deleteBrand(id: $id) {
            error
            id
            ok
            __typename
        }
    }
`;

export const DUPLICATE_PROPERTY_GROUP = gql`
    mutation DuplicateBrand($id: Int64!) {
        duplicateBrand(id: $id) {
            error
            id
            ok
            __typename
        }
    }
`;

export const UPDATE_PROPERTY_GROUP = gql`
    mutation UpdateBrand($id: Int64!, $replace: Boolean!, $name: String!, $projects: [Int64], $assignedAll: Boolean) {
        updateBrand(id: $id, replace: $replace, name: $name, projects: $projects, assignedAll: $assignedAll) {
            error
            id
            ok
        }
    }
`;

export const GET_USERS_QUERY = gql`
    query GetUsers($ref: String!) {
        users(ref: $ref) {
            info {
                count
            }
            results {
                brands {
                    id
                    name
                    ref
                    chainID
                }
            }
        }
    }
`;

export const GET_CORPORATE_DESIGNS = gql`
    query {
        designs(filter: { isCorporate: true }) {
            results {
                id
                name
                isAssigned
                isAssignedFromCorporate
                brands {
                    ref
                    name
                }
                visibleAllProject {
                    name
                    ref
                    type
                }
            }
        }
    }
`;

export const GET_DESIGNS_WITH_LINKED_SIGNAGES = (ids) => {
    if (ids.length > 0) {
        let queryBody = "query {";

        ids.forEach((id) => {
            queryBody += `contentTree${id}: contentTree(designID:${id}){
                screens {
                        dir {
                            contents {
                                id
                                linkedSignages {
                                id
                                }
                            }
                            subDirs {
                                contents {
                                    id
                                    linkedSignages {
                                    id
                                }
                            }
                        }
                    }
                }
            }`;
        });

        queryBody += "}";

        return gql`
            ${queryBody}
        `;
    } else {
        return gql`
            query {
                fakeQuery
            }
        `;
    }
};

export const GET_BRANDS_AND_PROPERTIES = gql`
    query GetBrandsAndProperties(
        $size: Int
        $page: Int
        $count: Int
        $orderBy: OrderBy
        $IDs: [Int64]
        $brandFilter: BrandFilter
        $propertyRefs: [String!]
    ) {
        brands(size: $size, page: $page, count: $count, orderBy: $orderBy, IDs: $IDs, filter: $brandFilter) {
            info {
                count
            }
            results {
                id
                chainID
                name
                ref
                hasUsers
                projects {
                    id
                    name
                    hasChainModule
                }
            }
        }
        properties(filter: { refs: $propertyRefs }) {
            info {
                count
            }
            results {
                id
                name
                hasChainModule
            }
        }
    }
`;
