import { gql } from "apollo-boost";
import { POLLINTERVAL_30S } from "hooks/Utils/Utils";
import { useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { useTranslation } from "react-i18next";

export const useNetworkMonitorCard = () => {
    const [summary, setSummary] = useState({});
    const { t } = useTranslation();

    const GET_SUMMARY = gql`
        {
            getNetworkMonitorSummaryInfo {
                criticalDevices
                monitoredDevices
                eventWarnings {
                    highEvents
                    highEvents
                    infoEvents
                    warningEvents
                }
                statusDevices {
                    onlineDevices
                    offlineDevices
                    unknownDevices
                }
            }
        }
    `;

    const [executeNetworkMonitorSummaryInfo, { data, loading, error }] = useLazyQuery(GET_SUMMARY, {
        fetchPolicy: "network-only",
        pollInterval: POLLINTERVAL_30S,
    });

    useEffect(() => {
        executeNetworkMonitorSummaryInfo();
    }, []);

    useEffect(() => {
        if (data) {
            const { getNetworkMonitorSummaryInfo } = data;
            const { eventWarnings, statusDevices, criticalDevices, monitoredDevices } = getNetworkMonitorSummaryInfo;
            const { highEvents, infoEvents, warningEvents } = eventWarnings;
            const { onlineDevices, offlineDevices, unknownDevices } = statusDevices;
            setSummary({
                criticalDevices,
                monitoredDevices,
                eventWarnings: [
                    {
                        events: highEvents,
                        icon: "critical",
                        label: t("critical"),
                    },
                    {
                        events: warningEvents,
                        icon: "warning",
                        label: t("average"),
                    },
                    {
                        events: infoEvents,
                        icon: "warning",
                        color: "text-blue-100",
                        label: t("informational"),
                    },
                ],
                statusDevices: [
                    offlineDevices && {
                        icon: "alert",
                        color: "text-red-100",
                        status: t("offline"),
                        devices: offlineDevices,
                    },
                    onlineDevices && {
                        icon: "alert",
                        color: "text-green-100",
                        status: t("online"),
                        devices: onlineDevices,
                    },
                    unknownDevices && {
                        icon: "alert",
                        color: "text-gray-500",
                        status: t("unknown"),
                        devices: unknownDevices,
                    },
                ],
            });
        }
    }, [data]);

    return { summary, loading, noHosts: error?.message?.includes("no hosts found") };
};
