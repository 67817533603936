import React from "react";
import { useTranslation } from "react-i18next";
import UseSectionHeader from "../../../useSectionHeader";
import Table from "components/ZafiroTable";
import Modal, { useModal } from "components/Modal";
import { useListCZ } from "hooks/GraphqlCalls/Hotel/CommonZones/useListCZ";
import Loading from "components/Loading";
import { BatchActionsModal } from "../Rooms/Rooms";
import { ROOM_TYPE } from "constants/rooms";

export const CommonZonesList = () => {
    const { t } = useTranslation();
    const { open, close } = useModal();
    const { rows, loading, filters, refetch } = useListCZ();

    return !loading ? (
        <>
            <UseSectionHeader title={t("common-zone-list")} value={"demo"} />

            <main className="bg-white p-6 rounded">
                <Table
                    id="common-zones-list"
                    search={true}
                    showCount={true}
                    paginate={true}
                    header={{
                        name: { title: t("name"), sortable: true },
                        content: { title: t("content") },
                        group: { title: t("group") },
                        "devices-notices": {
                            title: t("device-status"),
                            sortable: true,
                        },
                    }}
                    rows={rows}
                    cols={["name", "content", "group", "devices-notices"]}
                    filters={[
                        {
                            id: "filter-by-floor",
                            title: t("floor"),
                            multiple: true,
                            options: filters[0],
                            onFilter: (value, row) => value.some((selectedValue) => row?.floorFilter === selectedValue),
                        },
                        {
                            id: "filter-by-group",
                            title: t("group"),
                            multiple: true,
                            options: filters[1],
                            onFilter: (value, row) =>
                                value.some((selectedValue) => row?.groupFilter?.includes(selectedValue)),
                        },
                    ]}
                    batchActions={{
                        options: [
                            {
                                id: "add-to-group",
                                label: t("add-to-group"),
                                value: "add-to-group",
                            },
                        ],
                        onChange: (action, rows) => {
                            if (action === "add-to-group") {
                                open(
                                    <Modal
                                        id="add-to-group"
                                        title={<h1 className="text-2xl font-bold">{t("add-to-group")}</h1>}
                                        minWidth="30rem"
                                        className="w-1/2 p-10"
                                        onKeyPress={{ Enter: close }}
                                    >
                                        <BatchActionsModal
                                            rows={rows}
                                            filters={filters[1]}
                                            refetch={refetch}
                                            type={ROOM_TYPE.COMMONZONE}
                                        />
                                    </Modal>
                                );
                            }
                        },
                    }}
                />
            </main>
        </>
    ) : (
        <Loading adjust="section-loading" style={{ height: "50vh" }} />
    );
};
