import { useEffect, useState } from "react";
import { Session } from "hooks/Utils/Session";
import { useLazyQuery } from "react-apollo";
import {
    GET_USERS_QUERY,
    GET_BRANDS_AND_PROPERTIES,
    MUTATION_CREATE_GROUP,
    DELETE_PROPERTY_GROUP,
    DUPLICATE_PROPERTY_GROUP,
    UPDATE_PROPERTY_GROUP,
    GET_DESIGNS_BY_PROPERTIES,
    GET_CORPORATE_DESIGNS,
    GET_DESIGNS_WITH_LINKED_SIGNAGES,
} from "./mutationsQueries";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { POLLINTERVAL_15S } from "hooks/Utils/Utils";
import { checkHasLinkedSignages } from "components/Section/Design/Advertising/utils";

export const useListPropertyGroups = () => {
    const currentUser = Session.getUser();
    const currentUserRef = currentUser?.ref;

    const [brandsIds, setBrandsIds] = useState(null);
    const [projectsRef, setProjectsRef] = useState(null);
    const [brands, setBrands] = useState([]);
    const [properties, setProperties] = useState([]);
    const [inRefetch, setInRefetch] = useState(false);

    useEffect(() => {
        const projects = Session.getProjects();

        if (projects) {
            setProjectsRef(
                projects.map((project) => {
                    return project.ref;
                })
            );
        }
    }, []);

    const [getChainBrands, { data: dataChainBrands, refetch }] = useLazyQuery(GET_USERS_QUERY, {
        variables: { ref: currentUserRef },
        pollInterval: POLLINTERVAL_15S,
        fetchPolicy: "network-only",
    });

    const [getBrandsAndProperties, { data: dataBrandsAndProperties }] = useLazyQuery(GET_BRANDS_AND_PROPERTIES, {
        variables: {
            IDs: brandsIds || [],
            propertyRefs: projectsRef || [],
            orderBy: { field: "id", criteria: "desc" },
        },
        pollInterval: POLLINTERVAL_15S,
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (dataChainBrands) {
            const brands = dataChainBrands?.users?.results?.[0]?.brands || [];
            setBrandsIds(brands.map((brand) => brand.id));
        }
    }, [dataChainBrands]);

    useEffect(() => {
        if (dataBrandsAndProperties) {
            setProperties(dataBrandsAndProperties?.properties?.results);
            setBrands(dataBrandsAndProperties?.brands?.results);
        }
    }, [dataBrandsAndProperties]);

    useEffect(() => {
        if (currentUserRef) {
            getChainBrands({ variables: { ref: currentUserRef } });
        }
    }, [currentUserRef]);

    useEffect(() => {
        if (brandsIds?.length > 0) {
            getBrandsAndProperties({
                variables: { IDs: brandsIds || [], propertyRefs: projectsRef || [] },
            });
            setInRefetch(false);
        }
    }, [brandsIds, projectsRef, inRefetch]);

    return {
        brands,
        properties,
        refetch: (refetchBrandsAndProperties) => {
            refetch();
            if (refetchBrandsAndProperties) {
                setInRefetch(true);
            }
        },
    };
};

export const useGetHasLinkedSignages = ({ refs }) => {
    const [designIds, setDesignIds] = useState([]);
    const [hasLinkedSignagesState, setHasLinkedSignagesState] = useState(false);

    const [getDesignByProperties, { data: dataDesignByProperties, loading: loadingDesigns }] = useLazyQuery(
        GET_CORPORATE_DESIGNS,
        { fetchPolicy: "network-only" }
    );

    const [getContentTree, { data: dataContentTree, loading: loadingContentTree }] = useLazyQuery(
        GET_DESIGNS_WITH_LINKED_SIGNAGES(designIds),
        { fetchPolicy: "network-only" }
    );

    const loading = loadingDesigns || loadingContentTree;

    useEffect(() => {
        getDesignByProperties();
    }, []);

    useEffect(() => {
        const results = dataDesignByProperties?.designs?.results ?? [];
        const filteredIds = results
            .filter(
                (design) => design?.visibleAllProject?.type === "BRAND" && refs?.includes(design.visibleAllProject.ref)
            )
            .map((design) => design.id);

        if (filteredIds.length > 0) {
            setDesignIds(filteredIds);
        }
    }, [dataDesignByProperties]);

    useEffect(() => {
        if (designIds.length > 0) {
            getContentTree();
        }
    }, [designIds]);

    useEffect(() => {
        if (dataContentTree) {
            setHasLinkedSignagesState(checkHasLinkedSignages(dataContentTree));
        }
    }, [dataContentTree]);

    return { hasLinkedSignages: hasLinkedSignagesState, loading };
};

const useMutationWithHandlers = (mutation, { close, refetch }) => {
    const { t } = useTranslation();

    const [mutateFunction, { loading }] = useMutation(mutation, {
        onCompleted: () => {
            toast.success(t("operation-successful"));
            if (typeof refetch === "function") {
                refetch();
            }
            if (typeof close === "function") {
                close();
            }
        },
        onError: (error) => {
            const errorMessage = error?.graphQLErrors?.[0]?.message || error.message || "An unknown error occurred";
            toast.error(errorMessage);
            if (typeof refetch === "function") {
                refetch();
            }
            if (typeof close === "function") {
                close();
            }
        },
    });

    return { mutateFunction, loading };
};

export const useCreateGroupProperty = (params) => {
    const { mutateFunction: createGroupProperty, loading } = useMutationWithHandlers(MUTATION_CREATE_GROUP, params);
    return { createGroupProperty, loadingCreateGroupProperty: loading };
};

export const useDeletePropertyGroup = (params) => {
    const { mutateFunction: deletePropertyGroup, loading } = useMutationWithHandlers(DELETE_PROPERTY_GROUP, params);
    return { deletePropertyGroup, loadingDeletePropertyGroup: loading };
};

export const useDuplicatePropertyGroup = (params) => {
    const { mutateFunction: duplicatePropertyGroup, loading } = useMutationWithHandlers(
        DUPLICATE_PROPERTY_GROUP,
        params
    );
    return { duplicatePropertyGroup, loadingDuplicatePropertyGroup: loading };
};

export const useUpdatePropertyGroup = (params) => {
    const { mutateFunction: updatePropertyGroup, loading } = useMutationWithHandlers(UPDATE_PROPERTY_GROUP, params);
    return { updatePropertyGroup, loadingUpdatePropertyGroup: loading };
};
