import { HotelRoomsModel } from "../Rooms/models/HotelRoomsModel";
import _ from "lodash";

export const getHotelMap = (hotelMap) => {
    let wings = [];
    let floors = [];
    let currentFloors;
    if (hotelMap && Array.isArray(hotelMap)) {
        for (const wing of hotelMap) {
            if (!wings.some((w) => w.id === wing.id)) {
                wings.push({
                    id: wing.id,
                    value: wing.name,
                    visible: true,
                });
            }
            currentFloors = wing.floors;
            if (currentFloors !== null && currentFloors.length > 0) {
                for (const floor of currentFloors) {
                    if (!floors.some((f) => f.id === floor.id)) {
                        floors.push({
                            id: floor.id,
                            value: floor.name,
                            visible: true,
                            optGroup: wing.name,
                        });
                    }
                }
            }
        }
    }

    wings.sort(function (a, b) {
        return a.id - b.id;
    });
    return { wings, floors };
};

export const getRoomGroups = (roomGroups) => {
    let _roomgroups = [];

    if (!roomGroups) return [];
    for (const group of roomGroups)
        _roomgroups.push({
            id: group.id,
            value: group.name,
            visible: true,
        });
    return _roomgroups;
};

export const arrangeData = (hotelRoomStaticData, guestAccess, t, open, filterGroup, refetch) => {
    let tempArray = [];
    hotelRoomStaticData.hotelRooms.results.map((hotelRoom) => {
        tempArray.push(HotelRoomsModel(hotelRoom, guestAccess, t, open, filterGroup, refetch));
    });
    return tempArray;
};

export const arrangeFilterData = (hotelMap, roomGroups) => {
    let floorFilter = [];
    let groupFilter = [];
    for (const wing of hotelMap?.wings || []) {
        const filteredFloors = hotelMap?.floors?.filter((floor) => wing.value === floor.optGroup);
        floorFilter.push({
            id: wing.id,
            label: wing.value,
            options: filteredFloors?.map((filterFloor) => ({
                id: filterFloor.id,
                value: filterFloor.id,
                label: filterFloor.value,
            })),
        });
    }

    for (const group of roomGroups) {
        groupFilter.push({
            id: group.id,
            label: group.value,
            value: group.id,
        });
    }

    return [floorFilter, groupFilter];
};
