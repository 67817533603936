import Warning from "components/Warning";
import React from "react";
import { useTranslation } from "react-i18next";

export const ZabbixError = () => {
    const { t } = useTranslation();

    return (
        <Warning
            id="zabbix-unavailable"
            title={t(
                "Network monitoring unavailable: Zabbix server is currently down. No data is being received from monitored devices."
            )}
            collapsible={false}
            className="mt-5"
            icon="alert-red"
            design="black"
        />
    );
};
