import React, { useState, useEffect, useRef } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import { useAuth } from "hooks/Session/auth";
import Select from "components/Select";
import { Radiobutton } from "components/Inputs/Radiobuttons";
import Button from "components/Button";
import { useUploadFileContext } from "contexts/UploadFile/uploadFileContext";
import Loading from "components/Loading";
import { toast } from "react-toastify";

const TRANSCODE_QUALITIES = {
    "4K": "4k",
    FULL_HD: "fullhd",
    HD: "hd",
};

const ModalContent = (props) => {
    const { modalData = {}, closeAllModals } = props;
    const { filesInfo = [], currentIndex = 0, onCloseAction } = modalData;
    const currentFile = filesInfo?.[currentIndex] || {};
    const { name } = currentFile;

    const { t } = useTranslation();
    const { languages: projectLangs, token } = useAuth();
    const { generateFilesUploading, uploadFilesRequest, handleFilesUpload } = useUploadFileContext();
    const { open: openTranscodeVideoModal } = useTranscodeVideo();

    const defaultLang = projectLangs.find((lang) => lang.isDefault)?.languageRef || null;

    const [optionsSelected, setOptionsSelected] = useState({
        language: null,
        quality: TRANSCODE_QUALITIES.FULL_HD,
        validated: false,
    });
    const [loading, setLoading] = useState(true);
    const timeoutRef = useRef(null);

    useEffect(() => {
        setLoadingWithTimeout();
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (defaultLang) {
            handleLanguageChange(defaultLang);
        }
    }, [defaultLang]);

    const setLoadingWithTimeout = (duration = 1000) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => setLoading(false), duration);
    };

    const languagesOptions = projectLangs.map((lang) => ({
        id: "language-opt-" + lang.languageRef,
        label: t("language:" + lang.languageRef),
        value: lang.languageRef,
    }));

    const handleLanguageChange = (values) => {
        setOptionsSelected((prev) => ({
            ...prev,
            language: values,
        }));
    };

    const handleQualityChange = (quality) => {
        setOptionsSelected((prev) => ({
            ...prev,
            quality,
        }));
    };

    const resetModalState = () => {
        closeAllModals();
        setLoading(true);

        openTranscodeVideoModal({
            modalData: {
                filesInfo,
                onCloseAction: props?.modalData?.onCloseAction,
                currentIndex: currentIndex + 1,
            },
        });

        setOptionsSelected({
            language: defaultLang,
            quality: TRANSCODE_QUALITIES.FULL_HD,
            validated: false,
        });

        setLoadingWithTimeout();
    };

    const isLanguageSelected = () => {
        return Boolean(optionsSelected?.language);
    };

    const updateCurrentFileInfo = () => {
        filesInfo.forEach((fileInfo, index) => {
            if (index === currentIndex) {
                fileInfo.language = optionsSelected.language;
                fileInfo.quality = optionsSelected.quality;
            }
        });
    };

    const isLastFile = () => {
        return currentIndex === filesInfo.length - 1;
    };

    const proceedToNextFileOrUpload = async () => {
        if (!isLastFile()) {
            resetModalState();
        } else {
            await uploadFiles();
        }
    };

    const uploadFiles = async () => {
        const files = filesInfo.map((fileInfo) => ({ file: fileInfo }));
        closeAllModals();
        try {
            await handleFilesUpload({
                files,
                t,
                generateFilesUploading,
                uploadFilesRequest,
                token,
                onComplete: () => {
                    onCloseAction();
                },
            });
        } catch (err) {
            console.error("Error uploading files:", err);
        }
    };

    const handleValidationError = () => {
        toast.error(t("input error"));
        setOptionsSelected((prev) => ({ ...prev, validated: true }));
    };

    const handleSave = async () => {
        if (isLanguageSelected()) {
            updateCurrentFileInfo();
            await proceedToNextFileOrUpload();
        } else {
            handleValidationError();
        }
    };

    return (
        <Modal
            footer={
                !loading ? (
                    <>
                        <Button
                            design="blue-outline"
                            id="modal-transcode-cancel"
                            onClick={() => {
                                props.close();
                            }}
                        >
                            {t("cancel")}
                        </Button>
                        <Button design="blue" id="modal-transcode-save" onClick={handleSave}>
                            {t("save")}
                        </Button>
                    </>
                ) : null
            }
            title={`${name} - ${t("transcode-file")}`}
        >
            {loading ? (
                <Loading />
            ) : (
                <div>
                    <div className="mb-2">{t("select-lang-video")}&nbsp;*</div>
                    <Select
                        id="modal-transcode-video-language"
                        placeholder={t("select-a-language")}
                        multiple={false}
                        options={languagesOptions || []}
                        onChange={handleLanguageChange}
                        search={false}
                        value={defaultLang}
                        designClass={{
                            validation:
                                !optionsSelected.language && optionsSelected.validated
                                    ? "border border-red-100"
                                    : "border border-transparent",
                        }}
                    />
                    <div className="text-gray-700 my-5">*&nbsp;{t("select-lang-video-details")}</div>
                    <div className="mb-4">{t("what-qualities")}</div>
                    {Object.entries(TRANSCODE_QUALITIES).map(([key, value]) => (
                        <div className="mb-2" key={value}>
                            <Radiobutton
                                label={t(value)}
                                value={value}
                                checked={optionsSelected.quality === value}
                                onChange={() => handleQualityChange(value)}
                                id={`modal-transcode-video-quality-${value}`}
                            />
                        </div>
                    ))}
                </div>
            )}
        </Modal>
    );
};

const useTranscodeVideo = () => {
    const { open, close, closeAllModals } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close, closeAllModals };
            open(<ModalContent {...newProps} />);
        },
    };
};

export default useTranscodeVideo;
