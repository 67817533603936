import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import EmptyWidget from "components/Section/Design/Widgets/WidgetToolbars/CommonOptions/EmptyWidget";

import { WidgetContext } from "contexts/editor";

const WidgetCalendar = () => {
    const { id: widgetID, type, style, config } = useContext(WidgetContext);

    const [ready, setReady] = useState(!!window.Render);

    //TODO remove redux when new editor is implemented
    const { gridItems } = useSelector((state) => state.design);
    let reduxWidget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    if (reduxWidget) {
        reduxWidget.type = "CALENDAR";
    }

    const newWidget = {
        i: widgetID,
        type,
        widgetStyle: style,
        data: config?.data,
        actions: config?.actions,
    };

    const widget = reduxWidget || newWidget;

    useEffect(() => {
        setReady(!!window?.Render?.menuColorsAndAnimations && !!widget);
    }, [!!window?.Render?.menuColorsAndAnimations, !!widget]);

    if (!ready) {
        return <EmptyWidget type={type} />;
    }

    return (
        <div
            dangerouslySetInnerHTML={{
                width: "100%",
                height: "100%",
                __html: widget ? window.Render.widget(widget).outerHTML : null,
            }}
        ></div>
    );
};

export default WidgetCalendar;
