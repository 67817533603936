import React, { useEffect } from "react";
//Components
import UseSectionHeader from "../../useSectionHeader";
import { NetworkSettingsProvider } from "contexts/NetworkSettings";
import { useCastConfig } from "hooks/GraphqlCalls/Hotel/useCastConfig";
import Loading from "components/Loading";
import NetworkGrid from "../../NetworkGrid";

const CastSettingsWithContext = () => {
    const { networks, ready, executeQuery } = useCastConfig();

    useEffect(() => executeQuery(), []);

    return (
        <>
            <UseSectionHeader title={["cast-settings"]} />
            {ready ? (
                <NetworkGrid gridStyles={`grid grid-rows-2 grid-cols-6 gap-4`} networks={networks} type="cast" />
            ) : (
                <Loading adjust="section-loading" />
            )}
        </>
    );
};

const CastSettings = () => {
    return (
        <NetworkSettingsProvider>
            <CastSettingsWithContext />
        </NetworkSettingsProvider>
    );
};

export default CastSettings;
