import React, { Fragment, useCallback, useEffect, useState } from "react";
import Search from "components/TextInput/Search";
import { WingCard } from "./WingCard";
import { useHotelMap } from "hooks/GraphqlCalls/Hotel/useTopologyMap";
import update from "immutability-helper";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Session } from "hooks/Utils/Session";
import UseTitle from "components/useTitle";
import Icon from "components/Icon";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Loading from "components/Loading";
import { FloorCard } from "./FloorCard";
import TopologyItem from "./TopologyItem";
import MapItem from "components/MapItem";

const GUESTROOM = "GUESTROOM";
const COMMONZONE = "COMMONZONE";

export const TopologyMapView = () => {
    const sectionName = "topology-map";

    const { results: installationMap, loading, mapRefetch, setFilters, filters } = useHotelMap();
    const [activeWing, setActiveWing] = useState(null);
    const [activeFloor, setActiveFloor] = useState(null);
    const [wingCards, setWingCards] = useState(installationMap);
    const [roomOver, setRoomOver] = useState();

    const { t } = useTranslation();
    const floorsLength = installationMap?.map((wing) => (wing?.id === activeWing ? wing?.floors?.length : null));

    useEffect(() => {
        const pLocation = Session.getPreviousPath();
        if (
            pLocation.indexOf("add-wing") > -1 ||
            pLocation.indexOf("add-floor") > -1 ||
            pLocation.indexOf("add-room") > -1 ||
            pLocation.indexOf("edit-room") > -1
        ) {
            setActiveFloor(Number(Session.getSessionProp("topology-active-floor")));
            setActiveWing(Number(Session.getSessionProp("topology-active-wing")));
        } else {
            setActiveWing(installationMap?.[0]?.id);
            setActiveFloor(installationMap?.[0]?.floors?.[0]?.id);

            if (!Session.getSessionProp("topology-active-floor") && installationMap?.[0]?.floors?.[0]) {
                Session.setSessionProp("topology-active-floor", installationMap?.[0]?.floors?.[0]?.id);
            }
            if (!Session.getSessionProp("topology-active-wing") && installationMap?.[0]) {
                Session.setSessionProp("topology-active-wing", installationMap?.[0]?.id);
            }
        }

        if (installationMap?.length > 0) {
            Session.setSessionProp(
                "arrNamesWings",
                installationMap.map((r) => {
                    return r.name;
                })
            );
        }
    }, [installationMap]);

    const handleClickFloor = (e) => {
        setActiveFloor(Number(e.currentTarget.dataset.id));
        Session.setSessionProp("topology-active-floor", Number(e.currentTarget.dataset.id));
    };

    const moveWingCard = useCallback(
        (dragIndex, hoverIndex) => {
            const dragCard = wingCards[dragIndex];
            setWingCards(
                update(wingCards, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                })
            );
        },
        [wingCards]
    );

    let floors = null;
    installationMap.map((wing) => (wing.id === activeWing ? (floors = wing.floors) : null));

    const [floorCards, setFloorCards] = useState(floors);
    const moveFloorCard = useCallback(
        (dragIndex, hoverIndex) => {
            const dragCard = floorCards[dragIndex];
            setFloorCards(
                update(floorCards, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                })
            );
        },
        [floorCards]
    );

    useEffect(() => {
        let floors = null;
        installationMap.map((wing) => (wing.id === activeWing ? (floors = wing.floors) : null));
        setFloorCards(floors);
        // eslint-disable-next-line
    }, [activeWing]);

    useEffect(() => {
        setWingCards(installationMap);
        let floors = null;
        installationMap.map((wing) => (wing.id === activeWing ? (floors = wing.floors) : null));
        setFloorCards(floors);
        // eslint-disable-next-line
    }, [installationMap]);

    const handleClickWing = (e) => {
        const wingId = Number(e.currentTarget.dataset.id);
        setActiveWing(wingId);
        Session.setSessionProp("topology-active-wing", wingId);

        //mark first available floor
        let firstAvailableFloor = "";
        // eslint-disable-next-line
        installationMap.map((wing) => {
            if (wing.id === wingId) {
                firstAvailableFloor = wing?.floors?.[0]?.id;
            }
        });
        setActiveFloor(firstAvailableFloor);
        Session.setSessionProp("topology-active-floor", firstAvailableFloor);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="w-1/3">
                <Search onChange={(value) => setFilters(value)} />
            </div>
            {!loading && !filters ? (
                <section className="mt-10 ml-1">
                    <div>
                        <div className="flex">
                            <UseTitle text={"wings"} adjust="text-gray-900 font-bold text-lg pb-2" />
                            <span className="ml-2 p-0.5 text-gray-800">({installationMap.length})</span>
                        </div>
                        <div className="flex flex-wrap items-center">
                            {wingCards.map((wing, index) => (
                                <WingCard
                                    key={wing.id}
                                    parentSectionName={sectionName}
                                    id={wing.id}
                                    wing={wing}
                                    index={index}
                                    moveCard={moveWingCard}
                                    handleClickWing={handleClickWing}
                                    activeWing={activeWing}
                                    mapRefetch={mapRefetch}
                                />
                            ))}
                            <Button
                                id={`${sectionName}-add-wing`}
                                design="blue-outline"
                                className="flex items-center px-6"
                                to={`/hotel/property-settings/topology/add-wing`}
                            >
                                <Icon type="add" />
                                <span className="font-bold">{t("add-wing")}</span>
                            </Button>
                        </div>
                    </div>
                    <div className="my-8">
                        <div className="flex">
                            <UseTitle text={"floors"} adjust="text-gray-900 font-bold text-lg pb-2" />
                            <span className="ml-2 p-0.5 text-gray-800">({floorsLength})</span>
                        </div>
                        <div className="flex flex-wrap items-center">
                            {floorCards
                                ? floorCards.map((floor, index) => (
                                      <FloorCard
                                          key={floor.id}
                                          parentSectionName={sectionName}
                                          id={floor.id}
                                          floor={floor}
                                          index={index}
                                          moveCard={moveFloorCard}
                                          handleClickFloor={handleClickFloor}
                                          activeFloor={activeFloor}
                                          mapRefetch={mapRefetch}
                                      />
                                  ))
                                : null}
                            <Button
                                id={`${sectionName}-add-floor`}
                                design="blue-outline"
                                className="flex items-center px-6"
                                to={`/hotel/property-settings/topology/add-floor/${activeWing}`}
                            >
                                <Icon type="add" />
                                <span className="font-bold">{t("add-floor")}</span>
                            </Button>
                        </div>
                    </div>
                    {installationMap?.map((wing) =>
                        wing.id === activeWing
                            ? wing.floors.map((floor, index) =>
                                  floor.id === activeFloor ? (
                                      <div className="border border-gray-300 p-4 rounded">
                                          <div key={index}>
                                              <div className="flex">
                                                  <UseTitle
                                                      text={"common-zones"}
                                                      adjust="text-gray-900 font-bold text-lg pb-2"
                                                  />
                                                  <span className="ml-2 p-0.5 text-gray-800">
                                                      (
                                                      {floor.rooms.filter((room) => room.type === COMMONZONE)?.length ||
                                                          0}
                                                      )
                                                  </span>
                                              </div>
                                              <div className="flex flex-wrap items-center">
                                                  {floor.rooms
                                                      .filter((room) => room.type === COMMONZONE)
                                                      .map((room) => (
                                                          <div
                                                              id={`${sectionName}-room-${room.name}-container`}
                                                              onMouseEnter={() => setRoomOver(room.id)}
                                                              onMouseLeave={() => setRoomOver(null)}
                                                              className="inline-flex"
                                                              key={room.id}
                                                              data-id={room.id}
                                                          >
                                                              <div className="">
                                                                  <TopologyItem
                                                                      data={{
                                                                          ...room,
                                                                          parentSectionName: `${sectionName}-room`,
                                                                      }}
                                                                      showOptions={roomOver === room.id}
                                                                      mapRefetch={mapRefetch}
                                                                  />
                                                              </div>
                                                          </div>
                                                      ))}
                                                  <Button
                                                      id={`${sectionName}-add-room`}
                                                      design="blue-outline"
                                                      className="flex items-center px-6"
                                                      to={`/hotel/property-settings/topology/add-common-zone/${activeFloor}`}
                                                  >
                                                      <Icon type="add" />
                                                      <span className="font-bold">{t("add-common-zone")}</span>
                                                  </Button>
                                              </div>
                                          </div>
                                          <div key={floor.id} className="mt-6">
                                              <div className="flex">
                                                  <div>
                                                      <UseTitle
                                                          text={"rooms"}
                                                          adjust="text-gray-900 font-bold text-lg pb-2"
                                                      />
                                                  </div>
                                                  <span className="ml-2 p-0.5 text-gray-800">
                                                      (
                                                      {floor.rooms.filter((room) => room.type === GUESTROOM)?.length ||
                                                          0}
                                                      )
                                                  </span>
                                              </div>
                                              <div className="flex flex-wrap items-center">
                                                  {floor.rooms
                                                      .filter((room) => room.type === GUESTROOM)
                                                      .map((room) => (
                                                          <div
                                                              id={`${sectionName}-room-${room.name}-container`}
                                                              onMouseEnter={() => setRoomOver(room.id)}
                                                              onMouseLeave={() => setRoomOver(null)}
                                                              className="inline-flex"
                                                              key={room.id}
                                                              data-id={room.id}
                                                          >
                                                              <div className="">
                                                                  <TopologyItem
                                                                      data={{
                                                                          ...room,
                                                                          parentSectionName: `${sectionName}-room`,
                                                                      }}
                                                                      showOptions={roomOver === room.id}
                                                                      mapRefetch={mapRefetch}
                                                                  />
                                                              </div>
                                                          </div>
                                                      ))}
                                                  <Button
                                                      id={`${sectionName}-add-room`}
                                                      design="blue-outline"
                                                      className="flex items-center px-6"
                                                      to={`/hotel/property-settings/topology/add-room/${activeFloor}`}
                                                  >
                                                      <Icon type="add" />
                                                      <span className="font-bold">{t("add-room")}</span>
                                                  </Button>
                                              </div>
                                          </div>
                                      </div>
                                  ) : null
                              )
                            : null
                    )}
                </section>
            ) : filters ? (
                <FilteredRooms
                    mapRefetch={mapRefetch}
                    setRoomOver={setRoomOver}
                    roomOver={roomOver}
                    activeFloor={activeFloor}
                    installationMap={installationMap}
                />
            ) : (
                <Loading adjust="section-loading" style={{ height: "50vh" }} />
            )}
        </DndProvider>
    );
};

export const FilteredRooms = ({ mapRefetch, installationMap, setRoomOver, roomOver, activeFloor, view }) => {
    const { t } = useTranslation();
    const sectionName = "topology-map";

    const renderRooms = (rooms, roomType, addRoomPath, addRoomText) => (
        <>
            <div
                className={`flex flex-wrap items-center py-2 ${
                    rooms?.some((room) => room.type === GUESTROOM) &&
                    rooms?.some((room) => room.type === COMMONZONE) &&
                    roomType === COMMONZONE &&
                    "border-b border-gray-300"
                }`}
            >
                {rooms.map((room, index) =>
                    room.type === roomType ? (
                        <div
                            {...(view !== "map" ? { onMouseEnter: () => setRoomOver(room.id) } : {})}
                            {...(view !== "map" ? { onMouseLeave: () => setRoomOver(null) } : {})}
                            className="inline-flex"
                            key={index}
                            data-id={room.id}
                        >
                            {view === "map" ? (
                                <MapItem data={room} />
                            ) : (
                                <div>
                                    <TopologyItem
                                        data={{
                                            ...room,
                                            parentSectionName: `${sectionName}-room`,
                                        }}
                                        showOptions={roomOver === room.id}
                                        mapRefetch={mapRefetch}
                                    />
                                </div>
                            )}
                        </div>
                    ) : null
                )}
                {rooms.some((room) => room.type === roomType) && view !== "map" && (
                    <div className="inline-flex">
                        <Button
                            id={`${sectionName}-add-room`}
                            design="blue-outline"
                            className="flex items-center"
                            to={addRoomPath}
                        >
                            <Icon type="add" />
                            <span className="font-bold">{t(addRoomText)}</span>
                        </Button>
                    </div>
                )}
            </div>
        </>
    );

    return installationMap?.filter((wing) => wing?.floors?.filter((floor) => floor?.rooms?.length > 0).length > 0)
        .length === 0 ? (
        <div className="first-capital"> {t("no-search-results")}</div>
    ) : (
        installationMap?.map((wing, index) =>
            wing?.floors?.filter((floor) => floor?.rooms?.length > 0).length > 0 ? (
                <Fragment key={index}>
                    <UseTitle text={wing?.name} adjust="pt-6 text-gray-900 font-bold text-lg" />
                    <div>
                        {wing?.floors?.map((floor) =>
                            floor?.rooms?.length > 0 ? (
                                <Fragment key={floor.id}>
                                    <UseTitle text={floor.name} adjust="pt-4 text-gray-900 font-normal text-lg" />
                                    {renderRooms(
                                        floor.rooms,
                                        COMMONZONE,
                                        `/hotel/property-settings/topology/add-common-zone/${activeFloor}`,
                                        "add-common-zone"
                                    )}
                                    {renderRooms(
                                        floor.rooms,
                                        GUESTROOM,
                                        `/hotel/property-settings/topology/add-room/${activeFloor}`,
                                        "add-room"
                                    )}
                                </Fragment>
                            ) : null
                        )}
                    </div>
                </Fragment>
            ) : null
        )
    );
};
