import React, { useRef, useState, useMemo, useEffect } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { WIDGET, WIDGETS, MIN_WIDTH, MIN_HEIGHT, WIDGET_TOOLBAR } from "constants/editor";

import { useConfirm } from "components/Modal";

import Icon from "components/Icon";

import Widget from "./widget";
import Toolbar, { Settings } from "./toolbar";

const Item = ({
    widget,
    layoutRef,
    parentLayoutRef,
    disabled,
    selected,
    editingMenu,
    insideMenu,
    containerSelected,
    setContainerSelected,
    resizing,
    dragging,
    rowHeight,
    margin,
    gap,
    dragClassName = "widget-label",
    droppingItem,
    onChange,
}) => {
    const { t } = useTranslation();
    const { open: confirm } = useConfirm();

    const widgetRef = useRef(null);

    const [scrollEnabled, setScrollEnabled] = useState(false);
    const [widgetSizes, setWidgetSizes] = useState(null);

    const isMenu = widget?.type === WIDGET.TYPE.TVMENU;
    const isContainer = widget?.type === WIDGET.TYPE.CONTAINER;

    const ID = widget?.id;
    const isDraggable = widget?.layout?.isDraggable !== false;

    const itemResizing = resizing && String(resizing?.i) === String(ID) ? resizing : null;
    const itemDragging = dragging && String(dragging?.i) === String(ID) ? dragging : null;
    const currentItemLayout = itemResizing || widget?.layout;

    // Item is disabled if any of the following conditions are met:
    // - The item is disabled
    // - The item is being resized
    // - The item is being dragged
    const isDisabled = disabled || itemResizing || itemDragging;
    const isSelected = selected && !disabled;
    const isActive = isSelected && (containerSelected || (!isContainer && !isMenu));

    const toolbar = [
        ...(WIDGETS[widget?.type]?.toolbar || []),
        WIDGET_TOOLBAR.DEBUG, //TODO remove this
    ];

    const deleteAction = () => {
        confirm({
            id: `modal-delete-widget-${ID}`,
            message: t("Are you sure you want to delete this widget?"),
            warning: t("Please note that this action cannot be undone"),
            title: t("Delete widget"),
            onConfirm: () => {
                if (onChange) {
                    onChange(null);
                }
            },
            confirmText: t("delete"),
            sensitive: true,
        });
    };

    const menu = useMemo(
        () =>
            toolbar?.length
                ? toolbar.map((type) => {
                      const isDelete = type === WIDGET_TOOLBAR.DELETE;
                      return {
                          type,
                          action: isDelete ? deleteAction : null,
                          content: !isDelete ? (
                              <Settings
                                  id={type}
                                  data={widget}
                                  onChange={(widget) => {
                                      console.log("TEST Menu onChange");
                                      if (onChange) {
                                          onChange(widget);
                                      }
                                  }}
                              />
                          ) : null,
                      };
                  })
                : [],
        [toolbar, onChange]
    );

    const config = {
        data: widget?.data,
        actions: widget?.actions,
        style: widget?.style,
        layout: {
            cols: currentItemLayout?.w,
            maxRows: currentItemLayout?.h,
            rowHeight,
            margin,
            gap,
        },
        widgets: widget?.widgets,
    };

    const title = t(WIDGETS[widget?.type]?.name);
    const itemSize = `${currentItemLayout?.w}x${currentItemLayout?.h}`;

    const displayTitle = isActive;
    const displaySize = itemResizing && isActive;

    const topSpacing = widget?.layout?.y || 0 * rowHeight || 1;

    const tagHeight = 22;

    useEffect(() => {
        setScrollEnabled(layoutRef?.current?.hasScroll ? layoutRef.current.hasScroll() : false);
    }, [layoutRef?.current]);

    useEffect(() => {
        if (widgetRef.current) {
            const { width, height } = widgetRef.current.getBoundingClientRect();
            setWidgetSizes({ width, height });
        }
    }, [widgetRef.current]);

    return (
        <>
            {displayTitle ? (
                <div
                    className={dragClassName || ""}
                    style={
                        isMenu
                            ? {
                                  position: "absolute",
                                  borderRadius: "0.25rem 0.25rem 0 0",
                                  top: 0,
                                  left: 0,
                                  height: `${tagHeight}px`,
                                  lineHeight: `${tagHeight}px`,
                                  transformOrigin: "-4px -1px",
                                  transform: "rotate(90deg) rotate(180deg) translateX(-100%) translateY(-100%)",
                              }
                            : {
                                  height: `${tagHeight}px`,
                                  left: "-2px",
                                  ...(scrollEnabled && topSpacing <= tagHeight
                                      ? {
                                            top: 0,
                                            borderRadius: "0 0 0.25rem 0.25rem",
                                        }
                                      : {
                                            top: `${tagHeight * -1}px`,
                                            borderRadius: "0.25rem 0.25rem 0 0",
                                        }),
                              }
                    }
                >
                    <div className="widget-tag mx-auto flex space-x-1 items-center h-full">
                        {isDraggable && !isMenu ? <Icon type="move-widget" size={1} /> : null}
                        <div className={isMenu ? "text-base px-3" : ""}>{title}</div>
                    </div>
                </div>
            ) : null}
            <div
                ref={widgetRef}
                className={classNames({
                    "relative w-full h-full": true,
                    "overflow-hidden": !isMenu && !isContainer,
                })}
            >
                <Widget
                    id={ID}
                    type={widget?.type}
                    layoutRef={layoutRef}
                    widgetSizes={widgetSizes}
                    config={config}
                    disabled={isDisabled}
                    editorSelected={containerSelected}
                    editingMenu={editingMenu}
                    insideMenu={insideMenu}
                    selected={isSelected}
                    onItemSelect={() => {
                        if (isContainer || isMenu) {
                            setContainerSelected(false);
                        } else {
                            setContainerSelected(true);
                        }
                    }}
                    droppingItem={isContainer || isMenu ? droppingItem : null}
                    onChange={(newConfig) => {
                        if (onChange) {
                            onChange(newConfig);
                        }
                    }}
                />
            </div>
            {displaySize ? (
                <div
                    className="mt-1 w-full mx-auto flex items-center fixed pointer-events-none"
                    style={{ bottom: "-2em" }}
                >
                    <div className="widget-tag mx-auto flex space-x-1 items-center">{itemSize}</div>
                </div>
            ) : null}
            <Toolbar
                type={widget?.type}
                visible={isActive && !itemResizing && !itemDragging}
                targetRef={widgetRef}
                layoutRef={parentLayoutRef || layoutRef}
                menu={menu}
            />
        </>
    );
};

export const newItem = (props) => {
    const item = {
        ...props,
        isDraggable: props?.isDraggable ?? true,
        isResizable: props?.isResizable ?? true,
        allowOverlap: props?.allowOverlap ?? false,
        outOfGrid: props?.outOfGrid ?? false,
        w: props?.w || MIN_WIDTH,
        h: props?.h || MIN_HEIGHT,
        x: props?.x || 0,
        y: props?.y || 0,
        minW: props?.minW ?? MIN_WIDTH,
        minH: props?.minH ?? MIN_HEIGHT,
    };
    item.static = !item.isDraggable && !item.isResizable;

    if (item.isResizable && !item.isDraggable) {
        item.resizeHandles = ["se"];
    }

    if (props?.grid) {
        // Ensure the item is within the grid
        const fixedWidth = Math.max(item.x + item.w - item.x, 1);
        const fixedHeight = Math.max(item.y + item.h - item.y, 1);
        if (fixedWidth != item.w || fixedHeight != item.h) {
            console.warn(
                `Item ${item.i} resized to fit the grid: w: ${fixedWidth}, h: ${fixedHeight}. Original w: ${item.w}, h: ${item.h}`
            );
            item.w = fixedWidth;
            item.h = fixedHeight;
        }
        // Check grid limits
        const scrollX = props?.grid?.scrollX;
        const scrollY = props?.grid?.scrollY;
        const layoutRight = props?.grid?.cols;
        const layoutBottom = props?.grid?.rows;
        const itemRight = item.x + item.w;
        const itemBottom = item.y + item.h;
        if (!scrollX && layoutRight && layoutRight < itemRight) {
            item.w = layoutRight - item.x;
        }
        if (!scrollY && layoutBottom && layoutBottom < itemBottom) {
            item.h = layoutBottom - item.y;
        }
    }
    return item;
};

export default Item;
