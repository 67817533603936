import React, { useEffect, useRef, useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { Radiobutton } from "components/Inputs/Radiobuttons";
import { capitalizeFirst } from "hooks/Utils/Utils";
import Select from "components/Select";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import Icon from "components/Icon";
import ZafiroTable from "components/ZafiroTable";
import uuid from "react-uuid";
import Loading from "components/Loading";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { Session } from "hooks/Utils/Session";

export const TYPES_MODAL_ASSIGN_EMAILS = {
    FEEDBACK_SETTINGS: "FEEDBACK_SETTINGS",
    SHOP_EMAILS: "SHOP_EMAILS",
    SHOP_EMAILS_WITHOUT_ORDER_MANAGEMENT: "SHOP_EMAILS_WITHOUT_ORDER_MANAGEMENT",
};

const MUTATIONS = {
    FEEDBACK_SETTINGS: gql`
        mutation UpdateFeedbackSetting($projectID: Int64!, $feedbackEmails: [String], $userIds: [Int64]) {
            updateFeedbackSetting(projectID: $projectID, feedbackEmails: $feedbackEmails, userIDs: $userIds) {
                error
                ok
            }
        }
    `,
    SHOP_EMAILS: gql`
        mutation UpdateShop($id: Int64!, $feedbackEmails: [String!], $userIds: [Int!]) {
            updateShop(id: $id, destinationEmails: $feedbackEmails, destinationUsers: $userIds) {
                error
                id
                ok
            }
        }
    `,
    SHOP_EMAILS_WITHOUT_ORDER_MANAGEMENT: gql`
        mutation UpdateShop($id: Int64!, $feedbackEmails: [String!], $userIds: [Int!]) {
            updateShop(id: $id, destinationEmails: $feedbackEmails, destinationUsers: $userIds) {
                error
                id
                ok
            }
        }
    `,
};

export const QUERIES = {
    FEEDBACK_SETTINGS: gql`
        query GetUsers {
            users {
                results {
                    email
                    id
                }
            }
        }
    `,
    SHOP_EMAILS: gql`
        query GetUsersInfo($token: String!) {
            shops(token: $token) {
                results {
                    usersInfo {
                        accountname
                        fullName
                        id
                    }
                }
            }
        }
    `,
    SHOP_EMAILS_WITHOUT_ORDER_MANAGEMENT: gql`
        query GetUsers {
            users {
                results {
                    email
                    id
                    roles {
                        accesses {
                            category
                            name
                        }
                    }
                }
            }
        }
    `,
};

const ContentModalAssignEmails = ({
    onCLickSave,
    type,
    close,
    selectedData,
    setIsLoadingData,
    actionPostUpdate,
    extraVariables,
}) => {
    const { t } = useTranslation();

    const [inputMode, setInputMode] = useState("users");
    const [emailList, setEmailList] = useState([]);
    const [emailRowEntries, setEmailRowEntries] = useState([]);
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [initialUsersOption, setInitialUserOptions] = useState([]);
    const [internalLoading, setInternalLoading] = useState(false);

    const emailInputRef = useRef(null);

    const { data } = useUsersData({ type: type, setInternalLoading });

    const { mutateUsersData } = useMutateUsersData({
        type: type,
        variables: extractVariablesFn(type, emailRowEntries, extraVariables),
        actionPostUpdate,
        close,
    });

    const extractValidEmails = (text) => {
        const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
        return text.match(emailPattern) || [];
    };

    const createEmailRow = (id, email, isFromUsers) => ({
        id,
        email: email,
        delete: (
            <DeleteRow
                key={id}
                index={emailRowEntries.length}
                onRemove={() => {
                    handleRemoveRowFn({
                        id: id,
                        isFromUsers,
                        email,
                        type,
                        setEmailRowEntries,
                        userOptions,
                        setUserOptions,
                        data,
                    });
                }}
            />
        ),
        isFromUsers,
        value: email,
    });

    useEffect(() => {
        setIsLoadingData(internalLoading);
    }, [internalLoading]);

    useEffect(() => {
        const initialRowEntries = getInitialRowEntriesFn(selectedData, userOptions, createEmailRow);
        setEmailRowEntries(initialRowEntries);

        const filteredUserOptions = userOptions.filter((userOption) => !selectedData.users.includes(userOption.value));

        setUserOptions(filteredUserOptions);
    }, [selectedData, initialUsersOption]);

    useEffect(() => {
        if (onCLickSave) {
            mutateUsersData();
            setInternalLoading(true);
        }
    }, [onCLickSave, emailRowEntries]);

    useEffect(() => {
        const userOptions = getUserOptionsByTypeFn(type, data, selectedData);
        setUserOptions(userOptions);
        setInitialUserOptions([...userOptions]);
    }, [data]);

    const handleAddButtonClick = () => {
        if (inputMode === "write") {
            handleAddRowFn({
                items: emailList,
                isFromUsers: false,
                emailRowEntries,
                createEmailRow,
                setEmailRowEntries,
            });

            emailInputRef.current.value = "";
            setEmailList([]);
        } else if (inputMode === "users") {
            handleAddRowFn({
                items: userOptions.filter((user) => selectedUserIds.includes(user.value)),
                isFromUsers: true,
                emailRowEntries,
                createEmailRow,
                setEmailRowEntries,
            });

            setUserOptions((prevOptions) => prevOptions.filter((user) => !selectedUserIds.includes(user.value)));
            setSelectedUserIds([]);
        }
    };

    return (
        <div className=" w-full flex justify-center space-x-20">
            {!internalLoading ? (
                <>
                    <div className=" w-1/2">
                        <div className=" mb-5">{getSubtitleFn(type, t)}</div>
                        <Radiobutton
                            label={getLabelForRadioButtonFn(type, t)}
                            value="users"
                            checked={inputMode === "users"}
                            onChange={({ value }) => setInputMode(value)}
                            id="modals-assign-emails-users"
                        />
                        <div className=" mb-12 mt-2 pl-8">
                            <Select
                                id="modals-assign-emails-select"
                                disabled={inputMode !== "users"}
                                placeholder={t("select-a-user")}
                                multiple={true}
                                options={userOptions || []}
                                onChange={(values) => {
                                    setSelectedUserIds(values);
                                }}
                            />
                        </div>

                        <Radiobutton
                            label={capitalizeFirst(t("write-emails"))}
                            value="write"
                            checked={inputMode === "write"}
                            onChange={({ value }) => setInputMode(value)}
                            id="modals-assign-emails-write"
                        />
                        <div className=" mt-2 pl-8">
                            <div className={` mb-2 ${inputMode !== "write" ? " text-gray-600" : ""}`}>
                                {t("enter-emails-separated-by-symbol", { symbol: ";" })}
                            </div>
                            <textarea
                                ref={emailInputRef}
                                rows="4"
                                id="modals-assign-emails-text-area"
                                className={`w-full resize-none ${
                                    inputMode !== "write" ? "bg-gray-300" : "bg-gray-200"
                                } rounded pl-3 p-2 `}
                                disabled={inputMode !== "write"}
                                onChange={(e) => {
                                    const validEmails = extractValidEmails(e.target.value);
                                    setEmailList(validEmails);
                                }}
                            ></textarea>
                        </div>
                        <div className=" w-full mt-14">
                            <AddButton
                                onClick={() => {
                                    handleAddButtonClick();
                                }}
                                label={t("Add")}
                            />
                        </div>
                    </div>
                    <div className=" w-1/2">
                        <UsersTable {...{ emailRowEntries, setEmailRowEntries, setUserOptions, type, data }} />
                    </div>
                </>
            ) : (
                <Loading />
            )}
        </div>
    );
};

const UsersTable = ({ emailRowEntries, setEmailRowEntries, setUserOptions, type, data }) => {
    const tableUsersRef = useRef(null);
    const { t } = useTranslation();

    return (
        <div>
            <ZafiroTable
                ref={tableUsersRef}
                id="modals-assign-emails-user-list"
                search={true}
                showCount={true}
                bodyClassName={"border border-gray-200"}
                header={{
                    email: { title: "Email", sortable: true },
                    delete: { title: "", sortable: false },
                }}
                cols={["email", "delete"]}
                headerStyle={{ boxShadow: null }}
                rows={emailRowEntries}
                batchActions={true}
                minHeight="20rem"
                maxHeight="20rem"
                customTexts={{
                    noTableData: t("not-emails-yet"),
                    noSearchResults: t("not-emails-yet"),
                    countResults: (count) => t("x emails", { count }),
                }}
            ></ZafiroTable>
            <Button
                className={`mt-4 text-zafiro-600 font-bold `}
                id="modals-assign-emails-delete-batch-emails"
                onClick={() => {
                    handleRemoveSelectedRowsFn({
                        type,
                        tableUsersRef,
                        emailRowEntries,
                        data,
                        setUserOptions,
                        setEmailRowEntries,
                    });
                }}
            >
                {t("delete-selected-emails")}
            </Button>
        </div>
    );
};

const AddButton = ({ onClick, label }) => (
    <Button onClick={onClick} id="modals-assign-emails-add-button" className="w-full" design="blue">
        {label}
    </Button>
);

const DeleteRow = ({ index, onRemove }) => {
    return (
        <div className="w-full flex justify-end">
            <Button onClick={onRemove} id={`row-email-delete-button-${index}`} className="text-gray-800">
                <Icon type="delete" size={1.4} />
            </Button>
        </div>
    );
};

const useMutateUsersData = ({
    type,
    variables,
    actionPostUpdate = () => {
        console.info("No action post update assigned");
    },
    close,
}) => {
    const { t } = useTranslation();

    const [internalLoading, setInternalLoading] = useState(false);

    const [mutateUser, { loading, error }] = useMutation(MUTATIONS[type], {
        onCompleted: (data) => {
            setInternalLoading(false);
            toast.success(t("operation-successful"));
            actionPostUpdate();
            close();
        },
        onError: (err) => {
            setInternalLoading(false);
            toast.error(err);

            console.log(err);
            actionPostUpdate();
            close();
        },
    });

    const mutateUsersData = () => {
        if (mutateUser) {
            setInternalLoading(true);
            mutateUser({
                variables: { ...variables },
            });
        } else {
            console.error("No mutation found for the provided type.");
        }
    };

    return { mutateUsersData, loading: internalLoading || loading, error };
};

const useUsersData = ({ type, setInternalLoading }) => {
    const [loading, setLoading] = useState(true);

    const tokenShop = Session.getSessionProp("tokenShop");

    const variables = {};

    if (type === TYPES_MODAL_ASSIGN_EMAILS.SHOP_EMAILS) {
        variables["token"] = tokenShop;
    }

    const [fetchUsers, { data, error }] = useLazyQuery(QUERIES[type], {
        fetchPolicy: "network-only",
        onCompleted: () => {
            setLoading(false);
        },
        onError: () => {
            setLoading(false);
        },
        variables,
    });

    useEffect(() => {
        setInternalLoading(loading);
    }, [loading]);

    useEffect(() => {
        fetchUsers();
    }, []);

    return { data, loading, error };
};

const ModalAssingEmails = (props) => {
    const [onCLickSave, setOnClickSave] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(true);
    const { t } = useTranslation();

    return (
        <Modal
            title={props?.title || ""}
            footer={
                !isLoadingData ? (
                    <>
                        <Button
                            design="blue-outline"
                            id="modals-assign-emails-cancel"
                            onClick={props?.close ? props.close : null}
                        >
                            {t("cancel")}
                        </Button>
                        <Button design="blue" id="modals-assign-emails-save" onClick={() => setOnClickSave(true)}>
                            {t("save")}
                        </Button>
                    </>
                ) : null
            }
            minWidth="80vw"
        >
            <ContentModalAssignEmails
                {...{
                    action: props?.action || null,
                    onCLickSave,
                    type: props.type,
                    close: props.close,
                    selectedData: props?.selectedData || { emails: [], users: [] },
                    setIsLoadingData,
                    actionPostUpdate: props?.actionPostUpdate,
                    extraVariables: props?.extraVariables || {},
                }}
            />
        </Modal>
    );
};

const useModalAssignEmails = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const finalProps = {
                ...props,
                close,
            };

            open(<ModalAssingEmails {...finalProps} />);
        },
        close,
    };
};

export default useModalAssignEmails;

const extractVariablesFn = (type, emailRowEntries, extraVariables = {}) => {
    const feedbackEmails =
        type === TYPES_MODAL_ASSIGN_EMAILS.SHOP_EMAILS_WITHOUT_ORDER_MANAGEMENT
            ? emailRowEntries.map((entry) => entry.value)
            : emailRowEntries.filter((entry) => !entry.isFromUsers).map((entry) => entry.value);

    const userIds =
        type === TYPES_MODAL_ASSIGN_EMAILS.SHOP_EMAILS_WITHOUT_ORDER_MANAGEMENT
            ? null
            : emailRowEntries.filter((entry) => entry.isFromUsers).map((entry) => entry.id);

    return {
        feedbackEmails,
        userIds,
        ...extraVariables,
    };
};

const handleAddRowFn = ({ items, isFromUsers, emailRowEntries, createEmailRow, setEmailRowEntries }) => {
    const emailExists = (email) => emailRowEntries.some((entry) => entry.value === email);

    const newEntries = items
        .filter((item) => !emailExists(isFromUsers ? item.label : item))
        .map((item) => {
            const id = isFromUsers ? item.value : uuid();
            const email = isFromUsers ? item.label : item;
            return createEmailRow(id, email, isFromUsers);
        });

    setEmailRowEntries((prevEntries) => [...prevEntries, ...newEntries]);
};

const handleRemoveSelectedRowsFn = ({
    type,
    tableUsersRef,
    emailRowEntries,
    data,
    setUserOptions,
    setEmailRowEntries,
}) => {
    if (type === TYPES_MODAL_ASSIGN_EMAILS.SHOP_EMAILS_WITHOUT_ORDER_MANAGEMENT) {
        const emailsToRemove = tableUsersRef.current?.getSelectedRows()?.map((item) => item.email) || [];

        const emailUsers = getDataUsersResultsWithShopAdministrator(data?.users?.results)?.map((user) => user.label);

        const newEmailRowEntries = emailRowEntries.filter((entry) => {
            const shouldRemove = emailsToRemove.includes(entry.email);

            if (shouldRemove && emailUsers.includes(entry.value)) {
                setUserOptions((prevOptions) => [...prevOptions, { value: entry.id, label: entry.value }]);
            }

            return !shouldRemove;
        });

        setEmailRowEntries(newEmailRowEntries);
    } else {
        const idsToRemove = tableUsersRef.current?.getSelectedRows()?.map((item) => item.id) || [];

        const newEmailRowEntries = emailRowEntries.filter((entry) => {
            const shouldRemove = idsToRemove.includes(entry.id);

            if (shouldRemove && entry.isFromUsers) {
                setUserOptions((prevOptions) => [...prevOptions, { value: entry.id, label: entry.value }]);
            }

            return !shouldRemove;
        });

        setEmailRowEntries(newEmailRowEntries);
    }
};

const getInitialRowEntriesFn = (selectedData, userOptions, createEmailRow) => {
    return [
        ...(selectedData?.emails || []).map((email) => createEmailRow(uuid(), email, false)),
        ...(selectedData?.users || []).reduce((entries, userId) => {
            const user = userOptions.find((u) => u.value === userId);
            if (user) {
                entries.push(createEmailRow(user.value, user.label, true));
            }
            return entries;
        }, []),
    ];
};

const getUserOptionsByTypeFn = (type, data, selectedData) => {
    if (!data || !selectedData) return [];

    switch (type) {
        case TYPES_MODAL_ASSIGN_EMAILS.FEEDBACK_SETTINGS:
            if (Array.isArray(data?.users?.results)) {
                return data.users.results.map((user) => ({
                    value: user.id,
                    label: user.email,
                }));
            }
            break;

        case TYPES_MODAL_ASSIGN_EMAILS.SHOP_EMAILS:
            if (Array.isArray(data?.shops?.results?.[0]?.usersInfo)) {
                return data.shops.results[0].usersInfo.map((user) => ({
                    value: user.id,
                    label: user.accountname,
                }));
            }
            break;

        case TYPES_MODAL_ASSIGN_EMAILS.SHOP_EMAILS_WITHOUT_ORDER_MANAGEMENT:
            if (Array.isArray(data?.users?.results)) {
                return getDataUsersResultsWithShopAdministrator(data.users.results).filter(
                    (item) => !selectedData.emails.includes(item.label)
                );
            }
            break;

        default:
            return [];
    }
    return [];
};

const getDataUsersResultsWithShopAdministrator = (data) => {
    return data
        .filter(
            ({ roles }) =>
                Array.isArray(roles) &&
                roles.some(
                    ({ accesses }) =>
                        Array.isArray(accesses) && accesses.some(({ name }) => name === "shopsAdministrator")
                )
        )
        .map(({ id, email }) => ({
            value: id,
            label: email,
        }));
};

const getSubtitleFn = (type, t) => {
    switch (type) {
        case TYPES_MODAL_ASSIGN_EMAILS.FEEDBACK_SETTINGS:
            return t("an-email-will-be-sent-when-the-guest-has-rated-their-satisfaction-below-the-previously-selected");
        case TYPES_MODAL_ASSIGN_EMAILS.SHOP_EMAILS:
        case TYPES_MODAL_ASSIGN_EMAILS.SHOP_EMAILS_WITHOUT_ORDER_MANAGEMENT:
            return t("select-the-users-who-will-receive-an-email");
        default:
            return null;
    }
};

const getLabelForRadioButtonFn = (type, t) => {
    switch (type) {
        case TYPES_MODAL_ASSIGN_EMAILS.FEEDBACK_SETTINGS:
        case TYPES_MODAL_ASSIGN_EMAILS.SHOP_EMAILS_WITHOUT_ORDER_MANAGEMENT:
            return t("users-with-manager-access");
        case TYPES_MODAL_ASSIGN_EMAILS.SHOP_EMAILS:
            return t("shop-users");
        default:
            return "";
    }
};

const handleRemoveRowFn = ({ id, isFromUsers, email, type, setEmailRowEntries, userOptions, setUserOptions, data }) => {
    setEmailRowEntries((prevEntries) => prevEntries.filter((entry) => entry.id !== id));

    if (isFromUsers && type !== TYPES_MODAL_ASSIGN_EMAILS.SHOP_EMAILS_WITHOUT_ORDER_MANAGEMENT) {
        const removedUser = userOptions.find((user) => user.value === id);
        if (removedUser) {
            setUserOptions((prevOptions) => [...prevOptions, removedUser]);
        }
    }

    if (type === TYPES_MODAL_ASSIGN_EMAILS.SHOP_EMAILS_WITHOUT_ORDER_MANAGEMENT) {
        const removedUser = getDataUsersResultsWithShopAdministrator(data?.users?.results)?.find(
            (user) => email === user.label
        );
        if (removedUser) {
            setUserOptions((prevOptions) => [...prevOptions, removedUser]);
        }
    }
};
