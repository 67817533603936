import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
//API
import { useNavigate as useHistory } from "react-router-dom";

//Components
import UseIcon from "./useIcon";
//Actions
import { openModal, setModalContent, showGlobalLoading } from "../actions/uiActions";
import { changeActionValues, changeGlobalAction } from "../actions/globalActions";
import { setExecuteQuery } from "../actions/sectionActions";

//Utils
import useComponentVisible from "../components/useComponentVisible";
import getActionModalContent from "../hooks/Utils/DataCalc/getActionModalContent";
import ReactTooltip from "components/ReactTooltip";
import { capitalizeFirst, parseBoolean } from "../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";

const UseItemActions = ({
    actions,
    id,
    indexParent,
    containerAdjust,
    dataType,
    executeSectionQuery,
    scrollParentId = null,
    isFolder = false,
    elementReference = null,
    adjustFontSize,
    activeStyle,
    isWelcomeSequence = false,
    onClickActions,
}) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { t } = useTranslation();
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const classIsFolder = !isFolder ? "pt-1" : "";

    //Data
    const icon = {
        name: "row-actions",
    };

    //History
    const history = useHistory();

    //States
    const [active, setActive] = useState("");
    const [posY, setPosY] = useState(0);

    //Actions
    const dispatch = useDispatch();

    const showModal = (e, action) => {
        //Get action modal content...
        const actionInputs = getActionModalContent(
            e.currentTarget.dataset.action_name,
            dataType,
            null,
            actions[e.currentTarget.dataset.action_index],
            langStrings
        );

        const modal = {
            id: id,
            title: e.currentTarget.dataset.modal_title
                ? e.currentTarget.dataset.modal_title
                : e.currentTarget.dataset.action_name,
            value: e.currentTarget.dataset.modal_value,
            inputs: actionInputs,
            bodyAdjust: e.currentTarget.dataset.modal_bodyadjust,
            useGlobalLoading: true,
            executeSectionQuery: executeSectionQuery,
            twoColumns: parseBoolean(e.currentTarget.dataset.modal_twocolumns),
            buttons: parseBoolean(e.currentTarget.dataset.modal_confirm)
                ? []
                : [{ name: "cancel", style: "white", action: "close" }],
        };

        if (parseBoolean(e.currentTarget.dataset.modal_confirm)) {
            modal.buttons.push({
                name: e.currentTarget.dataset?.modal_btn_name ? e.currentTarget.dataset.modal_btn_name : "accept",
                style: "blue",
                action: "close",
            });
        } else if (
            e.currentTarget.dataset.action_name.includes("delete") ||
            e.currentTarget.dataset.action_name.includes("erase")
        ) {
            modal.buttons.push({
                name: "delete",
                style: "red",
                action: "delete",
            });
        } else if (e.currentTarget.dataset.action_name.includes("reset-wifi")) {
            modal.buttons.push({
                name: "log-out",
                style: "red",
                action: "reset",
            });
        } else if (e.currentTarget.dataset.action_name.includes("reset")) {
            modal.buttons.push({
                name: "reset",
                style: "red",
                action: "reset",
            });
        } else if (e.currentTarget.dataset.action_name.includes("remove")) {
            modal.buttons.push({
                name: "remove",
                style: "red",
                action: "remove",
            });
        } else {
            modal.buttons.push({
                name: e.currentTarget.dataset.modal_btn_name
                    ? e.currentTarget.dataset.modal_btn_name
                    : e.currentTarget.dataset.action_name,
                style: "blue",
                action: e.currentTarget.dataset.action_name,
            });
        }

        const initialInputData = () => {
            let selectedValues = {};
            if (modal.inputs) {
                // eslint-disable-next-line
                modal.inputs.forEach((input) => {
                    selectedValues[input.name] = input.selected;
                });
            }
            return selectedValues;
        };

        dispatch(
            changeGlobalAction({
                actionName: e.currentTarget.dataset.action_name,
                itemsAffected: [e.currentTarget.dataset.id],
                values: initialInputData(),
            })
        );

        if (action?.modal_action_values) {
            dispatch(changeActionValues({ ...action.modal_action_values }));
        }

        // dispatch()
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    const directExecute = (e) => {
        if (actions[e.currentTarget.dataset.action_index].hideGlobalLoading) {
            dispatch(showGlobalLoading(false));
        } else {
            dispatch(showGlobalLoading(true));
        }
        dispatch(
            setExecuteQuery({
                action: actions[e.currentTarget.dataset.action_index].mutation,
                params: actions[e.currentTarget.dataset.action_index].mutationParams,
            })
        );
    };

    const directLink = (link, target) => {
        if (target === "_blank") {
            window.open(link, "_blank");
        } else {
            history(link);
        }
    };

    const getReferenceScrollItem = (item) => {
        if (item.classList.contains("scrollReference")) {
            return item;
        } else if (item.parentElement) {
            return getReferenceScrollItem(item.parentElement);
        }
    };

    //Listeners
    useEffect(() => {
        if (isComponentVisible) {
            setActive("t-action-active");

            const scrollRefItem = getReferenceScrollItem(ref.current);

            //Place options in screen
            ref.current.children[1].style.top = `${
                ref.current.parentElement.offsetTop +
                5 -
                (scrollRefItem ? scrollRefItem.scrollTop : 0) -
                (scrollParentId ? document.getElementById(scrollParentId).scrollTop : 0)
            }px`;
            ref.current.children[1].style.left = `${ref.current.parentElement.offsetLeft - 153}px`;
            ref.current.children[1].style.display = "block";

            if (elementReference) {
                const elementXReference = document.getElementById(elementReference);

                if (
                    elementXReference.getBoundingClientRect().x - ref.current.children[1].getBoundingClientRect().x >
                    0
                ) {
                    ref.current.children[1].style.left = `${ref.current.parentElement.offsetLeft}px`;
                }
            }

            const percentagePage = Math.floor((posY / window.innerHeight) * 100);

            if (percentagePage > 77) {
                ref.current.children[1].style.top = `${
                    ref.current.parentElement.offsetTop -
                    ref.current.children[1].clientHeight -
                    (scrollRefItem ? scrollRefItem.scrollTop : 0) -
                    (scrollParentId ? document.getElementById(scrollParentId).scrollTop : 0)
                }px`;
            }
        } else {
            setActive("");
        }
        // eslint-disable-next-line
    }, [isComponentVisible]);

    return (
        <div ref={ref} className="t-actions-icon contents overflow-visible font-normal">
            <div
                id={id ? `actions-button` : null}
                onClick={(e) => {
                    setPosY(e.clientY);
                    setIsComponentVisible(!isComponentVisible ? true : false);
                    e.stopPropagation();
                    if (onClickActions && typeof onClickActions === "function") {
                        onClickActions(e);
                    }
                }}
                onMouseEnter={isWelcomeSequence ? () => setIsComponentVisible(true) : null}
                className="cursor-pointer"
            >
                <UseIcon
                    id={id ? `actions-button-icon` : null}
                    icon={icon}
                    adjust={` ${adjustFontSize ? adjustFontSize : " text-lg"} z-100 px-2 ${classIsFolder} ${
                        active && (activeStyle || active)
                    }`}
                />
            </div>
            {isComponentVisible ? (
                <div
                    id="actions-container"
                    className={`t-actions-container ${containerAdjust} hidden  z-201`}
                    onClick={() => setIsComponentVisible(false)}
                    onMouseLeave={isWelcomeSequence ? () => setIsComponentVisible(false) : null}
                >
                    <div className="t-actions-background">
                        <div id="action">
                            {actions.length > 0 ? (
                                actions.map((action, index) =>
                                    action.name ? (
                                        <Fragment key={index}>
                                            <div
                                                id={
                                                    action.name && indexParent
                                                        ? `action-${action.name}-${indexParent}`
                                                        : action.id
                                                }
                                                data-tip
                                                data-for={`action_${index}`}
                                                className={`t-action-container first-capital ${
                                                    action.disabled ? "disabled cursor-default" : ""
                                                } ${action.adjust}`}
                                                data-action_name={action.action ? action.action : action.name}
                                                data-action_buttons={action.buttons}
                                                data-action_items={action.buttons}
                                                data-action_index={index}
                                                data-modal_title={action.modal_title}
                                                data-modal_confirm={action.modal_confirm}
                                                data-modal_btn_name={action.modal_btn_name}
                                                data-modal_value={action.modal_value}
                                                data-modal_bodyadjust={action.modal_bodyAdjust}
                                                data-modal_twocolumns={action.modal_twoColumns}
                                                data-id={id}
                                                onClick={(e) =>
                                                    action.disabled
                                                        ? null
                                                        : action.link
                                                        ? directLink(action.link, action.target ? action.target : null)
                                                        : action.mutation
                                                        ? directExecute(e)
                                                        : !action.noModal
                                                        ? showModal(e, action)
                                                        : action.actionCallback
                                                        ? action.actionCallback()
                                                        : null
                                                }
                                            >
                                                {action.icon ? (
                                                    <span
                                                        className={`icon icon-${action.icon} ${action.iconAdjust}`}
                                                    ></span>
                                                ) : null}
                                                {capitalizeFirst(t(action.name))}
                                            </div>
                                            {action.tooltip || (action.disabled && action.disabledTooltip) ? (
                                                <ReactTooltip
                                                    id={`action_${index}`}
                                                    delayShow={1000}
                                                    type={"light"}
                                                    borderColor={"#D3DAE1"}
                                                    border={true}
                                                    multiline={true}
                                                >
                                                    {action.disabled && action.disabledTooltip
                                                        ? action.disabledTooltip
                                                        : action.tooltip}
                                                </ReactTooltip>
                                            ) : null}
                                        </Fragment>
                                    ) : null
                                )
                            ) : (
                                <span className="t-noaction-container">{t("no-actions")}</span>
                            )}
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default UseItemActions;
