import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";

//Actions
import { setLoadingModalContent } from "../../actions/uiActions";
import { changeActionValues, cleanActionValues } from "../../actions/globalActions";

//Components
import UseSelectWithSearch from "../../components/useSelectWithSearch";
//Utils
import {
    capitalizeFirst,
    cloneObject,
    DISABLED_BG_LABEL,
    GUEST_BG_LABEL,
    ROOM_BG_LABEL,
    COMMONZONE_BG_LABEL,
    uncapitalizeFirst,
} from "../../hooks/Utils/Utils";
import UseModalResultsDestinations from "./UseModalResultsDestinations";
import { SCREEN } from "constants/editor";

const UseModalDestinations = ({ occupiedRooms = false, isFilterDestination = false, index }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //Constantes
    const INITIAL_FILTER_LIST = [{ type: null }];
    const { cScreenData } = useSelector((state) => state.design);
    const actionData = useSelector((state) => state.action.values);
    const sectionContentData = useSelector((state) => state.sectionContent.data);
    const { destination: destinationGridsChannel, typeDestination } = useSelector(
        (state) => state.ui.modalContent.inputs[index]
    );
    const isPublish = cScreenData?.landing?.isEnabled;
    const ptarget = cScreenData ? cScreenData.ptarget : null;
    const destination = getDestionation({ ptarget, sectionContentData, destinationGridsChannel });

    const roomCat = capitalizeFirst(t("by room"));
    const guestCat = capitalizeFirst(t("by guest"));
    const commonZoneCat = capitalizeFirst(t("by common zone"));
    const sectionName = `editor-modal-destinations`;

    let completefilterTypes = [
        { id: "all", name: capitalizeFirst(t("all property")) },
        { id: "tvLocation", name: capitalizeFirst(t("tv-location")), category: roomCat },
        { id: "room", name: capitalizeFirst(t("room")), category: roomCat },
        { id: "roomGroup", name: capitalizeFirst(t("room-group")), category: roomCat },
        { id: "floor", name: capitalizeFirst(t("floor")), category: roomCat },
        { id: "wing", name: capitalizeFirst(t("wing")), category: roomCat },
        { id: "guest", name: capitalizeFirst(t("guest")), category: guestCat },
        { id: "guestGroup", name: capitalizeFirst(t("guest-group")), category: guestCat },
        { id: "language", name: capitalizeFirst(t("language")), category: guestCat },
        { id: "country", name: capitalizeFirst(t("country")), category: guestCat },
        { id: "tvLocationZone", name: capitalizeFirst(t("tv-location")), category: commonZoneCat },
        { id: "commonZone", name: capitalizeFirst(t("common-zones")), category: commonZoneCat },
        { id: "commonZoneGroup", name: capitalizeFirst(t("commonZoneGroup")), category: commonZoneCat },
    ];

    if (actionData && actionData.screentype === "MOBILE") {
        completefilterTypes.push({ id: "without_room", name: capitalizeFirst(t("user_without_room")) });
    }
    const screenType = cScreenData?.screenType;
    const isChromecastScreen = screenType === SCREEN.TYPE.CCINSTRUCTION;
    const sectionsSpecificFilters = [
        "gridChannelsDestinations",
        "signageDestinations",
        "tickerTapesDestinations",
        isChromecastScreen,
    ];

    if (sectionsSpecificFilters.includes(typeDestination)) {
        completefilterTypes = completefilterTypes.filter((item) => item.id !== "all");
    } else {
        completefilterTypes = completefilterTypes.filter(
            (item) => !["tvLocationZone", "commonZone", "commonZoneGroup"].includes(item.id)
        );
    }

    //States
    const [filters, setFilters] = useState([]);
    const [tvLocation, settvLocation] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [roomGroups, setRoomGroups] = useState([]);
    const [guests, setGuests] = useState([]);
    const [guestGroups, setGuestGroups] = useState([]);
    const [floors, setFloors] = useState([]);
    const [wings, setWings] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [countries, setCountries] = useState([]);
    const [tvLocationZone, settvLocationZone] = useState([]);
    const [commonZone, setCommonZone] = useState([]);
    const [commonZoneGroup, setCommonZoneGroup] = useState([]);
    const [availableFilters, setAvailableFilters] = useState(completefilterTypes);

    const [addFilterEnabled, setAddFilterEnabled] = useState(false);

    const GET_OPTIONS = gql`
        {
            tvLocation: tvLocations(filter: { roomType: GUESTROOM }) {
                results
            }
            rooms(orderBy: { field: "name", criteria: "ASC" }, filter: { type: GUESTROOM }) {
                results {
                    id
                    name
                    number
                    roomTVs {
                        name
                    }
                }
            }
            occupiedRoomsInfo {
                name
                id
            }
            roomGroups(orderBy: { field: "name", criteria: "ASC" }, filter: { type: GUESTROOM }) {
                results {
                    id
                    name
                }
            }
            wings: installationMap {
                results {
                    id
                    name
                    floors {
                        id
                        name
                    }
                }
            }
            guests(mainGuest: true, filter: { checkedOut: false }, orderBy: { field: "name", criteria: "ASC" }) {
                results {
                    id
                    name
                    surname
                }
            }
            guestGroups(orderBy: { field: "name", criteria: "ASC" }) {
                results {
                    id
                    name
                }
            }
            languages {
                results {
                    ref
                }
            }
            countries {
                results {
                    information {
                        name
                    }
                    ref
                }
            }
            tvLocationCommonZone: tvLocations(filter: { roomType: COMMONZONE }) {
                results
            }
            commonZone: rooms(orderBy: { field: "name", criteria: "ASC" }, filter: { type: COMMONZONE }) {
                results {
                    id
                    name
                    number
                    roomTVs {
                        name
                    }
                }
            }
            commonZoneGroup: roomGroups(orderBy: { field: "name", criteria: "ASC" }, filter: { type: COMMONZONE }) {
                results {
                    id
                    name
                }
            }
        }
    `;

    const [getOptionsQuery, { data }] = useLazyQuery(GET_OPTIONS);

    //Listeners
    useEffect(() => {
        getOptionsQuery();
        return () => {
            const actionDataKey = Object.keys(actionData);
            let newActionData = {};
            actionDataKey.forEach((key) => {
                if (!key.includes(`filter_`)) {
                    newActionData[key] = actionData[key];
                }
            });
            dispatch(cleanActionValues());
            dispatch(changeActionValues(newActionData));
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setAddFilterEnabled(filters && filters.filter((filter) => !filter.type).length === 0 ? true : false);
    }, [filters]);

    useEffect(() => {
        if (data?.rooms?.results && data?.commonZone?.results) {
            arrangeData({
                occupiedRooms,
                data,
                settvLocation,
                setRooms,
                setRoomGroups,
                setGuests,
                setGuestGroups,
                setWings,
                setLanguages,
                setCountries,
                setFloors,
                settvLocationZone,
                setCommonZone,
                setCommonZoneGroup,
                destination,
                setFilters,
                INITIAL_FILTER_LIST,
                t,
            });
            dispatch(setLoadingModalContent(false));
        }
        // eslint-disable-next-line
    }, [data, destinationGridsChannel]);

    useEffect(() => {
        if (actionData) {
            let filterTypesCopy = cloneObject(completefilterTypes);
            let filtersCopy = cloneObject(filters);
            // eslint-disable-next-line
            filtersCopy.map((filter, idx) => {
                if (actionData[`filter_${idx}`]) {
                    filter.type = actionData[`filter_${idx}`][0] || null;
                    filterTypesCopy = filterTypesCopy.filter((item) => item.id !== filter.type);
                }
            });
            setFilters(filtersCopy);
            setAvailableFilters(filterTypesCopy);
        }
        // eslint-disable-next-line
    }, [actionData]);

    //Actions
    const removeFilter = (idx) => {
        let filtersCopy = cloneObject(filters);
        const actionDataKey = Object.keys(actionData);
        let newActionData = {};
        actionDataKey.forEach((key) => {
            if (!key.includes(`filter_${idx}`)) {
                newActionData[key] = actionData[key];
            }
        });
        dispatch(cleanActionValues());
        dispatch(changeActionValues(newActionData));
        filtersCopy.map((item, itemIdx) => {
            if (itemIdx === idx) {
                item.hidden = true;
            }
        });

        setFilters(filtersCopy.length > 0 ? filtersCopy.filter((item) => item.type !== null) : INITIAL_FILTER_LIST);
    };

    let visibleFilterIndex = 0;

    const getPlaceholderSelect = (filterType) => {
        const filterMappings = {
            tvLocation: "tv location",
            room: "room",
            wing: "wing",
            roomGroup: "room-group",
            guest: "guest",
            guestGroup: "guest-group",
            language: "language",
            country: "country",
            floor: "floor",
            tvLocationZone: "tv location",
            commonZone: "commonZone",
            commonZoneGroup: "commonZoneGroup",
        };

        const filterValue = filterMappings[filterType];
        return filterValue ? `${t("select")} ${uncapitalizeFirst(t(filterValue))}` : "";
    };
    return (
        <div className="grid gap-4 grid-cols-2 grid-flow-col">
            <div className="">
                {isFilterDestination ? null : (
                    <div className="mb-4">
                        {capitalizeFirst(
                            t(
                                typeDestination === "gridChannelsDestinations"
                                    ? "define-where-you-want-to-apply-the-grid"
                                    : "select-recipients-message"
                            )
                        )}
                        :
                    </div>
                )}
                {filters.map((filter, idx) => {
                    if (filter.hidden) return null;

                    const currentVisibleFilterIndex = visibleFilterIndex;

                    visibleFilterIndex += 1;
                    return (
                        <Fragment key={`${sectionName}-filter-${filter.type}`}>
                            <div className="flex mb-4">
                                <div style={{ width: "40%" }}>
                                    <UseSelectWithSearch
                                        data={{
                                            selectPlaceHolder: capitalizeFirst(t("select filter type")),
                                            optionsAdjust: "mt-11",
                                            oneSelected: true,
                                            noSelect: true,
                                            hideSearch: true,
                                            name: `filter_${idx}`,
                                            optionData: filter.type ? completefilterTypes : availableFilters,
                                            block: filter.type,
                                            selectedIds: [filter.type],
                                        }}
                                    />
                                </div>
                                {filter.type ? (
                                    <>
                                        {filter.type !== "all" ? (
                                            <div className="ml-4" style={{ width: "50%" }}>
                                                <UseSelectWithSearch
                                                    data={{
                                                        selectPlaceHolder: getPlaceholderSelect(filter.type),
                                                        optionsAdjust: "mt-11",
                                                        name: `filter_${idx}_ids`,
                                                        noSelect: true,
                                                        oneSelected: false,
                                                        optionData: getTypeOptions({
                                                            type: filter.type,
                                                            tvLocation,
                                                            rooms,
                                                            roomGroups,
                                                            floors,
                                                            wings,
                                                            guests,
                                                            guestGroups,
                                                            languages,
                                                            countries,
                                                            tvLocationZone,
                                                            commonZone,
                                                            commonZoneGroup,
                                                        }),
                                                        selectedIds: filter.ids,
                                                        categoryCheck:
                                                            filter.type === "room" || "tvLocation" || "tvLocationZone"
                                                                ? true
                                                                : false,
                                                        selectedColor:
                                                            filter.type != "room" || "tvLocation" || "tvLocationZone"
                                                                ? getSelectedColor(filter.type)
                                                                : null,
                                                        useOptionID: false,
                                                    }}
                                                />
                                            </div>
                                        ) : null}
                                        <div
                                            className="icon icon-delete sidebar-menu-link text-2xl -mt-2 cursor-pointer"
                                            onClick={(e) => removeFilter(idx)}
                                            id={`${sectionName}-remove-filters-${currentVisibleFilterIndex}`}
                                        ></div>
                                    </>
                                ) : null}
                            </div>
                        </Fragment>
                    );
                })}

                {addFilterEnabled && availableFilters && availableFilters.length > 0 ? (
                    <div
                        className={`flex mt-2 font-medium items-center 
                   text-zafiro-200 sidebar-submenu-link cursor-pointer
                `}
                        id="add-filter-button"
                        onClick={(e) => setFilters([...filters, { type: null }])}
                    >
                        <div className="icon icon-add mr-2"></div>
                        <div className="first-capital mt-1"> {t("add-filter")}</div>
                    </div>
                ) : null}
                {isPublish && (filters?.length === 0 || filters?.filter((item) => !item.hidden).length === 0) ? (
                    <div className="absolute mt-6">
                        <span className="icon icon-warning  mr-3 text-lg text-orange-100"></span>
                        <span>{capitalizeFirst(t("delete-filters-confirm"))}</span>
                    </div>
                ) : null}
            </div>
            <div className="">
                <UseModalResultsDestinations
                    allfilters={{
                        tvLocation,
                        rooms,
                        floors,
                        wings,
                        roomGroups,
                        stayGuests: guests,
                        stayGroups: guestGroups,
                        languages,
                        countries,
                        tvLocationZone,
                        commonZone,
                        commonZoneGroup,
                    }}
                    typeDestination={typeDestination}
                />
            </div>
        </div>
    );
};

export default UseModalDestinations;

const arrangeData = (props) => {
    const {
        occupiedRooms,
        data,
        setRooms,
        settvLocation,
        setRoomGroups,
        setGuests,
        setGuestGroups,
        setWings,
        setLanguages,
        setCountries,
        setFloors,
        settvLocationZone,
        setCommonZone,
        setCommonZoneGroup,
        destination,
        setFilters,
        INITIAL_FILTER_LIST,
        t,
    } = props;

    const tvLocationRooms = data.rooms.results.flatMap((room) =>
        room.roomTVs.map((tv) => ({ roomName: room.name, tvName: tv.name }))
    );

    const filteredTvLocations = data.tvLocation.results.filter((tvLocationName) =>
        tvLocationRooms.some((roomTv) => roomTv.tvName === tvLocationName)
    );

    settvLocation(
        filteredTvLocations.map((item) => {
            const roomTv = tvLocationRooms.find((roomTv) => roomTv.tvName === item);
            return {
                id: item,
                name: `${roomTv.roomName} ${roomTv.tvName}`,
                category: t("select-all"),
            };
        })
    );

    const roomsIdOccupied = data.occupiedRoomsInfo.map((roomOccupied) => roomOccupied.id);
    let disabledFreeRooms = data.rooms.results.map((room) => {
        let occupied = roomsIdOccupied.includes(room.id);
        return {
            ...room,
            category: t("select-all"),
            color: !occupied ? DISABLED_BG_LABEL : getSelectedColor("room"),
            tooltip: !occupied ? t("room-without-guest") : null,
        };
    });
    setRooms(disabledFreeRooms);

    setRoomGroups(data.roomGroups.results);
    setGuests(
        data.guests.results.map((item) => ({
            id: item.id,
            name: `${item.name} ${item.surname}`,
        }))
    );
    setGuestGroups(data.guestGroups.results);
    setWings(data.wings.results);
    setLanguages(getLanguagesList(data.languages.results, t));
    setCountries(getCountriesList(data.countries.results, t));

    let floors = [];
    data.wings.results.map((wing) =>
        wing.floors.map((floor) => floors.push({ id: floor.id, name: floor.name, category: wing.name }))
    );
    setFloors(floors);

    const tvLocationCommonZone = data.commonZone.results.flatMap((commonZone) =>
        commonZone.roomTVs.map((tv) => ({ commonZoneName: commonZone.name, tvName: tv.name }))
    );

    const filteredTvLocationsCommonZone = data.tvLocation.results.filter((tvLocationName) =>
        tvLocationCommonZone.some((roomTv) => roomTv.tvName === tvLocationName)
    );

    settvLocationZone(
        filteredTvLocationsCommonZone.map((item) => {
            const commonZoneTv = tvLocationCommonZone.find((commonZoneTv) => commonZoneTv.tvName === item);
            return {
                id: item,
                name: commonZoneTv ? `${commonZoneTv.roomName} ${commonZoneTv.tvName}` : item,
                category: t("select-all"),
            };
        })
    );

    let commonZone = [];
    data.commonZone.results.map((zone) =>
        commonZone.push({
            id: zone.id,
            name: zone.name,
        })
    );
    setCommonZone(commonZone);
    setCommonZoneGroup(data.commonZoneGroup.results);

    let cFilters = [];
    if (destination) {
        if (destination.tvLocation && destination.tvLocation.length > 0) {
            let cTvlocation = [];
            destination.tvLocation.forEach((dTvlocation) => {
                const matchingRoomTv = tvLocationRooms.find((roomTv) => roomTv.tvName === dTvlocation);
                if (matchingRoomTv) {
                    cTvlocation.push(dTvlocation);
                }
            });
            if (cTvlocation.length > 0) {
                cFilters.push({ type: "tvLocation", ids: cTvlocation });
            }
        }
        if (destination.rooms.length > 0) {
            let cRooms = [];
            destination.rooms.map((dRoom) => cRooms.push(dRoom.id));
            cFilters.push({ type: "room", ids: cRooms });
        }
        if (destination.wings.length > 0) {
            let cWings = [];
            destination.wings.map((dWing) => cWings.push(dWing.id));
            cFilters.push({ type: "wing", ids: cWings });
        }
        if (destination.floors.length > 0) {
            let cFloors = [];
            destination.floors.map((dFloor) => cFloors.push(dFloor.id));
            cFilters.push({ type: "floor", ids: cFloors });
        }
        if (destination.stayGuests.length > 0) {
            let cStayGuests = [];
            destination.stayGuests.map((stayGuest) => cStayGuests.push(stayGuest.id));
            cFilters.push({ type: "guest", ids: cStayGuests });
        }
        if (destination.stayGroups.length > 0) {
            let cStayGroups = [];
            destination.stayGroups.map((stayGroup) => cStayGroups.push(stayGroup.id));
            cFilters.push({ type: "guestGroup", ids: cStayGroups });
        }
        if (destination.roomGroups.length > 0) {
            let cRoomGroups = [];
            destination.roomGroups.map((roomGroup) => cRoomGroups.push(roomGroup.id));
            cFilters.push({ type: "roomGroup", ids: cRoomGroups });
        }
        if (destination.languages.length > 0) {
            let cLanguages = [];
            destination.languages.map((language) => cLanguages.push(language.ref));
            cFilters.push({ type: "language", ids: cLanguages });
        }
        if (destination.countries && destination.countries.length > 0) {
            let cCountries = [];
            destination.countries.map((country) => cCountries.push(country.ref));
            cFilters.push({ type: "country", ids: cCountries });
        }
        if (destination.tvLocationCommonZone && destination.tvLocationCommonZone.length > 0) {
            let cTvlocationcommonZone = [];
            destination.tvLocationCommonZone.forEach((dTvlocationCommonZone) => {
                const matchingRoomTv = tvLocationRooms.find((roomTv) => roomTv.tvName === dTvlocationCommonZone);
                if (matchingRoomTv) {
                    cTvlocationcommonZone.push(dTvlocationCommonZone);
                }
            });
            if (cTvlocationcommonZone.length > 0) {
                cFilters.push({ type: "tvLocationCommonZone", ids: cTvlocationcommonZone });
            }
        }
        if (destination.commonZone && destination.commonZone.length > 0) {
            let cCommonZone = [];
            destination.commonZone.map((commonZone) => cCommonZone.push(commonZone.id));
            cFilters.push({ type: "commonZone", ids: cCommonZone });
        }
        if (destination.commonZoneGroup && destination.commonZoneGroup.length > 0) {
            let cCommonZoneGroup = [];
            destination.commonZoneGroup.map((commonZoneGroup) => cCommonZoneGroup.push(commonZoneGroup.id));
            cFilters.push({ type: "commonZoneGroup", ids: cCommonZoneGroup });
        }
        if (destination.all) {
            cFilters.push({ type: "all" });
        }
    }
    setFilters(cFilters.length === 0 ? INITIAL_FILTER_LIST : cFilters);
};

const getLanguagesList = (list, t) => {
    let langs = [];
    list.map((lang) => {
        langs.push({
            id: lang.ref,
            name: t("language:" + lang.ref),
            value: lang.ref,
            visible: true,
        });
        return lang;
    });
    langs.sort(compare);
    return langs;
};

const getCountriesList = (list, t) => {
    let countries = [];
    list.map((country) => {
        countries.push({
            id: country.ref,
            name: t("country:" + country.ref.toLowerCase()),
            value: country.ref,
            visible: true,
        });
        return country;
    });
    countries.sort(compare);
    return countries;
};

function compare(a, b) {
    if (a.name > b.name) {
        return 1;
    }
    if (a.name < b.name) {
        return -1;
    }
    // a must be equal to b
    return 0;
}

const getDestionation = (props) => {
    const { ptarget, sectionContentData, destinationGridsChannel } = props;
    let response = null;
    if (ptarget?.direct?.destination) {
        response = ptarget.direct.destination;
    } else if (sectionContentData?.destination) {
        response = sectionContentData.destination;
    } else if (destinationGridsChannel) {
        response = destinationGridsChannel;
    }
    return response;
};

const getTypeOptions = (props) => {
    const {
        type,
        tvLocation,
        rooms,
        roomGroups,
        floors,
        wings,
        guests,
        guestGroups,
        languages,
        countries,
        tvLocationZone,
        commonZone,
        commonZoneGroup,
    } = props;
    let options = [];
    switch (type) {
        case "tvLocation":
            options = tvLocation;
            break;
        case "room":
            options = rooms;
            break;
        case "roomGroup":
            options = roomGroups;
            break;
        case "floor":
            options = floors;
            break;
        case "wing":
            options = wings;
            break;
        case "guest":
            options = guests;
            break;
        case "guestGroup":
            options = guestGroups;
            break;
        case "language":
            options = languages;
            break;
        case "country":
            options = countries;
            break;
        case "tvLocationZone":
            options = tvLocationZone;
            break;
        case "commonZone":
            options = commonZone;
            break;
        case "commonZoneGroup":
            options = commonZoneGroup;
            break;
        default:
            break;
    }
    return options;
};

const getSelectedColor = (type) => {
    switch (type) {
        case "tvLocation":
        case "room":
        case "roomGroup":
        case "floor":
        case "wing":
            return ROOM_BG_LABEL;
        case "guest":
        case "guestGroup":
        case "language":
        case "country":
            return GUEST_BG_LABEL;
        case "tvLocationZone":
        case "commonZone":
        case "commonZoneGroup":
            return COMMONZONE_BG_LABEL;
        default:
            break;
    }
    return null;
};
