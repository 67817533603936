import {
    REMOVE_GRID_ITEM,
    REMOVE_MENU_IN_CUSTOM_SCREENS,
    SET_ITEM_SELECTED,
    SET_GRID_LAYOUT,
    ADD_GRID_ITEM,
    CLONING_GRID_ITEM,
    DUPLICATE_GRID_ITEM,
    SET_DESIGN_TOOLBAR_VISIBLE,
    SET_WIDGET_TOOLBAR_VISIBLE,
    UPDATE_GRID_ITEM_PROPERTY,
    UPDATE_GRID_ITEM_STYLE,
    UPDATE_GRID_ITEM_ACTIONS,
    UPDATE_GRID_ITEM_POS,
    UPDATE_SCREEN_PROPERTY,
    SET_SCREEN_CONTENTSTYLE,
    SET_CURRENT_SCREEN_DATA,
    SET_CURRENT_LANG,
    SET_CURRENT_DEVICE,
    CLEAN_LAYOUT,
    SET_DROPPING_ITEM,
    SET_EDITOR_MODE,
    UPDATE_CONTAINER_ITEM_POS,
    SET_CONTAINER_IN_EDITION,
    SET_COLOR_PICKER_REFERENCE,
    SET_TEXT_EDITOR_SELECTION_STYLES,
    SET_COLOR_TO_APPLY_IN_TEXT_EDITOR,
    SET_WIDGET_TOOLBAR_ENABLED,
    SET_TOAST_DATA,
    EDITING_MENU,
    RESIZE_WINDOW_MENU,
    TYPE_MENU,
    HIDDEN_MENU_VISIBLE,
    SET_WIDGET_MENU_COLORS_AND_ANIMATIONS,
    SET_TV_SCREENS_DATA,
    SET_PREVIEW_MENU_ID_SELECTED,
    KEY_EVENT,
} from "../actions/designActions";

import { WidgetAvailability, WIDGETS } from "../hooks/Utils/Widgets";
import { safeJsonParse, safeJsonStringify } from "hooks/Utils/Utils";

const initialState = {
    widgets: [],
    itemSelected: null,
    editorMode: "EDITION",
    designToolbarVisible: null,
    widgetToolbarVisible: null,
    widgetToolbarVisibleTab: null,
    showToolbarOptions: null,
    gridItems: [],
    cloningGridItemId: false,
    gridLayout: [],
    containerInEdition: null,
    containersLayouts: [],
    colorPickerReference: null,
    widgetToolbarEnabled: true,
    toastData: null,
    editingMenu: false,
    typeMenu: null,
    hiddenMenuVisible: false,
    widgetMenuColorsAndAnimations: null,
    tvScreensData: null,
    previewMenuIdSelected: null,
    resizeWindowMenu: false,
};

export default function (state = initialState, action) {
    if (!state.widgets.length) {
        state.widgets = Object.keys(WIDGETS).map((type) => {
            const widget = WIDGETS[type];
            return {
                type,
                name: widget.name,
                icon: widget.icon,
                hidden: widget.hidden,
                isResizable: widget.isResizable,
                isDraggable: widget.isDraggable,
                usingDriveRender: widget.usingDriveRender,
            };
        });
    }

    const cloneElement = (element) => {
        return safeJsonParse(safeJsonStringify(element));
    };

    switch (action.type) {
        case REMOVE_GRID_ITEM:
            const itemId = action.payload;
            let idxToDelete = -1;
            let container = null;

            if (state.containerInEdition) {
                //remove item from a container
                container = state.containersLayouts.filter(
                    (container) => container.i === parseInt(state.containerInEdition)
                )[0];
                container.layout.map((item, index) => (item.i === itemId ? (idxToDelete = index) : null));
                if (idxToDelete > -1) {
                    container.layout.splice(idxToDelete, 1);
                }
                state.gridItems.map((item, index) => (item.i === itemId ? (idxToDelete = index) : null));

                if (idxToDelete > -1) {
                    state.gridItems.splice(idxToDelete, 1);
                }

                state.itemSelected = null;

                //Actulizamos las pos del elemento cScreenData
                const containerPosis = JSON.parse(
                    state.cScreenData.data.contentWidgets.filter(
                        (contentWidget) => contentWidget.ID === parseInt(state.containerInEdition)
                    )[0].pos
                );

                let posToDelete = null;

                containerPosis.map((containerItem, idx) =>
                    containerItem.ID === parseInt(itemId) ? (posToDelete = idx) : null
                );

                containerPosis.splice(posToDelete, 1);

                state.cScreenData.data.contentWidgets.map((contentWidget) =>
                    contentWidget.ID === parseInt(state.containerInEdition)
                        ? (contentWidget.pos = JSON.stringify(containerPosis))
                        : null
                );

                return {
                    ...state,
                    containersLayouts: [...state.containersLayouts],
                    cScreenData: { ...state.cScreenData },
                };
            }

            state.gridLayout.map((item, index) => (item.i === itemId ? (idxToDelete = index) : null));

            if (idxToDelete > -1) {
                state.gridLayout.splice(idxToDelete, 1);
            }
            let containerToDelete = -1;
            state.containersLayouts.map((item, index) =>
                String(item.i) === String(itemId) ? (containerToDelete = index) : null
            );

            if (containerToDelete > -1) {
                state.containersLayouts.splice(containerToDelete, 1);
            }

            state.gridItems.map((item, index) => (item.i === itemId ? (idxToDelete = index) : null));

            if (idxToDelete > -1) {
                state.gridItems.splice(idxToDelete, 1);
            }

            state.itemSelected = null;

            //actualizar cScreenData.pos para quitar el item en todos los layouts
            const contenPositions = JSON.parse(state.cScreenData.data.pos);

            const devicesList = Object.keys(contenPositions);

            //Borro el widget en todos los layouts
            let posToDelete = null;
            // eslint-disable-next-line
            devicesList.map((device) => {
                posToDelete = null;
                // eslint-disable-next-line
                contenPositions[device].map((item, idx) =>
                    parseInt(item.ID) === parseInt(itemId) ? (posToDelete = idx) : null
                );
                contenPositions[device].splice(posToDelete, 1);
                return device;
            });

            state.cScreenData.data.pos = JSON.stringify(contenPositions);

            return {
                ...state,
                gridLayout: [...state.gridLayout],
                containersLayouts: [...state.containersLayouts],
                cScreenData: { ...state.cScreenData },
            };

        case ADD_GRID_ITEM:
            const newWidget = action.payload.itemData;
            const containerID = action.payload.containerID;
            state.gridItems.push({ i: newWidget.i, data: newWidget.data, widgetStyle: newWidget.style });

            if (containerID) {
                newWidget.w = newWidget.pos ? newWidget.pos.w : 1;
                newWidget.h = newWidget.pos ? newWidget.pos.h : 1;
                newWidget.x = newWidget.pos ? newWidget.pos.x : 0;
                newWidget.y = newWidget.pos ? newWidget.pos.y : 0;

                const containerWidget = state.cScreenData.data.contentWidgets.filter(
                    (contentWidget) => contentWidget.ID === parseInt(containerID)
                )[0];
                let containerPosis = containerWidget.pos ? JSON.parse(containerWidget.pos) : [];

                if (!containerPosis) {
                    containerPosis = [];
                }

                containerPosis.push({
                    ID: parseInt(newWidget.i),
                    H: newWidget.h,
                    W: newWidget.w,
                    X: newWidget.x,
                    Y: newWidget.y,
                });

                state.cScreenData.data.contentWidgets.map((contentWidget) =>
                    contentWidget.ID === parseInt(containerID)
                        ? (contentWidget.pos = JSON.stringify(containerPosis))
                        : null
                );

                if (!containerWidget.contentWidgets) {
                    state.cScreenData.data.contentWidgets.filter(
                        (contentWidget) => contentWidget.ID === parseInt(containerID)
                    )[0].contentWidgets = [];
                }
                state.cScreenData.data.contentWidgets
                    .filter((contentWidget) => contentWidget.ID === parseInt(containerID))[0]
                    .contentWidgets.push({
                        ID: parseInt(newWidget.i),
                        type: newWidget ? newWidget.type : null,
                        data: {},
                    });

                if (state.containersLayouts.filter((cLayout) => cLayout.i === parseInt(containerID))[0]) {
                    state.containersLayouts.map((container) =>
                        container.i === parseInt(containerID) ? container.layout.push(newWidget) : null
                    );
                } else {
                    state.containersLayouts.push({
                        i: parseInt(containerID),
                        layout: [newWidget],
                    });
                }
            } else {
                const pos =
                    newWidget && newWidget.pos ? newWidget.pos[newWidget.deviceType] : { w: 1, h: 1, x: 0, y: 0 };
                newWidget.w = pos ? pos.w : 1;
                newWidget.h = pos ? pos.h : 1;
                newWidget.x = pos ? pos.x : 0;
                newWidget.y = pos ? pos.y : 0;
                const { disabled } = WidgetAvailability({
                    widgetType: newWidget ? newWidget.type : null,
                    deviceType: newWidget ? newWidget.deviceType : null,
                    screenType: newWidget ? newWidget.screenType : null,
                });
                if (!disabled) {
                    state.gridLayout.push(newWidget);
                }

                //actualizar cScreenData.pos para añadir el item en todos los layouts
                const contenPositionsAdd =
                    state.cScreenData && state.cScreenData.data ? JSON.parse(state.cScreenData.data.pos) : null || {};

                const devicesListAdd = Object.keys(contenPositionsAdd);

                //Añado el widget en todos los layouts
                devicesListAdd.map((device) => {
                    const { disabled } = WidgetAvailability({
                        widgetType: newWidget ? newWidget.type : null,
                        deviceType: device,
                        screenType: newWidget ? newWidget.screenType : null,
                    });
                    if (!disabled) {
                        if (!contenPositionsAdd[device]) {
                            contenPositionsAdd[device] = [];
                        }
                        const p = newWidget.pos ? newWidget.pos[device] : { w: 1, h: 1, x: 0, y: 0 };
                        if (p) {
                            contenPositionsAdd[device].push({
                                ID: parseInt(newWidget.i),
                                h: p.h,
                                w: p.w,
                                x: p.x,
                                y: p.y,
                            });
                        }
                    }
                    return device;
                });

                if (newWidget.type === "CONTAINER" || newWidget.type === "TVMENU") {
                    state.containersLayouts.push({ i: parseInt(newWidget.i), layout: [] });
                }

                if (!state.cScreenData) state.cScreenData = { data: {} };
                if (!state.cScreenData.data) state.cScreenData.data = {};
                state.cScreenData.data.pos = JSON.stringify(contenPositionsAdd);

                if (!state.cScreenData.data.contentWidgets) {
                    state.cScreenData.data.contentWidgets = [];
                }
                state.cScreenData.data.contentWidgets.push({
                    ID: parseInt(newWidget.i),
                    type: newWidget ? newWidget.type : null,
                    data: {},
                });
            }

            //Add item en cScreendata.contentWidgets

            return {
                ...state,
                gridLayout: [...state.gridLayout],
                containersLayouts: [...state.containersLayouts],
                gridItems: [...state.gridItems],
                cScreenData: { ...state.cScreenData },
            };

        case REMOVE_MENU_IN_CUSTOM_SCREENS:
            const newContainersLayout = state.containersLayouts.filter((container) => {
                return Number(container.i) !== Number(action.payload);
            });

            const newGridLayout = state.gridLayout.filter((item) => {
                return Number(item.i) !== Number(action.payload);
            });

            state.cScreenData.data.contentWidgets = state.cScreenData.data.contentWidgets.filter((item) => {
                return Number(item.ID) !== Number(action.payload);
            });

            const contentPositions =
                state.cScreenData && state.cScreenData.data ? JSON.parse(state.cScreenData.data.pos) : null || {};

            const devicesListAdd = Object.keys(contentPositions);

            // eslint-disable-next-line
            devicesListAdd.forEach((device) => {
                if (device === "TV" && contentPositions["TV"]) {
                    const idxMenu = contentPositions["TV"].findIndex((item) => {
                        return Number(item.ID) === Number(action.payload);
                    });

                    contentPositions["TV"].splice(idxMenu, 1);
                }
            });

            state.cScreenData.data.pos = JSON.stringify(contentPositions);

            return {
                ...state,
                gridLayout: [...newGridLayout],
                containersLayouts: [...newContainersLayout],
                cScreenData: { ...state.cScreenData },
            };

        case DUPLICATE_GRID_ITEM:
            let layoutTmp = cloneElement(state.gridLayout);
            let idxToDuplicate = -1;
            let giIdxToDuplicate = -1;
            let ciIdxToDuplicate = -1;
            state.gridLayout.map((item, index) =>
                item.i === action.payload.cloneItemId ? (idxToDuplicate = index) : null
            );

            let containerItemToDuplicate = null;

            state.containersLayouts.map((containerLayout) =>
                containerLayout.layout.map((layout, index) =>
                    layout.i === action.payload.cloneItemId
                        ? ((ciIdxToDuplicate = index), (containerItemToDuplicate = cloneElement(layout)))
                        : null
                )
            );

            state.gridItems.map((item, index) =>
                item.i === action.payload.cloneItemId ? (giIdxToDuplicate = index) : null
            );

            if (idxToDuplicate > -1) {
                let itemDuplicated = cloneElement(layoutTmp[idxToDuplicate]);
                itemDuplicated.i = `${action.payload.newItemId}`;
                itemDuplicated.y = itemDuplicated.y + itemDuplicated.h;

                let gridItemDuplicated = cloneElement(state.gridItems[giIdxToDuplicate]);
                gridItemDuplicated.i = `${action.payload.newItemId}`;
                let containerDuplicated = null;

                if (itemDuplicated.type === "CONTAINER") {
                    containerDuplicated = cloneElement(
                        state.containersLayouts.filter(
                            (container) => container.i === parseInt(action.payload.cloneItemId)
                        )[0]
                    );
                    containerDuplicated.i = `${action.payload.newItemId}`;
                }

                return {
                    ...state,
                    gridLayout: [...state.gridLayout, itemDuplicated],
                    containersLayouts: containerDuplicated
                        ? [...state.containersLayouts, containerDuplicated]
                        : [...state.containersLayouts],
                    gridItems: [...state.gridItems, gridItemDuplicated],
                };
            } else if (ciIdxToDuplicate > -1) {
                let gridItemDuplicated = cloneElement(state.gridItems[giIdxToDuplicate]);
                gridItemDuplicated.i = `${action.payload.newItemId}`;

                containerItemToDuplicate.i = `${action.payload.newItemId}`;
                state.containersLayouts.map((containerLayout) =>
                    containerLayout.layout.map((layout, index) =>
                        layout.i === action.payload.cloneItemId
                            ? containerLayout.layout.push(containerItemToDuplicate)
                            : null
                    )
                );

                return {
                    ...state,
                    containersLayouts: [...state.containersLayouts],
                    gridItems: [...state.gridItems, gridItemDuplicated],
                };
            }
            return {
                ...state,
            };

        case SET_ITEM_SELECTED:
            state.gridLayout && state.gridLayout.map((gridItem) => (gridItem.static = gridItem.i !== action.payload));

            state.containersLayouts.map((container) =>
                container.layout.map((item) => (item.static = item.i !== action.payload))
            );

            return {
                ...state,
                itemSelected: action.payload,
                gridLayout: [...cloneElement(state.gridLayout)],
                containersLayouts: [...cloneElement(state.containersLayouts)],
            };

        case SET_GRID_LAYOUT:
            return {
                ...state,
                itemSelected: action.payload,
            };

        case CLEAN_LAYOUT:
            return {
                ...state,
                gridLayout: null,
            };
        case SET_DROPPING_ITEM:
            return {
                ...state,
                droppingItem: action.payload,
            };

        case SET_CURRENT_SCREEN_DATA:
            return {
                ...state,
                cScreenData: action.payload,
                gridLayout: ComposeGridLayout(action.payload ? action.payload.data : null, state.cDevice),
                containersLayouts: composeContainersLayouts(action.payload ? action.payload.data : null, state.cDevice),
                gridItems: composeGridItems(action.payload ? action.payload.data : null),
            };
        case SET_CURRENT_LANG:
            return {
                ...state,
                cLang: action.payload,
            };
        case SET_CURRENT_DEVICE:
            return {
                ...state,
                cDevice: action.payload,
                widgetToolbarVisible: null,
                itemSelected: null,
                gridLayout: ComposeGridLayout(state.cScreenData ? state.cScreenData.data : null, action.payload),
                containersLayouts: composeContainersLayouts(
                    state.cScreenData ? state.cScreenData.data : null,
                    action.payload
                ),
            };
        case SET_DESIGN_TOOLBAR_VISIBLE:
            return {
                ...state,
                designToolbarVisible: action.payload,
            };
        case SET_WIDGET_TOOLBAR_VISIBLE:
            return {
                ...state,
                widgetToolbarVisible: action.payload ? action.payload.name : null,
                widgetToolbarVisibleTab: action.payload
                    ? action.payload.tab === -1
                        ? state.widgetToolbarVisibleTab
                        : action.payload.tab
                    : null,
            };
        case UPDATE_GRID_ITEM_PROPERTY:
            state.gridItems.map((gridItem) =>
                gridItem.i === action.payload.itemId
                    ? action.payload.property === "data"
                        ? (gridItem[action.payload.property] = action.payload.value)
                        : gridItem.data
                        ? (gridItem.data[action.payload.property] = action.payload.value)
                        : ((gridItem.data = {}), (gridItem.data[action.payload.property] = action.payload.value))
                    : null
            );
            return {
                ...state,
                gridItems: [...cloneElement(state.gridItems)],
            };
        case UPDATE_GRID_ITEM_STYLE:
            state.gridItems.map((gridItem) =>
                gridItem.i === action.payload.itemId
                    ? action.payload.property === "widgetStyle"
                        ? (gridItem["widgetStyle"] = action.payload.value)
                        : gridItem.widgetStyle
                        ? (gridItem.widgetStyle[action.payload.property] = action.payload.value)
                        : ((gridItem.widgetStyle = {}),
                          (gridItem.widgetStyle[action.payload.property] = action.payload.value))
                    : null
            );
            return {
                ...state,
                gridItems: [...state.gridItems],
            };
        case UPDATE_GRID_ITEM_ACTIONS:
            state.gridItems.map((gridItem) =>
                gridItem.i === action.payload.itemId
                    ? action.payload.property === "actions"
                        ? (gridItem[action.payload.property] = action.payload.value.actions)
                        : gridItem.actions
                        ? (gridItem.actions[action.payload.property] = action.payload.value)
                        : ((gridItem.actions = {}), (gridItem.actions[action.payload.property] = action.payload.value))
                    : null
            );
            return {
                ...state,
                gridItems: [...state.gridItems],
            };
        case UPDATE_CONTAINER_ITEM_POS:
            state.containersLayouts
                .filter((container) => container.i === parseInt(action.payload.containerId))[0]
                .layout.map((gridItem) =>
                    gridItem.i === action.payload.itemId
                        ? ((gridItem.x = action.payload.pos.x),
                          (gridItem.y = action.payload.pos.y),
                          (gridItem.w = action.payload.pos.w),
                          (gridItem.h = action.payload.pos.h))
                        : null
                );

            const containerWidget = state.cScreenData.data.contentWidgets.filter(
                (contentWidget) => contentWidget.ID === parseInt(action.payload.containerId)
            )[0];
            let containerPosis = containerWidget.pos ? JSON.parse(containerWidget.pos) : [];

            if (!containerPosis) {
                containerPosis = [];
            }

            containerPosis.map((widget) =>
                widget.ID === parseInt(action.payload.itemId)
                    ? ((widget.H = action.payload.pos.h),
                      (widget.W = action.payload.pos.w),
                      (widget.X = action.payload.pos.x),
                      (widget.Y = action.payload.pos.y))
                    : null
            );

            state.cScreenData.data.contentWidgets.map((contentWidget) =>
                contentWidget.ID === parseInt(action.payload.containerId)
                    ? (contentWidget.pos = JSON.stringify(containerPosis))
                    : null
            );

            return {
                ...state,
                containersLayouts: [...cloneElement(state.containersLayouts)],
                cScreenData: { ...state.cScreenData },
            };
        case UPDATE_GRID_ITEM_POS:
            state.gridLayout.map((gridItem) =>
                gridItem.i === action.payload.itemId
                    ? ((gridItem.x = action.payload.pos.x),
                      (gridItem.y = action.payload.pos.y),
                      (gridItem.w = action.payload.pos.w),
                      (gridItem.h = action.payload.pos.h))
                    : null
            );

            return {
                ...state,
                gridLayout: [...cloneElement(state.gridLayout)],
            };

        case CLONING_GRID_ITEM:
            return {
                ...state,
                cloningGridItemId: action.payload,
            };
        case SET_EDITOR_MODE:
            return {
                ...state,
                editorMode: action.payload,
            };
        case UPDATE_SCREEN_PROPERTY:
            state.cScreenData.data[action.payload.property] = action.payload.value;

            return {
                ...state,
                cScreenData: { ...state.cScreenData },
            };
        case SET_SCREEN_CONTENTSTYLE:
            return {
                ...state,
                cScreenData: {
                    ...state.cScreenData,
                    contentStyle: action.payload,
                },
            };
        case SET_CONTAINER_IN_EDITION:
            return {
                ...state,
                containerInEdition: action.payload,
            };
        case SET_COLOR_PICKER_REFERENCE:
            return {
                ...state,
                colorPickerReference: action.payload,
            };
        case SET_TEXT_EDITOR_SELECTION_STYLES:
            return {
                ...state,
                textEditorSelecionStyles: action.payload,
            };
        case SET_COLOR_TO_APPLY_IN_TEXT_EDITOR:
            return {
                ...state,
                colorToApplyInTextEditor: action.payload,
            };
        case SET_WIDGET_TOOLBAR_ENABLED:
            return {
                ...state,
                widgetToolbarEnabled: action.payload,
            };
        case SET_TOAST_DATA:
            return {
                ...state,
                toastData: action.payload,
            };
        case EDITING_MENU:
            return {
                ...state,
                editingMenu: action.payload,
            };
        case RESIZE_WINDOW_MENU:
            return {
                ...state,
                resizeWindowMenu: action.payload,
            };
        case TYPE_MENU:
            return {
                ...state,
                typeMenu: action.payload,
            };
        case HIDDEN_MENU_VISIBLE:
            return {
                ...state,
                hiddenMenuVisible: action.payload,
            };
        case SET_WIDGET_MENU_COLORS_AND_ANIMATIONS:
            return {
                ...state,
                widgetMenuColorsAndAnimations: action.payload,
            };
        case SET_TV_SCREENS_DATA:
            return {
                ...state,
                tvScreensData: action.payload,
            };
        case SET_PREVIEW_MENU_ID_SELECTED:
            return {
                ...state,
                previewMenuIdSelected: action.payload,
            };
        case KEY_EVENT:
            return {
                ...state,
                keyEvent: action.payload,
            };
        default:
            return state;
    }
}

export const ComposeGridLayout = (screenData, cDevice) => {
    let gridLayout = [];
    if (screenData && screenData.pos) {
        const deviceType = cDevice ? cDevice.name : null;
        const contenPositions = JSON.parse(screenData.pos);
        if (deviceType && Object.keys(contenPositions).length !== 0) {
            if (contenPositions[deviceType] === undefined) {
                // Widget not available for this device
            } else {
                contenPositions[deviceType].map((content, index) => {
                    const w =
                        screenData && screenData.contentWidgets
                            ? screenData.contentWidgets.filter((contentWidget) => contentWidget.ID === content.ID)
                            : null;
                    const posDevices = screenData && screenData.pos ? JSON.parse(screenData.pos) : {};
                    const devicesList = Object.keys(posDevices);
                    const posWidget = {};
                    // eslint-disable-next-line
                    devicesList.forEach((device) => {
                        const propertyPos = posDevices[device][index]
                            ? posDevices[device][index]
                            : { ID: 0, Y: 0, X: 0, H: 0, W: 0 };
                        posWidget[device] = {
                            id: propertyPos ? propertyPos.ID : 0,
                            y: propertyPos ? propertyPos.Y : 0,
                            x: propertyPos ? propertyPos.X : 0,
                            h: propertyPos ? propertyPos.H : 0,
                            w: propertyPos ? propertyPos.W : 0,
                        };
                    });

                    const wStyle = (w && w.length > 0 && w[0].widgetStyle ? JSON.parse(w[0].widgetStyle) : null) || {};
                    return gridLayout.push({
                        i: `${content.ID}`,
                        x: typeof content.x === "undefined" ? content.X : content.x,
                        y: typeof content.y === "undefined" ? content.Y : content.y,
                        h: typeof content.h === "undefined" ? content.H : content.h,
                        w: typeof content.w === "undefined" ? content.W : content.w,
                        isResizable: !Object.keys(wStyle).includes("isResizable") ? true : wStyle.isResizable,
                        isDraggable: !Object.keys(wStyle).includes("isDraggable") ? true : wStyle.isDraggable,
                        static: true,
                        type: w && w.length > 0 ? w[0].type : null,
                        data: {},
                        pos: posWidget,
                        resizeHandles: ["se"],
                    });
                });
            }
        }
    }
    return gridLayout;
};

const composeContainersLayouts = (screenData, cDevice) => {
    let containersLayouts = [];

    if (!screenData || !screenData.contentWidgets || !cDevice) {
        return containersLayouts;
    }
    screenData.contentWidgets.forEach((container) => {
        if (container.type === "CONTAINER" || container.type === "TVMENU") {
            const contenPositions = container && container.pos ? JSON.parse(container.pos) : null;
            if (!contenPositions) {
                containersLayouts.push({ i: container.ID, layout: [] });
                return;
            }
            let containerPosis = [];

            contenPositions.map(
                (containerItem) =>
                    (containerItem = containerPosis.push({
                        i: `${containerItem.ID}`,
                        x: typeof containerItem.x === "undefined" ? containerItem.X : containerItem.x,
                        y: typeof containerItem.y === "undefined" ? containerItem.Y : containerItem.y,
                        h: typeof containerItem.h === "undefined" ? containerItem.H : containerItem.h,
                        w: typeof containerItem.w === "undefined" ? containerItem.W : containerItem.w,
                        isResizable: true,
                        resizeHandles: ["se"],
                        type: container.contentWidgets.filter(
                            (contentWidget) => contentWidget.ID === containerItem.ID
                        )[0].type,
                        data: {},
                    }))
            );
            containersLayouts.push({ i: container.ID, layout: containerPosis });
        }
    });

    return containersLayouts;
};

const composeGridItems = (screenData) => {
    let gridItems = [];
    if (!screenData || !screenData.contentWidgets) {
        return gridItems;
    }
    // eslint-disable-next-line
    screenData.contentWidgets.map((content) => {
        if (content && content.type === "TVMENU" && JSON.stringify(content.data) === "{}") {
            // eslint-disable-next-line
            return;
        }

        let a = JSON.parse(content.actions ? content.actions : null);
        gridItems.push({
            i: `${content.ID}`,
            data: JSON.parse(content.data ? content.data : null),
            actions: a ? a.actions : null,
            widgetStyle: JSON.parse(content.widgetStyle),
        });
    });

    //Añadir items dentro de contenedores
    // eslint-disable-next-line
    screenData.contentWidgets.map((content) => {
        if (content && content.type === "TVMENU" && JSON.stringify(content.data) === "{}") {
            // eslint-disable-next-line
            return;
        }

        if (content.contentWidgets) {
            // eslint-disable-next-line
            content.contentWidgets.map((cContent) => {
                let a = JSON.parse(cContent.actions);

                gridItems.push({
                    i: `${cContent.ID}`,
                    data: JSON.parse(cContent.data),
                    actions: a ? a.actions : null,
                    widgetStyle: JSON.parse(cContent.widgetStyle),
                });
            });
        }
    });
    return gridItems;
};
