import React, { useContext, useEffect, useState } from "react";
import { withApollo } from "@apollo/react-hoc";

//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Actions
import { removeApolloCacheKeys } from "../../../../hooks/Utils/Utils";

//UTils
import { CSVLink } from "react-csv";
import { Session } from "../../../Utils/Session";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "contexts/Global";

const ExportMap = ({ startMapExport, setStartMapExport, client }) => {
    //Constantes
    const HOTEL_NAME = Session.getProject()?.name;
    const { t } = useTranslation();
    const { setLoading } = useContext(GlobalContext);

    //States
    const [exportData, setExportData] = useState([]);
    const [exportHeaders, setExportHeaders] = useState([]);

    const ALL_ROOMS_INFO = gql`
        {
            rooms: rooms(page: 1, size: 10000, orderBy: { field: "number", criteria: "asc" }) {
                info {
                    count
                }
                results {
                    floorID
                    floorName
                    id
                    name
                    number
                    pmsNumber
                    roomTVs {
                        id
                        name
                    }
                    wingID
                    wingName
                }
            }
        }
    `;

    const [executeAllRoomsQuery, allRoomsData] = useLazyQuery(ALL_ROOMS_INFO);

    useEffect(() => {
        if (startMapExport) {
            setLoading(true);
            executeAllRoomsQuery();
        }
    }, [startMapExport]);

    useEffect(() => {
        if (allRoomsData && allRoomsData.data && allRoomsData.data.rooms && allRoomsData.data.rooms.results) {
            const rooms = allRoomsData.data.rooms.results;

            setExportHeaders([
                { label: t("wing"), key: "wing" },
                { label: t("floor"), key: "floor" },
                {
                    label: t("room-number"),
                    key: "roomnumber",
                },
                {
                    label: t("pms-number"),
                    key: "pmsnumber",
                },
                {
                    label: t("name"),
                    key: "roomname",
                },
                { label: t("tvs"), key: "tvs" },
                {
                    label: t("location"),
                    key: "location",
                },
            ]);

            const rows = rooms.map((room) => ({
                wing: room.wingName || "",
                floor: room.floorName || "",
                roomnumber: room.number || "",
                pmsnumber: room.pmsNumber || "",
                roomname: room.name || "",
                tvs: room.roomTVs.length,
                location: room.roomTVs.map((rtv) => rtv.name).join(","),
            }));

            setExportData(rows);

            setLoading(false);
            setTimeout(function () {
                document.querySelector("#exportLink").click();
                //remove apollo cache for next downloads
                removeApolloCacheKeys(client.cache, "rooms");
                setStartMapExport(false);
            }, 200);
        }
        // eslint-disable-next-line
    }, [allRoomsData.data]);

    return (
        <CSVLink
            id={"exportLink"}
            className="hidden"
            data={exportData}
            headers={exportHeaders}
            filename={`${HOTEL_NAME ? HOTEL_NAME : "hotel"}-topology.csv`}
        ></CSVLink>
    );
};

export default withApollo(ExportMap);
