const EditRoomGroupModal = ({ roomName, roomId, type }) => {
    console.log("Esto es el type", type);
    const modalInputs = [
        {
            name: "room-groups",
            optionsQuery: `{
                data: roomGroups(filter: { type: ${type} }) {
                    info { count }
                    results { id name type }
                }
                selected: rooms(id: ${roomId}) { 
                    results { roomGroups { id } } 
                }
            }`,
            selectedIdsPath: "data.selected.results[0].roomGroups",
            selectPlaceHolder: "select-group",
            cStyle: "w-full",
            sort: true,
            type: "selectMultipleWithSearch",
        },
    ];

    const modal = {
        title: "assign-to-group",
        value: roomName,
        inputs: modalInputs,
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            { name: "assign-to-group", style: "blue", action: "room-group-assign" },
        ],
    };

    return modal;
};

export default EditRoomGroupModal;
