import React, { useEffect } from "react";
//Components
import UseSectionHeader from "../../../useSectionHeader";
//Utils
import Table from "components/ZafiroTable";
import { useTranslation } from "react-i18next";
import { useAddToGroup, useListRooms } from "hooks/GraphqlCalls/Hotel/Rooms/useListRooms";
import Loading from "components/Loading";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import Select from "components/Select";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { ROOM_TYPE } from "constants/rooms";

const Rooms = () => {
    const { t } = useTranslation();
    const {
        data,
        filters,
        refetch,
        getRooms,
        count,
        page,
        sort,
        search,
        status,
        group,
        deviceLocation,
        setPage,
        setSort,
        setStatus,
        setGroup,
        setSearch,
        setDeviceLocation,
    } = useListRooms();
    const { open, close } = useModal();

    useEffect(() => getRooms(), [page, search, sort, status, group, deviceLocation]);

    return (
        <>
            <UseSectionHeader title={["list-of", "rooms"]} value={"demo"} />
            <div className="main-container">
                {data?.length ? (
                    <Table
                        id="rooms-list"
                        paginate={true}
                        header={{
                            room: { title: capitalizeFirst(t("room")), sortable: true, width: "20%" },
                            group: { title: capitalizeFirst(t("group")), width: "30%" },
                            status: { title: capitalizeFirst(t("status")), sortable: true, width: "15%" },
                            guest: { title: capitalizeFirst(t("guest")), width: "5%", align: "center" },
                            stay: { title: t("stay"), sortable: true, width: "15%" },
                            "devices-notices": {
                                title: capitalizeFirst(t("device-status")),
                                sortable: true,
                                width: "15%",
                            },
                        }}
                        cols={["room", "group", "status", "guest", "stay", "devices-notices"]}
                        rows={data}
                        customPagination={{ value: page, onChange: setPage }}
                        customSearch={{ value: search, onChange: setSearch }}
                        customSort={{ value: sort, onChange: setSort }}
                        customCount={count || 0}
                        showCount={true}
                        search={true}
                        filters={[
                            {
                                id: "filter-by-floor",
                                title: t("floor"),
                                multiple: true,
                                options: filters[0],
                                onChange: (value) => setDeviceLocation(value),
                            },
                            {
                                id: "filter-by-status",
                                title: t("status"),
                                options: [
                                    { id: "status-occupied", label: t("occupied"), value: "occupied" },
                                    { id: "status-empty", label: t("empty"), value: "empty" },
                                ],
                                onChange: (value) => setStatus(value),
                            },
                            {
                                id: "filter-by-group",
                                title: t("group"),
                                multiple: true,
                                options: filters[1],
                                onChange: (value) => setGroup(value),
                            },
                        ]}
                        batchActions={{
                            options: [
                                {
                                    id: "add-to-group",
                                    label: t("add-to-group"),
                                    value: "add-to-group",
                                },
                            ],
                            onChange: (action, rows) => {
                                if (action === "add-to-group") {
                                    open(
                                        <Modal
                                            id="add-to-group"
                                            title={<h1 className="text-2xl font-bold">{t("add-to-group")}</h1>}
                                            minWidth="30rem"
                                            className="w-1/2 p-10"
                                            onKeyPress={{ Enter: close }}
                                        >
                                            <BatchActionsModal
                                                rows={rows}
                                                filters={filters[1]}
                                                refetch={refetch}
                                                type={ROOM_TYPE.ROOM}
                                            />
                                        </Modal>
                                    );
                                }
                            },
                        }}
                    />
                ) : (
                    <Loading adjust="section-loading" style={{ height: "50vh" }} />
                )}
            </div>
        </>
    );
};

export default Rooms;

export const BatchActionsModal = ({ type, rows, filters, refetch }) => {
    const [roomsGroupsId, setRoomsGroupsId] = React.useState([]);
    const roomsId = rows?.length ? rows?.map((room) => room.id) : [];
    const { t } = useTranslation();
    const { close } = useModal();
    const { updateRoomGroup } = useAddToGroup({ roomsGroupsId, roomsId, refetch });
    return (
        <>
            <main>
                <h2 className="my-10 mb-3">
                    {type === ROOM_TYPE.COMMONZONE ? t("zones-to-add-to-group") : t("rooms-to-add-to-group")}
                </h2>
                <div className="flex gap-2 mb-5">
                    {rows?.map((room) => {
                        return <p className="px-3 py-1 bg-gray-200">{room?.roomName}</p>;
                    })}
                </div>
                <div>
                    <p>{t("select-group")}</p>
                    <Select
                        id="select-group"
                        label={t("select-group")}
                        options={filters}
                        multiple
                        style="mt-4"
                        onChange={(value) => {
                            const selectedIds = filters
                                .filter((option) => {
                                    return value?.includes(option.value);
                                })
                                .map((option) => option.id);
                            setRoomsGroupsId(selectedIds);
                        }}
                    />
                </div>
            </main>
            <footer className="flex w-full gap-4 mt-10 justify-end">
                <Button id="cancel" design="white" onClick={close}>
                    {t("cancel")}
                </Button>
                <Button id="alert-accept" design="blue" onClick={updateRoomGroup}>
                    {t("add-to-group")}
                </Button>
            </footer>
        </>
    );
};
