import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EditorContext, WidgetContext } from "contexts/editor";

import Widget from "./Widget";
import EmptyWidget from "./WidgetToolbars/CommonOptions/EmptyWidget";
import WidgetElement from "../../../Widget";

const WidgetType = "IMAGE";

const WidgetImage = ({ status, statusStyles }) => {
    const { deviceType, editorSize } = useContext(EditorContext);
    const { config: cfg, id: widgetID } = useContext(WidgetContext);
    const { gridItems } = useSelector((state) => state.design);
    const { lang } = useParams();

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;

    const parentStyles = {
        active: { bgColor: statusStyles?.active?.bgColor },
        focus: { bgColor: statusStyles?.focus?.bgColor },
    };

    const style = cfg?.style?.export();
    const deviceConfig = cfg?.data || Widget.ParseData(widget?.data, deviceType);

    const config = {
        src: deviceConfig ? { externalUrl: deviceConfig.externalUrl, libraryRef: deviceConfig.libraryRef } : null,
        adjust: deviceConfig?.adjust,
        status: status,
        lang: lang,
    };

    const styles = {
        ...style,
        status: {
            ...style?.status,
            active: {
                ...style?.status?.active,
                ...parentStyles?.active,
            },
            focus: {
                ...style?.status?.focus,
                ...parentStyles?.focus,
            },
        },
    };

    const isEmpty = !config?.src?.externalUrl && !config?.src?.libraryRef;

    return isEmpty ? (
        <EmptyWidget type={WidgetType}></EmptyWidget>
    ) : (
        <WidgetElement
            id={"widget_render_" + widgetID}
            type={WidgetType}
            device={deviceType}
            deviceWidth={editorSize?.width}
            config={config}
            style={styles}
        />
    );
};

export default WidgetImage;
