import { useEffect, useState } from "react";

import { DEVICE } from "constants/editor";

export const DEVICES = [
    {
        id: DEVICE.TYPE.TV,
        cols: 64,
        rows: 36,
        scrollX: false,
        scrollY: false,
    },
    {
        id: DEVICE.TYPE.DESKTOP,
        cols: 64,
        rows: 36,
        scrollX: false,
        scrollY: true,
    },
    {
        id: DEVICE.TYPE.MOBILE,
        cols: 12,
        rows: 26,
        scrollX: false,
        scrollY: true,
    },
];

const BORDER_SIZE = 5;
const SCROLLBAR_SIZE = 5;

const MIN_AREA_WIDTH = 450;
const MIN_AREA_HEIGHT = 550;

const useDevice = ({ editorRef, deviceType, customDevice, area, readOnly }) => {
    const current = { ...DEVICES.find((d) => d.id === deviceType) };
    const cols = customDevice?.cols ?? current?.cols;
    const rows = customDevice?.rows ?? current?.rows;
    const scrollX = customDevice?.scrollX ?? current?.scrollX;
    const scrollY = customDevice?.scrollY ?? current?.scrollY;
    const marginX = customDevice?.margin?.w ?? 0;
    const marginY = customDevice?.margin?.h ?? 0;

    const ratio = current?.cols && current?.rows ? current?.cols / current?.rows : 0;
    const landscape = ratio > 1;
    const portrait = ratio < 1;
    const square = ratio === 1;

    const borderSize = readOnly ? 0 : BORDER_SIZE;

    const bordersX = borderSize * 2;
    const bordersY = borderSize * 2;

    const [editorSize, setEditorSize] = useState({ width: 0, height: 0 });

    const scrollBarSize = scrollY ? SCROLLBAR_SIZE : 0;

    const areaRect = area ?? { width: 800, height: 800 };
    const areaWidth = Math.max(areaRect?.width || 0, MIN_AREA_WIDTH);
    const areaHeight = Math.max(areaRect?.height || 0, MIN_AREA_HEIGHT);

    const maxWidth = (areaWidth || 0) - bordersX - scrollBarSize;
    const maxHeight = areaHeight ? areaHeight - bordersY : 0;

    const [width, height] = (() => {
        if (landscape) {
            if (maxWidth / ratio > maxHeight) {
                return [maxHeight * ratio, maxHeight];
            }
            return [maxWidth, maxWidth / ratio];
        }
        if (maxHeight * ratio > maxWidth) {
            return [maxWidth, maxWidth / ratio];
        }
        return [maxHeight * ratio, maxHeight];
    })();

    const editorElement = editorRef?.current?.getElement();

    const { size: rowHeight, gap: gapX } = calculateSize(
        Math.max(0, height - bordersY - marginY * 2),
        rows,
        customDevice?.itemsMargin?.h || 0
    );
    const { size: colWidth, gap: gapY } = calculateSize(
        Math.max(0, width - bordersX - marginX * 2),
        cols,
        customDevice?.itemsMargin?.w || 0
    );

    useEffect(() => {
        const observer = new ResizeObserver((e) => {
            for (let entry of e) {
                setEditorSize({
                    width: entry?.contentRect?.width,
                    height: entry?.contentRect?.height,
                });
            }
        });
        if (editorElement) {
            observer.observe(editorElement);
        }
        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [editorElement, cols, rows]);

    const loading = editorElement && !(editorSize?.width && ratio);

    return {
        ...current,
        width: width ? width + scrollBarSize : 0,
        height: width ? height : 0,
        editor: {
            width: loading ? 0 : editorSize?.width + scrollBarSize,
            height: loading ? 0 : editorSize?.height,
        },
        border: loading ? 0 : borderSize,
        scrollbar: scrollBarSize,
        cols,
        rows,
        scrollX,
        scrollY,
        colWidth,
        rowHeight,
        margin: { x: marginX, y: marginY },
        gap: { x: gapY, y: gapX },
        ratio,
        landscape,
        portrait,
        square,
        loading,
    };
};

const calculateSize = (size, parts, gap) => {
    const gapSize = (gap || 0) * (parts - 1);
    const fullSize = size - gapSize;
    const result = { size: Math.max(0, fullSize / parts), gap };

    if (result.size < 1) {
        if (gap) {
            return calculateSize(size, parts, gap - 1);
        }
        return { size: 0, gap };
    }
    return result;
};

export default useDevice;
