import { useEffect } from "react";
import { GET_DHCP } from "../services/getMonitorInfo";
import { useLazyQuery } from "react-apollo";
import { pathsWithoutChainOrProjectRef } from "hooks/Utils/Utils";
import { useLocation } from "react-router-dom";

import { useAuth } from "hooks/Session/auth";

export const useIsDhcp = () => {
    const { isLogged, isCorporate } = useAuth();

    const location = useLocation();
    const isNonChainRefOrProjectPath = [...pathsWithoutChainOrProjectRef].includes(location.pathname);
    const isLoggedIn = isLogged && !isNonChainRefOrProjectPath && !isCorporate;

    const [executeGetDhcpQuery, { data, called, loading }] = useLazyQuery(GET_DHCP, {
        pollInterval: 200000,
    });

    useEffect(() => {
        if (isLoggedIn) {
            executeGetDhcpQuery();
        }
    }, [isLoggedIn]);

    return { isDhcp: data?.getMonitorAvailability, ready: called && !loading, called, loading, executeGetDhcpQuery };
};
