import { useEffect, useState } from "react";
import { useApolloClient } from "react-apollo";

import { ManagerService } from "App";

export const getGQLClient = (options) => {
    const { client } = queryConfig({ ...options });
    return client;
};

const useGQLQuery = (query, options) => {
    const defaultClient = useApolloClient();

    const { onSuccess, onError, client } = queryConfig({ ...options, defaultClient });

    const [{ data, loading, error, called }, setQueryState] = useState({});

    const ready = called && !loading && !error;

    const load = (variables) => {
        const props = { variables };
        setQueryState({
            data: null,
            loading: true,
            error: null,
            called: true,
        });

        client
            .query({
                query,
                fetchPolicy: options?.fetchPolicy ?? "no-cache",
                errorPolicy: options?.errorPolicy ?? "all",
                ...props,
            })
            .then((response) => {
                setQueryState((prev) => ({ ...prev, data: response?.data, loading: response?.loading }));
            })
            .catch((err) => {
                setQueryState((prev) => ({ ...prev, error: err }));
            });
    };

    useEffect(() => {
        if (ready && onSuccess) {
            onSuccess(data);
        }
    }, [ready]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error]);

    return { load, data, loading, error, called, ready };
};

const queryConfig = (options) => {
    const {
        onSuccess,
        onError,
        token: customToken,
        serviceURL: customServiceURL,
        service: customService,
        defaultClient,
    } = options || {};
    const service = customService || ManagerService;
    const client = customToken ? service(customToken, customServiceURL) : defaultClient;
    return {
        onSuccess,
        onError,
        client,
    };
};

export default useGQLQuery;
