import React, { useRef, forwardRef, useEffect, useState, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
    useRoomGroups,
    useDeleteRoomGroup,
    useCreateRoomGroup,
    useUpdateRoomGroup,
    useRoomGroupRooms,
    useRooms,
} from "hooks/Data/useRoomGroups";
import { useConfirm, useModal } from "components/Modal";

import UseSectionHeader from "components/useSectionHeader";
import Loading from "components/Loading";
import Table from "components/ZafiroTable";
import Button from "components/Button";
import Icon from "components/Icon";
import TextInput from "components/TextInput";
import Select from "components/Select";
import Tag from "components/Tag";

import { ROOM_TYPE } from "constants/rooms";

const ZoneGroups = () => {
    const { t } = useTranslation();

    const { open: confirm } = useConfirm();

    const [groups, setGroups] = useState(null);

    const { load, loading } = useRoomGroups(ROOM_TYPE.COMMONZONE, {
        onSuccess: (response) => {
            setGroups(response?.results);
        },
    });

    const { open: groupModal } = useGroupModal({ onSuccess: () => load() });

    const remove = useDeleteRoomGroup({
        onSuccess: () => toast.success(t("operation-successful")),
        onError: () => toast.error(t("mutation-error")),
    });

    const deleteGroup = (id) => () => {
        setGroups(groups.map((g) => (g.id === id ? { ...g, deleted: true } : g)));
        remove(id, {
            onError: () => {
                setGroups(groups.map((g) => (g.id === id ? { ...g, deleted: false } : g)));
            },
        });
    };

    useEffect(() => {
        load();
    }, []);

    if (loading) {
        return <Loading adjust="section-loading" />;
    }

    const groupList = groups?.filter((g) => !g.deleted);

    return (
        <>
            <UseSectionHeader title={t("Common Zones groups")} />
            <Table
                id="zones-groups-list"
                className="main-container"
                search={true}
                showCount={true}
                topRightCorner={
                    <Button id="add-group" design="blue" onClick={() => groupModal()}>
                        {t("add-group")}
                    </Button>
                }
                header={{
                    name: { title: t("group-name"), sortable: true },
                    zones: { title: t("Number of common zones"), sortable: true },
                    actions: { align: "right" },
                }}
                cols={["name", "zones", "actions"]}
                rows={
                    groupList?.length
                        ? groupList.map((group) => {
                              const zonesCount = group?.rooms?.length || 0;
                              const zonesNames = group?.rooms?.length ? group?.rooms.map((z) => z.name) : null;
                              const alt = (zonesNames || []).join(", ");
                              const placeholder = (zonesNames || []).join("\n");
                              return {
                                  id: group?.id,
                                  name: group?.name,
                                  zones: (
                                      <div
                                          alt={alt}
                                          sortvalue={zonesCount}
                                          data-tip={placeholder}
                                          data-for="default-tooltip"
                                      >
                                          {t("x common zones", { count: zonesCount })}
                                      </div>
                                  ),
                                  actions: (
                                      <div className="flex items-center space-x-5 text-gray-800">
                                          <Button
                                              id={`edit-zone-${group?.id}`}
                                              design="text-link"
                                              onClick={() => {
                                                  groupModal(group);
                                              }}
                                          >
                                              <Icon type="edit" size="2xl" />
                                          </Button>
                                          <Button
                                              id={`delete-zone-${group?.id}`}
                                              design="text-link"
                                              onClick={() => {
                                                  confirm({
                                                      id: `modal-delete-zone-${group?.id}`,
                                                      message: t(
                                                          "Are you sure you want to delete this common zones group?"
                                                      ),
                                                      title: `${group?.name} - ${t("delete")}`,
                                                      onConfirm: deleteGroup(group?.id),
                                                      confirmText: t("delete"),
                                                      sensitive: true,
                                                  });
                                              }}
                                          >
                                              <Icon type="delete" size="2xl" />
                                          </Button>
                                      </div>
                                  ),
                              };
                          })
                        : []
                }
            />
        </>
    );
};

const useGroupModal = ({ onSuccess }) => {
    const { t } = useTranslation();
    const { open: openModal, close: closeModal } = useModal();

    const formRef = useRef(null);

    return {
        open: (group) => {
            openModal({
                id: "modal-group",
                className: "modal-size-lg p-10",
                title: group?.id ? `${group?.name} - ${t("edit-group")}` : t("New group of common zones"),
                children: (
                    <EditGroup
                        ref={formRef}
                        group={group}
                        onSuccess={(data) => {
                            closeModal();
                            if (onSuccess) {
                                onSuccess(data);
                            }
                        }}
                    />
                ),
                footer: (
                    <>
                        <Button design="basic" id="modal-button-close" onClick={closeModal}>
                            {t("cancel")}
                        </Button>
                        <Button design="blue" id="modal-button-save" onClick={() => formRef?.current?.submit()}>
                            {group?.id ? t("save") : t("add")}
                        </Button>
                    </>
                ),
            });
        },
        close: closeModal,
    };
};

const EditGroup = forwardRef(({ group, onSuccess }, ref) => {
    const { t } = useTranslation();

    const nameRef = useRef(null);

    const [zones, setZones] = useState(null);
    const [formData, setFormData] = useState({
        name: group?.name || "",
        zones: group?.rooms || [],
    });

    const { load: loadGroup } = useRoomGroupRooms();
    const { load: loadRooms } = useRooms(ROOM_TYPE.COMMONZONE, {
        onSuccess: (response) => {
            setZones(response?.results);
        },
    });
    console.log(formData);
    const callbacks = {
        onSuccess: () => {
            toast.success(t("operation-successful"));
        },
        onError: () => {
            toast.error(t("mutation-error"));
        },
    };

    const update = useUpdateRoomGroup(callbacks);
    const create = useCreateRoomGroup(callbacks);

    const zonesOptions = zones?.length ? zones?.map((z) => ({ value: z?.id, label: z?.name })) : [];

    useEffect(() => {
        loadRooms();
    }, []);

    useEffect(() => {
        if (group?.id) {
            loadGroup(group?.id, ROOM_TYPE.COMMONZONE, {
                onSuccess: (response) => {
                    setFormData({ ...formData, zones: response?.results?.map((r) => r.id) });
                },
            });
        }
    }, [group?.id]);

    useImperativeHandle(ref, () => ({
        submit: () => {
            if (!nameRef?.current?.isValid()) {
                return toast.error(t("errors-in-red"));
            }
            const input = {
                name: formData?.name,
                rooms: formData?.zones,
            };
            const success = (response) => {
                if (onSuccess) {
                    onSuccess(response);
                }
            };
            if (group?.id) {
                update({ id: group?.id, ...input }, { onSuccess: success });
            } else {
                create({ type: ROOM_TYPE.COMMONZONE, ...input }, { onSuccess: success });
            }
        },
    }));

    if (zones === null) {
        return <Loading />;
    }
    return (
        <div className="grid grid-cols-2 justify-items-start">
            <div className="flex flex-col space-y-8">
                <div className="flex flex-col space-y-4">
                    <label htmlFor="name">{t("give-group-name")}</label>
                    <TextInput
                        ref={nameRef}
                        id="group-name"
                        required={true}
                        placeholder={t("name")}
                        value={group?.name}
                        onChange={(value) => {
                            setFormData((prev) => ({ ...prev.formData, name: value }));
                        }}
                    />
                </div>
                <div className="flex flex-col space-y-4">
                    <label htmlFor="name">{t("Add or remove common zones from the group")}:</label>
                    <Select
                        id="group-zones"
                        value={formData?.zones}
                        multiple={true}
                        options={zonesOptions}
                        onChange={(value) => {
                            setFormData({ ...formData, zones: value });
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-col space-y-4 w-full">
                <div>{t("Common zones added")}:</div>
                <div className="bg-gray-200 min-h-10 rounded p-5 flex flex-col space-y-2">
                    {formData?.zones?.length ? (
                        <>
                            <div className="font-bold">{t("x common zones", { count: formData?.zones?.length })}</div>
                            <div className="text-sm flex flex-wrap gap-1">
                                {formData?.zones?.length
                                    ? formData?.zones.map((id) => {
                                          const zone = zonesOptions.find((z) => z.value === id);
                                          return (
                                              <Tag key={zone?.value} className="px-2 py-1 m-0">
                                                  <div className="flex flex-row space-x-1 items-center justify-between">
                                                      <div>{zone?.label || id}</div>
                                                      <Button
                                                          id="delete-tag-button"
                                                          onClick={() => {
                                                              setFormData({
                                                                  ...formData,
                                                                  zones: formData?.zones.filter((z) => z !== id),
                                                              });
                                                          }}
                                                      >
                                                          <Icon type="remove" />
                                                      </Button>
                                                  </div>
                                              </Tag>
                                          );
                                      })
                                    : null}
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
});

export default ZoneGroups;
