import React from "react";
import { useSelector } from "react-redux";
import UseComponentLoading from "../../useComponentLoading";
//Components
import UseRoomCodeCard from "./useRoomCodeCard";
import UseRoomGroupCard from "./useRoomGroupCard";
import UseWarningCard from "./useWarningCard";
import UseAvailableContentCard from "./useAvailableContentCard";

const UseDashboardCards = (props) => {
    //Store data
    const { parentSectionName, zoneId, roomName } = props;
    const { dashboardCards } = useSelector((state) => state.ui);

    const loadCardData = (cardData, index) => {
        if (cardData.type) {
            switch (cardData.type) {
                case "roomcode-card":
                    return <UseRoomCodeCard data={cardData} key={index} />;
                case "roomgroup-card":
                    return <UseRoomGroupCard data={cardData} key={index} parentSectionName={parentSectionName} />;
                case "warning-card":
                    return <UseWarningCard data={cardData} key={index} />;
                case "available-content-card":
                    if (parentSectionName.toLowerCase() === "common-zones") {
                        return (
                            <UseAvailableContentCard data={cardData} key={index} zoneId={zoneId} roomName={roomName} />
                        );
                    }
                    break;
                default:
                    return;
            }
        }
    };
    return (
        <>
            <div className="pb-10 w-full">
                {dashboardCards ? (
                    <div
                        className={`grid grid-cols-1 md:grid-cols-2 xl:grid-cols-${
                            parentSectionName === "common-zones"
                                ? 4
                                : dashboardCards.filter((dashboardCard) => dashboardCard.visible).length > 3
                                ? 3
                                : dashboardCards.filter((dashboardCard) => dashboardCard.visible).length
                        } xl:gap-10 md:gap-4 gap-4 w-100`}
                    >
                        {dashboardCards.map((cardData, index) => loadCardData(cardData, index))}
                    </div>
                ) : (
                    <UseComponentLoading />
                )}
            </div>
        </>
    );
};

export default UseDashboardCards;
