import { convertMsToTime } from "hooks/Utils/DesignUtils";
import { formatBytes } from "hooks/Utils/Utils";
import i18n from "i18n";
import moment from "moment";

export const getDaysNamesByValues = (values) => {
    const daysOfWeek = ["day-1", "day-2", "day-3", "day-4", "day-5", "day-6", "day-7"];
    const translatedDays = values.map((value) => i18n.t(daysOfWeek[(value - 1) % 7]));
    return translatedDays.join(", ");
};

export const getTimesRangesFormatted = (timeRanges) => {
    const formattedRanges = timeRanges.map((range) => {
        const startTime = range.startTime.slice(0, 5);
        const endTime = range.endTime.slice(0, 5);

        return `${startTime} - ${endTime}`;
    });

    return formattedRanges.join(" / ");
};

export const isSchedulling = (data) => {
    if (!data?.scheduleDailyType && !data?.scheduleDaysType && !data?.scheduleStart) {
        return false;
    } else {
        return true;
    }
};

export const ScheduledDateColumn = (props) => {
    const startDate = props.scheduleStart
        ? new window.ZDate(props.scheduleStart).toLocaleString(null, {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
          })
        : "-";
    const endDate = props.scheduleEnd
        ? `- ${new window.ZDate(props.scheduleEnd).toLocaleString(null, {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
          })}`
        : "";
    return `${startDate} ${endDate}`;
};

export const buildAssetInformation = (props) => {
    const defaultFile =
        props.languages.find((val) => val.language == props.defaultLanguage) ||
        props.languages.find((val) => val.language == props.defaultFileLanguage);
    const name = defaultFile?.name || props?.name;
    const type =
        defaultFile?.contentType.split("/")?.[1].toUpperCase() || props?.contentType.split("/")?.[1].toUpperCase();
    const size = formatBytes(defaultFile?.size || props?.size);
    const dimensions = defaultFile?.dimensions
        ? `${defaultFile?.dimensions?.width} x ${defaultFile?.dimensions?.height}`
        : "";
    const languages =
        props.defaultFileLanguage == ""
            ? i18n.t("multi-language")
            : props.languages
                  .reduce((acc, curr) => {
                      let text = "";
                      if (curr.language) {
                          text = i18n.t("language:" + curr.language);
                          if (curr.language == props.defaultFileLanguage) {
                              text += `(${i18n.t("default-lang")})`;
                          }
                          acc.push(text);
                      }
                      return acc;
                  }, [])
                  .join(",");
    return { name, type, size, dimensions, languages };
};

export const buildVideoInformation = (props) => {
    const defaultFile =
        props.languages.find((val) => val.language == props.defaultLanguage) ||
        props.languages.find((val) => val.language == props.defaultFileLanguage);
    const name = defaultFile?.name || props?.name;
    const type = defaultFile?.contentType || props?.contentType.split("/")?.[1].toUpperCase();
    const size = formatBytes(defaultFile?.size || props?.size);
    const duration = defaultFile.duration ? convertMsToTime(defaultFile?.duration || props?.duration) : "";
    const quality = i18n.t(defaultFile?.resolution).split("-")[0];
    const languages =
        props.defaultFileLanguage == ""
            ? i18n.t("multilanguage")
            : props.languages
                  .reduce((acc, curr) => {
                      let text = "";
                      if (curr.language) {
                          text = i18n.t("language:" + curr.language);
                          if (curr.language == props.defaultFileLanguage) {
                              text += `(${i18n.t("default-lang")})`;
                          }
                          acc.push(text);
                      }
                      return acc;
                  }, [])
                  .join(",");
    return { name, type, size, duration, quality, languages };
};

export const formatSecondsToHMS = (seconds) => {
    return moment.utc(moment.duration(seconds, "seconds").asMilliseconds()).format("HH:mm:ss");
};

export const formatMillisecondsToHMS = (milliseconds) => {
    return moment.utc(milliseconds).format("HH:mm:ss");
};

export const checkHasLinkedSignages = (contentTrees) => {
    const searchDir = (dir) => {
        if (!dir) return false;
        if (Array.isArray(dir.contents)) {
            for (const content of dir.contents) {
                if (Array.isArray(content.linkedSignages) && content.linkedSignages.length > 0) {
                    return true;
                }
            }
        }

        if (Array.isArray(dir.subDirs)) {
            for (const subDir of dir.subDirs) {
                if (searchDir(subDir)) return true;
            }
        }

        return false;
    };

    for (const key in contentTrees) {
        if (contentTrees[key]?.screens?.dir && searchDir(contentTrees[key].screens.dir)) {
            return true;
        }
    }

    return false;
};
