import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
//COMPONENTS
import { EditorContext, WidgetProvider } from "../../../../contexts/editor";
import GridItemToolbar from "./WidgetToolbars/GridItemToolbar";
import Widget from "./Widget";
import WidgetTVMenu from "./WidgetTVMenu";
//ACTIONS
import {
    setItemSelected,
    setDesignToolbarVisible,
    setContainerInEdition,
    setColorPickerReference,
    setWidgetToolbarVisible,
    updateGridItemPos,
    addGridItem,
    setTypeMenu,
    removeMenuInCustomScreens,
    setHiddenMenuVisible,
    setEditingMenu,
    setPreviewMenuIdSelected,
} from "../../../../actions/designActions";
import { setExecuteQuery } from "../../../../actions/sectionActions";
import { useLazyQuery } from "react-apollo";
import { cleanActionValues } from "../../../../actions/globalActions";
//UTILS
import { ensureToolbarPos, ensureWidgetOptionsPos } from "../../../../hooks/Utils/DesignUtils";
import { SCREEN, DEVICE } from "constants/editor";
import { arrangeGridItemToAdd } from "../../../../hooks/Utils/Design/EditorUtils";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { gql } from "apollo-boost";
import WidgetStyle from "zrender/widgetStyle";
import { GlobalContext } from "contexts/Global";

const ReactGridLayout = WidthProvider(RGL);

const MenuEditor = ({ typeMenu, editorSize }) => {
    const { deviceType, theme } = useContext(EditorContext);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { type: screenType, id, landingid, screenid } = useParams();

    //STORE DATA
    const {
        gridItems,
        gridLayout,
        editingMenu,
        editorMode,
        resizeWindowMenu,
        cDevice,
        itemSelected,
        widgetToolbarVisible,
        widgets,
        hiddenMenuVisible,
        cScreenData,
        previewMenuIdSelected,
        cLang,
    } = useSelector((state) => state.design);
    const { values } = useSelector((state) => state.action);
    const { refreshData } = useSelector((state) => state.sectionContent);
    const { warningsMinimized } = useSelector((state) => state.ui);
    const { warnings: uiWarnings } = useContext(GlobalContext);

    //STATE VARIABLES
    const [layoutCopy, setLayoutCopy] = useState([]);
    const [menuEditorSize, setMenuEditorSize] = useState(null);
    const [menuTvWidgetId, setMenuTvWidgetId] = useState(null);
    const [savePreviewMenuId, setSavePreviewMenuId] = useState(null);
    const [prevRefresh, setPrevRefresh] = useState(false);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [showHomeMenu, setShowHomeMenu] = useState(null);
    const [menusToClean, setMenusToClean] = useState([]);
    const [containerMenuSize, setContainerMenuSize] = useState({ width: "auto", height: "auto", overflow: "visible" });
    const [saveMenuSize, setSaveMenuSize] = useState(null);
    const [hasAMenu, setHasAMenu] = useState(null);

    const styleScreen =
        cScreenData && cScreenData.contentStyle ? JSON.parse(JSON.stringify(cScreenData.contentStyle)) : null;
    const widgetMenuTv = gridItems ? gridItems.filter((w) => w && w.i === menuTvWidgetId)[0] : null;
    const data = Widget.ParseData(widgetMenuTv ? widgetMenuTv.data : null, deviceType);
    const colsMenuEditor = editorSize && editorSize.cols ? editorSize.cols : 0;
    const rowsMenuEditor = editorSize && editorSize.rowHeight ? editorSize.rowHeight : 0;
    const hiddenMenu = data && data.menuType ? data.menuType === "hidden-menu" : false;
    const blurBackground = data && data.blurMainScreen && hiddenMenu === true ? true : false;
    const isGeneralTV = screenType === SCREEN.TYPE.GENERAL && deviceType === DEVICE.TYPE.TV ? true : false;
    const isGeneralMobile = screenType === SCREEN.TYPE.GENERAL && deviceType === DEVICE.TYPE.MOBILE ? true : false;
    const isGeneral = screenType === SCREEN.TYPE.GENERAL ? true : false;
    const isTV = screenType === SCREEN.TYPE.TV;

    const GET_MENU = gql`
        {
            contentInterface(designID: ${id}, id: ${previewMenuIdSelected}) {
                devices{
                    type
                    name
                    grid{
                        cols
                        rows
                    }
                }
                menu {
                    ID
                    pos
                    data
                    fonts {
                        name
                    }
                    widgetStyle
                    contentWidgets {
                        data
                        pos
                        ID
                        widgetStyle
                        type
                        fonts {
                            name
                        }
                    }
                }
                style
                pos
            }
        }
    `;

    const [executeQuery, { data: dataMenu }] = useLazyQuery(GET_MENU, { fetchPolicy: "network-only" });

    //LISTENERS
    useEffect(() => {
        setMenuEditorSize(editorSize);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // eslint-disable-next-line
        if (gridLayout) {
            // eslint-disable-next-line
            gridLayout.map((widget) => {
                if (widget.type === "TVMENU") {
                    widget.pos = { x: widget.x, y: widget.y, w: widget.w, h: widget.h };
                    delete widget.static;
                    widget.resizeHandles = ["se"];
                    setLayoutCopy([widget]);
                    setMenuTvWidgetId(widget.i);
                    if (isGeneral && !menusToClean.includes(String(widget.i))) {
                        const menusToCleanTemp = [...menusToClean];
                        menusToCleanTemp.push(String(widget.i));
                        setMenusToClean(menusToCleanTemp);
                    }
                }
            });
        }
        // eslint-disable-next-line
    }, [gridLayout]);

    useEffect(() => {
        if (menuTvWidgetId && isGeneralTV) {
            const menuToDelete = gridLayout.filter(
                (widget) => widget.type === "TVMENU" && widget.i !== menuTvWidgetId
            )[0];

            if (menuToDelete) {
                dispatch(removeMenuInCustomScreens(menuToDelete.i));
            }
        }
        // eslint-disable-next-line
    }, [menuTvWidgetId, deviceType, screenid]);

    useEffect(() => {
        if (cLang && previewMenuIdSelected && isGeneralTV) {
            setForceRefresh(true);
        }

        if (cLang && isTV && cScreenData) {
            if (!editingMenu) {
                dispatch(setEditingMenu(true));
                dispatch(setHiddenMenuVisible(false));
                setTimeout(() => {
                    dispatch(setEditingMenu(false));
                }, 100);
            }
        }

        // eslint-disable-next-line
    }, [cLang]);

    useEffect(() => {
        if (isGeneralMobile && previewMenuIdSelected) {
            // eslint-disable-next-line
            menusToClean.map((idMenu) => {
                dispatch(removeMenuInCustomScreens(Number(idMenu)));
            });
            setMenuTvWidgetId(null);
        }
        // eslint-disable-next-line
    }, [isGeneralMobile]);

    useEffect(() => {
        if ((refreshData && isGeneral) || (forceRefresh && isGeneral)) {
            setSavePreviewMenuId(previewMenuIdSelected);
            dispatch(setPreviewMenuIdSelected(null));
            setPrevRefresh(true);
        }
        if (refreshData && isTV && !editingMenu) {
            dispatch(setEditingMenu(true));
            dispatch(setHiddenMenuVisible(false));
            setTimeout(() => {
                dispatch(setEditingMenu(false));
            }, 100);
        }
        // eslint-disable-next-line
    }, [refreshData, forceRefresh]);

    useEffect(() => {
        if (prevRefresh === false && savePreviewMenuId) {
            dispatch(setPreviewMenuIdSelected(savePreviewMenuId));
            setSavePreviewMenuId(null);
        }
        // eslint-disable-next-line
    }, [prevRefresh]);

    useEffect(() => {
        if (cScreenData && menuTvWidgetId && prevRefresh && menusToClean.length > 0) {
            // eslint-disable-next-line
            menusToClean.map((idMenu) => {
                dispatch(removeMenuInCustomScreens(Number(idMenu)));
            });
            setForceRefresh(false);
            setPrevRefresh(false);
            setMenuTvWidgetId(null);
            setMenusToClean([]);
        }
        // eslint-disable-next-line
    }, [cScreenData]);

    useEffect(() => {
        if (deviceType === DEVICE.TYPE.TV && isGeneralTV) {
            dispatch(setEditingMenu(true));
            dispatch(setHiddenMenuVisible(false));
            setTimeout(() => {
                dispatch(setEditingMenu(false));
            }, 100);
        }
        if (isTV && typeMenu === "fixed-menu") {
            dispatch(setEditingMenu(true));
            dispatch(setHiddenMenuVisible(false));
            setTimeout(() => {
                dispatch(setEditingMenu(false));
            }, 100);
        }
        // eslint-disable-next-line
    }, [deviceType]);

    useEffect(() => {
        if (isGeneralTV && previewMenuIdSelected) {
            executeQuery();
            const menu = document.querySelector("#widgetMenuContainer");
            if (menu) {
                menu.style.backgroundColor = "transparent";
            }
            setContainerMenuSize({
                width: "auto",
                height: "auto",
                overflow: "hidden",
                top: 0,
                left: 0,
            });
            dispatch(setHiddenMenuVisible(false));
        }
        // eslint-disable-next-line
    }, [previewMenuIdSelected, deviceType, screenid]);

    useEffect(() => {
        if (isTV) {
            setContainerMenuSize({
                width: "auto",
                height: "auto",
                overflow: "hidden",
                top: 0,
                left: 0,
            });
        }
        // eslint-disable-next-line
    }, [landingid]);

    useEffect(() => {
        dispatch(cleanActionValues());
        setMenuTvWidgetId(null);
        dispatch(setPreviewMenuIdSelected(null));
        setMenusToClean([]);
        // eslint-disable-next-line
    }, [screenid, landingid]);

    useEffect(() => {
        if (styleScreen) {
            if (styleScreen.showHomeMenu) {
                setShowHomeMenu(true);
            } else {
                setShowHomeMenu(false);
            }
        }
    }, [styleScreen, previewMenuIdSelected]);

    useEffect(() => {
        if (values && values["preview-menu-and-background-option-selected"]) {
            dispatch(setPreviewMenuIdSelected(values["preview-menu-and-background-option-selected"]));
        }
        // eslint-disable-next-line
    }, [values]);

    useEffect(() => {
        dispatch(setTypeMenu(typeMenu));
        // eslint-disable-next-line
    }, [typeMenu]);

    useEffect(() => {
        if (
            isGeneralTV &&
            dataMenu &&
            previewMenuIdSelected &&
            dataMenu.contentInterface &&
            dataMenu.contentInterface.pos &&
            dataMenu.contentInterface.style &&
            dataMenu.contentInterface.menu &&
            dataMenu.contentInterface.menu.ID
        ) {
            if (Number(menuTvWidgetId) === Number(dataMenu.contentInterface.menu.ID)) {
                return;
            }
            if (dataMenu?.contentInterface?.menu?.data) {
                //Aquí se calcula la posición que debe tener el menu en el grid definido para la pantalla actual
                const homeGrid = dataMenu?.contentInterface?.devices?.find(
                    (device) => device.type == SCREEN.TYPE.TV
                )?.grid;
                const menuItemId = dataMenu?.contentInterface?.menu?.ID;
                let contentInterfacePos = JSON.parse(dataMenu.contentInterface.pos);
                const menuPosis = contentInterfacePos?.[SCREEN.TYPE.TV].find((item) => item.ID === menuItemId);
                const menuPosisInCScreen = {
                    W: Math.round((cDevice?.grid.cols * menuPosis.W) / homeGrid.cols),
                    H: Math.round((cDevice?.grid.rows * menuPosis.H) / homeGrid.rows),
                };
                contentInterfacePos[SCREEN.TYPE.TV].map((item) => {
                    if (item.ID === menuItemId) {
                        item.H = menuPosisInCScreen.H;
                        item.W = menuPosisInCScreen.W;
                    }
                });
                dataMenu.contentInterface.pos = JSON.stringify(contentInterfacePos);
            }
            const menuItem = arrangeGridItemToAdd({
                unparsedPos: dataMenu.contentInterface.pos,
                idItem: dataMenu.contentInterface.menu.ID,
                itemType: "TVMENU",
                unparsedDataItem: dataMenu.contentInterface.menu.data,
                unparsedStyleItem: dataMenu.contentInterface.menu.widgetStyle,
                deviceType: deviceType,
            });
            dispatch(addGridItem(menuItem));
            if (dataMenu.contentInterface.menu.contentWidgets && dataMenu.contentInterface.menu.pos) {
                dataMenu.contentInterface.menu.contentWidgets.map((item) => {
                    const itemInsideMenu = arrangeGridItemToAdd({
                        unparsedPos: dataMenu.contentInterface.menu.pos,
                        idItem: item.ID,
                        itemType: item.type,
                        unparsedDataItem: item.data,
                        unparsedStyleItem: item.widgetStyle,
                        deviceType: undefined,
                    });
                    dispatch(addGridItem(itemInsideMenu, String(dataMenu.contentInterface.menu.ID)));
                    return item;
                });
            }
            setHasAMenu(true);
        }
        if (
            isGeneralTV &&
            dataMenu &&
            previewMenuIdSelected &&
            dataMenu &&
            dataMenu.contentInterface &&
            dataMenu.contentInterface.menu === null
        ) {
            setHasAMenu(false);
        }
        // eslint-disable-next-line
    }, [dataMenu, deviceType, previewMenuIdSelected]);

    useEffect(() => {
        if (isGeneralTV && !hasAMenu) {
            // eslint-disable-next-line
            menusToClean.map((idMenu) => {
                dispatch(removeMenuInCustomScreens(Number(idMenu)));
            });
        }
        // eslint-disable-next-line
    }, [hasAMenu]);

    useEffect(() => {
        setMenuEditorSize(editorSize);
        // eslint-disable-next-line
    }, [editorSize]);

    useEffect(() => {
        ensureToolbarPositions();
        // eslint-disable-next-line
    }, [widgetToolbarVisible]);

    useEffect(() => {
        if (itemSelected) {
            const toolbar = document.querySelector(`#toolbar_${itemSelected}`);
            if (toolbar) toolbar.classList.remove("invisible");
        }
        if (itemSelected === menuTvWidgetId && typeMenu === "fixed-menu" && !editingMenu) {
            dispatch(setWidgetToolbarVisible(null));
        }
        // eslint-disable-next-line
    }, [itemSelected]);

    useEffect(() => {
        if (cDevice) {
            setTimeout(() => {
                const evt = document.createEvent("UIEvents");
                evt.initUIEvent("resize", true, false, window, 0);
                window.dispatchEvent(evt);
            }, 10);
        }
        // eslint-disable-next-line
    }, [cDevice]);

    useEffect(() => {
        const menuWidgetZone = document.querySelector(`#widget_zone_${menuTvWidgetId}`);
        const menuWidget = document.querySelector(`#widget_${menuTvWidgetId}`);
        if (
            menuWidgetZone &&
            menuWidget &&
            editingMenu === false &&
            typeMenu === "fixed-menu" &&
            !uiWarnings &&
            !resizeWindowMenu
        ) {
            setContainerMenuSize({
                width: menuWidgetZone.offsetWidth,
                height: menuWidgetZone.offsetHeight,
                overflow: "hidden",
                top: menuWidgetZone.getBoundingClientRect().y,
                left: menuWidgetZone.getBoundingClientRect().x,
            });
            menuWidget.style.marginTop = "-" + getComputedStyle(menuWidget).getPropertyValue("top");
            menuWidget.style.marginLeft = "-" + getComputedStyle(menuWidget).getPropertyValue("left");
        } else if (
            ((menuWidgetZone && menuWidget && editingMenu === true) ||
                (menuWidgetZone && menuWidget && editingMenu === false && typeMenu === "hidden-menu" && isGeneralTV) ||
                (menuWidgetZone && menuWidget && resizeWindowMenu === true && editingMenu === false)) &&
            !uiWarnings
        ) {
            menuWidget.style.marginTop = "0px";
            menuWidget.style.marginLeft = "0px";
            setContainerMenuSize({ width: "auto", height: "auto", overflow: "visible" });
        }
    }, [menuTvWidgetId, editingMenu, layoutCopy, resizeWindowMenu]);

    useEffect(() => {
        const menuWidgetZone = document.querySelector(`#widget_zone_${menuTvWidgetId}`);
        const menuWidget = document.querySelector(`#widget_${menuTvWidgetId}`);
        if (
            menuWidgetZone &&
            menuWidget &&
            editingMenu === false &&
            typeMenu === "fixed-menu" &&
            uiWarnings &&
            !resizeWindowMenu
        ) {
            setSaveMenuSize(menuWidgetZone.getBoundingClientRect().y);
            setContainerMenuSize({
                width: menuWidgetZone.offsetWidth,
                height: menuWidgetZone.offsetHeight,
                overflow: "hidden",
                top: menuWidgetZone.getBoundingClientRect().y,
                left: menuWidgetZone.getBoundingClientRect().x,
            });
            menuWidget.style.marginTop = "-" + getComputedStyle(menuWidget).getPropertyValue("top");
            menuWidget.style.marginLeft = "-" + getComputedStyle(menuWidget).getPropertyValue("left");
        } else if (
            ((menuWidgetZone && menuWidget && editingMenu === true) ||
                (menuWidgetZone && menuWidget && editingMenu === false && typeMenu === "hidden-menu" && isGeneralTV) ||
                (menuWidgetZone && menuWidget && resizeWindowMenu === true && editingMenu === false)) &&
            uiWarnings
        ) {
            menuWidget.style.marginTop = "0px";
            menuWidget.style.marginLeft = "0px";
            setContainerMenuSize({ width: "auto", height: "auto", overflow: "visible" });
        }
        // eslint-disable-next-line
    }, [editingMenu, menuTvWidgetId, layoutCopy]);

    useEffect(() => {
        if (saveMenuSize) {
            if (warningsMinimized) {
                setSaveMenuSize(saveMenuSize - (70 - 28));
            } else {
                setSaveMenuSize(saveMenuSize + (70 - 28));
            }
        }
        // eslint-disable-next-line
    }, [warningsMinimized]);

    useLayoutEffect(() => {
        const menuContainer = document.querySelector("#menu-container");

        if (saveMenuSize && menuContainer) {
            setContainerMenuSize({ ...containerMenuSize, top: saveMenuSize });
        }
        // eslint-disable-next-line
    }, [saveMenuSize]);

    //FUNCTIONS
    const ensureToolbarPositions = () => {
        if (itemSelected && editingMenu === true) {
            ensureToolbarPos(itemSelected);
            ensureWidgetOptionsPos(itemSelected, editorSize);
        }
    };

    const hideTools = (contanier) => {
        dispatch(setDesignToolbarVisible(null));
        dispatch(setContainerInEdition(contanier));
        dispatch(setColorPickerReference(null));
        if (widgetToolbarVisible !== "text-settings") {
            dispatch(setWidgetToolbarVisible(null));
        }
    };

    const showItemOptions = (id) => {
        if (editorMode === "PREVIEW") {
            return;
        }
        dispatch(setItemSelected(id));
    };

    const selectItemResized = () => {
        const items = document.querySelectorAll(":hover");
        if (!items) {
            return;
        }
        Array.from(items).map((item) =>
            item.id.indexOf("widget_") > -1 ? dispatch(setItemSelected(item.id.replace("widget_", ""))) : null
        );
    };

    const updateLayout = (layout, oldDragItem, newDragItem) => {
        if (
            oldDragItem.x !== newDragItem.x ||
            oldDragItem.y !== newDragItem.y ||
            oldDragItem.w !== newDragItem.w ||
            oldDragItem.h !== newDragItem.h
        ) {
            //check if new posics are inside the edition area
            let widgetMenuContainer = document.querySelector("#widgetMenuContainer");
            let widget = document.querySelector(`#widget_${newDragItem.i}`);
            const editorPos = widgetMenuContainer.getClientRects()[0];
            const widgetPos = widget.getClientRects()[0];
            const prevPos = {
                x: oldDragItem.x,
                y: oldDragItem.y,
                w: oldDragItem.w,
                h: oldDragItem.h,
            };
            const newPos = {
                x: newDragItem.x,
                y: newDragItem.y,
                w: newDragItem.w,
                h: newDragItem.h,
            };

            //Si al hacer el resize el widget queda fuera del editor, se cancela
            if (widgetPos.y + widgetPos.height > editorPos.y + editorPos.height) {
                dispatch(updateGridItemPos(newDragItem.i, newPos));

                setTimeout(() => {
                    dispatch(updateGridItemPos(oldDragItem.i, prevPos));
                    toast.error(t("place-into-edition-area"));
                }, 100);

                return;
            }
            ensureToolbarPositions();
            dispatch(
                setExecuteQuery({
                    action: "updateWidgetPos",
                    params: {
                        widgetID: newDragItem.i,
                        widgetPos: newPos,
                        prevWidgetPos: prevPos,
                    },
                })
            );
        }
    };

    const onDragStart = (e) => {
        if (itemSelected) {
            const toolbar = document.querySelector(`#toolbar_${itemSelected}`);
            const editorToolbar = document.querySelector(`#widget_${itemSelected} .rdw-editor-toolbar`);
            if (toolbar) toolbar.classList.add("invisible");
            if (editorToolbar) editorToolbar.style.visibility = "hidden";
        }
    };

    const onDragStop = (layout, oldDragItem, newDragItem) => {
        setTimeout(() => {
            if (itemSelected) {
                const toolbar = document.querySelector(`#toolbar_${itemSelected}`);
                const editorToolbar = document.querySelector(`#widget_${itemSelected} .rdw-editor-toolbar`);
                if (toolbar) toolbar.classList.remove("invisible");
                if (editorToolbar) editorToolbar.style.visibility = "visible";
                ensureToolbarPositions();
            }
        }, 500);
        updateLayout(layout, oldDragItem, newDragItem);
    };

    return editingMenu ||
        (typeMenu === "fixed-menu" && isTV) ||
        (typeMenu === "hidden-menu" && isGeneralTV && hiddenMenuVisible && showHomeMenu && previewMenuIdSelected) ||
        (typeMenu === "fixed-menu" && isGeneralTV && showHomeMenu && previewMenuIdSelected) ? (
        <div
            className="relative"
            id="menu-container"
            style={{
                position: "fixed",
                top: `${containerMenuSize && containerMenuSize.top ? containerMenuSize.top + "px" : ""}`,
                left: `${containerMenuSize && containerMenuSize.left ? containerMenuSize.left + "px" : ""}`,
            }}
        >
            {itemSelected === menuTvWidgetId && editingMenu === false && typeMenu !== "hidden-menu" ? (
                <div
                    id={`item_label_menu_tv`}
                    className={`z-500 absolute top-0 text-base `}
                    style={{ rotate: "-90deg", marginTop: "1.5rem", marginLeft: "-4.1rem" }}
                >
                    <div className="flex items-center">
                        <div
                            style={{ borderRadius: "0.5rem 0.5rem 0px 0px" }}
                            className="rounded bg-zafiro-400 inline-block px-6 py-2 text-base text-white"
                        >
                            <span className="capitalize">{t("menu")}</span>
                        </div>
                    </div>
                </div>
            ) : null}
            <div
                id="widgetMenuContainer"
                style={{
                    position: "absolute",
                    width: containerMenuSize.width,
                    height: containerMenuSize.height,
                    overflow: containerMenuSize.overflow,
                }}
                className={`${
                    itemSelected === menuTvWidgetId && editingMenu === false && typeMenu !== "hidden-menu"
                        ? "widgetSelected"
                        : ""
                }`}
            >
                {blurBackground ? <div className="blur absolute left-0 top-0 h-full w-full"></div> : null}
                <ReactGridLayout
                    className="widget-menu-container"
                    style={{
                        height:
                            menuEditorSize && menuEditorSize.screenAreaHeight ? menuEditorSize.screenAreaHeight : "0px",
                        width:
                            menuEditorSize && menuEditorSize.screenAreaWidth ? menuEditorSize.screenAreaWidth : "0px",
                    }}
                    layout={layoutCopy}
                    cols={menuEditorSize && menuEditorSize.cols ? menuEditorSize.cols : colsMenuEditor}
                    rowHeight={menuEditorSize && menuEditorSize.rowHeight ? menuEditorSize.rowHeight : rowsMenuEditor}
                    useCSSTransforms={false}
                    isBounded={true}
                    containerPadding={[0, 0]}
                    compactType={null}
                    isDraggable={false}
                    preventCollision={true}
                    isDroppable={false}
                    resizeHandles={["se"]}
                    draggableHandle={".grid-item-label"}
                    onResizeStart={selectItemResized}
                    onResize={ensureToolbarPositions}
                    onResizeStop={updateLayout}
                    onDragStart={onDragStart}
                    onDragStop={onDragStop}
                >
                    {layoutCopy.map((copy) => {
                        const copyID = copy.i;
                        const type = copy.type;
                        const draggable = copy.isDraggable;
                        const iWidget = gridItems.filter((item) => item.i === copyID)[0];
                        const cStyle = Widget.ParseStyle(iWidget ? iWidget.widgetStyle : null, deviceType);
                        const style = new WidgetStyle(type, theme);
                        style.fill(cStyle);

                        return (
                            <div
                                id={`widget_${copyID}`}
                                key={`${copyID}`}
                                onClick={(e) => {
                                    hideTools(copyID);
                                    showItemOptions(copyID);
                                    e.stopPropagation();
                                    dispatch(setWidgetToolbarVisible(null));
                                }}
                                onMouseDown={(e) => e.stopPropagation()}
                                className={`react-grid-layout relative mb-40 ${
                                    itemSelected === copyID ? "widgetSelected" : ""
                                } ${editorMode === "PREVIEW" || editingMenu === false ? "previewMode" : ""}`}
                            >
                                <WidgetProvider
                                    value={{
                                        id: copyID || 0,
                                        type,
                                        style: cStyle,
                                        config: {
                                            style,
                                            data: null,
                                        },
                                    }}
                                >
                                    <Widget.Zone>
                                        {type === "TVMENU" ? <WidgetTVMenu widgets={widgets} /> : null}
                                    </Widget.Zone>
                                    <GridItemToolbar />
                                    {(itemSelected === copyID && editingMenu === true) ||
                                    (itemSelected === copyID &&
                                        editingMenu === false &&
                                        isGeneralTV &&
                                        typeMenu === "hidden-menu") ? (
                                        <div
                                            id={`item_label_menu_tv`}
                                            data-menu-id={menuTvWidgetId}
                                            className={`${
                                                draggable ? "grid-item-label cursor-move" : "cursor-default"
                                            }  z-500 absolute top-0 -ml-16 text-base `}
                                            style={{ rotate: "-90deg", marginTop: "1.7rem" }}
                                        >
                                            <div className="flex items-center">
                                                <div
                                                    style={{ borderRadius: "0.5rem 0.5rem 0px 0px" }}
                                                    className="rounded bg-zafiro-400 inline-block px-6 py-2 text-base text-white"
                                                >
                                                    <span className="capitalize">
                                                        {type === "TVMENU" ? t("menu") : "-"}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </WidgetProvider>
                            </div>
                        );
                    })}
                </ReactGridLayout>
            </div>
        </div>
    ) : null;
};

export default MenuEditor;
