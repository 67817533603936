import React, { useContext, useState, useEffect, useLayoutEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { gql } from "apollo-boost";
import { toast } from "react-toastify";
import RGL, { WidthProvider } from "react-grid-layout";

//Components
import Widget from "./Widgets/Widget";
import TabBar from "./Widgets/TabBar";
import GridItemToolbar from "./Widgets/WidgetToolbars/GridItemToolbar";
import DesignToolbar from "../../../components/Section/Design/DesignToolbar";
import ScreenToolbar from "../../../components/Section/Design/Widgets/WidgetToolbars/ScreenToolbar";
import DesignSubmenu from "../../../components/Section/Design/DesignSubmenu";
import DevicesSubmenu from "../../../components/Section/Design/DevicesSubmenu";
import { EditorProvider, WidgetProvider } from "../../../contexts/editor";
import ResizeListener from "./ResizeListener";
import WidgetStyle from "zrender/widgetStyle";
import ReactTooltip from "components/ReactTooltip";

//Actions
import {
    setItemSelected,
    setWidgetToolbarVisible,
    setDesignToolbarVisible,
    setCurrentScreenData,
    setCurrentDevice,
    setEditorMode,
    updateGridItemPos,
    setContainerInEdition,
    setColorPickerReference,
    setWidgetToolbarEnabled,
    setEditingMenu,
    setWidgetMenuColorsAndAnimations,
} from "../../../actions/designActions";
import {
    openSidebar,
    closeSidebar,
    setSectionStyle,
    showGlobalLoading,
    showMainMenu,
} from "../../../actions/uiActions";

import {
    setRefreshContentData,
    setExecuteQuery,
    setSectionContent,
    cleanSectionContent,
} from "../../../actions/sectionActions";

//Utils
import {
    ensureToolbarPos,
    calculateEditorSizes,
    ensureWidgetOptionsPos,
    GetThemeDefault,
    scaleFontSize,
} from "../../../hooks/Utils/DesignUtils";
import UpdateDesign from "../../../hooks/GraphqlCalls/Design/UpdateDesign";

//API
import { Session } from "../../../hooks/Utils/Session";
import { GetDeviceLayoutPosition, GetScreenDeviceSizes } from "../../../hooks/Utils/Widgets";
import CustomColorPicker from "./Widgets/WidgetToolbars/OptionsDetails/CustomColorPicker";
import { useMSQuery } from "../../../hooks/GraphqlCalls/useMSQuery";
import useTheme from "../../../hooks/GraphqlCalls/useTheme";
import { contentData } from "../../../hooks/Utils/Utils";
import WelcomeSequenceSubMenu from "./WelcomeSequenceSubMenu";
import { SCREEN, DEVICE, WELCOME_SEQUENCE, WIDGET } from "constants/editor";
import {
    EDITOR_MODE,
    calculateTopMobileCoverPos,
    getLandingContentQuery,
    getMenuColorsAndAnimations,
    getSalesContentQuery,
    getWelcomeContentQuery,
    returnWidgetByType,
} from "../../../hooks/Utils/Design/EditorUtils";
import WelcomeOKToContinue from "./WelcomeOKToContinue";
import MenuEditor from "./Widgets/MenuEditor";
import UpdateTVMenuNav from "../../../hooks/GraphqlCalls/Design/UpdateTVMenuNav";
import UpdateWidgetItem from "../../../hooks/GraphqlCalls/Design/UpdateWidgetItem";
import KeyControl from "./Widgets/KeyControl";
import SalesEditor from "./SalesEditor";
import { SALES_TOOLBAR, parseSalesPredefinedScreenData } from "../../../hooks/Utils/Design/SalesUtils";

import { GlobalContext } from "contexts/Global";
import { useAuth } from "hooks/Session/auth";

const ReactGridLayout = WidthProvider(RGL);

const ScreensEditor = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { warnings: uiWarnings, project } = useContext(GlobalContext);

    const { id: designID, screenid, type: screenType, lang, landingid } = useParams();

    const { exec: queryTheme, loading: loadingTheme, reload: refreshTheme, data: theme } = useTheme({ designID });

    //Store data
    const {
        gridLayout,
        itemSelected,
        droppingItem,
        widgetToolbarVisible,
        gridItems,
        cScreenData,
        cDevice,
        editorMode,
        widgets,
        editingMenu,
        widgetMenuColorsAndAnimations,
        previewMenuIdSelected,
        tvScreensData,
    } = useSelector((state) => state.design);

    //States
    const [isWelcome, setIsWelcome] = useState(screenType === SCREEN.TYPE.WELCOME);
    const [editorSize, setEditorSize] = useState(calculateEditorSizes(cDevice, cScreenData, null, isWelcome));

    const [screenLayout, setScreenLayout] = useState(null);
    const [blockEditionText, setBlockEditionText] = useState(null);
    const [warnings, setWarnings] = useState({});
    const [isOnDrag, setIsOnDrag] = useState(false);
    const [isOnResize, setIsOnResize] = useState(false);
    const [fetchTheme, setFetchTheme] = useState(null);

    const [isUsingHomeBg, setIsUsingHomeBg] = useState(false);
    const [showHomeMenu, setShowHomeMenu] = useState(null);
    const [styleBgImg, setStyleBgImage] = useState(null);
    const [styleBg, setStyleBg] = useState(null);
    const [styleOpacity, setStyleOpacity] = useState(1);

    const { permissions, warningsMinimized } = useSelector((state) => state.ui);
    const { languages: projectLangs } = useAuth();

    const { isImpersonating } = useAuth();
    const { refreshData } = useSelector((state) => state.sectionContent);
    const projectRef = project?.ref;
    const ensureToolbarPositions = useCallback(() => {
        if (itemSelected) {
            ensureToolbarPos(itemSelected);
            ensureWidgetOptionsPos(itemSelected, editorSize);
        }
    }, [itemSelected, editorSize]);
    const isCustType =
        screenType === "GENERAL" || screenType === "TV" || screenType === "MOBILE" || screenType === "PORTAL";

    const deviceType = cDevice?.type;

    const isMobile = deviceType === DEVICE.TYPE.MOBILE;
    const isGeneralTV = screenType === SCREEN.TYPE.GENERAL && deviceType === DEVICE.TYPE.TV;
    const isGeneralMobile = screenType === SCREEN.TYPE.GENERAL && deviceType === DEVICE.TYPE.MOBILE;
    const isGeneral = screenType === SCREEN.TYPE.GENERAL;
    const isTV = screenType === SCREEN.TYPE.TV;
    const isDesktop = deviceType === DEVICE.TYPE.DESKTOP;

    let widgetTopBar = null;
    let widgetRooms = null;
    // eslint-disable-next-line
    let widgetMenu = null;

    const finalLayout = screenLayout
        ? screenLayout.filter((item) => {
              const type = item.type;
              const isTopBar = type === "TOPBAR";
              const isRooms = type === "ROOMS";
              const isTvMenu = type === "TVMENU";
              const isWiFi = type === "WIFILOGIN";
              if (isTopBar || isRooms || isTvMenu) {
                  if (isTopBar) widgetTopBar = item;
                  if (isRooms) widgetRooms = item;
                  if (isTvMenu) widgetMenu = item;
                  return null;
              }
              item.isDraggable = Object.keys(item).includes("isDraggable")
                  ? item.isDraggable
                  : editorMode === EDITOR_MODE.EDITION;
              item.isDroppable = item.isDraggable;
              //   item.isResizable = item.isResizable;
              item.resizeHandles = item.isResizable ? ["se"] : null;

              if (isWiFi) {
                  item.minW = Math.ceil(Math.max(editorSize.cols * (isMobile ? 1 : 0.3), 1));
                  item.minH = Math.ceil(Math.max(editorSize.rows * (isMobile ? 0.5 : 0.4), 1));
              }
              return item;
          })
        : null;

    const isSalesEditor = screenType === SCREEN.TYPE.SALES || screenType === SCREEN.TYPE.SALESMOBILE;
    const isContentEditor =
        !isSalesEditor && cScreenData && finalLayout && editorSize && editorSize.height && cDevice ? true : false;

    const isCorporate = projectRef === "CORPORATE";

    const CONTENT_QUERY = screenid
        ? `content(id:"${screenid || 0}", designID:"${designID || 0}" , lang:"${lang || "en"}"){
        results{
            ${contentData()}                             
        }
    }`
        : null;

    const getQuery = () => {
        if (isWelcome) {
            return designID && landingid ? getWelcomeContentQuery({ welcomeId: landingid, designID, lang }) : null;
        } else if (isSalesEditor) {
            return designID && screenid ? getSalesContentQuery({ salesId: screenid, designID, lang }) : null;
        } else if (landingid) {
            return designID && landingid ? getLandingContentQuery({ landingid, designID, lang }) : null;
        }
        return CONTENT_QUERY;
    };
    // This dummyQuery is a patch to avoid query failure when screen is deleted. Remove when the code is refactored
    const dummyQuery = "languages(page: 1, size: 1) { info { page } }";
    const [queryContent, { data: content, error, loading: loadingScreen, networkStatus }] = useMSQuery(
        gql`{${getQuery() || dummyQuery}}`,
        {
            fetchPolicy: "network-only",
        }
    );

    const isReady = networkStatus === 7;

    const widgetMenuID = gridLayout ? gridLayout.find((widget) => widget.type === "TVMENU") : null;
    const widgetMenuInfo = widgetMenuID ? gridItems.find((item) => Number(item.i) === Number(widgetMenuID.i)) : null;
    const widgetMenuData = widgetMenuInfo?.data ? widgetMenuInfo.data : null;
    const widgetMenuStyles = widgetMenuInfo?.widgetStyle ? widgetMenuInfo.widgetStyle : null;
    const marginLeftMenuContainer = `-${editorSize && editorSize.width / 2 - 30}px`;
    const widgetMenuType = widgetMenuData?.menuType ? widgetMenuData.menuType : "none";

    const updateLayout = useCallback(
        (layout, oldDragItem, newDragItem) => {
            if (
                oldDragItem.x !== newDragItem.x ||
                oldDragItem.y !== newDragItem.y ||
                oldDragItem.w !== newDragItem.w ||
                oldDragItem.h !== newDragItem.h
            ) {
                const prevPos = {
                    x: oldDragItem.x,
                    y: oldDragItem.y,
                    w: oldDragItem.w,
                    h: oldDragItem.h,
                };
                let newPos = {
                    x: newDragItem.x,
                    y: newDragItem.y,
                    w: newDragItem.w,
                    h: newDragItem.h,
                };

                //check if new posics are inside the edition area
                let editorContainer = document.querySelector(`#editor_screen_area`);
                let widget = document.querySelector(`#widget_${newDragItem.i}`);
                if (editorContainer && widget) {
                    const editorPos = editorContainer.getClientRects()[0];
                    const widgetPos = widget.getClientRects()[0];

                    //Si al hacer el resize el widget queda fuera del editor, se cancela
                    const maxHeight = editorPos.height * (isMobile ? 10 : 1) - cScreenData.contentStyle.margin.h;
                    if (widgetPos.y + widgetPos.height > editorPos.y + maxHeight) {
                        // max height of widget to editor screen
                        if (newPos.h === prevPos.h && newPos.w === prevPos.w) {
                            newPos.y = cDevice.grid.rows - newPos.h;
                        } else {
                            newPos.h = cDevice.grid.rows - newPos.y;
                        }
                        dispatch(updateGridItemPos(newDragItem.i, newPos));
                    }
                }

                ensureToolbarPositions();
                dispatch(
                    setExecuteQuery({
                        action: "updateWidgetPos",
                        params: {
                            widgetID: newDragItem.i,
                            widgetPos: newPos,
                            prevWidgetPos: prevPos,
                        },
                    })
                );
            }
        }, // eslint-disable-next-line
        [dispatch, ensureToolbarPositions, isMobile, t]
    );

    const screenDevicesData = cScreenData && cScreenData.data ? cScreenData.data.devices : null;
    const deviceData =
        screenDevicesData && deviceType ? screenDevicesData.filter((d) => d.type === deviceType)[0] : null;
    const maxX = deviceData && deviceData.grid ? deviceData.grid.cols : 0;
    const maxY = deviceData && deviceData.grid ? deviceData.grid.rows : 0;

    const showTopBar = isMobile && widgetTopBar;
    const showRooms = isMobile && widgetRooms;
    const showTabBar = showTopBar;

    const style = cScreenData ? cScreenData.contentStyle : null;
    const custPreview =
        tvScreensData && tvScreensData.filter((screen) => Number(screen.id) === Number(previewMenuIdSelected))[0];
    const stylesCust = custPreview && custPreview.homeBgStyles ? JSON.parse(custPreview.homeBgStyles) : null;

    const paddingX = style && style.margin ? style.margin.w : 10;
    const paddingY = style && style.margin ? style.margin.h : 10;
    const itemsPaddingX = style && style.itemsMargin ? style.itemsMargin.w : 10;
    const itemsPaddingY = style && style.itemsMargin ? style.itemsMargin.h : 10;

    const bgImage = styleBgImg
        ? styleBgImg.externalUrl
            ? styleBgImg.externalUrl
            : styleBgImg.libraryRef
            ? Session.getDasUrl(styleBgImg.libraryRef + "?lang=" + lang)
            : null
        : null;

    const handleResize = () => {
        calculateEditorSizes(cDevice, cScreenData, setEditorSize, isWelcome);
    };

    //KeyControl to manage keyboard arrows and SUPR key
    KeyControl();

    //Listeners
    const hideTools = () => {
        dispatch(setDesignToolbarVisible(null));
        dispatch(setContainerInEdition(null));
        dispatch(setColorPickerReference(null));
        if (widgetToolbarVisible !== "text-settings") {
            dispatch(setWidgetToolbarVisible(null));
        }
    };

    useEffect(() => {
        if (
            screenType === SCREEN.TYPE.WELCOME ||
            screenType === SCREEN.TYPE.SALES ||
            screenType === SCREEN.TYPE.SALESMOBILE
        ) {
            dispatch(setWidgetToolbarEnabled(false));
        } else {
            dispatch(setWidgetToolbarEnabled(true));
        }
        setIsWelcome(screenType === SCREEN.TYPE.WELCOME);
        // eslint-disable-next-line
    }, [screenType]);

    useLayoutEffect(() => {
        handleResize();
        // eslint-disable-next-line
    }, [isWelcome]);

    /*
    useLayoutEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [cDevice, cScreenData, isWelcome, setEditorSize]);
    */

    useLayoutEffect(() => {
        if (style && (style.margin || style.itemsMargin)) {
            handleResize();
        }
        // eslint-disable-next-line
    }, [cScreenData]);

    useEffect(() => {
        if (isGeneral) {
            setStyleBg(null);
            setIsUsingHomeBg(false);
            setStyleOpacity(1);
        }
        // eslint-disable-next-line
    }, [screenid, landingid]);

    useEffect(() => {
        if (style && !isUsingHomeBg) {
            if (style.images && style.images.length > 0) {
                setStyleBgImage(style.images[0]);
            } else {
                setStyleBgImage(null);
            }
            if (style.bgColor) {
                setStyleBg(style.bgColor);
            } else {
                setStyleBg(null);
            }
            if (style.opacity) {
                setStyleOpacity(style.opacity / 100);
            } else {
                setStyleOpacity(1);
            }
        } else if (!isUsingHomeBg) {
            setStyleBgImage(null);
            setStyleBg(null);
            setStyleOpacity(1);
        }
        if (isGeneral === false) {
            setIsUsingHomeBg(false);
        }
        // eslint-disable-next-line
    }, [style, screenType, isUsingHomeBg]);

    useEffect(() => {
        if (isUsingHomeBg === true && custPreview && isGeneralTV) {
            if (stylesCust) {
                if (stylesCust.images && stylesCust.images.length > 0) {
                    setStyleBgImage(stylesCust.images[0]);
                } else {
                    setStyleBgImage(null);
                }
                if (stylesCust.bgColor) {
                    setStyleBg(stylesCust.bgColor);
                } else {
                    setStyleBg(null);
                }
                if (stylesCust.opacity) {
                    setStyleOpacity(stylesCust.opacity / 100);
                } else {
                    setStyleOpacity(1);
                }
            } else {
                setStyleBgImage(null);
                setStyleBg(null);
                setStyleOpacity(1);
            }
        } else if (isUsingHomeBg && !custPreview && style && isGeneralTV) {
            if (style.images && !style.useHomeBackground && style.images.length > 0) {
                setStyleBgImage(style.images[0]);
            } else {
                setStyleBgImage(null);
            }
            if (style.bgColor && !style.useHomeBackground) {
                setStyleBg(style.bgColor);
            } else {
                setStyleBg(null);
            }
            if (style.opacity) {
                setStyleOpacity(style.opacity / 100);
            } else {
                setStyleOpacity(1);
            }
        }
        // eslint-disable-next-line
    }, [custPreview, screenType, isUsingHomeBg, previewMenuIdSelected, showHomeMenu, deviceType]);

    useEffect(() => {
        if (isGeneralMobile && style) {
            if (style.images && style.images.length > 0) {
                setStyleBgImage(style.images[0]);
            } else {
                setStyleBgImage(null);
            }
            if (style.bgColor) {
                setStyleBg(style.bgColor);
            } else {
                setStyleBg(null);
            }
            if (style.opacity) {
                setStyleOpacity(style.opacity / 100);
            } else {
                setStyleOpacity(1);
            }
        }
    }, [isGeneralMobile, style]);

    useEffect(() => {
        if (style) {
            if (style.useHomeBackground) {
                setIsUsingHomeBg(true);
            } else {
                setIsUsingHomeBg(false);
            }
        }
        if (style) {
            if (style.showHomeMenu) {
                setShowHomeMenu(true);
            } else {
                setShowHomeMenu(false);
            }
        }
        // eslint-disable-next-line
    }, [cScreenData, previewMenuIdSelected]);

    useEffect(() => {
        const storageDesignList = JSON.parse(Session.getSessionProp("designList"));
        dispatch(closeSidebar());
        dispatch(setSectionStyle("w-full  absolute px-0 py-0"));
        dispatch(showGlobalLoading(false));
        dispatch(showMainMenu(false));

        //set editor mode
        let mode = EDITOR_MODE.EDITION;
        if (storageDesignList && designID) {
            const cDesign = storageDesignList.filter((design) => parseInt(design.id) === parseInt(designID))[0];
            if (cDesign && cDesign.type === "CORPORATE") {
                mode = isCorporate ? EDITOR_MODE.EDITION : EDITOR_MODE.PREVIEW;
                setBlockEditionText(t("you-cannot-edit-screen"));
            } else if (cDesign && cDesign.type === "LOCAL" && isCorporate) {
                mode = EDITOR_MODE.PREVIEW;
                setBlockEditionText(t("corporate-cannot-edit-screen"));
            }
        }

        dispatch(setEditorMode(mode));

        return () => {
            dispatch(setItemSelected(null));
            dispatch(showMainMenu(true));
            dispatch(openSidebar());
            dispatch(setSectionStyle(null));
            dispatch(setCurrentDevice(null));
            dispatch(setDesignToolbarVisible(null));
            dispatch(setCurrentScreenData(null));
            if (window.Render) {
                window.Render.exit();
            }
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (designID !== fetchTheme?.designID && !fetchTheme?.isFetched && designID && screenid) {
            setFetchTheme({ designID: designID, isFetched: true });
        }

        if (!fetchTheme && queryTheme) {
            queryTheme();
        }
    }, [designID, screenid, fetchTheme]);

    useLayoutEffect(() => {
        if (cDevice) {
            handleResize();

            //Sin esta mierda la librería RGL no refresca bien los items del layout
            setTimeout(() => {
                const evt = document.createEvent("UIEvents");
                evt.initUIEvent("resize", true, false, window, 0);
                window.dispatchEvent(evt);
            }, 10);
        }
        // eslint-disable-next-line
    }, [cDevice]);

    useEffect(() => {
        if (screenid || landingid) {
            queryContent();
        }
    }, [designID, screenid, landingid, lang]);

    useEffect(() => {
        if (isTV || isGeneral) {
            dispatch(
                setWidgetMenuColorsAndAnimations(
                    getMenuColorsAndAnimations(widgetMenuStyles, theme, GetThemeDefault, widgetMenuData)
                )
            );
        } else {
            dispatch(setWidgetMenuColorsAndAnimations(null));
        }
        // eslint-disable-next-line
    }, [JSON.stringify(widgetMenuColorsAndAnimations), theme, cScreenData, gridItems]);

    useEffect(() => {
        if (theme && window.Render && cDevice && editorSize) {
            window.Render.parseManagerData = true;
            window.Render.dasURL = Session.getDasUrl() + "/";
            window.Render.castURL = Session.getCastUrl();
            window.Render.widgetContainerIdentifier = `widget_zone_{id}`;
            window.Render.preventWidgetContainerStyles = true;
            window.Render.screenSizes = editorSize;
            window.Render.init(lang, null, theme, null, theme.logos, null, widgetMenuColorsAndAnimations, cDevice.type);
        }
        // eslint-disable-next-line
    }, [editorSize, cDevice, theme, lang, widgetMenuColorsAndAnimations]);

    useEffect(() => {
        dispatch(showGlobalLoading(loadingScreen || loadingTheme));
        // eslint-disable-next-line
    }, [loadingScreen, loadingTheme]);

    useEffect(() => {
        // const w = screenLayout ? screenLayout.filter((item) => item.i === itemSelected)[0] : null;
        // dispatch(setWidgetToolbarVisible(null, "regular"));
        // eslint-disable-next-line
    }, [itemSelected]);

    useEffect(() => {
        if (refreshData) {
            refreshTheme();
            queryContent();
            // if (typeof refreshContent === "function") {
            //     refreshContent();
            // }
            dispatch(setRefreshContentData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        //Se utiliza esto en lugar de gridLayout directamente porque
        //sino la librería no renderiza bien los items cuando hay cambios en gridlayout
        if (gridLayout) {
            const layout = JSON.parse(JSON.stringify(gridLayout));
            setScreenLayout(
                layout
                    ? layout.filter((item) => {
                          const type = item.type;
                          const isTopBar = type === "TOPBAR";
                          const isRooms = type === "ROOMS";
                          const isTvMenu = type === "TVMENU";
                          if (
                              !isTopBar &&
                              !isRooms &&
                              !isTvMenu &&
                              deviceType !== "Mobile" &&
                              deviceType !== "Desktop"
                          ) {
                              // No scroll
                              const itemMaxX = Math.min(
                                  item.x + item.w,
                                  deviceData && deviceData.grid && deviceData.grid.cols ? deviceData.grid.cols : 0
                              );
                              const itemMaxY = Math.min(
                                  item.y + item.h,
                                  deviceData && deviceData.grid && deviceData.grid.rows ? deviceData.grid.rows : 0
                              );
                              if (maxX > 0 && maxY > 0 && (itemMaxX > maxX || itemMaxY > maxY)) {
                                  //   console.log(
                                  //       `Widget ID ${item.i} (${item.type}) is out of ${
                                  //           itemMaxX > maxX ? "horizontal" : "vertical"
                                  //       } limits`
                                  //   );
                                  // Intentamos reubicarlo
                                  const pos =
                                      cScreenData && cScreenData.data
                                          ? GetDeviceLayoutPosition({
                                                widgetType: item.type,
                                                deviceType,
                                                devicePosition: { w: item.w, h: item.h },
                                                screen: cScreenData.data,
                                            })
                                          : null;
                                  if (pos == null) {
                                      return null;
                                  }
                                  const oldLayoutItem = { ...item };
                                  console.log(
                                      `Widget ID ${item.i} (${item.type}) relocated to ${pos.x}:${pos.y} with new size of ${pos.w}x${pos.h}`
                                  );
                                  item.w = pos.w;
                                  item.h = pos.h;
                                  item.x = pos.x;
                                  item.y = pos.y;
                                  updateLayout(null, oldLayoutItem, item);
                              }
                          }
                          return item;
                      })
                    : null
            );
        }
        // eslint-disable-next-line
    }, [gridLayout, deviceType, cScreenData, maxX, maxY, updateLayout]);

    useEffect(() => {
        const products = permissions?.product;
        const tvEnabled = products && products.tv;
        const mobileEnabled = products && products.mobile;

        const castEnabled = products && products.cast;
        const wifiEnabled = products && products.wifi;

        dispatch(showGlobalLoading(false));
        const langs = projectLangs;
        if (content?.content?.results && isReady && !isWelcome && !isSalesEditor) {
            const contentResult = landingid ? content.content.results[0].content : content.content.results[0];
            if (landingid) {
                Session.setSessionProp("landingId", content.content.results[0].id);
            } else {
                Session.removeSessionProp("landingId");
            }

            const screenStyle = contentResult?.contentStyle ? JSON.parse(contentResult.contentStyle) : null;
            dispatch(
                setCurrentScreenData({
                    contentStyle: screenStyle
                        ? screenStyle
                        : {
                              margin: {
                                  h: 10,
                                  w: 10,
                              },
                              itemsMargin: {
                                  h: 10,
                                  w: 10,
                              },
                          },
                    data: contentResult,
                    landing: landingid
                        ? {
                              name: content.content.results[0].name,
                              isEnabled: content.content.results[0].isEnabled,
                          }
                        : null,
                    screenType,
                    ptarget: content.content.results[0].ptarget,
                    langs: langs,
                })
            );
            if (!cDevice && contentResult?.devices) {
                const filteredDevices = contentResult.devices.filter((d) => {
                    switch (d.type) {
                        case "Mobile":
                            return isCustType ? mobileEnabled || wifiEnabled : mobileEnabled || castEnabled;
                        case "Desktop":
                            return wifiEnabled;
                        case "TV":
                            return isCustType ? tvEnabled : tvEnabled || castEnabled;
                        default:
                            return false;
                    }
                });

                if (screenType === SCREEN.TYPE.PORTAL) {
                    dispatch(setCurrentDevice(filteredDevices[1]));
                } else {
                    dispatch(setCurrentDevice(filteredDevices[0]));
                }
            }
        } else if (content?.content?.results && isReady && isWelcome) {
            dispatch(setCurrentDevice(GetScreenDeviceSizes({ screenType })));
            // get content screen from sequence
            const welcomeContentResult = content.content.results[0]?.welcomeContents.find(
                (welcomeContent) => welcomeContent.contentId === parseInt(screenid)
            );
            if (!welcomeContentResult) {
                dispatch(setCurrentScreenData(null));
                return;
            }
            const welcomeScreenStyle = welcomeContentResult.content.contentStyle
                ? JSON.parse(welcomeContentResult.content.contentStyle)
                : null;
            if (welcomeContentResult.contentType === "WIDGETS") {
                dispatch(setWidgetToolbarEnabled(true));
            } else {
                dispatch(setWidgetToolbarEnabled(false));
            }
            dispatch(
                setCurrentScreenData({
                    contentStyle: welcomeScreenStyle
                        ? welcomeScreenStyle
                        : {
                              margin: {
                                  h: 10,
                                  w: 10,
                              },
                              itemsMargin: {
                                  h: 10,
                                  w: 10,
                              },
                          },
                    data: {
                        ...welcomeContentResult,
                        devices: welcomeContentResult.content.devices,
                        contentWidgets: welcomeContentResult.content.contentWidgets,
                        pos: welcomeContentResult.content.pos,
                        name: content.content.results[0].name,
                        isEnabled: content.content.results[0].isEnabled,
                    },
                    welcome: {
                        name: content.content.results[0].name,
                        sequence: content.content.results[0].welcomeContents,
                        isEnabled: content.content.results[0].isEnabled,
                    },
                    ptarget: content.content.results[0].ptarget,
                    langs: langs,
                })
            );
        } else if (content?.content && isSalesEditor && content.content.id === Number(screenid)) {
            dispatch(cleanSectionContent());
            dispatch(setSectionContent({ salesToolbar: SALES_TOOLBAR.SETTINGS }));
            dispatch(
                setSectionContent({
                    screenIds: { salesId: screenid, designID },
                    isPublished: content.content.isPublished,
                })
            );
            dispatch(setCurrentDevice(GetScreenDeviceSizes({ screenType })));
            dispatch(
                setCurrentScreenData({
                    contentStyle: {
                        margin: {
                            h: 10,
                            w: 10,
                        },
                        itemsMargin: {
                            h: 10,
                            w: 10,
                        },
                    },
                    langs: langs,
                    data: { name: content.content.name, devices: [GetScreenDeviceSizes({ screenType })] },
                })
            );
            dispatch(setSectionContent(parseSalesPredefinedScreenData(screenType, content.content.data, theme)));
        } else {
            dispatch(setCurrentScreenData(null));
        }
        // eslint-disable-next-line
    }, [content, networkStatus]);

    //set best position for toolbar
    useEffect(() => {
        ensureToolbarPositions();
        // eslint-disable-next-line
    }, [itemSelected]);

    useEffect(() => {
        ensureToolbarPositions();
        // eslint-disable-next-line
    }, [widgetToolbarVisible]);

    useEffect(() => {
        dispatch(setEditingMenu(false));
        // eslint-disable-next-line
    }, [designID]);

    const layoutChange = (layout) => {
        //mientras hago drag de elementos externos no actualizo layout
        if (layout.filter((item) => item.i === "__dropping-elem__").length > 0) {
            return;
        }
    };

    const onDragStart = (e) => {
        setIsOnDrag(true);
        if (itemSelected) {
            const toolbar = document.querySelector(`#toolbar_${itemSelected}`);
            const editorToolbar = document.querySelector(`#widget_${itemSelected} .rdw-editor-toolbar`);
            if (toolbar) toolbar.classList.add("invisible");
            if (editorToolbar) editorToolbar.style.visibility = "hidden";
        }
        //  setItemSelected(null);
    };

    const onDragStop = (layout, oldDragItem, newDragItem) => {
        setIsOnDrag(false);
        setTimeout(() => {
            if (itemSelected) {
                const toolbar = document.querySelector(`#toolbar_${itemSelected}`);
                const editorToolbar = document.querySelector(`#widget_${itemSelected} .rdw-editor-toolbar`);
                if (toolbar) toolbar.classList.remove("invisible");
                if (editorToolbar) editorToolbar.style.visibility = "visible";
                ensureToolbarPositions();
            }
        }, 500);
        updateLayout(layout, oldDragItem, newDragItem);
    };

    const onDrop = (layout, item, _event) => {
        setIsOnDrag(false);
        if (window.getSelection().toString() !== "" || !Session.getSessionProp("widgetTypeDrag")) {
            return;
        }
        if (!item) return;
        const editorContainer = document.querySelector(`#editor_screen_area`);
        const newItem = document.querySelector(".react-grid-placeholder");
        const editorPos = editorContainer.getClientRects()[0];
        const newItemPos = newItem.getClientRects()[0];

        if (newItemPos.y + newItemPos.height > editorPos.y + editorPos.height) {
            toast.error(t("place-into-edition-area"));
            return;
        }

        dispatch(
            setExecuteQuery({
                action: "createWidget",
                params: {
                    deviceType: deviceType,
                    widgetType: Session.getSessionProp("widgetTypeDrag"),
                    widgetPos: {
                        x: item.x,
                        y: item.y,
                        w: item.w,
                        h: item.h,
                    },
                },
            })
        );
    };

    const showItemOptions = (id) => {
        if (editorMode === "PREVIEW") {
            return;
        }
        dispatch(setItemSelected(id));
    };

    const selectItemResized = () => {
        const items = document.querySelectorAll(":hover");
        if (!items) {
            return;
        }
        Array.from(items).map((item) =>
            item.id.indexOf("widget_") > -1 ? dispatch(setItemSelected(item.id.replace("widget_", ""))) : null
        );
    };

    const printEditor = () => {
        return (
            <div className="relative mx-auto">
                <div
                    id="editor_screen_area"
                    className={`relative mx-auto`}
                    style={{
                        width: editorSize.screenAreaWidth,
                        marginTop: isTV || isGeneralTV ? "24px" : isGeneralMobile ? "24px" : "0px",
                        height: editorSize.screenAreaHeight * valueToScroll,
                        outline:
                            itemSelected === "bg" && editingMenu !== null && editingMenu === false
                                ? "2px solid #2286EF"
                                : "none",
                    }}
                    onClick={(e) => {
                        if (window.getSelection().toString() !== "") {
                            return;
                        }

                        hideTools();
                        if (editorMode === EDITOR_MODE.EDITION) {
                            dispatch(setItemSelected("bg"));
                        }
                        e.stopPropagation();
                    }}
                    onDoubleClick={(e) => {
                        hideTools();
                        if (editorMode === EDITOR_MODE.EDITION) {
                            dispatch(setItemSelected("bg"));
                            dispatch(setWidgetToolbarVisible("screen-settings"));
                        }
                        e.stopPropagation();
                    }}
                >
                    {showRooms ? (
                        <div
                            id="widget-rooms"
                            className="mx-auto bg-white"
                            style={{
                                width: editorSize ? editorSize.screenAreaWidth : "auto",
                                zIndex: -1,
                            }}
                        >
                            {printItem(widgetRooms)}
                        </div>
                    ) : null}
                    <ReactGridLayout
                        className="layout mx-auto"
                        style={{
                            marginTop: "0px",
                            width: editorSize.screenAreaWidth,
                            height: editorSize.screenAreaHeight * (isMobile ? 5 : 1),
                            backgroundColor: bgImage || styleBg ? "transparent" : "white",
                        }}
                        cols={editorSize.cols}
                        rowHeight={editorSize.rowHeight}
                        useCSSTransforms={false}
                        isBounded={editorSize.isBounded || true}
                        preventCollision={true}
                        layout={finalLayout}
                        onLayoutChange={layoutChange}
                        droppingItem={droppingItem}
                        onDrop={onDrop}
                        isDroppable={editorMode === EDITOR_MODE.EDITION}
                        draggableHandle={".grid-item-label"}
                        onDragStart={onDragStart}
                        onDragStop={onDragStop}
                        onResize={ensureToolbarPositions}
                        resizeHandles={["se"]}
                        onResizeStart={(e) => {
                            setIsOnResize(true);
                            selectItemResized();
                        }}
                        onResizeStop={(layout, oldItem, newItem) => {
                            setIsOnResize(false);
                            updateLayout(layout, oldItem, newItem);
                        }}
                        margin={[itemsPaddingX, itemsPaddingY]}
                        containerPadding={[paddingX, paddingY]}
                        compactType={null}
                    >
                        {finalLayout.map((item) => {
                            return printItem(item);
                        })}
                    </ReactGridLayout>
                    <ScreenToolbar width={editorSize.screenAreaWidth} deviceType={deviceType} />
                    {isWelcome && cScreenData.data.contentType !== WELCOME_SEQUENCE.WELCOME_LANG ? (
                        <WelcomeOKToContinue />
                    ) : null}
                </div>
            </div>
        );
    };

    const printMenuEditor = () => {
        return isTV || isGeneralTV || isGeneral ? (
            <div
                className=" z-205 border fixed"
                style={{
                    width: "auto",
                    height: "auto",
                    top:
                        isImpersonating && !uiWarnings
                            ? "14.1rem"
                            : uiWarnings && !isImpersonating
                            ? warningsMinimized
                                ? "13rem"
                                : "16.1rem"
                            : uiWarnings && isImpersonating
                            ? warningsMinimized
                                ? "16.1rem"
                                : "19.1rem"
                            : "11.1rem",
                    left: "50%",
                    marginLeft: marginLeftMenuContainer,
                }}
            >
                <MenuEditor editorSize={editorSize} typeMenu={widgetMenuType} />
            </div>
        ) : null;
    };

    const printItem = (widget) => {
        if (!widget) return null;
        const id = widget.i;
        const type = widget.type;
        const iWidget = gridItems.filter((item) => item.i === id)[0];

        const iStyle = Widget.ParseStyle(iWidget ? iWidget.widgetStyle : null, deviceType);

        const style = new WidgetStyle(type, theme);
        style.fill(iStyle);

        const curType = widgets.find((w) => w.type === type);
        const draggable = widget.isDraggable;
        const setWarn = (widgetID, warn) => {
            setWarnings({ ...warnings, [widgetID]: warn });
        };

        const classItemEditor = classNames("react-grid-layout", {
            widgetSelected: itemSelected === id,
            previewMode: editorMode === EDITOR_MODE.PREVIEW,
            "border border-gray-800":
                isWelcome &&
                widget.type === WELCOME_SEQUENCE.WELCOME_VIDEO &&
                cScreenData.data.contentType === WELCOME_SEQUENCE.WELCOME_VIDEO,
            "widget-container": type === WIDGET.TYPE.CONTAINER,
            "widget-menu": type === WIDGET.TYPE.TVMENU,
            "h-14": type === WIDGET.TYPE.TOPBAR,
        });

        return (
            <div
                id={`widget_${id}`}
                key={id}
                onClick={(e) => {
                    if (e.target.tagName === "VIDEO") {
                        // Clicked on a video element
                    } else {
                        hideTools();
                        showItemOptions(id);
                    }
                    e.stopPropagation();
                }}
                onMouseDown={(e) => e.stopPropagation()}
                className={classItemEditor}
            >
                <WidgetProvider
                    value={{
                        id,
                        type,
                        style: iStyle,
                        config: {
                            style,
                            data: null,
                        },
                    }}
                >
                    <Widget.Zone
                        style={
                            Widget.ImplementNewLibrary(type)
                                ? null
                                : {
                                      background:
                                          curType && curType.usingDriveRender
                                              ? "transparent"
                                              : iStyle && iStyle.bgColor
                                              ? iStyle.bgColor
                                              : GetThemeDefault({ theme, id: "bgColor" }),
                                      color:
                                          iStyle && iStyle.fgColor
                                              ? iStyle.fgColor
                                              : GetThemeDefault({ theme, id: "fgColor" }),
                                      borderWidth:
                                          iStyle && iStyle.borderWidth ? `${scaleFontSize(iStyle.borderWidth)}px` : "",
                                      borderColor: iStyle && iStyle.borderColor ? iStyle.borderColor : "transparent",
                                      borderRadius: iStyle && iStyle.radius ? `${iStyle.radius}rem` : "",
                                  }
                        }
                    >
                        {returnWidgetByType(type, { isOnDrag, isOnResize, widgets, setWarning: setWarn })}
                    </Widget.Zone>
                    <GridItemToolbar />

                    <div
                        id={`item_label_${id}`}
                        className={`${draggable ? "grid-item-label cursor-move " : "cursor-default"}${
                            itemSelected === id ? "" : "hidden"
                        }  z-500 invisible absolute top-0`}
                    >
                        <div className="flex items-center">
                            <div className=" rounded bg-zafiro-400 flex items-center px-2 text-base">
                                {draggable ? <i className="icon icon-enlarge mr-2 text-sm text-white"></i> : null}
                                <span className="capitalize text-white ">{curType?.name ? t(curType.name) : "-"}</span>
                            </div>
                            {warnings?.[id] ? (
                                <>
                                    <i
                                        className="icon icon-warning ml-2 text-2xl text-orange-100"
                                        data-tip
                                        data-for={`widget_warn_tooltip_${id}`}
                                    ></i>
                                    {warnings[id] !== true ? (
                                        <ReactTooltip
                                            id={`widget_warn_tooltip_${id}`}
                                            delayShow={250}
                                            type="light"
                                            border={true}
                                            borderColor="text-gray-200"
                                            arrowColor="text-gray-200"
                                            className="font-normal"
                                        >
                                            {warnings[id]}
                                        </ReactTooltip>
                                    ) : null}
                                </>
                            ) : null}
                        </div>
                    </div>
                </WidgetProvider>
            </div>
        );
    };

    const printTopMobileCover = () => {
        let topStyle = calculateTopMobileCoverPos(
            isGeneralMobile,
            isMobile,
            isImpersonating,
            uiWarnings,
            warningsMinimized
        );

        return (
            <div
                id="top-mobile-cover"
                className={`z-205 fixed mx-auto ${editorBackground} text-center`}
                style={{
                    top: topStyle,
                    left: "50%",
                    marginLeft: `-${editorSize && editorSize.width / 2 - 30.5}px`,
                    width: editorSize ? editorSize.width + "px" : "calc(100% - 4.7rem)",
                    paddingTop: isGeneralMobile ? "0rem" : paddingTopMobileCover + "rem",
                }}
            >
                {isGeneralMobile ? (
                    <div
                        className={`-mt-16  w-full ${editorBackground}`}
                        style={{
                            width: "w-full",
                            height: "4rem",
                        }}
                    ></div>
                ) : null}
                {showTopBar ? (
                    <div
                        id="top-bar"
                        className="mx-auto bg-white"
                        style={{
                            width: editorSize ? editorSize.width : "auto",
                            zIndex: -1,
                        }}
                    >
                        {printItem(widgetTopBar)}
                    </div>
                ) : null}
            </div>
        );
    };

    let warningHeight = null;
    if (uiWarnings && warningsMinimized) {
        warningHeight = 2;
    } else if (uiWarnings) {
        warningHeight = 5;
    }

    const topBackground =
        6.2 + (isMobile ? 1.7 : 0) + (showTopBar ? 1.8 : 0) + (isImpersonating ? 3 : 0) + warningHeight;

    const paddingTopScrRem = 2 + (isMobile ? 1.5 : 0) + (showTopBar ? 1.7 : 0);
    const paddingTopScrPx = 0 + (isMobile ? 2 : 0) + (showTopBar ? 2 : 0);
    const valueToScroll = isMobile || isDesktop ? 5 : 1;

    const paddingTopMobileCover = 3.5 + (showTopBar ? -1.5 : 0) + warningHeight;
    let bgAlpha = 1;
    if (style?.bgColor?.includes("rgba")) {
        const _rgba = style.bgColor.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
        bgAlpha = _rgba[4];
    }
    let backgroundColor = styleBg ? styleBg : "white";
    if (
        (screenid === undefined && editorMode === EDITOR_MODE.EDITION) ||
        screenType === SCREEN.TYPE.SALES ||
        screenType === SCREEN.TYPE.SALESMOBILE
    ) {
        backgroundColor = "#e9edf0";
    }
    const cssRules = `
    .screen-background:after,
    .screen-background:before{
        content: " ";
        display: block;
        position: fixed;
        top: ${topBackground}rem;
        left:calc(50% - ${editorSize ? editorSize.screenAreaWidth / 2 + "px" : "0"} - 2.35rem - 2px + ${
        isWelcome && editorSize && editorSize.welcomeWidth ? editorSize.welcomeWidth / 2 + "px" : "0px"
    });
        margin-top: ${isTV || isGeneralTV || isGeneralMobile ? "calc(3.2rem + 24px)" : "3.2rem"} ;
        margin-left: 4.7rem;
        z-index: -2;
        opacity: ${bgAlpha * styleOpacity};
        width: ${editorSize ? editorSize.screenAreaWidth + "px" : "100%"};
        height: ${editorSize ? editorSize.screenAreaHeight + "px" : "100%"};
        background-color: ${backgroundColor};
    }
    .screen-background:before{
        z-index: -1;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        opacity: ${styleOpacity};
        background-image: ${bgImage ? `url("${bgImage}")` : "none"};
    }    `;

    const editorBackground = "bg-gray-200";

    if (error) {
        console.warn("Error: ", error);
        return null;
    }

    return (
        <EditorProvider value={{ deviceType, lang, editorSize, itemSelected, screenType, theme }}>
            <UpdateDesign />
            <UpdateTVMenuNav />
            <UpdateWidgetItem />
            <ResizeListener />
            {
                <>
                    <style>{cssRules}</style>
                    <CustomColorPicker />
                    <DesignSubmenu />
                    <div className="flex">
                        <DesignToolbar />
                        {editorMode !== EDITOR_MODE.EDITION ? (
                            <div
                                className="absolute z-10 flex items-center justify-items-start bg-gray-100 py-1"
                                style={{
                                    marginLeft: "4.7rem",
                                    marginTop: "3.2rem",
                                    width: "calc(100% - 4.7rem)",
                                }}
                            >
                                <div className="ml-4 flex w-8 pr-2 text-2xl">
                                    <span className={`icon icon-warning  cursor-help  text-orange-100`}></span>
                                    <span className={`icon icon-warning2   cursor-help -ml-1em text-white`}></span>
                                    {/* <span className={`icon icon-warning1  cursor-help  -ml-1em  text-white`}></span> */}
                                </div>
                                <div className="flex">{blockEditionText}</div>
                            </div>
                        ) : null}

                        <div
                            className="fixed z-10"
                            style={{
                                marginLeft: "4.7rem",
                                width: "calc(100% - 4.7rem)",
                            }}
                        >
                            <DevicesSubmenu />
                        </div>

                        <div
                            id="editor_screen_container"
                            className={`screen-background fixed h-full w-full ${
                                loadingScreen || loadingTheme ? "opacity-0" : "opacity-100"
                            } overflow-y-scroll ${editorBackground} ${isWelcome ? "flex" : ""}`}
                            style={{
                                marginLeft: "4.7rem",
                                paddingTop: `calc(${paddingTopScrRem}rem + ${paddingTopScrPx}px)`,
                                marginTop: "3.2rem",
                                width: "calc(100% - 4.7rem)",
                            }}
                            onClick={(e) => {
                                if (window.getSelection().toString() !== "") {
                                    return;
                                }
                                hideTools();
                                dispatch(setItemSelected(null));
                                e.stopPropagation();
                            }}
                        >
                            {isMobile && isContentEditor ? (
                                printTopMobileCover()
                            ) : isDesktop && isContentEditor ? (
                                <div
                                    id="top-desktop-cover"
                                    className={`z-205 fixed mx-auto ${editorBackground} text-center`}
                                    style={{
                                        top: "5.4rem",
                                        left: "50%",
                                        marginLeft: `-${editorSize && editorSize.width / 2 - 30.5}px`,
                                        width: editorSize ? editorSize.width + "px" : "calc(100% - 4.7rem)",
                                        paddingTop: paddingTopMobileCover + "rem",
                                    }}
                                />
                            ) : null}
                            {isContentEditor ? printMenuEditor() : null}

                            {editorSize?.height && isWelcome ? <WelcomeSequenceSubMenu /> : null}

                            {isSalesEditor ? (
                                <SalesEditor />
                            ) : isContentEditor ? (
                                printEditor()
                            ) : screenid === undefined && editorMode === EDITOR_MODE.EDITION ? (
                                <div className=" first-capital mt-12 text-center">
                                    {t("create-screen-warning") + "."}
                                </div>
                            ) : null}

                            {isMobile && isContentEditor ? (
                                <div
                                    className={`z-200 fixed ${editorBackground} text-center ${
                                        showTabBar ? "pb-8" : "h-14"
                                    }`}
                                    style={{
                                        left: "50%",
                                        marginLeft: `-${editorSize && editorSize.width / 2 - 30.5}px`,
                                        width: editorSize ? editorSize.width + "px" : "calc(100% - 4.7rem)",
                                        bottom: 0,
                                    }}
                                >
                                    {showTabBar ? (
                                        <div
                                            id="tab-bar"
                                            className="mx-auto border-b-2 border-l-2 border-r-2 border-transparent bg-white"
                                            style={{ width: editorSize.width }}
                                        >
                                            <TabBar editorSize={editorSize} />
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </>
            }
        </EditorProvider>
    );
};

export default ScreensEditor;
