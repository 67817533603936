import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from "react";
import classNames from "classnames";
import md5 from "md5";
import RGL, { WidthProvider } from "react-grid-layout-juanedc";

import { WIDGET, MIN_WIDTH, MIN_HEIGHT } from "constants/editor";

import Item, { newItem } from "./item";
import { DEVICES } from "components/Editor/devices";
import { safeJsonParse, safeJsonStringify } from "hooks/Utils/Utils";

const ReactGridLayout = WidthProvider(RGL);

const GridLayout = forwardRef(
    (
        {
            widgets: initialWidgets,
            disabled: editingMenu,
            editorSelected,
            readOnly,
            droppable,
            rowHeight,
            margin,
            gap,
            droppingItem,
            scrollable,
            cols,
            rows,
            scrollX,
            scrollY,
            onChange,
            onItemSelect,
            onDraggingChange,
            onScrollableChange,
            draggableHandle,
            parentLayoutRef,
            deviceType,
            isMenu,
            isContainer,
            blur,
            style,
        },
        ref
    ) => {
        const [key, setKey] = useState(`layout-${Date.now()}`);
        const layoutRef = useRef(null);

        // If we are editing a menu, we disable all the widgets (menu still enabled for editing)
        const disabled = readOnly || editingMenu;

        const dragClassName = draggableHandle?.substring(1);

        const insideMenu = isMenu;

        const defaultDevice = { ...DEVICES.find((d) => d.id === deviceType) };

        const [widgets, setWidgets] = useState([...(initialWidgets || [])]);
        const widgetsHash = md5(safeJsonStringify(widgets || []) || "[]");

        const { selected, setSelected } = useSelectItem({ onSelect: onItemSelect });
        const { selected: containerSelected, setSelected: setContainerSelected } = useSelectItem();

        const selectItem = (item) => {
            setSelected(item?.i);
            if (item) {
                const type = widgets?.find((w) => String(w?.id) === String(item?.i))?.type;
                if (type === WIDGET.TYPE.CONTAINER || type === WIDGET.TYPE.TVMENU) {
                    setContainerSelected(true);
                }
            } else {
                setContainerSelected(null);
            }
        };

        const [dragging, setDragging] = useState(null);
        const [resizing, setResizing] = useState(null);

        const working = resizing || dragging;

        const dropping = !!droppingItem;
        const droppingWidth = Math.max(droppingItem?.layout?.w || 0, MIN_WIDTH);
        const droppingHeight = Math.max(droppingItem?.layout?.h || 0, MIN_HEIGHT);

        const layout = widgets?.length
            ? widgets
                  // Map widgets to layout items
                  .map((widget) => {
                      return newItem({
                          i: String(widget?.id),
                          ...(widget?.type === WIDGET.TYPE.TVMENU
                              ? {
                                    w: cols,
                                    h: (6 / defaultDevice?.rows) * rows,
                                    x: 0,
                                    y: 0,
                                    isDraggable: false,
                                    allowOverlap: true, // Custom prop
                                }
                              : null),
                          ...widget?.layout,
                          ...(widget?.type === WIDGET.TYPE.TOPBAR
                              ? {
                                    w: cols,
                                    h: (2 / defaultDevice?.rows) * rows,
                                    x: 0,
                                    y: 0,
                                    isDraggable: false,
                                    isResizable: false,
                                    outOfGrid: true, // Custom prop
                                }
                              : null),
                          ...(widget?.type === WIDGET.TYPE.ROOMS
                              ? {
                                    w: cols,
                                    h: (2 / defaultDevice?.rows) * rows * 2,
                                    x: 0,
                                    y: 0,
                                    isDraggable: false,
                                    isResizable: false,
                                    outOfGrid: true, // Custom prop
                                }
                              : null),
                          grid: { cols, rows, scrollX, scrollY }, // Custom prop
                          isBounded: true,
                      });
                  })
                  // Filter out null widgets
                  .filter((item) => item)
            : [];

        // When device is not scrollable vertically, we can evaluate the max rows based on the layout and enable the scroll in this case
        const maxRows = scrollable
            ? undefined
            : Math.max(
                  rows,
                  layout?.reduce((y, item) => Math.max(y, item?.y + item?.h), 0)
              );
        const scrollEnabled = !!scrollable || maxRows != rows;

        const { fitItem, fitItemSize } = useLayoutLimits({ maxRows, cols });

        const parseItem = (item) => {
            const fullItem = editorLayout?.find((i) => String(i.i) === String(item.i));
            if (fullItem) {
                Object.keys(fullItem).forEach((key) => {
                    if (!item.hasOwnProperty(key)) {
                        item[key] = fullItem[key];
                    }
                });
            }
            return item;
        };
        const parseLayout = (lay) => lay?.map((i) => parseItem(i));

        const { apply: applyLayoutChanges, set: setLayoutChanges } = useLayoutChanges({
            id: key,
            onSuccess: (layout) => {
                setWidgets(layout);
                setKey(Date.now());
            },
        });

        const editorLayout = layout?.filter((item) => !item?.outOfGrid);

        useEffect(() => {
            if (onDraggingChange) {
                onDraggingChange(dragging);
            }
        }, [dragging]);

        useEffect(() => {
            if (onScrollableChange) {
                onScrollableChange(scrollEnabled);
            }
        }, [scrollEnabled]);

        useEffect(() => {
            console.log("TEST useEffect widgetsHash");
            if (onChange) {
                onChange(widgets);
            }
        }, [widgetsHash]);

        useEffect(() => {
            setWidgets(initialWidgets || []);
        }, [ref]);

        useEffect(() => {
            if (editingMenu) {
                const tvMenu = widgets?.find((w) => w?.type === WIDGET.TYPE.TVMENU);
                selectItem(editorLayout?.find((item) => String(item.i) === String(tvMenu?.id)));
            } else {
                selectItem(null);
            }
        }, [editingMenu]);

        useEffect(() => {
            console.log("TEST LOAD GridLayout");
        }, []);

        const config = {
            style: {
                minHeight: "100%",
                ...style,
            },
            containerPadding: [margin?.x || 0, margin?.y || 0],
            margin: [gap?.x || 0, gap?.y || 0],
            cols,
            maxRows,
            rowHeight,
            isBounded: false,
            compactType: null,
            useCSSTransforms: true,
            transformScale: 1,
            preventCollision: false,
            allowOverlap: true,
            resizeHandles: ["sw", "nw", "se", "ne"],
            isResizable: !disabled,
            isDraggable: !disabled,
            draggableHandle,
            isDroppable: droppable ?? !disabled,
            droppingItem: newItem({
                i: "new",
                w: droppingWidth,
                h: droppingHeight,
                grid: { cols, rows, scrollX, scrollY },
            }),
            onResizeStart: (layout, oldItem, newItem, placeholder, e, element) => {
                setResizing(newItem);
                selectItem(newItem);
            },
            onResize: (layout, oldItem, newItem, placeholder, e, element) => {
                if (!layout || !newItem || !oldItem || !placeholder) {
                    return;
                }
                layout = parseLayout(layout);
                newItem = parseItem(newItem);
                if (!fitItemSize(newItem, layout)) {
                    newItem = oldItem;
                }
                setResizing(newItem);
                layout = layout.map((item) => {
                    if (String(item.i) === String(newItem.i)) {
                        return newItem;
                    }
                    return item;
                });
                setLayoutChanges(layout);
            },
            onResizeStop: (layout, oldItem, newItem, placeholder, e, element) => {
                if (!layout || !newItem || !oldItem) {
                    return;
                }
                setResizing(null);
                applyLayoutChanges(widgets);
            },
            onDragStart: (layout, oldItem, newItem, placeholder, e, element) => {
                document.title = "DRAG START";
                setDragging({
                    ...newItem,
                });
                if (e?.dataTransfer) {
                    e.dataTransfer.setData("text/plain", "");
                }
            },
            onDrag: (layout, oldItem, newItem, placeholder, e, element) => {
                if (!layout || !newItem || !oldItem || !placeholder) {
                    return;
                }
                layout = parseLayout(layout);
                const widgetType = widgets?.find((w) => String(w?.id) === String(newItem.i))?.type;
                newItem.w = oldItem.w;
                newItem.h = oldItem.h;
                newItem = parseItem(newItem);
                if (!fitItem(widgetType, newItem, layout)) {
                    if (dropping) {
                        // Cancel item drop
                        newItem.w = 0;
                        newItem.h = 0;
                        newItem.x = 0;
                        newItem.y = 0;
                    } else {
                        // Reset item to original position
                        newItem.h = oldItem.h;
                        newItem.w = oldItem.w;
                        newItem.x = oldItem.x;
                        newItem.y = oldItem.y;
                    }
                }

                layout = layout.map((item) => {
                    if (String(item.i) === String(newItem.i)) {
                        return newItem;
                    }
                    return item;
                });
                setLayoutChanges(layout);

                placeholder.x = newItem.x;
                placeholder.y = newItem.y;
                placeholder.w = newItem.w;
                placeholder.h = newItem.h;
            },
            onDragStop: () => {
                setDragging(null);
                applyLayoutChanges(widgets);
            },
            onDrop: (layout, newItem, e) => {
                layout = parseLayout(layout);
                newItem = parseItem(newItem);
                setDragging(null);
                if (!fitItem(droppingItem?.type, newItem, layout)) {
                    // Cancel item drop
                    return;
                }
                // Add new widget
                const newID = `new-${Date.now()}`;
                setWidgets([
                    ...(widgets || []),
                    {
                        ...droppingItem,
                        id: String(newID),
                        layout: {
                            ...newItem,
                            i: String(newID),
                        },
                    },
                ]);
            },
        };

        useEffect(() => {
            if ((disabled || editorSelected) && !editingMenu) {
                selectItem(null);
            }
        }, [disabled, editorSelected]);

        useImperativeHandle(ref, () => ({
            getElement: () => {
                return layoutRef?.current?.elementRef?.current;
            },
            hasScroll: () => {
                return scrollEnabled;
            },
        }));

        return (
            <ReactGridLayout ref={layoutRef} key={key} {...config}>
                {editorLayout?.length
                    ? editorLayout.map((item) => {
                          const widget = widgets?.find((w) => String(w?.id) === String(item.i));
                          const isMenu = widget?.type === WIDGET.TYPE.TVMENU;
                          const isContainer = widget?.type === WIDGET.TYPE.CONTAINER;
                          const isSelected = String(selected) === String(item?.i);
                          // Widget is disabled if:
                          // - Layout is disabled
                          // - Is a menu and is not editing menu
                          const widgetDisabled = (!isMenu && disabled) || (isMenu && !editingMenu);
                          const widgetHidden = widgetDisabled && isMenu && widget?.data?.menuType === "hidden-menu";

                          return (
                              <div
                                  key={item?.i}
                                  data-grid={item}
                                  onClick={
                                      !widgetDisabled
                                          ? (e) => {
                                                if (e) {
                                                    e.stopPropagation();
                                                }
                                                selectItem(item);
                                            }
                                          : () => alert("?")
                                  }
                                  className={classNames({
                                      "widget-menu": isMenu,
                                      "widget-container bg-black bg-opacity-25": isContainer,
                                      "disabled pointer-events-none": widgetDisabled,
                                      hidden: widgetHidden,
                                      selected: isSelected,
                                  })}
                                  style={{
                                      filter: blur && !isMenu ? "blur(2px)" : undefined,
                                  }}
                              >
                                  {!working && working ? (
                                      <div className="w-full h-full bg-gray-400 bg-opacity-50"></div>
                                  ) : (
                                      <Item
                                          item={item}
                                          layoutRef={ref}
                                          parentLayoutRef={parentLayoutRef}
                                          editingMenu={editingMenu}
                                          insideMenu={insideMenu}
                                          disabled={widgetDisabled}
                                          widget={{ ...widget, layout: item }}
                                          selected={isSelected}
                                          containerSelected={containerSelected}
                                          setContainerSelected={setContainerSelected}
                                          resizing={resizing}
                                          dragging={dragging}
                                          margin={null}
                                          gap={gap}
                                          rowHeight={rowHeight}
                                          droppingItem={droppingItem}
                                          dragClassName={dragClassName}
                                          onChange={(newConfig) => {
                                              console.log("TEST layout item onchange", newConfig);
                                              setWidgets(
                                                  widgets
                                                      .map((w) => {
                                                          if (String(w.id) === String(widget.id)) {
                                                              if (newConfig) {
                                                                  // Update widget
                                                                  if (
                                                                      w?.type === WIDGET.TYPE.CONTAINER ||
                                                                      w?.type === WIDGET.TYPE.TVMENU
                                                                  ) {
                                                                      // Check and update containers limits
                                                                      w.layout.minH = newConfig?.widgets?.reduce(
                                                                          (y, w) =>
                                                                              Math.max(y, w?.layout?.y + w?.layout?.h),
                                                                          0
                                                                      );
                                                                      w.layout.minW = newConfig?.widgets?.reduce(
                                                                          (x, w) =>
                                                                              Math.max(x, w?.layout?.x + w?.layout?.w),
                                                                          0
                                                                      );
                                                                  }
                                                                  return {
                                                                      ...w,
                                                                      data: newConfig?.data,
                                                                      actions: newConfig?.actions,
                                                                      style: newConfig?.style,
                                                                      widgets: newConfig?.widgets,
                                                                  };
                                                              } else {
                                                                  // Remove widget
                                                                  return null;
                                                              }
                                                          }
                                                          return w;
                                                      })
                                                      .filter((w) => w)
                                              );
                                          }}
                                      />
                                  )}
                              </div>
                          );
                      })
                    : null}
            </ReactGridLayout>
        );
    }
);

const useLayoutChanges = ({ id, onSuccess }) => {
    if (!id) {
        throw new Error("useLayoutChanges requires an id");
    }

    const set = (layout) => {
        sessionStorage.setItem(id, safeJsonStringify(layout));
    };

    const apply = (widgets) => {
        const currentLayout = safeJsonParse(sessionStorage.getItem(id));
        if (!currentLayout) {
            console.log("TEST No layout found in session storage");
            return;
        }

        const newLayout = (widgets || []).map((widget) => {
            const itemLayout = currentLayout?.find((item) => String(item.i) === String(widget?.id));
            if (!itemLayout) {
                console.warn(`No layout found for widget ${widget?.id}`);
            }
            return {
                ...widget,
                layout: {
                    ...widget?.layout,
                    ...(itemLayout
                        ? {
                              x: itemLayout?.x,
                              y: itemLayout?.y,
                              w: itemLayout?.w,
                              h: itemLayout?.h,
                          }
                        : {}),
                },
            };
        });
        if (onSuccess) {
            onSuccess(newLayout);
        }
    };

    return {
        apply,
        set,
    };
};

const useSelectItem = ({ onSelect } = {}) => {
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        if (onSelect && selected) {
            onSelect(selected);
        }
    }, [selected]);

    return {
        selected,
        setSelected,
    };
};

const useLayoutLimits = ({ maxRows, cols }) => {
    const checkItemBottomLimit = (item) => {
        return item && (!maxRows || item.y + item.h <= maxRows);
    };
    const checkItemRightLimit = (item) => {
        return item && item.x + item.w <= cols;
    };
    const checkItemSize = (item) => {
        return item && item?.w >= MIN_WIDTH && item?.h >= MIN_HEIGHT;
    };

    const xCrash = (a, b) => {
        const outLeft = a.x + a.w <= b.x; // a is left of b
        const outRight = a.x >= b.x + b.w; // a is right of b
        return !(outRight || outLeft);
    };
    const yCrash = (a, b) => {
        const above = a.y + a.h <= b.y; // a is above b
        const below = a.y >= b.y + b.h; // a is below b
        return !(above || below);
    };
    const belowCrash = (a, items) =>
        items.sort((a, b) => a.y - b.y || a.x - b.x).find((b) => xCrash(a, b) && b.y + b.h > a.y && b?.y - a.y > 0);

    const rightCrash = (a, items) =>
        items.sort((a, b) => a.x - b.x || a.y - b.y).find((b) => yCrash(a, b) && b.x < a.x + a.w && b?.x - a.x > 0);

    const checkItemCrash = (a, layout) =>
        a &&
        (a?.outOfGrid ||
            a?.allowOverlap ||
            !layout
                .filter((b) => b.i !== a.i && !b.allowOverlap && !b.outOfGrid)
                .some((b) => {
                    return xCrash(a, b) && yCrash(a, b);
                }));

    const checkItem = (item, layout) => {
        const checkSize = checkItemSize(item);
        const checkBottom = checkItemBottomLimit(item);
        const checkRight = checkItemRightLimit(item);
        const checkCrash = checkItemCrash(item, layout);
        return checkSize && checkBottom && checkRight && checkCrash;
    };

    useEffect(() => {
        console.log("TEST LOAD useLayoutLimits");
    }, []);

    return {
        fitItemSize: (item, layout) => {
            if (!checkItem(item, layout)) {
                //TODO adjust item size to max allowed instead return to initial size
                return false;
            }
            return true;
        },
        fitItem: (type, item, layout) => {
            const otherItems = layout?.filter((o) => String(o?.i) !== String(item?.i));
            const initial = { ...item };
            // isFlexible means that the widget can be resized to fit
            const isFlexible = !(type === WIDGET.TYPE.TVMENU || type === WIDGET.TYPE.CONTAINER);

            if (isFlexible) {
                if (!checkItemCrash(item, layout)) {
                    const itemBelow = belowCrash(item, otherItems);
                    if (itemBelow) {
                        item.h = Math.max(Math.min(itemBelow?.y - item.y, item.h), MIN_HEIGHT);
                    }
                    const itemRight = rightCrash(item, otherItems);
                    if (itemRight) {
                        item.w = Math.max(Math.min(itemRight?.x - item.x, item.w), MIN_WIDTH);
                        item.h = initial?.h;
                        const itemBelow = belowCrash(item, otherItems);
                        if (itemBelow) {
                            item.h = Math.max(Math.min(itemBelow?.y - item.y, item.h), MIN_HEIGHT);
                        }
                    }
                }
                if (!checkItemBottomLimit(item)) {
                    item.h = Math.max(maxRows - item.y, MIN_HEIGHT);
                }
                if (!checkItemRightLimit(item)) {
                    item.w = Math.max(cols - item.x, MIN_WIDTH);
                }
            }

            if (!checkItem(item, layout)) {
                return false;
            }

            return true;
        },
    };
};

export default GridLayout;
