import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useListFilters, useScheduleSignage, useGetFiltersDestinations } from "../graphql/useSignages";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import Icon from "components/Icon";
import Select from "components/Select";
import Loading from "components/Loading";

const FilterSelector = (props) => {
    const value = Object.keys(props.value)?.[0];
    return (
        <div className="w-full grid grid-cols-5 gap-2">
            <div className=" col-span-2">
                <Select
                    multiple={false}
                    options={props.filterTypes}
                    value={value}
                    onChange={(val) => {
                        props.setFilters((curr) => {
                            let response = curr;
                            response[props.index] = {};
                            if (val) {
                                response[props.index][val] = [];
                            }
                            return [...response];
                        });
                    }}
                    id={"select-type"}
                ></Select>
            </div>
            {value && (
                <>
                    <div className=" col-span-2">
                        <Select
                            multiple={true}
                            id={"select-type"}
                            options={props?.listFilters?.[value]?.map((val) => ({ value: val.id, label: val.name }))}
                            onChange={(val) => {
                                props.setFilters((curr) => {
                                    let response = curr;
                                    response[props.index] = {};
                                    response[props.index][value] = val;
                                    return [...response];
                                });
                            }}
                            value={props?.value?.[value]}
                        ></Select>
                    </div>
                    <Button
                        id={"delete-filter"}
                        onClick={() => {
                            props.setFilters((curr) => {
                                if (curr.length == 1) {
                                    return [{}];
                                } else {
                                    const response = [...curr];
                                    response.splice(props.index, 1);
                                    return response;
                                }
                            });
                        }}
                    >
                        <Icon size={"2xl"} type={"delete"}></Icon>
                    </Button>
                </>
            )}
        </div>
    );
};

const SelectFiltersList = (props) => {
    return (
        <>
            {props?.values?.length > 0 && (
                <div className=" m-4 flex flex-col gap-2">
                    <p className=" font-bold">{props.label}</p>
                    <div className=" flex gap-2">
                        {props.values.map((value) => (
                            <p className=" rounded-lg bg-purple-700 text-white py-1 px-2">{value.name}</p>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

const ModalAddFilters = (props) => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState([{}]);
    const [filtersList, setFilterList] = useState({ "common-zones": [], "tv-locations": [] });
    const [filterTypes, setFilterTypes] = useState([
        { value: "commonZones", label: t("common-zones"), disabled: false },
        { value: "groupCommonZones", label: t("group-common-zones"), disabled: false },
        { value: "tvLocations", label: t("tv-locations"), disabled: false },
    ]);

    const {
        getFilters,
        filters: listFilters,
        loading,
    } = useListFilters({
        onCompleted: () => {
            const currentFilters = [];
            const commonZones = props?.filters?.roomsTpCommonZone?.map((val) => val.id) || [];
            const groupCommonZones = props?.filters?.roomGroupsTpCommonZone?.map((val) => val.id) || [];
            const tvLocations = props?.filters?.roomTvsTpCommonZone?.map((val) => val.id) || [];
            if (commonZones.length > 0) {
                currentFilters.push({ commonZones });
            }
            if (groupCommonZones.length > 0) {
                currentFilters.push({ groupCommonZones });
            }
            if (tvLocations.length > 0) {
                currentFilters.push({ tvLocations });
            }
            if (currentFilters.length > 0) {
                setFilters(currentFilters);
            }
        },
    });
    const { getFilterDestinations, loading: loadingDestinations, destinations } = useGetFiltersDestinations();
    const { updateScheduleSignage } = useScheduleSignage({
        onCompleted: () => {
            props.getSignage();
            props.close();
        },
    });

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    const handleAddClick = () => {
        const filterDestination = filters.reduce((acc, curr) => {
            acc = { ...acc, ...curr };
            return acc;
        }, {});

        updateScheduleSignage({
            variables: {
                id: props?.id,
                destination: {
                    roomTpCommonZoneIDs: filterDestination.commonZones,
                    roomGroupTpCommonZoneIDs: filterDestination.groupCommonZones,
                    roomTvTpCommonZoneIDs: filterDestination.tvLocations,
                },
            },
        });
    };

    useEffect(() => {
        const usedFilters = filters.reduce((acc, curr) => {
            const values = Object.keys(curr);
            acc = [...acc, ...values];
            return acc;
        }, []);

        setFilterTypes((filters) => {
            const response = filters.map((filter) => {
                return { ...filter, disabled: usedFilters.includes(filter.value) };
            });
            return response;
        });
        const filterDestination = filters.reduce((acc, curr) => {
            acc = { ...acc, ...curr };
            return acc;
        }, {});
        getFilterDestinations({
            variables: {
                filters: {
                    roomGroupTpCommonZoneIDs: filterDestination?.groupCommonZones,
                    roomTpCommonZoneIDs: filterDestination?.commonZones,
                    roomTvTpCommonZoneIDs: filterDestination?.tvLocations,
                },
            },
        });
    }, [filters]);

    useEffect(() => {
        getFilters();
    }, []);

    useEffect(() => {
        setFilterList({
            "common-zones": {
                values: destinations?.commonZones,
                label:
                    destinations?.commonZones?.length == 1
                        ? t("x common zones_one", { count: destinations?.commonZones?.length })
                        : t("x common zones_other", { count: destinations?.commonZones?.length }),
            },
            "tv-locations": {
                values: destinations?.tvLocations,
                label: `${destinations?.tvLocations?.length} ${t(
                    destinations?.tvLocations?.length > 1 ? "tv-locations" : "tv-location"
                )}`,
            },
        });
    }, [destinations?.commonZones, destinations?.tvLocations]);

    return (
        <Modal
            title={
                <div className="flex section-title mb-5 gap-2">
                    <p>{t("add-filters")}</p>
                    <Icon
                        className={"mt-2"}
                        size={"2xl"}
                        type={"info"}
                        tooltip={t("signage-only-will-be-displayed-in-commmon-zones")}
                    ></Icon>
                </div>
            }
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="blue" id="modal-button-add" onClick={handleAddClick} disabled={loading}>
                        {t("add")}
                    </Button>
                </>
            }
            className={"w-10/12 p-10"}
        >
            {loading ? (
                <Loading></Loading>
            ) : (
                <div className=" flex">
                    <div className="flex flex-1 flex-col gap-2">
                        <p>{t("define-where-the-sequence-will-be-displayed")}:</p>
                        {filters.map((val, index) => {
                            return (
                                <FilterSelector
                                    key={`filter-n-${index}`}
                                    value={val}
                                    listFilters={listFilters}
                                    filterTypes={filterTypes}
                                    filters={props.filters}
                                    index={index}
                                    setFilters={setFilters}
                                ></FilterSelector>
                            );
                        })}

                        <Button
                            design={"link"}
                            id={"add-filter"}
                            disabled={
                                Object.keys(filters[filters.length - 1]).length == 0 ||
                                !filterTypes.find((val) => !val.disabled)
                            }
                            onClick={() => {
                                setFilters((val) => {
                                    const response = val;
                                    response.push({});
                                    return [...response];
                                });
                            }}
                        >
                            <Icon type="add"></Icon>
                            <span>{t("add-filter")}</span>
                        </Button>
                    </div>
                    <div className="flex flex-1 flex-col gap-2">
                        <p>{t("filter-results")}</p>
                        <div className="rounded-lg bg-gray-300 min-h-30 w-full relative">
                            {!loadingDestinations ? (
                                <div className="w-full min-h-full flex justify-center items-center">
                                    <Loading overlay={false}></Loading>
                                </div>
                            ) : (
                                <>
                                    {Object.entries(filtersList).map((value) => {
                                        return (
                                            <SelectFiltersList
                                                label={t(value[1]?.label)}
                                                values={value[1]?.values}
                                            ></SelectFiltersList>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export const UseModalAddFilters = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalAddFilters {...newProps} />);
        },
    };
};
