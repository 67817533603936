import React, { useState } from "react";
import UseSectionHeader from "../../../useSectionHeader";
import { useHotelMap, useRoom, useTopologyValidation } from "hooks/GraphqlCalls/Hotel/useTopologyMap";
import { useParams } from "react-router-dom";
import { Session } from "hooks/Utils/Session";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { FloorDetails } from "./TopologyComponents/FloorsDetail";

export const AddRoom = () => {
    const GUESTROOM = "GUESTROOM";
    const [floorSettings, setFloorSettings] = useState([]);
    const { results: installationMap } = useHotelMap();
    const { validate, tvsPerRoomValidation, starterRoomValidation } = useTopologyValidation();
    const { createRoom } = useRoom();
    const { t } = useTranslation();
    const { id } = useParams();

    const wing = installationMap?.find((wing) => wing?.floors?.find((floor) => parseInt(floor.id) === parseInt(id)));
    const floor = wing?.floors?.find((floor) => parseInt(floor.id) === parseInt(id));
    const roomOrder = floor?.rooms?.length + 1;
    const lastRoom = floor?.rooms?.[floor?.rooms?.length - 1];
    const floorName = floor?.name;
    const wingName = wing?.name;
    const prevLocation = JSON.parse(Session.getPreviousPath())?.pathname;

    const handleSave = () => {
        const newRoom = {
            floorID: id,
            floors:
                [
                    {
                        numbersOfRooms: floorSettings[roomOrder]?.numbersOfRooms,
                        starterRoom: floorSettings[roomOrder]?.starterRoom,
                        tvsPerRoom: floorSettings[roomOrder]?.tvsPerRoom,
                        type: GUESTROOM,
                    },
                ] || [],
        };
        if (validate(newRoom)) {
            createRoom(newRoom);
        }
    };

    return (
        <>
            <UseSectionHeader title={["add-room", "(", wingName, ",", floorName, ")"]} navToPreviousPage={true} />

            <div className="bg-white p-6">
                <FloorDetails
                    id={roomOrder}
                    checked={true}
                    tvsPerRoomLabel={t("tvs-per-room")}
                    setFloorSettings={setFloorSettings}
                    installationMap={installationMap}
                    addingRoom={true}
                    pos={wing?.pos}
                    lastRoom={lastRoom?.number}
                    starterRoomValidation={starterRoomValidation}
                    tvsPerRoomValidation={tvsPerRoomValidation}
                    isRoom={true}
                />
                <div className="flex gap-3 mt-10 justify-end">
                    <Button id="cancel-add-room" className="btn btn-white" to={`${prevLocation}`}>
                        {t("cancel")}
                    </Button>
                    <Button id="add-room" className="btn btn-blue" onClick={handleSave}>
                        {t("save")}
                    </Button>
                </div>
            </div>
        </>
    );
};
