import { useState, useEffect } from "react";

import { safeJsonParse } from "hooks/Utils/Utils";
import { MANAGER_PREFIX } from "hooks/Utils/Session";

/**
 * This hook is used to store data persistently in local storage and update the state of the component
 * @param {string} name The key identifier for the data
 * @param {Object} defaultData The default data to be stored
 * @returns
 */
const useLocalState = (name, defaultData) => {
    const storageKey = name ? `${MANAGER_PREFIX}-${name}` : null;
    const stored = storageKey ? safeJsonParse(localStorage.getItem(storageKey)) : null;
    const initialData = Array.isArray(defaultData) ? [...defaultData, ...stored] : { ...defaultData, ...stored };

    const [data, setData] = useState(initialData);

    useEffect(() => {
        if (storageKey) {
            localStorage.setItem(storageKey, JSON.stringify(data));
        }
    }, [data]);

    useEffect(() => {
        setData(initialData);
    }, [name]);

    return [data, setData];
};

export default useLocalState;
