import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Button from "components/Button";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import UseIcon from "../../useIcon";
import DeviceContentSelector from "../Hotel/CommonZones/DeviceContentSelector";
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation } from "react-apollo";
import Icon from "components/Icon";
import { toast } from "react-toastify";

export const TV_CONTENTS = gql`
    query {
        roomtvContents
    }
`;
export const UPDATE_COMMONZONE = gql`
    mutation UpdateRoomTV($roomTvID: Int64!, $contents: [String!]!) {
        updateRoomTV(roomTvID: $roomTvID, contents: $contents) {
            ok
            error
        }
    }
`;
export const GET_CONTENTS = gql`
    {
        rooms(filter: { type: COMMONZONE }) {
            results {
                name
                number
                roomTVs {
                    id
                    tvInputID
                    name
                    contents
                }
            }
        }
    }
`;
function UseAvailableContentCard({ data, zoneId, roomName }) {
    const GET_DATA = gql`
        {
            devices(filter: { roomID: ${zoneId} }) {
                results {
                    name
                    function
                    ref
                    type
                    location
                    roomID
                    roomTVID
                    roomTVName
                    roomNumber
                }
            }
        }
    `;

    const [executeQuery, { data: dataDevices }] = useLazyQuery(GET_DATA, {
        fetchPolicy: "network-only",
    });

    const [executeContents, { data: dataContents, refetch }] = useLazyQuery(GET_CONTENTS, {
        fetchPolicy: "network-only",
    });

    const [executeQueryTvContents, { data: dataTvContents }] = useLazyQuery(TV_CONTENTS, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (zoneId) {
            executeQuery({ variables: { roomId: zoneId } });
            executeContents();
            executeQueryTvContents();
        }
    }, [zoneId]);

    const { headerText } = data;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { t } = useTranslation();
    const { open } = useModal();

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const projectContents = dataTvContents?.roomtvContents || [];

    const rooms = dataContents?.rooms?.results || [];
    const devices = dataDevices?.devices;

    const availableContents = rooms
        .map((room) => room.roomTVs)
        .flat()
        .map((roomTv) => roomTv.contents)
        .flat();

    const contentsUSe = Array.isArray(availableContents) ? availableContents : [];

    const predefinedContent = contentsUSe && contentsUSe.length > 0 ? contentsUSe : projectContents;

    const contentTypes = [
        { key: "TVCHANNELS", type: "tv-settings", color: "green-400" },
        { key: "SIGNAGESEQUENCES", type: "advertising", color: "purple-900" },
        { key: "CASTINSTRUCTIONSCREEN", type: "wificast", color: "zafiro-400" },
    ];

    return (
        <div className={`dc-card-container bg-white text-blue-400 grid grid-cols-3`}>
            <div className="col-span-2 first-capital">{t(headerText)}</div>
            {projectContents.length > 1 && (
                <div
                    id="actions-button-available-content"
                    className="icon-row-actions text-lg cursor-pointer text-right"
                    onClick={toggleDropdown}
                >
                    {isDropdownOpen && (
                        <div className="absolute mt-2 left-1/2 transform -translate-x-1/2 w-60 bg-white border border-gray-300 rounded z-50 text-left capitalize font-sans">
                            <div
                                className="px-4 py-4 cursor-pointer hover:bg-gray-100 text-left"
                                onClick={() => {
                                    setIsDropdownOpen(
                                        open(
                                            <ManageContentModal
                                                onConfirm={() => {}}
                                                availableContents={projectContents}
                                                devices={devices}
                                                roomName={roomName}
                                                contentsUSe={contentsUSe}
                                                executeQueryTvContents={executeQueryTvContents}
                                                executeContents={executeContents}
                                                dataContents={dataContents}
                                            />
                                        )
                                    );
                                }}
                            >
                                {t("manage-content")}
                            </div>
                        </div>
                    )}
                </div>
            )}

            <div className="col-span-3 flex space-x-4 mt-2 relative ">
                {contentTypes.map(({ key, type, color }) =>
                    predefinedContent.includes(key) && projectContents.includes(key) ? (
                        <div className="border-b border-gray-100 py-4" key={key}>
                            <div
                                className={`flex items-center justify-center p-1 w-10 border-2 border-${color} rounded-lg`}
                            >
                                <Icon
                                    type={type}
                                    className={`icon inline-block text-xl lg:text-2xl text-${color} font-bold`}
                                    size={2}
                                />
                            </div>
                        </div>
                    ) : null
                )}
            </div>
        </div>
    );
}
const ManageContentModal = ({
    availableContents,
    devices,
    roomName,
    contentsUSe,
    onConfirm,
    executeQueryTvContents,
    executeContents,
    dataContents,
}) => {
    const { t } = useTranslation();
    const { close } = useModal();

    const [selectedOptions, setSelectedOptions] = useState({});
    const [initialState, setInitialState] = useState({});

    useEffect(() => {
        if (devices?.results && dataContents?.rooms?.results) {
            const newState = {};

            const contentsMapping = {};
            dataContents.rooms.results.forEach((room) => {
                room.roomTVs.forEach((roomTv) => {
                    contentsMapping[roomTv.id] = roomTv.contents || [];
                });
            });

            devices.results.forEach((device) => {
                const contents = contentsMapping[device.roomTVID] || [];

                const contentsToUse = contents.length === 0 ? contentsUSe : contents;

                newState[device.roomTVID] = {
                    signage: contentsToUse.includes("SIGNAGESEQUENCES"),
                    tvChannels: contentsToUse.includes("TVCHANNELS"),
                    castScreen: contentsToUse.includes("CASTINSTRUCTIONSCREEN"),
                };
            });

            setSelectedOptions(newState);
            setInitialState(newState);
        }
    }, [devices, dataContents, contentsUSe]);

    const [executeUpdateRoomTV] = useMutation(UPDATE_COMMONZONE);

    const handleSave = async () => {
        const hasSelection = Object.values(selectedOptions).some(
            (options) => options.signage || options.tvChannels || options.castScreen
        );

        if (!hasSelection) {
            toast.error(t("Please select at least one option."));
            return;
        }

        const updates = Object.keys(selectedOptions).map((deviceId) => {
            const options = selectedOptions[deviceId];
            const contents = [];
            if (options.signage) contents.push("SIGNAGESEQUENCES");
            if (options.tvChannels) contents.push("TVCHANNELS");
            if (options.castScreen) contents.push("CASTINSTRUCTIONSCREEN");

            return { roomTvID: parseInt(deviceId, 10), contents };
        });

        // Realizar la mutación para actualizar los dispositivos
        for (const update of updates) {
            try {
                const { data } = await executeUpdateRoomTV({
                    variables: update,
                });
                if (!data.updateRoomTV.ok) {
                    console.error("Error updating RoomTV", data.updateRoomTV.error);
                }
            } catch (error) {
                console.error("Mutation error:", error);
            }
        }

        if (executeQueryTvContents) await executeQueryTvContents();
        if (executeContents) await executeContents();

        if (onConfirm) {
            onConfirm();
        }

        toast.success(t("operation-successful"));
        close();
    };

    return (
        <Modal
            id={`manage-content-modal-${roomName}`}
            title={
                <div className="flex items-center text-3xl font-sans font-bold">
                    {`${roomName} - ${t("manage-content")}`}
                    <Icon
                        className="ml-2 -mt-1"
                        type="info"
                        tooltip={t(
                            "Please note that you will need tv device function to display signage and tv channels, and chromecast function to display the cast instruction screen."
                        )}
                        size={1.5}
                    />
                </div>
            }
            onClose={close}
            className="w-2/7 p-10"
            footer={
                <footer className="flex w-full gap-4 mt-10 justify-end">
                    <Button id="cancel" className="btn-white" onClick={close}>
                        {t("Cancel")}
                    </Button>
                    <Button id="alert-save" design="blue" onClick={handleSave}>
                        {t("Save")}
                    </Button>
                </footer>
            }
        >
            <div className="mt-2 mb-2">
                {t("Select the available content for each TV location of this common zone:")}
            </div>
            <DeviceContentSelector
                devices={devices}
                availableContents={availableContents}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
            />
        </Modal>
    );
};

export default UseAvailableContentCard;
