import React, { useRef, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { getWidgetToolbarIcon, WIDGET_TOOLBAR, WIDGET, WIDGETS, SCREEN_CONFIGURATIONS } from "constants/editor";

import { safeJsonStringify } from "hooks/Utils/Utils";

import Button from "components/Button";
import Icon from "components/Icon";
import TextInput from "components/TextInput";
import { useFloatDropdown } from "components/Dropdown";

const Toolbar = ({ type, visible, targetRef, layoutRef, menu }) => {
    const toolbarRef = useRef(null);
    const menuRef = useRef(null);
    const containerRef = useRef(document.createElement("div"));

    const [activeSettings, setActiveSettings] = useState(null);
    const [toolbarPosition, setToolbarPosition] = useState(null);
    const [toolbarPositionAdjusted, setToolbarPositionAdjusted] = useState(null);
    const [maxHeight, setMaxHeight] = useState(null);
    const [ready, setReady] = useState(false);

    const { open: openFloat, close: closeFloat } = useFloatDropdown({
        id: "toolbar-float",
        dropdown: menuRef.current,
        container: containerRef.current,
        enabled: true,
        dropdownDirection: "down",
        zIndex: 990,
    });

    const toolbarSpacing = 5;

    const openSettings = (settings) => {
        setActiveSettings((current) => (current === settings ? null : settings));
    };

    const reset = () => {
        setActiveSettings(null);
        setToolbarPosition(null);
        setToolbarPositionAdjusted(null);
        setMaxHeight(null);
        setReady(false);
    };

    useEffect(() => {
        const layoutElement = layoutRef?.current ? layoutRef?.current?.getElement() : null;

        const editorArea = [WIDGET.TYPE.TOPBAR, WIDGET.TYPE.ROOMS].includes(type)
            ? layoutElement?.parentElement?.parentElement?.parentElement
            : null;

        const targetBounds = targetRef?.current ? targetRef.current.getBoundingClientRect() : null;
        const layoutBounds = editorArea?.getBoundingClientRect() || layoutElement?.getBoundingClientRect();
        const toolbarBounds = toolbarRef.current ? toolbarRef.current.getBoundingClientRect() : null;
        const menuBounds = menuRef.current ? menuRef.current.getBoundingClientRect() : null;
        let toolbarBottom = toolbarBounds?.bottom || 0;
        const toolbarWidth = toolbarBounds?.width || 0;
        const toolbarHeight = toolbarBounds?.height || 0;
        const toolbarLeft = toolbarBounds?.left || 0;
        const targetTop = targetBounds?.top || 0;
        const targetRight = targetBounds?.right || 0;
        const targetBottom = targetBounds?.bottom || 0;
        const targetLeft = targetBounds?.left || 0;
        const layoutRight = layoutBounds?.right || 0;
        const layoutLeft = layoutBounds?.left || 0;
        const layoutTop = layoutBounds?.top || 0;
        const layoutBottom = layoutBounds?.bottom || 0;

        if (!visible) {
            // Reset toolbar when it's not visible
            reset();
        } else if (!ready) {
            // Calculate toolbar position for the first time
            if (!targetRef) {
                // Top Right inside
                setToolbarPosition({
                    top: true,
                    style: {
                        top: `${toolbarSpacing + 5}px`,
                        right: `${toolbarSpacing + 5}px`,
                        zIndex: 355,
                        alignItems: "flex-end",
                    },
                });
                toolbarBottom = layoutTop + toolbarHeight + toolbarSpacing + 5;
            } else if (toolbarWidth && targetRight) {
                if (targetRight + toolbarSpacing + toolbarWidth < layoutRight) {
                    // Right side
                    setToolbarPosition({
                        right: true,
                        style: {
                            left: `calc(100% + ${toolbarSpacing}px)`,
                            top: 0,
                        },
                    });
                    toolbarBottom = targetTop + toolbarHeight;
                } else if (targetLeft - toolbarSpacing - toolbarWidth > layoutLeft) {
                    // Left side
                    setToolbarPosition({
                        left: true,
                        style: {
                            right: `calc(100% + ${toolbarSpacing}px)`,
                            top: 0,
                            alignItems: "flex-end",
                            zIndex: 265,
                        },
                    });
                    toolbarBottom = targetTop + toolbarHeight;
                } else if (layoutBottom - targetBottom > toolbarHeight * 4) {
                    // Bottom right
                    setToolbarPosition({
                        bottom: true,
                        style: {
                            right: 0,
                            top: `calc(100% + ${toolbarSpacing}px)`,
                            alignItems: "flex-end",
                        },
                    });
                } else {
                    // Top Right inside
                    setToolbarPosition({
                        top: true,
                        style: {
                            top: `${toolbarSpacing + 5}px`,
                            right: `${toolbarSpacing + 5}px`,
                            zIndex: 265,
                            alignItems: "flex-end",
                        },
                    });
                    toolbarBottom = targetTop + toolbarSpacing + toolbarHeight;
                }
            } else {
                setToolbarPosition(null);
            }
            // Set max height for toolbar content
            setMaxHeight(Math.max(0, document.body.clientHeight - toolbarBottom - toolbarSpacing * 4));
            setReady(true);
        } else if (activeSettings) {
            // Check if new toolbar width is in layout bounds
            if (toolbarPosition?.left && toolbarLeft < layoutLeft) {
                setToolbarPositionAdjusted({
                    ...toolbarPosition,
                    style: {
                        ...toolbarPosition.style,
                        right: undefined,
                        left: 0 - (targetLeft - menuBounds?.x) + "px",
                        alignItems: "flex-start",
                    },
                });
            } else if (toolbarPosition?.right && toolbarBounds?.right > layoutRight) {
                setToolbarPositionAdjusted({
                    ...toolbarPosition,
                    style: {
                        ...toolbarPosition.style,
                        left: `calc(100% - ${toolbarWidth - menuBounds?.width - toolbarSpacing}px)`,
                        alignItems: "flex-end",
                    },
                });
            }
        } else {
            setToolbarPositionAdjusted(null);
        }
    }, [visible, activeSettings, targetRef]);

    useEffect(() => {
        if (activeSettings) {
            openFloat();
        } else {
            closeFloat();
        }
    }, [activeSettings]);

    return visible && menu?.length ? (
        <div
            ref={toolbarRef}
            className={classNames({
                "absolute pointer-events-none invisible": !ready,
                "absolute overflow-auto flex flex-col items-start space-y-1": true,
            })}
            style={toolbarPositionAdjusted?.style || toolbarPosition?.style}
        >
            <div
                ref={menuRef}
                className="inline-flex items-center space-x-1 shadow border py-1 px-2 rounded border-gray-100 bg-white text-gray-800"
            >
                {menu.map((item) => {
                    const menuType = item?.type;
                    const icon = getWidgetToolbarIcon(menuType);
                    const menuClass = classNames({
                        "rounded p-3": true,
                        "hover:bg-gray-200": menuType !== WIDGET_TOOLBAR.DELETE,
                        "hover:bg-red-100 hover:text-white": menuType === WIDGET_TOOLBAR.DELETE,
                        "bg-gray-200": activeSettings === menuType,
                    });
                    const menuAction = item?.action || (() => openSettings(menuType));
                    return (
                        <Button id={`item-menu-${menuType}`} key={menuType} onClick={menuAction} className={menuClass}>
                            <Icon type={icon} size={icon === "superuser" ? 1.8 : 1.3} />
                        </Button>
                    );
                })}
            </div>
            {activeSettings && containerRef.current
                ? ReactDOM.createPortal(
                      <div
                          className="overflow-auto max-w-lg max-h-full border mt-1 p-5 mb-5 rounded border-gray-100 bg-white text-gray-800 shadow"
                          style={{ maxHeight: maxHeight || "auto" }}
                      >
                          {menu.find((item) => item?.type === activeSettings)?.content}
                      </div>,
                      containerRef.current
                  )
                : null}
        </div>
    ) : null;
};

export const Settings = ({ id, data: initialData, onChange }) => {
    const [data, setData] = useState(initialData);

    const [currentTab, setCurrentTab] = useState(0);

    useEffect(() => {
        setData(initialData);
    }, [id]);

    useEffect(() => {
        if (onChange) {
            onChange(data);
        }
    }, [data]);

    if (id === WIDGET_TOOLBAR.DEBUG) {
        return (
            <pre className="text-base p-2 min-w-120 whitespace-pre-wrap break-all">
                {safeJsonStringify(data, null, 2)}
            </pre>
        );
    }

    if (id === WIDGET_TOOLBAR.ACTIONS) {
        //TODO mejorar esto
        const inputs = [
            getConfigInput("actions", "type", data, setData),
            getConfigInput("actions", "value", data, setData),
        ];
        return (
            <div className="flex flex-col space-y-2 min-w-60">
                {inputs?.length ? (
                    inputs.map((key) => {
                        // if is valid react element return
                        if (React.isValidElement(key)) {
                            return key;
                        }
                        return (
                            <div key={key?.id} className="flex flex-col space-y-1 bg-orange-200 text-orange-600">
                                <div>{key?.label}</div>
                                <TextInput
                                    id={`settings-${id}-${key?.id}`}
                                    value={key?.value}
                                    placeholder={key?.placeholder}
                                    onChange={key?.onChange}
                                />
                            </div>
                        );
                    })
                ) : (
                    <Icon type="warning" text={`Unconfigured toolbar ${id} for widget ${data?.type}`} />
                )}
            </div>
        );
    }

    const tabs = getTabs(id, data?.type);
    const inputs = parseInputs(data?.type, tabs?.[currentTab], data, setData);
    return (
        <div className="flex flex-col space-y-2 min-w-60">
            {tabs?.length > 1 ? (
                <div className="flex flex-row space-x-2">
                    {tabs.map((tab) => (
                        <Button
                            key={tab?.name}
                            id={`settings-${id}-tab-${tab?.id}`}
                            onClick={() => setCurrentTab(tab?.index)}
                            className={classNames({
                                "p-2": true,
                                "bg-gray-200": tab?.index === currentTab,
                            })}
                        >
                            {tab?.name}
                        </Button>
                    ))}
                </div>
            ) : null}
            {inputs?.length ? (
                inputs.map((key) => {
                    // if is valid react element return
                    if (React.isValidElement(key)) {
                        return key;
                    }
                    return (
                        <div key={key?.id} className="flex flex-col space-y-1 bg-orange-200 text-orange-600">
                            <div>{key?.label}</div>
                            <TextInput
                                id={`settings-${id}-${key?.id}`}
                                value={key?.value}
                                placeholder={key?.placeholder}
                                onChange={key?.onChange}
                            />
                        </div>
                    );
                })
            ) : (
                <Icon type="warning" text={`Unconfigured toolbar ${id} for widget ${data?.type}`} />
            )}
        </div>
    );
};

const AddImage = ({ id, value, onChange }) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col space-y-1 border-b pb-10">
            <strong>{t("add-image")}</strong>
            <div>Library Ref</div>
            <TextInput
                id={`settings-${id}`}
                value={value?.libraryRef}
                onChange={(libraryRef) => onChange({ libraryRef })}
            />
            <div>External URL</div>
            <TextInput
                id={`settings-${id}`}
                value={value?.externalUrl}
                onChange={(externalUrl) => onChange({ externalUrl })}
            />
        </div>
    );
};

const ImageAdjust = ({ id, value, onChange }) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col space-y-1 border-b pb-10">
            <strong>{t("image-options")}</strong>
            <div>Adjust</div>
            <TextInput
                id={`settings-${id}`}
                value={value}
                onChange={(value) => onChange(value)}
                placeholder="contain | cover | cover-adjust"
            />
        </div>
    );
};

const Alignment = ({ id, value, onChange }) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col space-y-1 border-b pb-10">
            <strong>{t("alignment")}</strong>
            <div>X</div>
            <TextInput
                id={`settings-${id}-x`}
                value={value?.x}
                onChange={(x) =>
                    onChange({
                        ...value,
                        x,
                    })
                }
            />
            <div>Y</div>
            <TextInput
                id={`settings-${id}-y`}
                value={value?.y}
                onChange={(y) =>
                    onChange({
                        ...value,
                        y,
                    })
                }
            />
        </div>
    );
};

const TextOptions = ({ id, value, onChange, title = false }) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col space-y-1 border-b pb-10">
            <strong>{t("Text options")}</strong>
            {title ? (
                <>
                    <div>{t("text")}</div>
                    <TextInput
                        id={`settings-${id}-text`}
                        value={value?.text}
                        onChange={(text) =>
                            onChange({
                                ...value,
                                text,
                            })
                        }
                    />
                </>
            ) : null}
            <div>Font Style</div>
            <TextInput
                id={`settings-${id}-fontStyle`}
                value={value?.fontStyle}
                onChange={(fontStyle) =>
                    onChange({
                        ...value,
                        fontStyle,
                    })
                }
            />
            <div>Font</div>
            <TextInput
                id={`settings-${id}-font`}
                value={value?.font}
                onChange={(font) =>
                    onChange({
                        ...value,
                        font,
                    })
                }
            />
            <div>Size</div>
            <TextInput
                id={`settings-${id}-size`}
                value={value?.size}
                onChange={(size) =>
                    onChange({
                        ...value,
                        size,
                    })
                }
            />
            <div>Bold</div>
            <TextInput
                id={`settings-${id}-bold`}
                value={value?.bold}
                onChange={(bold) =>
                    onChange({
                        ...value,
                        bold,
                    })
                }
            />
            <div>Italic</div>
            <TextInput
                id={`settings-${id}-italic`}
                value={value?.italic}
                onChange={(italic) =>
                    onChange({
                        ...value,
                        italic,
                    })
                }
            />
            <div>Underline</div>
            <TextInput
                id={`settings-${id}-underline`}
                value={value?.underline}
                onChange={(underline) =>
                    onChange({
                        ...value,
                        underline,
                    })
                }
            />
        </div>
    );
};

const IconOptions = ({ id, value, onChange, iconStyle = false }) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col space-y-1 border-b pb-10">
            <strong>{t("icon")}</strong>
            <div>Show Icon</div>
            <TextInput
                id={`settings-${id}-show`}
                value={value?.show}
                onChange={(show) =>
                    onChange({
                        ...value,
                        show,
                    })
                }
            />
            {iconStyle ? (
                <>
                    <div>Icon</div>
                    <TextInput
                        id={`settings-${id}-icon`}
                        value={value?.icon}
                        onChange={(icon) =>
                            onChange({
                                ...value,
                                icon,
                                lib: "fa",
                            })
                        }
                    />
                    <div>Size</div>
                    <TextInput
                        id={`settings-${id}-size`}
                        value={value?.size}
                        onChange={(size) =>
                            onChange({
                                ...value,
                                size,
                            })
                        }
                    />
                    <div>Position</div>
                    <TextInput
                        id={`settings-${id}-position`}
                        value={value?.position}
                        onChange={(position) =>
                            onChange({
                                ...value,
                                position,
                            })
                        }
                    />
                    <div>Margin</div>
                    <TextInput
                        id={`settings-${id}-margin`}
                        value={value?.margin}
                        onChange={(margin) =>
                            onChange({
                                ...value,
                                margin,
                            })
                        }
                    />
                </>
            ) : null}
        </div>
    );
};

const Colors = ({ id, value, onChange, icon = false, bullets = false }) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col space-y-1 border-b pb-10">
            <strong>{t("colors")}</strong>
            <div>Texto</div>
            <TextInput
                id={`settings-${id}-fg`}
                value={value?.fg}
                onChange={(fg) =>
                    onChange({
                        ...value,
                        fg,
                    })
                }
            />
            <div>Fondo</div>
            <TextInput
                id={`settings-${id}-bg`}
                value={value?.bg}
                onChange={(bg) =>
                    onChange({
                        ...value,
                        bg,
                    })
                }
            />
            {icon ? (
                <>
                    <div>Icon</div>
                    <TextInput
                        id={`settings-${id}-icon`}
                        value={value?.icon}
                        onChange={(icon) =>
                            onChange({
                                ...value,
                                icon,
                            })
                        }
                    />
                </>
            ) : null}
            {bullets ? (
                <>
                    <div>Bullets</div>
                    <TextInput
                        id={`settings-${id}-bullets`}
                        value={value?.bullets}
                        onChange={(bullets) =>
                            onChange({
                                ...value,
                                bullets,
                            })
                        }
                    />
                </>
            ) : null}
        </div>
    );
};
const Border = ({ id, value, onChange }) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col space-y-1 border-b pb-10">
            <strong>{t("border")}</strong>
            <div>Color</div>
            <TextInput
                id={`settings-${id}-color`}
                value={value?.color}
                onChange={(color) =>
                    onChange({
                        ...value,
                        color,
                    })
                }
            />
            <div>Width</div>
            <TextInput
                id={`settings-${id}-width`}
                value={value?.width}
                onChange={(width) =>
                    onChange({
                        ...value,
                        width,
                    })
                }
            />
        </div>
    );
};

const Corners = ({ id, value, onChange }) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col space-y-1 border-b pb-10">
            <strong>{t("corners")}</strong>
            <div>Radius</div>
            <TextInput id={`settings-${id}`} value={value} onChange={(value) => onChange(value)} placeholder="1.0" />
        </div>
    );
};

const getTabs = (toolbarType, widgetType) => {
    const configs = widgetType ? WIDGETS?.[widgetType]?.configurations : SCREEN_CONFIGURATIONS;
    const tabs = configs?.[toolbarType];
    return tabs?.length
        ? tabs.map((tab, index) => ({
              id: index + 1,
              index,
              name: "Tab " + (index + 1),
              inputs: tab,
          }))
        : [];
};

const parseInputs = (widgetType, tab, config, onChange) => {
    return tab?.inputs?.length
        ? tab?.inputs.map((data) => {
              if (Array.isArray(data)) {
                  const [configType, configID] = data;
                  return getConfigInput(configType, configID, config, onChange);
              }
              return getConfigInput("custom", widgetType, data, tab?.id, config, onChange);
          })
        : [];
};

const updateConfig = (id, config, onChange) => (newValue) => {
    const [key, value] = writeValue(id, newValue, config);
    if (onChange) {
        if (!config) config = {};

        const ensureIcon = () => {
            if (!config.items) config.items = {};
            if (!config.items.icon) config.items.icon = {};
        };
        const ensureDisabled = () => {
            if (!config.status) config.status = {};
            if (!config.status.disabled) config.status.disabled = {};
        };

        switch (key) {
            case "image":
                config.libraryRef = value?.libraryRef;
                config.externalUrl = value?.externalUrl;
                break;
            case "textSize":
                config.size = value;
                break;
            case "iconColor":
                ensureIcon();
                config.items.icon.fgColor = value;
                break;
            case "iconSize":
                ensureIcon();
                config.items.icon.size = value;
                break;
            case "iconPosition":
                ensureIcon();
                config.items.icon.position = value;
                break;
            case "disabledBgColor":
                ensureDisabled();
                config.status.disabled.bgColor = value;
                break;
            case "disabledFgColor":
                ensureDisabled();
                config.status.disabled.fgColor = value;
                break;
            case "disabledBorderColor":
                ensureDisabled();
                config.status.disabled.borderColor = value;
                break;
            case "disabledBorderWidth":
                ensureDisabled();
                config.status.disabled.borderWidth = value;
                break;
            default:
                break;
        }

        onChange({
            ...config,
            [key]: value,
        });
    }
};

const getInput = (configType, widgetType, id, tab, config, onChange) => {
    if (configType === "custom") {
        switch (id) {
            case "image":
                return (
                    <AddImage
                        key={id}
                        id={id}
                        value={{
                            externalUrl: config?.data?.externalUrl,
                            libraryRef: config?.data?.libraryRef,
                        }}
                        onChange={(value) => {
                            onChange({
                                ...config,
                                data: {
                                    ...config?.data,
                                    externalUrl: value?.externalUrl,
                                    libraryRef: value?.libraryRef,
                                },
                            });
                        }}
                    />
                );
            case "imageAdjust":
                return (
                    <ImageAdjust
                        key={id}
                        id={id}
                        value={config?.data?.adjust}
                        onChange={(value) => {
                            onChange({
                                ...config,
                                data: {
                                    ...config?.data,
                                    adjust: value,
                                },
                            });
                        }}
                    />
                );
            case "alignment":
                return (
                    <Alignment
                        key={id}
                        id={id}
                        value={{
                            x: config?.style?.alignment,
                            y: config?.style?.vAlignment,
                        }}
                        onChange={(value) => {
                            onChange({
                                ...config,
                                style: {
                                    ...config?.style,
                                    alignment: value?.x,
                                    vAlignment: value?.y,
                                },
                            });
                        }}
                    />
                );
            case "text":
            case "textStyle":
                const hasTitle = id === "text";
                return (
                    <TextOptions
                        key={id}
                        id={id}
                        title={hasTitle}
                        value={{
                            ...(hasTitle ? { title: config?.data?.title?.text } : {}),
                            fontStyle: config?.style?.fontStyle,
                            font: config?.style?.font?.name,
                            libraryRef: config?.style?.font?.libraryRef,
                            externalUrl: config?.style?.externalUrl,
                            size: config?.style?.textSize,
                            bold: config?.style?.bold,
                            italic: config?.style?.italic,
                            underline: config?.style?.underline,
                        }}
                        onChange={(value) => {
                            onChange({
                                ...config,
                                ...(hasTitle ? { data: { ...config?.data, title: { text: value?.title } } } : {}),
                                style: {
                                    ...config?.style,
                                    fontStyle: value?.fontStyle,
                                    font: {
                                        ...config?.style?.font,
                                        name: value?.font,
                                    },
                                    externalUrl: value?.externalUrl,
                                    textSize: value?.size,
                                    bold: value?.bold,
                                    italic: value?.italic,
                                    underline: value?.underline,
                                },
                            });
                        }}
                    />
                );
            case "icon":
            case "showIcon":
                const iconStyle = id === "icon";
                return (
                    <IconOptions
                        key={id}
                        id={id}
                        iconStyle={iconStyle}
                        value={{
                            show: config?.data?.showIcon,
                            ...(iconStyle
                                ? {
                                      icon: config?.data?.icon,
                                      lib: config?.data?.lib,
                                      size: config?.style?.items?.icon?.size,
                                      position: config?.style?.items?.icon?.position,
                                      margin: config?.style?.items?.icon?.padding,
                                  }
                                : {}),
                        }}
                        onChange={(value) => {
                            onChange({
                                ...config,
                                data: {
                                    ...config?.data,
                                    showIcon: value?.show,
                                    ...(iconStyle ? { icon: value?.icon, lib: value?.lib } : {}),
                                },
                                ...(iconStyle
                                    ? {
                                          style: {
                                              ...config?.style,
                                              iconSize: value?.size,
                                              iconMargin: value?.margin,
                                          },
                                      }
                                    : {}),
                            });
                        }}
                    />
                );
            case "colors":
            case "colorsWithIcon":
            case "colorsWithBullets":
                const hasIcon = id === "colorsWithIcon";
                const hasBullets = id === "colorsWithBullets";
                //TODO Check values based on tab and widgetType
                return (
                    <Colors
                        key={id}
                        id={id}
                        widgetType={widgetType}
                        tab={tab}
                        value={{
                            fg: config?.style?.fgColor,
                            bg: config?.style?.bgColor,
                            ...(hasIcon
                                ? {
                                      icon: config?.style?.iconColor || config?.style?.items?.icon?.fgColor,
                                  }
                                : {}),
                            ...(hasBullets
                                ? {
                                      bullets: config?.style?.items?.bullets?.fgColor,
                                  }
                                : {}),
                        }}
                        icon={hasIcon}
                        bullets={hasBullets}
                        onChange={(value) => {
                            onChange({
                                ...config,
                                style: {
                                    ...config?.style,
                                    fgColor: value?.fg,
                                    bgColor: value?.bg,
                                    ...(hasIcon || hasBullets
                                        ? {
                                              ...(hasIcon ? { iconColor: value?.icon } : {}),
                                              items: {
                                                  ...config?.style?.items,
                                                  ...(hasBullets ? { bullets: value?.bullets } : {}),
                                                  ...(hasIcon
                                                      ? {
                                                            icon: {
                                                                ...config?.style?.items?.icon,
                                                                fgColor: value?.icon,
                                                            },
                                                        }
                                                      : {}),
                                              },
                                          }
                                        : {}),
                                },
                            });
                        }}
                    />
                );
            case "border":
                return (
                    <Border
                        key={id}
                        id={id}
                        value={{
                            color: config?.style?.borderColor,
                            width: config?.style?.borderWidth,
                        }}
                        onChange={(value) => {
                            onChange({
                                ...config,
                                style: {
                                    ...config?.style,
                                    borderColor: value?.color,
                                    borderWidth: value?.width,
                                },
                            });
                        }}
                    />
                );
            case "corners":
                return (
                    <Corners
                        key={id}
                        id={id}
                        value={config?.style?.radius}
                        onChange={(value) => {
                            onChange({
                                ...config,
                                style: {
                                    ...config?.style,
                                    radius: value,
                                },
                            });
                        }}
                    />
                );
            default:
                return <Icon type="warning" text={`Unconfigured setting with id ${id}`} />;
        }
    }
    return {
        id,
        type: configType,
        label: id,
        placeholder: getPlaceholder(id),
        value: readValue(id, config),
        onChange: updateConfig(id, config, onChange),
    };
};

const getConfigInput = (configType, widgetType, id, tab, data, onChange) => {
    let config;
    if (configType === "custom") {
        config = data;
    } else if (configType === "actions") {
        config = data?.actions?.[configType]?.[0];
    } else {
        config = data?.[configType];
    }
    return getInput(configType, widgetType, id, tab, config, (newConfig) => {
        if (configType === "custom") {
            onChange((current) => ({
                ...current,
                ...newConfig,
            }));
        } else if (configType === "actions") {
            onChange((current) => ({
                ...current,
                actions: {
                    actions: [newConfig],
                },
            }));
        }
        onChange((current) => ({
            ...current,
            [configType]: {
                ...current?.[configType],
                ...newConfig,
            },
        }));
    });
};

const booleanConfigs = ["bold", "italic", "underline", "showIcon", "showIconAlarmSet", "blurMainScreen"];
const numericConfigs = [
    "opacity",
    "imageOpacity",
    "radius",
    "borderWidth",
    "disabledBorderWidth",
    "textSize",
    "iconSize",
    "iconMargin",
];
const urlConfigs = ["images", "externalUrl", "music"];

const getPlaceholder = (id) => {
    switch (id) {
        case "opacity":
        case "imageOpacity":
            return "100";
        case "radius":
            return "1.0";
        case "adjust":
            return "contain | cover | cover-adjust";
        case "fontStyle":
            return "heading | paragraph";
        case "libraryRef":
            return "Asset ref";
        case "iconPosition":
            return "top | bottom | left | right";
        case "alignment":
            return "left | center | right";
        case "vAlignment":
            return "top | center | bottom";
        case "menuType":
            return "fixed-menu | hidden-menu | none";
        case "buttonRC":
            return "[] home-portal | menu | left | top | bottom | right";
        default:
            if (booleanConfigs.includes(id)) {
                return "true | false";
            }
            if (numericConfigs.includes(id)) {
                return "0";
            }
            if (urlConfigs.includes(id)) {
                return "https://";
            }
            return "";
    }
};

const readValue = (id, config) => {
    switch (id) {
        case "music":
            return config?.music?.externalUrl;
        case "images":
            return config?.images?.[0]?.externalUrl;
        case "font":
            return config?.font?.name; //font {name, libraryRef}
        case "title":
            return config?.title?.text; //title {id,text}
        case "buttonRC":
            return config?.buttonRC?.join(",");
        default:
            return config?.[id];
    }
};
const writeValue = (id, newValue, config) => {
    switch (id) {
        case "music":
            newValue = { externalUrl: newValue };
            break;
        case "images":
            newValue = [{ externalUrl: newValue }];
            break;
        case "font":
            newValue = {
                ...config?.[id],
                name: newValue,
            };
            break;
        case "title":
            newValue = {
                ...config?.[id],
                text: newValue,
            };
            break;
        case "buttonRC":
            newValue = newValue?.length ? newValue.split(",").map((v) => v.trim()) : [];
            break;
        default:
            if (booleanConfigs.includes(id)) {
                newValue = newValue === "true";
            }
            if (numericConfigs.includes(id)) {
                newValue = parseFloat(newValue);
                if (isNaN(newValue)) {
                    newValue = "";
                }
            }
    }
    return [id, newValue];
};

export default Toolbar;
