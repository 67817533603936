import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
//Components
import UseSectionHeader from "../../../useSectionHeader";
import NSStatus, { networkServerErrorDiv } from "../../../../hooks/GraphqlCalls/Hotel/Monitoring/NSStatus";
//Utils
import ExportDevices from "../../../../hooks/Utils/Hotel/Monitor/ExportDevices";
import { Session } from "../../../../hooks/Utils/Session";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { CastError } from "./CastError";
import { parseBoolean } from "hooks/Utils/Utils";
import { useGetCCguestNetwork } from "hooks/GraphqlCalls/Hotel/Monitoring/useGetCCguestNetwork";
import FilterCCsContext, { FilterCCsProvider } from "../context/FilterCCsContext";
import Table, { stringValue } from "components/ZafiroTable";
import { useListDevices } from "hooks/GraphqlCalls/Hotel/Monitoring/useListDevices";
import DropdownButton from "components/DropdownButton";
import Icon from "components/Icon";
import Button from "components/Button";
import Modal, { useModal } from "components/Modal";
import { ChromeCastWarnings } from "../ChromeCastWarnings";
import { useDevicesActions } from "hooks/GraphqlCalls/Hotel/Monitoring/useDevicesActions";
import Loading from "components/Loading";
import { DashboardCards } from "components/Section/DasboardCards/DashboardCards";

const MonitorDevicesWithContext = () => {
    const { t } = useTranslation();
    const { serverError, customServerError } = useSelector((state) => state.table);
    const { permissions } = useSelector((state) => state.ui);
    const { devices, filters, dashboardCards, count, getDevices } = useListDevices();
    const {
        page,
        setPage,
        search,
        setSearch,
        sort,
        setSort,
        deviceFunction,
        setDeviceFunction,
        deviceModel,
        setDeviceModel,
        deviceLocation,
        setDeviceLocation,
        deviceStatus,
        setDeviceStatus,
        roomType,
        setRoomType,
    } = useContext(FilterCCsContext);
    const [startDataExport, setStartDataExport] = useState(false);
    const [monitorLink, setMonitorLink] = useState(null);
    const networkWarning = parseBoolean(Session.getSessionProp("networkWarnings"));
    const hasCast = permissions?.product?.cast;
    const hasNetworkMonitor = permissions?.hotel?.networkMonitor;

    NSStatus({ clearTableResults: false, showGenericNotification: false, customServerError: true });

    const GET_MONITOR_LINK = gql(`query{
        getMonitorLink{
          link
        }
      }
      `);

    const [getMonitorLink, { data: dataMonitorLink }] = useLazyQuery(GET_MONITOR_LINK, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        getMonitorLink();
    }, []);

    useEffect(() => {
        const link = dataMonitorLink?.getMonitorLink?.link;
        if (link) {
            setMonitorLink(link);
        }
    }, [dataMonitorLink]);

    useEffect(() => {
        getDevices();
    }, [page, search, sort, deviceModel, deviceFunction, deviceStatus, deviceLocation, roomType]);

    const exportButton = () => {
        setStartDataExport(true);
    };

    return (
        <>
            <UseSectionHeader
                title={hasNetworkMonitor ? t("property-monitor") : t("monitor of devices and networks")}
                value={"demo"}
                {...(hasNetworkMonitor ? { navToPreviousPage: true } : {})}
            />
            <ExportDevices
                startDataExport={startDataExport}
                setStartDataExport={setStartDataExport}
                deviceType="all"
            ></ExportDevices>
            <DashboardCards data={dashboardCards} />
            {hasCast && networkWarning && !serverError ? <CastError collapsible={true} /> : null}
            {serverError ? networkServerErrorDiv(customServerError, t) : null}
            <div className="main-container">
                {devices ? (
                    <Table
                        id="monitor-devices-table"
                        cols={["name", "function", "room", "status", "lastActivityDate"]}
                        header={{
                            name: { title: t("name"), sortable: true, width: "25%" },
                            function: { title: t("device-function"), sortable: true, width: "15%" },
                            room: { title: t("tv-location"), sortable: true, width: "20%" },
                            status: { title: t("status"), sortable: true, width: "15%" },
                            lastActivityDate: { title: t("last-display"), sortable: true, width: "25%" },
                        }}
                        rows={devices}
                        paginate={true}
                        showCount={true}
                        customFilterColocated={true}
                        topRightCorner={<RightCornerComponent monitorLink={monitorLink} exportButton={exportButton} />}
                        customPagination={{ value: page, onChange: setPage }}
                        customCount={count || 0}
                        customSearch={{ value: search, onChange: setSearch }}
                        customSort={{ value: sort, onChange: setSort }}
                        search={true}
                        filters={[
                            {
                                id: "filter-by-device-function",
                                title: t("device-function"),
                                multiple: true,
                                options: filters?.deviceFunctions,
                                onChange: (value) => {
                                    setPage(1);
                                    setDeviceFunction(value);
                                },
                            },
                            {
                                id: "filter-by-model",
                                title: t("device-model"),
                                multiple: true,
                                options: filters?.deviceModels,
                                onChange: (value) => {
                                    setPage(1);
                                    setDeviceModel(value);
                                },
                            },
                            {
                                id: "filter-by-floor",
                                title: t("floor"),
                                multiple: true,
                                options: filters?.floors,
                                onChange: (value) => {
                                    setPage(1);
                                    setDeviceLocation(value);
                                },
                            },
                            {
                                id: "filter-by-room-type",
                                title: t("area-type"),
                                options: [
                                    { id: "rooms", label: t("rooms"), value: "GUESTROOM" },
                                    { id: "common-zones", label: t("common-zones"), value: "COMMONZONE" },
                                ],
                                onChange: (value) => {
                                    setPage(1);
                                    setRoomType(value);
                                },
                            },
                            {
                                id: "filter-by-status",
                                title: t("status"),
                                multiple: true,
                                options: filters?.status,
                                onChange: (value) => {
                                    setPage(1);
                                    setDeviceStatus(value);
                                },
                            },
                        ]}
                    />
                ) : (
                    <Loading adjust="section-loading" style={{ height: "50vh" }} />
                )}
            </div>
        </>
    );
};

export const MonitorDevices = () => (
    <FilterCCsProvider>
        <MonitorDevicesWithContext />
    </FilterCCsProvider>
);

const RightCornerComponent = ({ monitorLink, exportButton }) => {
    const { authoriseAllChromecast, syncAllChromecast } = useDevicesActions();
    const { tableLength } = useGetCCguestNetwork();
    const { t } = useTranslation();
    const { open, close } = useModal();
    const { permissions } = useSelector((state) => state.ui);
    const superUser = Session.isSuperUser();

    return (
        <div className="w-full flex flex-col gap-6 items-end">
            <div className="flex items-center gap-6">
                <div className="flex-1">
                    {superUser && (
                        <DropdownButton
                            id="staff-actions"
                            design="blue-outline"
                            label={
                                <div className="flex items-center gap-2">
                                    <Icon type="superuser" size="3xl" className="text-zafiro-800" />
                                    <span className="text-zafiro-800 font-bold">{t("es-staff-actions")}</span>
                                </div>
                            }
                            options={[
                                {
                                    key: "chromecast-authorize-all",
                                    label: t("chromecast-authorize-all"),
                                    description: t("chromecast-authorize-all-text"),
                                    function: authoriseAllChromecast,
                                    modal: true,
                                },
                                {
                                    key: "sync-casting-gateway",
                                    label: t("sync-casting-gateway"),
                                    description: t("sync-casting-gateway-text"),
                                    function: syncAllChromecast,
                                    modal: true,
                                },
                                {
                                    key: "check-zombie-machines",
                                    label: t("check-zombie-machines"),
                                    link: true,
                                },
                            ].map((option) => {
                                if (option.link) {
                                    return (
                                        <a
                                            className="px-4 mb-2 cursor-pointer flex items-center text-gray-900"
                                            id={`${option.key}-option`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={monitorLink}
                                        >
                                            {option.label}
                                            <Icon
                                                type="external-link"
                                                size="2xl"
                                                className="ml-1 focus:outline-none text-gray-800"
                                            />
                                        </a>
                                    );
                                } else if (option.modal) {
                                    return (
                                        <div
                                            className="px-4 mb-2 cursor-pointer flex items-center text-gray-900"
                                            id={`${option.key}-option`}
                                            key={option.key}
                                            onClick={() =>
                                                open(
                                                    <Modal
                                                        id={`${option.key}-modal`}
                                                        title={option.label}
                                                        footer={
                                                            <div className="flex justify-end gap-4">
                                                                <Button
                                                                    id="close"
                                                                    className="btn btn-white"
                                                                    onClick={close}
                                                                >
                                                                    {t("close")}
                                                                </Button>
                                                                <Button
                                                                    id="accept"
                                                                    design="blue"
                                                                    onClick={() => option?.function()}
                                                                >
                                                                    {t("accept")}
                                                                </Button>
                                                            </div>
                                                        }
                                                    >
                                                        {option.description}
                                                    </Modal>
                                                )
                                            }
                                        >
                                            {option.label}
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div
                                            className="px-4 mb-2 cursor-pointer flex items-center text-gray-900"
                                            id={`${option.key}-option`}
                                            key={option.key}
                                            onClick={option.action}
                                        >
                                            {option.label}
                                        </div>
                                    );
                                }
                            })}
                        />
                    )}
                </div>
                <Button id="export-devices" design="blue" onClick={exportButton}>
                    {t("export-devices")}
                </Button>
            </div>
            {tableLength > 0 && permissions?.hotel?.networkAlerts ? <ChromeCastWarnings /> : null}
        </div>
    );
};
