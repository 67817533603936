import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import UseSearch from "../useSearch";
import { useMSQuery } from "../../hooks/GraphqlCalls/useMSQuery";
import { gql } from "apollo-boost";
import { setLoadingModalContent } from "../../actions/uiActions";
import { capitalizeFirst } from "../../hooks/Utils/Utils";

export const UseModalAssignedTvs = ({ index }) => {
    const dispatch = useDispatch();

    const { isDefault, tvsAssigned } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const inputTvId = useSelector((state) => state.ui.modalContent.id);
    const { t } = useTranslation();
    const [tvsFiltered, setTvsFiltered] = useState();
    const [allTvs, setAllTvs] = useState();
    const [search, setSearch] = useState(null);

    const GET_ASSIGNED_TVS = gql`
        query Rooms($id: Int64!, $isDefault: Boolean!, $type: RoomType!) {
            rooms(filter: { tvinput: { id: $id, isDefault: $isDefault }, type: $type }) {
                results {
                    name
                    number
                    roomTVs {
                        id
                        tvInputID
                        name
                    }
                }
            }
        }
    `;

    const [getAssignedTvsQuery, { data, networkStatus }] = useMSQuery(GET_ASSIGNED_TVS, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        dispatch(setLoadingModalContent(true));
        getAssignedTvsQuery({ variables: { id: String(inputTvId), isDefault: isDefault, type: "GUESTROOM" } });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (data?.rooms?.results && networkStatus === 7) {
            setAllTvs(data.rooms.results);
            setTvsFiltered(data.rooms.results);
            dispatch(setLoadingModalContent(false));
        }
        // eslint-disable-next-line
    }, [data, networkStatus]);

    useEffect(() => {
        if (search) {
            setTvsFiltered(
                allTvs.filter(
                    (tv) => tv.name.toLowerCase().includes(search.toLowerCase()) || String(tv.number).includes(search)
                )
            );
        } else {
            setTvsFiltered(allTvs);
        }
        // eslint-disable-next-line
    }, [search]);

    // search handler
    const inputSearchChange = (change) => {
        if (change.target.value && change.target.value !== undefined) {
            setSearch(change.target.value);
        } else if (change.target.value === undefined || change.target.value === "") {
            setSearch(null);
        }
    };

    const printRoomTv = (room, index) => {
        return room.roomTVs.map((roomTV) => {
            if (
                (isDefault && (roomTV.tvInputID === inputTvId || roomTV.tvInputID === 0)) ||
                (!isDefault && roomTV.tvInputID === inputTvId)
            ) {
                return (
                    <div
                        key={index}
                        className="mx-2 inline-flex border-b border-gray-200 py-2"
                        id={`recipient_${index}`}
                    >
                        <div className="w-3/6 pr-2 my-auto">
                            <div className={`py-1  inline-block cursor-default`} style={{ maxWidth: "100%" }}>
                                {room.name}
                            </div>
                            <div className="text-gray-300">{room.number}</div>
                        </div>
                        <div className="w-3/6 pr-2 my-auto">
                            <div
                                className={`rounded py-1 px-4 m-1 inline-block text-white  bg-blue-300 truncate cursor-default`}
                                style={{ maxWidth: "100%" }}
                            >
                                {roomTV.name}
                            </div>
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        });
    };

    return (
        <>
            {tvsFiltered ? (
                <>
                    <div className="flex">
                        <div className="w-3/6">
                            <UseSearch
                                handleChangeSearch={inputSearchChange}
                                placeholder={capitalizeFirst(t("search"))}
                            />
                        </div>
                        <div className="w-3/6 pt-2 text-right">
                            {tvsAssigned || 0} {t("assigned-tvs")}
                        </div>
                    </div>
                    <div
                        id="tvs_show-header"
                        className="grid grid-cols-2 grid-flow-row overflow-y-auto mt-4 rounded border-b border-gray-200 font-bold text-lg"
                        style={{}}
                    >
                        <div className="pl-2">{capitalizeFirst(t("room"))}</div>
                        <div className="pl-2">{capitalizeFirst(t("locations"))}</div>
                    </div>
                    <div
                        id="tvs_show"
                        className="grid grid-cols-1 grid-flow-row overflow-y-auto rounded"
                        style={{
                            height: "50vh",
                            gridAutoRows: "min-content",
                        }}
                    >
                        {tvsFiltered.map((tv, index) => printRoomTv(tv, index))}
                    </div>
                </>
            ) : null}
        </>
    );
};
