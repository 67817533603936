import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { vw2px, GetThemeDefault, isEmpty } from "../../../../hooks/Utils/DesignUtils";
import PropertyConfig from "../../../../hooks/GraphqlCalls/Hotel/PropertyConfig";
import Widget from "../Widgets/Widget";
import { EditorContext, WidgetContext } from "../../../../contexts/editor";
import { DEVICE } from "constants/editor";

import { useAuth } from "hooks/Session/auth";

const WidgetChatButton = ({ setWarning }) => {
    const { project } = useAuth();
    const { deviceType, editorSize, itemSelected, theme } = useContext(EditorContext);
    const { config: cfg, id: widgetID } = useContext(WidgetContext);

    const { t } = useTranslation();
    const { lang } = useParams();
    const {
        gridItems,
        widgetToolbarVisible: toolbar,
        widgetToolbarVisibleTab: tab,
    } = useSelector((state) => state.design);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;

    const size = (s) => vw2px(s, editorSize?.width, deviceType === DEVICE.TYPE.MOBILE ? 3 : 1);

    const { config, loading, error } = PropertyConfig(project?.id);

    const chatURL = config ? config.chatURL : null;
    const warning = chatURL || loading ? false : error ? error : t("chatbutton-warning-unconfigured");

    const defaultBgColor = GetThemeDefault({ theme, id: "bgColor" });
    const defaultFgColor = GetThemeDefault({ theme, id: "fgColor" });
    const defaultIconColor = GetThemeDefault({ theme, id: "iconColor" });
    const defaultBorderColor = GetThemeDefault({ theme, id: "borderColor" });
    const defaultDisabledBgColor = GetThemeDefault({ theme, id: "disabledBgColor" });
    const defaultDisabledFgColor = GetThemeDefault({ theme, id: "disabledFgColor" });
    const defaultDisabledBorderColor = GetThemeDefault({ theme, id: "disabledBorderColor" });

    const disabled = itemSelected === widgetID && toolbar === "style" && tab === "disabled";

    const style = cfg?.style || Widget.ParseStyle(widget ? widget.widgetStyle : null, deviceType);
    const bgColor = style?.bgColor || defaultBgColor;
    const fgColor = style?.fgColor || defaultFgColor;
    const iconColor = style?.iconColor || style?.items?.icon?.fgColor || defaultIconColor;
    const radius = style && (style.radius || style.radius === 0) ? style.radius : 0;
    const textSize = style?.textSize || style?.size || 1.7;
    const iconSize = style?.iconSize || style?.items?.icon?.size || 1.7;
    const iconPosition = style?.iconPosition || style?.items?.icon?.position || "right";
    const iconMargin = style && !isEmpty(style.iconMargin) ? style.iconMargin : 0.5;
    const alignment = style && style.alignment ? style.alignment : "center";
    const valignment = style && style.vAlignment ? style.vAlignment : "center";
    const borderColor = style?.borderColor || defaultBorderColor;
    const borderWidth = style && style.borderWidth !== null ? style.borderWidth : 0;
    const bold = style ? style.bold !== false : true;
    const italic = style ? style.italic === true : true;
    const underline = style ? style.underline === true : true;
    const disabledBgColor = style?.disabledBgColor || style?.status?.disabled?.bgColor || defaultDisabledBgColor;
    const disabledFgColor = style?.disabledFgColor || style?.status?.disabled?.fgColor || defaultDisabledFgColor;
    const disabledBorderColor =
        style?.disabledBorderColor || style?.status?.disabled?.borderColor || defaultDisabledBorderColor;
    const disabledBorderWidth = style?.disabledBorderWidth || style?.status?.disabled?.borderWidth || 0;

    const data = cfg?.data || Widget.ParseData(widget ? widget.data : null, deviceType);
    const title = data
        ? data.title && data.title[lang]
            ? data.title[lang]
            : data.title && data.title.text
            ? data.title.text
            : ""
        : "";
    const showIcon = data ? data.showIcon : true;

    useEffect(() => {
        const wz = document.querySelector(`#widget_zone_${widgetID}`);
        if (wz) {
            wz.style.backgroundColor = "transparent";
            wz.style.borderColor = "";
            wz.style.borderWidth = 0;
            wz.style.borderRadius = 0;
        }
    }, [gridItems, widgetID]);

    useEffect(() => {
        if (setWarning && typeof setWarning === "function") {
            setWarning(widgetID, warning);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widgetID, warning]);

    useEffect(() => {
        if (error) console.error(error);
    }, [error]);

    let iconStyle = {
        color: disabled ? disabledFgColor : iconColor,
        fontSize: size(iconSize) + "px",
        display: iconPosition === "top" || iconPosition === "bottom" ? "block" : "inline",
    };
    switch (iconPosition) {
        case "top":
            iconStyle.paddingBottom = size(iconMargin) + "px";
            break;
        case "bottom":
            iconStyle.paddingTop = size(iconMargin) + "px";
            break;
        case "left":
            iconStyle.paddingRight = size(iconMargin) + "px";
            break;
        case "right":
        default:
            iconStyle.paddingLeft = size(iconMargin) + "px";
    }
    const icon = <i className="icon icon-chat" style={iconStyle}></i>;

    return (
        <div
            className={`grid place-items-${
                alignment === "left" ? "start" : alignment === "right" ? "end" : "center"
            } items-${valignment === "top" ? "start" : valignment === "bottom" ? "end" : "center"} shadow h-full p-2`}
            style={{
                color: disabled ? disabledFgColor : fgColor,
                fontSize: size(textSize) + "px",
                textAlign: alignment,
                backgroundColor: disabled ? disabledBgColor : bgColor,
                borderColor: disabled ? disabledBorderColor : borderColor,
                borderWidth: (disabled ? disabledBorderWidth : borderWidth) + "px",
                borderRadius: radius + "rem",
            }}
        >
            <div className={`text-center`}>
                {showIcon && (iconPosition === "left" || iconPosition === "top") ? icon : null}
                <span
                    style={{
                        fontWeight: bold ? "bold" : 400,
                        fontStyle: italic ? "italic" : "normal",
                        textAlign: "center",
                        textDecoration: underline ? "underline" : "none",
                        display: iconPosition === "top" || iconPosition === "bottom" ? "block" : "inline-block",
                    }}
                >
                    {title}
                </span>
                {showIcon && (iconPosition === "right" || iconPosition === "bottom") ? icon : null}
            </div>
        </div>
    );
};

export default WidgetChatButton;
