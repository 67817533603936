import React from "react";
import { useModal } from "components/Modal";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";

export const useEditGroups = ({ group, refetch, setLoadingRefresh, type }) => {
    const [selectData, setSelectData] = useState({ options: [], groupName: group?.name });
    const [groupName, setGroupName] = useState(group?.name);
    const [isOk, setIsOk] = useState(false);

    const { t } = useTranslation();
    const { close } = useModal();

    const roomsQuery = gql`
        query HotelRooms($roomgroupId: [Int64]!) {
            data: hotelRooms(
                layout: { orderBy: { field: "id", criteria: "asc" }, size: 100000 }
                filter: { roomType: GUESTROOM }
            ) {
                info {
                    count
                }
                results {
                    id
                    name
                }
            }
            selected: hotelRooms(
                layout: { orderBy: { field: "id", criteria: "asc" } }
                filter: { roomGroupID: $roomgroupId, roomType: GUESTROOM }
            ) {
                results {
                    id
                }
            }
        }
    `;

    const guestQuery = gql`
        query Guests($ggId: [Int64]!) {
            data: guests(filter: { checkedOut: false }, mainGuest: true, orderBy: { field: "name", criteria: "asc" }) {
                info {
                    count
                }
                results {
                    id
                    name
                    surname
                    rooms {
                        name
                        number
                    }
                }
            }
            selected: guests(filter: { guestGroup: $ggId }) {
                results {
                    id
                }
            }
        }
    `;

    const editGroupQuery = gql`
        mutation updateRoomGroup($name: String!, $rooms: [Int64]!, $id: Int64!) {
            updateRoomGroup(name: $name, rooms: $rooms, id: $id, replace: true) {
                error
                id
                ok
            }
        }
    `;

    const editGuestGroupQuery = gql`
        mutation updateGuestGroup($name: String!, $guests: [Int64]!, $id: Int64!) {
            updateGuestGroup(id: $id, batch: true, name: $name, guests: $guests) {
                error
                id
                ok
            }
        }
    `;

    const selectQuery = type === "room" ? roomsQuery : guestQuery;
    const selectMutation = type === "room" ? editGroupQuery : editGuestGroupQuery;

    const [executeQuery, { data, loading: queryLoading, error, called }] = useLazyQuery(selectQuery, {
        fetchPolicy: "network-only",
    });

    const [executeMutation] = useMutation(selectMutation);

    useEffect(() => {
        if (type === "room") {
            executeQuery({
                variables: {
                    roomgroupId: [group?.id.toString()] || "",
                },
                updateRoomGroup,
            });
        } else if (type === "guest") {
            executeQuery({
                variables: {
                    ggId: [group?.id.toString()] || "",
                },
                updateRoomGroup,
            });
        }
    }, []);

    useEffect(() => {
        if (!queryLoading && !error && data?.data) {
            setSelectData({
                options: data?.data?.results?.map((room) => {
                    return {
                        id: room?.id,
                        label: `${room?.name} ${room?.surname !== undefined ? room?.surname : ""}`,
                        roomsName: room?.rooms?.map((r) => (
                            <span className="text-xs text-gray-800 ml-4">{r.name}</span>
                        )),
                        selected: data?.selected?.results?.find((selected) => selected.id === room.id) ? true : false,
                        value: room?.id,
                    };
                }),
                groupName: group?.name,
            });
        }
    }, [data]);

    const handleSelect = (value) => {
        setSelectData({
            options: selectData?.options?.map((room) => {
                if (value?.find((selected) => room.id === selected)) {
                    return {
                        ...room,
                        selected: true,
                    };
                } else {
                    return {
                        ...room,
                        selected: false,
                    };
                }
            }),
            groupName: group?.name,
        });
    };

    const handleDelete = (id) => {
        setSelectData({
            options: selectData.options.map((room) => {
                if (room.id === id) {
                    return {
                        ...room,
                        selected: false,
                    };
                } else {
                    return room;
                }
            }),
            groupName: group?.name,
        });
    };

    const editGroupName = (value) => {
        setGroupName(value);
        setSelectData({
            ...selectData,
            groupName: value,
        });
    };

    const updateRoomGroup = () => {
        if (type === "room") {
            executeMutation({
                variables: {
                    name: groupName,
                    rooms: selectData?.options?.filter((room) => room?.selected).map((room) => room?.id),
                    id: group?.id,
                },
            }).then((response) => {
                if (response?.data?.updateRoomGroup?.ok) {
                    setLoadingRefresh(true);
                    setIsOk(response?.data?.updateRoomGroup?.ok);
                    toast.success(t("operation-successful"));
                }
                close();
            });
        } else if (type === "guest") {
            executeMutation({
                variables: {
                    name: groupName,
                    guests: selectData?.options?.filter((room) => room?.selected).map((room) => room?.id),
                    id: group?.id,
                },
            }).then((response) => {
                if (response?.data?.updateGuestGroup?.ok) {
                    setLoadingRefresh(true);
                    setIsOk(response?.data?.updateGuestGroup?.ok);
                    toast.success(t("operation-successful"));
                }
                close();
            });
        }
    };

    useEffect(() => {
        if (isOk && typeof refetch === "function") {
            refetch();
            setTimeout(() => {
                setLoadingRefresh(false);
            }, 1500);
        }
    }, [isOk]);

    return { selectData, handleSelect, handleDelete, editGroupName, queryLoading, called, updateRoomGroup };
};
