import React, { useEffect, useRef, useState } from "react";
import UseSectionHeader from "../../../components/useSectionHeader";
import { useTranslation } from "react-i18next";
import ZafiroTable from "components/ZafiroTable";
import { useLazyQuery, useMutation } from "react-apollo";
import { gql } from "apollo-boost";
import { useConfirm } from "components/Modal";
import Parser from "hooks/Utils/Parser";
import { Session } from "hooks/Utils/Session";
import { DisplayTimes } from "./DisplayTimes";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirst, formatPrice } from "hooks/Utils/Utils";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "components/ReactTooltip";
import Button from "components/Button";
import Icon from "components/Icon";
import ExportMovieCharges from "./ExportMovieCharges";
import { setRefreshData } from "actions/tableActions";

import { useAuth } from "hooks/Session/auth";
import SessionDate from "date";

const WatchedMoviesList = ({
    chargesData,
    topBar = true,
    modalContent,
    displayTimesWidth,
    hideSearch,
    onClickDisplayTimes,
    onCloseDisplayTimes,
    showTimesIcon,
    roomDetail,
}) => {
    const navigate = useNavigate();

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const { open: confirm } = useConfirm();
    const { project } = useAuth();

    const isSuperUser = Session.isSuperUser();
    const tableUsersRef = useRef(null);

    const projectID = project?.id;
    const { permissions } = useSelector((state) => state.ui);
    const { movies } = useSelector((state) => state.sectionContent);
    const freeToguest = permissions?.movies?.freeToguest;
    const adultContent = permissions?.movies?.adult;
    const revokePerm = isSuperUser || !freeToguest;
    const showPrice = !freeToguest || adultContent;
    var oneMonthAgo = new Date(new Date().setHours(0, 0, 0, 0));
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    const [movieChargesList, setMovieChargesList] = useState([]);
    const [startDataExport, setStartDataExport] = useState();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState();
    const [sort, setSort] = useState();
    const [dateFrom, setDateFrom] = useState(oneMonthAgo.toISOString());
    const [dateTo, setDateTo] = useState();
    const [pmsFilter, setPMSFilter] = useState();
    const [tableData, setTableData] = useState();

    const showExportBtn = topBar && !modalContent;
    const showDateFilter = !modalContent;
    const externalControl = !chargesData;

    const filterOptionAll = { id: "all", value: "ALL", label: `-- ${t("all")} --` };

    const PMS_STATUS_OPTIONS = [
        filterOptionAll,
        {
            value: "NO_PMS",
            label: t("NO_PMS"),
        },
        {
            value: "PMS_PENDING",
            label: t("PMS_PENDING"),
        },
        {
            value: "PMS_CHARGED",
            label: t("PMS_CHARGED"),
        },
        {
            value: "PMS_REJECTED",
            label: t("PMS_REJECTED"),
        },
    ];

    const translateSortCriteria = () => {
        if (!sort) {
            return "createdAt";
        }
        switch (sort?.field) {
            case "room":
                return "roomNumber";
            case "rentalDate":
                return "createdAt";
            case "pmsCharges":
                return "pmsStatus";

            default:
                return sort?.field;
        }
    };

    const filtersText = pmsFilter && pmsFilter != "ALL" ? `pmsStatus: ${pmsFilter}` : "";
    const dateFromFilter = dateFrom ? `dateFrom:"${dateFrom}"` : "";
    const dateToFilter = dateTo ? `dateTo:"${dateTo}"` : "";

    const GET_WATCHED_MOVIES = gql`
        {
            charges(
                page:${page},
                size:6, 
                filter: { 
                    type: MOVIE ${search ? `search:"${search}"` : ""},
                    ${filtersText}
                },
                ${dateFromFilter}
                ${dateToFilter}
                orderBy: { field: "${translateSortCriteria()}", criteria: "${sort?.criteria?.toUpperCase() || "DESC"}" }
            ){
                info {
                    count
                    page
                    size
                }
                results {
                    id
                    extId
                    stayGuestRoomId
                    createdAt
                    detailInfo
                    canceledAt
                    totalPriceAfterTaxes
                    currency
                    pmsStatus
                    canceledReason
                    roomId
                    roomNumber
                    guestCheckedOut
                }
            }
        }
    `;

    const MUTATIONS = {
        REVOKE_MOVIE: gql`
            mutation CancelMovieOrder($orderID: String!, $projectID: Int64!) {
                cancelMovieOrder(orderId: $orderID, projectId: $projectID) {
                    id
                    ok
                }
            }
        `,
    };

    const [getChargesData, { loading, refetch, data }] = useLazyQuery(GET_WATCHED_MOVIES, { pollInterval: 60000 });

    const [revokeMovie, { loadingRevoke, data: revokeData }] = useMutation(MUTATIONS["REVOKE_MOVIE"], {
        onCompleted: (data) => {
            toast.success(t("operation-successful"));
        },
        onError: (err) => {
            toast.error(t("error-mutation"));
            console.log(err);
        },
    });

    const formatDate = (date, type) => {
        let response = "";
        if (date) {
            if (type === "date") {
                response = date.toLocaleString({
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                });
            } else if (type === "time") {
                response = date.toLocaleString({
                    hour12: false,
                    hour: "2-digit",
                    minute: "2-digit",
                });
            } else {
                response = date;
            }
        }
        return response;
    };

    useEffect(() => {
        if (chargesData) {
            setMovieChargesList(chargesData);
        } else {
            getChargesData();
        }
    }, [page, search, sort, chargesData]);

    useEffect(() => {
        if (revokeData?.cancelMovieOrder?.ok) {
            revokeData?.cancelMovieOrder?.ok && dispatch(setRefreshData(true));
            if (!chargesData) {
                refetch();
            }
        }
    }, [revokeData]);

    useEffect(() => {
        movies && setMovieChargesList(movies);
    }, [movies]);

    useEffect(() => {
        data?.charges?.results && setMovieChargesList(data?.charges?.results);
    }, [data]);

    useEffect(() => {
        const tableDataList = movieChargesList
            ? movieChargesList.map((movieCharge, indx) => {
                  const noPriceInfo = showPrice && freeToguest && !movieCharge.detailInfo?.isAdultMovie;
                  const roomChange =
                      movieCharge.detailInfo?.rentedFromRoom &&
                      movieCharge.detailInfo?.rentedFromRoom != movieCharge.roomNumber;

                  console.log(SessionDate(movieCharge.createdAt));
                  const isDST = (options = { year: null, month: null, day: null }) => {
                      const d = new Date();
                      const currentYear = d.getFullYear();
                      const currentMonth = d.getMonth();
                      const currentDay = d.getDate();

                      const targetYear = options.year || currentYear;
                      const targetMonth = options.month || currentMonth;
                      const targetDay = options.day || currentDay;
                      const currentDate = new Date(Date.UTC(targetYear, targetMonth, targetDay));
                      console.log(`\nisDST() currentDate: ${currentDate}`);
                      // console.log(`currentMonth: ${currentMonth}`);
                      // console.log(`targetDay: ${targetDay}`);

                      // all dates in April - Oct is DST
                      if (targetMonth > 2 && targetMonth < 10) {
                          return true;
                      }

                      // all dates from Dec - Feb is NOT DST
                      if (targetMonth > 10 || targetMonth < 2) {
                          return false;
                      }

                      // ..is month of November
                      else if (targetMonth === 10) {
                          let endDST = 1;
                          for (let day = 1; day < 20; day++) {
                              const novDate = new Date(Date.UTC(targetYear, 10, day)); // November
                              const dayOfWeek = novDate.getDay(); // returns day-of-week number (e.g. 2 == "Tuesday")

                              // 0 == "Sunday"
                              if (dayOfWeek === 0) {
                                  endDST = day;
                                  break;
                              }
                          }

                          // console.log(`endDST: ${endDST}`);
                          if (targetDay >= endDST) {
                              return false; // is NOT DST if after first Sun in Nov
                          } else {
                              return true;
                          }
                      }

                      // ..is month of March
                      else if (targetMonth === 2) {
                          let startDst = 1;
                          for (let day = 1; day < 32; day++) {
                              const marchDate = new Date(Date.UTC(targetYear, 2, day)); // March
                              const dayOfWeek = marchDate.getDay(); // returns day-of-week number (e.g. 2 == "Tuesday")

                              // 0 == "Sunday"
                              if (dayOfWeek === 0) {
                                  startDst = day + 7; // needs to be 2nd Sunday of the month
                                  break;
                              }
                          }

                          // console.log(`startDst: ${startDst}`);
                          if (targetDay >= startDst) {
                              return true; // is DST if mid-March or later
                          } else {
                              return false;
                          }
                      }
                  };
                  let TIme = new Date(movieCharge.createdAt);
                  console.log(isDST({ day: TIme.getDate(), month: TIme.getMonth(), year: TIme.getFullYear() }));
                  return movieCharge
                      ? {
                            id: movieCharge.id,
                            movie: movieCharge.detailInfo?.name || "",
                            room: (
                                <>
                                    {!movieCharge.guestCheckedOut ? (
                                        <div
                                            id={`movie-room-${indx}`}
                                            className={!roomDetail && `link clickable font-bold`}
                                            onClick={() => {
                                                !roomDetail && goToRoom(movieCharge.roomId);
                                            }}
                                        >
                                            {movieCharge.roomNumber}
                                        </div>
                                    ) : (
                                        <div className="text-gray-700">{`${movieCharge.roomNumber} - ${t(
                                            "checked-out"
                                        )}`}</div>
                                    )}
                                    {roomChange && (
                                        <Icon
                                            type={"replace"}
                                            size={1}
                                            className={"text-gray-800"}
                                            tooltip={t(
                                                freeToguest && !movieCharge.detailInfo?.isAdultMovie
                                                    ? "room-change-tooltip-freetoguest"
                                                    : "rented-on-room-tooltip",
                                                {
                                                    from: movieCharge.detailInfo?.rentedFromRoom,
                                                    to: movieCharge.roomNumber,
                                                }
                                            )}
                                        />
                                    )}
                                </>
                            ),
                            rentalDate: (
                                <div className="table">
                                    <div>{formatDate(SessionDate(movieCharge.createdAt), "time")}</div>
                                    <div>{formatDate(SessionDate(movieCharge.createdAt), "date")}</div>
                                </div>
                            ),
                            price: noPriceInfo
                                ? "-"
                                : formatPrice(parseFloat(movieCharge.totalPriceAfterTaxes), {
                                      code: movieCharge.currency,
                                  }),
                            pmsStatus: movieCharge.pmsStatus,
                            pmsCharges: noPriceInfo ? (
                                "-"
                            ) : (
                                <div className="table">
                                    <div className="text-gray-700">
                                        <span className="cursor-help" data-tip data-for={`pmsStatus-Tooltip-${indx}`}>
                                            {t(movieCharge.pmsStatus)}
                                        </span>
                                        <ReactTooltip id={`pmsStatus-Tooltip-${indx}`}>
                                            {t(`${movieCharge.pmsStatus}-tooltip`)}
                                        </ReactTooltip>
                                    </div>
                                    <div className="text-red-100">{movieCharge.canceledAt && t("Revoked rent")}</div>
                                </div>
                            ),
                            displayTimes: (
                                <DisplayTimes
                                    movieCharge={movieCharge}
                                    onClickDisplayTimes={onClickDisplayTimes}
                                    onCloseDisplayTimes={onCloseDisplayTimes}
                                    displayTimesWidth={displayTimesWidth}
                                    showTimesIcon={showTimesIcon}
                                    formatDate={formatDate}
                                />
                            ),
                            rowConfig: {
                                actions:
                                    movieCharge?.detailInfo?.isAdultMovie && revokePerm && !movieCharge?.canceledAt
                                        ? [
                                              {
                                                  label: (
                                                      <>
                                                          <span className="icon icon-superuser text-xl"></span>
                                                          <span>{t("revoke-rental")}</span>
                                                      </>
                                                  ),
                                                  onClick: () => {
                                                      confirm({
                                                          id: "logout-devices",
                                                          message: Parser(
                                                              t("revoke-rental-text", {
                                                                  movie: movieCharge.detailInfo?.name || "",
                                                                  roomNumber: movieCharge.roomNumber,
                                                              })
                                                          ),
                                                          title: `${t("revoke-rental")}`,
                                                          confirmText: `${t("revoke-rental")}`,
                                                          onConfirm: () => {
                                                              revokeMovie({
                                                                  variables: {
                                                                      orderID: movieCharge.id,
                                                                      projectID: projectID,
                                                                  },
                                                              });
                                                          },
                                                          sensitive: true,
                                                      });
                                                  },
                                              },
                                          ]
                                        : null,
                            },
                        }
                      : null;
              })
            : null;
        setTableData(tableDataList);
    }, [movieChargesList]);

    const goToRoom = (roomId) => {
        navigate(`/hotel/rooms/room/detail/${roomId}`);
    };

    const setDateRange = (range) => {
        setDateFrom(range.from && new Date(range.from).toISOString());
        setDateTo(range.to && new Date(range.to).toISOString());
    };

    return (
        <>
            {!modalContent && <UseSectionHeader title={t("watched-movie-list")}></UseSectionHeader>}
            {!modalContent && (
                <ExportMovieCharges
                    startDataExport={startDataExport}
                    setStartDataExport={setStartDataExport}
                    pagedQuery={GET_WATCHED_MOVIES}
                />
            )}
            <div className="bg-white p-5 rounded">
                <ZafiroTable
                    ref={tableUsersRef}
                    id="watched-movie-list"
                    search={!hideSearch}
                    paginate={!modalContent}
                    showCount={true}
                    bodyClassName={""}
                    header={{
                        movie: { title: t("movie"), sortable: false, width: "30%" },
                        room: { title: capitalizeFirst(t("room")), sortable: !chargesData },
                        rentalDate: { title: t("rental-date"), sortable: !chargesData },
                        price: { title: capitalizeFirst(t("price")), sortable: false },
                        pmsCharges: { title: t("PMS charges"), sortable: !chargesData },
                        displayTimes: { title: "", sortable: false },
                    }}
                    cols={[
                        "movie",
                        "room",
                        "rentalDate",
                        showPrice && "price",
                        showPrice && "pmsCharges",
                        "displayTimes",
                    ]}
                    headerStyle={{ boxShadow: null }}
                    rows={tableData}
                    minHeight="30rem"
                    maxHeight={modalContent && "39vh"}
                    customPagination={externalControl ? { value: page, onChange: setPage } : null}
                    customCount={externalControl ? data?.charges?.info?.count || tableData?.length : null}
                    customSearch={externalControl ? { value: search, onChange: setSearch } : null}
                    customSort={externalControl ? { value: sort, onChange: setSort } : null}
                    customRangeSelector={
                        showDateFilter
                            ? {
                                  value: { from: oneMonthAgo, to: new Date(new Date().setHours(23, 59, 59, 999)) },
                                  onChange: setDateRange,
                              }
                            : null
                    }
                    filters={
                        showPrice
                            ? topBar && [
                                  {
                                      id: "filter-by-pms",
                                      title: t("PMS charges"),
                                      options: PMS_STATUS_OPTIONS,
                                      onChange: (value) => {
                                          if (externalControl) {
                                              setPMSFilter(value);
                                          }
                                      },
                                      onFilter: (value, row) => {
                                          if (externalControl) {
                                              return row;
                                          }
                                          const values = value || [];
                                          if (values === "ALL" || values.includes(row?.pmsStatus)) {
                                              return row;
                                          }
                                          return null;
                                      },
                                  },
                              ]
                            : []
                    }
                    topRightCorner={
                        showExportBtn && (
                            <div className="flex items-center gap-3">
                                <Button
                                    id="export-movie-charges-button"
                                    design="blue"
                                    onClick={() => setStartDataExport(true)}
                                >
                                    {t("export")}
                                </Button>
                            </div>
                        )
                    }
                ></ZafiroTable>
            </div>
        </>
    );
};

export default WatchedMoviesList;
