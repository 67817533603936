import { TopologyRoomModel } from "../models/TopologyModel";
/* eslint-disable */
export const arrangeData = (roomsInfo) => {
    const roomResults = roomsInfo?.rooms?.results?.map((room) => {
        roomsInfo?.floors?.results?.forEach((floor) => {
            floor.floors?.forEach((roomFloor) => {
                roomFloor.rooms?.forEach((roomFloorRoom) => {
                    if (roomFloorRoom.id === room.id) {
                        room.recentGuests = roomFloorRoom.recentGuests;
                    }
                });
            });
        });
        return room;
    });

    return roomResults?.map((room) => TopologyRoomModel(room));
};

export const arrangeFloorFilter = (floors) => {
    return floors?.map((floor) => {
        const { id, name, floors } = floor;
        return {
            id,
            label: name,
            options: floors?.map((floor) => ({
                id: floor.id,
                label: floor.name,
                value: floor.id,
            })),
        };
    });
};

export const arrangeLoactionFilter = (locations) => {
    return locations.map((location) => ({
        label: location,
        value: location,
    }));
};

export const updateTvsPerRoom = (floorsNumber, setTvsPerRoom, setFloorSettings) => {
    if (floorsNumber === 0) setFloorSettings([]);
    setTvsPerRoom((prevTvsPerRoom) => {
        let newTvsPerRoom;
        if (typeof Array.isArray(prevTvsPerRoom)) {
            newTvsPerRoom = prevTvsPerRoom;
        } else {
            newTvsPerRoom = { ...prevTvsPerRoom };
        }
        for (let i = 0; i < floorsNumber; i++) {
            setFloorSettings((prevFloorSettings) => {
                const newFloorSettings = [...prevFloorSettings];
                if (!newFloorSettings[i]) {
                    newFloorSettings[i] = {
                        floorOrder: i + 1,
                        floorName: `Floor ${i + 1}`,
                        numbersOfRooms: 1,
                        starterRoom: Number(newFloorSettings[i - 1]?.starterRoom + 100),
                        tvsPerRoom: newFloorSettings[i - 1]?.tvsPerRoom,
                    };
                } else if (newFloorSettings.length > floorsNumber) {
                    newFloorSettings.splice(floorsNumber);
                }
                return newFloorSettings;
            });
        }
        return newTvsPerRoom;
    });
};

export const getTotals = (data) => {
    let wings = 0;
    let floors = 0;
    let commonZones = 0;
    let rooms = 0;
    let warnings = 0;
    if (data?.installationMap) {
        wings = data?.installationMap.results.length;
        // eslint-disable-next-line
        data.installationMap.results.map((wing) => {
            floors = floors + wing.floors.length;
            // eslint-disable-next-line
            wing.floors.map((floor) => {
                // eslint-disable-next-line
                floor.rooms.map((room) => {
                    if (room?.type === "COMMONZONE") commonZones++;
                    if (room?.type === "GUESTROOM") rooms++;
                    if (room.warnings) {
                        room.warnings.map((warning) => {
                            if (!isWarning(warning)) {
                                return null;
                            }
                            warnings++;
                            return warning;
                        });
                    }
                });
            });
        });
    }
    return { wings, floors, rooms, commonZones, warnings };
};

export const getDashboardCards = (wings, floors, rooms, commonZones, warnings) => {
    return [
        {
            icon: "wing",
            count: wings,
            subtitle: "wings",
            noShadow: true,
        },
        {
            icon: "floor",
            count: floors,
            subtitle: "floors",
            noShadow: true,
        },
        {
            icon: "room",
            count: rooms,
            subtitle: "rooms",
            noShadow: true,
        },
        {
            icon: "Commonzones_large",
            count: commonZones,
            subtitle: "common-zones",
            noShadow: true,
        },
        {
            icon: "blue-warning",
            count: warnings,
            subtitle: "warnings",
            noShadow: true,
        },
    ];
};

export const isWarning = (warning) => {
    if (warning.level < 2) {
        return true;
    }

    return false;
};
