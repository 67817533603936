import React from "react";
import Icon from "components/Icon";
import { Session } from "hooks/Utils/Session";
import CountUp from "hooks/Utils/CountUp";
import Loading from "components/Loading";
import { useTranslation } from "react-i18next";

export const DashboardCards = ({ data, monitoring }) => {
    const superUser = Session.isSuperUser();
    const { t } = useTranslation();

    return data?.length ? (
        <div
            className={`mb-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-${
                data?.length === 1 ? 3 : data?.length
            } xl:gap-10 md:gap-4 gap-4 w-100`}
        >
            {data?.map((card, index) => {
                const { icon, count, title, subtitle, textColor, actions, noShadow, id } = card;
                return (
                    <div
                        className={`relative dc-card-container-noShadow text-zafiro-800 bg-white w-full ${
                            superUser && actions && "relative"
                        } ${!noShadow ? "hover:shadow-lg" : ""} ${monitoring && "border rounded border-gray-300"}`}
                        key={index}
                    >
                        <div className="mt-3">
                            <Icon type={icon} size="5xl" className="text-zafiro-800" />
                        </div>
                        <div className={`flex flex-col items-end ${superUser && actions && "mr-40"}`}>
                            <p className="font-bold text-5xl">
                                <span className={textColor}>{count >= 0 ? <CountUp number={count} /> : null}</span>
                                <span className={`text-2xl ml-2 font-semibold ${textColor}`}>{t(title)}</span>
                            </p>
                            <p className="text-lg font-light">{t(subtitle)}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    ) : (
        <Loading className="mb-8" />
    );
};
