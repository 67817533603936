import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { EditorContext, WidgetContext } from "../../../../contexts/editor";
import Widget from "./Widget";
import { useSelector } from "react-redux";
import { GetThemeDefault } from "../../../../hooks/Utils/DesignUtils";
import { sizeToPx } from "../../../../hooks/Utils/Design/FontUtils";
import { ifStyleAttributeExist, STYLE_ATTRIBUTE } from "../../../../hooks/Utils/Design/EditorUtils";
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";
import ISO6391 from "iso-639-1";
import { useAuth } from "hooks/Session/auth";

const WidgetLangSelection = () => {
    const { lang } = useParams();

    const { languages: projectLangs } = useAuth();
    const { gridItems } = useSelector((state) => state.design);

    const { config, id: widgetID } = useContext(WidgetContext);
    const { deviceType, editorSize, theme } = useContext(EditorContext);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const data = config?.data || Widget.ParseData(widget ? widget.data : null, deviceType);

    const defaultFgColor = GetThemeDefault({ theme, id: "fgColor" });
    const defaultBgColor = GetThemeDefault({ theme, id: "bgColor" });

    const style = config?.style || Widget.ParseStyle(widget ? widget.widgetStyle : null, deviceType);
    const fgColor = style?.fgColor || defaultFgColor;
    const bgColor = style?.bgColor || defaultBgColor;
    const fontName = style?.fontName || style?.font?.name || theme?.headerStyle?.font;
    const textSize = style?.textSize || style?.size || theme?.headerStyle?.size;
    // const bold = style && style.bold ? (style.bold !== false ? true : false) : theme?.headerStyle?.bold;
    const bold = ifStyleAttributeExist(style, STYLE_ATTRIBUTE.BOLD) ? style.bold : theme?.headerStyle?.bold;
    const italic = ifStyleAttributeExist(style, STYLE_ATTRIBUTE.ITALIC) ? style.italic : theme?.headerStyle?.italic;
    const underline = ifStyleAttributeExist(style, STYLE_ATTRIBUTE.UNDERLINE)
        ? style.underline
        : theme?.headerStyle?.underline;
    const optionsColor = style.optionsColor ? style.optionsColor : fgColor;
    const langsFontName = style && style.langsFontName ? style.langsFontName : theme?.paragraphStyle?.font;
    const langsTextSize = style.langsTextSize ? style.langsTextSize : theme?.paragraphStyle?.size;
    const langsBold = ifStyleAttributeExist(style, "langsBold") ? style.langsBold : false;
    const langsItalic = ifStyleAttributeExist(style, "langsItalic") ? style.langsItalic : false;
    const langsUnderline = ifStyleAttributeExist(style, "langsUnderline") ? style.langsUnderline : false;

    const title = data
        ? data.title && data.title[lang]
            ? data.title[lang]
            : data.title && data.title.text
            ? data.title.text
            : ""
        : "";

    const printLanguage = (pLang, index) => {
        const OPTION_SELECTED = 1;
        const focusBg =
            parseInt(index) === OPTION_SELECTED
                ? theme && theme.focus && theme?.focus?.rgbaBG
                    ? theme?.focus?.rgbaBG
                    : "blue"
                : "none";
        const focusFg =
            parseInt(index) === OPTION_SELECTED
                ? theme && theme.focus && theme?.focus?.rgbaFG
                    ? theme?.focus?.rgbaFG
                    : fgColor
                : optionsColor;

        return (
            <div
                key={index}
                className="mx-3 py-2 text-center"
                style={{
                    fontFamily: langsFontName,
                    fontSize: sizeToPx(langsTextSize, editorSize ? editorSize.width : null, deviceType) + "px",
                    color: index === OPTION_SELECTED ? focusFg : widget.widgetStyle?.optionsColor,
                    backgroundColor: index === OPTION_SELECTED ? focusBg : "",
                    fontWeight: langsBold ? "bold" : 400,
                    fontStyle: langsItalic ? "italic" : "normal",
                    textDecoration: langsUnderline ? "underline" : "none",
                }}
            >
                {ISO6391.getNativeName(pLang.languageRef)}
            </div>
        );
    };

    return (
        <div className="h-full">
            <div
                className="w-full py-5 leading-10"
                style={{
                    fontFamily: fontName,
                    fontSize: sizeToPx(textSize, editorSize ? editorSize.width : null, deviceType) + "px",
                    color: fgColor ? fgColor : "black",
                    backgroundColor: bgColor,
                    fontWeight: bold ? "bold" : 400,
                    fontStyle: italic ? "italic" : "normal",
                    textDecoration: underline ? "underline" : "none",
                    textAlign: "center",
                    display: "inline-block",
                }}
            >
                {capitalizeFirst(title)}
            </div>
            <div className="flex" style={{ backgroundColor: bgColor, height: "calc(100% - 5rem)" }}>
                <div className="py-auto my-auto w-full items-center text-center align-middle">
                    {projectLangs?.length ? projectLangs.map((pLang, index) => printLanguage(pLang, index)) : ""}
                </div>
            </div>
        </div>
    );
};

export default WidgetLangSelection;
