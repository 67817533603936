import React, { useState } from "react";
import classNames from "classnames";
import Button from "./Button";

/**
 * DaySelector Component
 *
 * Allows users to select days of the week, supporting multiple selections and different languages.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {boolean} [props.multiple=true] - Enables multiple day selections.
 * @param {'es'|'en'} [props.language='es'] - Language for day initials ('es' for Spanish, 'en' for English).
 * @param {function} [props.onChange] - Callback function invoked when selected days change.
 * @param {string} props.id - Unique identifier for the DaySelector component.
 * @param {string} [props.width='100%'] - Width of the DaySelector container.
 * @param {number[]} [props.defaultValue=[]] - Array of day indices selected by default.
 *
 * @returns {JSX.Element} The rendered DaySelector component.
 *
 * @example
 * <DaySelector
 *   id="weekday-selector"
 *   multiple={true}
 *   language="en"
 *   onChange={(selectedDays) => console.log(selectedDays)}
 *   defaultValue={[0, 2, 4]}
 *   width="300px"
 * />
 */

const DaySelector = ({ multiple = true, language = "es", onChange, id, width, defaultValue = [], isValid = true }) => {
    if (!id) {
        throw new Error("DaySelector requires an id prop");
    }

    const initialsInLanguage = {
        es: ["L", "M", "X", "J", "V", "S", "D"],
        en: ["M", "T", "W", "T", "F", "S", "S"],
    };

    const initials = initialsInLanguage[language];
    const [selectedDays, setSelectedDays] = useState(defaultValue);

    const toggleDay = (dayIndex) => {
        let newSelectedDays;
        if (multiple) {
            if (selectedDays.includes(dayIndex)) {
                newSelectedDays = selectedDays.filter((d) => d !== dayIndex);
            } else {
                newSelectedDays = [...selectedDays, dayIndex];
            }
        } else {
            if (selectedDays.includes(dayIndex)) {
                newSelectedDays = [];
            } else {
                newSelectedDays = [dayIndex];
            }
        }
        setSelectedDays(newSelectedDays);
        if (onChange) {
            onChange(newSelectedDays);
        }
    };

    return (
        <div
            className={classNames("w-full flex rounded", {
                "border border-transparent": isValid,
                "border border-red-100": !isValid,
            })}
            style={{ width: width || "100%" }}
        >
            {initials.map((initial, index) => {
                const isSelected = selectedDays.includes(index + 1);

                const buttonClass = classNames("w-full flex items-center justify-center p-2", {
                    "bg-zafiro-600 text-white cursor-pointer": isSelected,
                    "bg-gray-200 text-gray-800 hover:bg-gray-300 hover:text-gray-800 cursor-pointer": !isSelected,
                });

                return (
                    <Button
                        id={`${id}-day-button-${index}`}
                        key={`${initial}-${index}`}
                        onClick={() => toggleDay(index + 1)}
                        className={buttonClass}
                        aria-pressed={isSelected}
                        role="button"
                    >
                        {initial}
                    </Button>
                );
            })}
        </div>
    );
};

export default DaySelector;
