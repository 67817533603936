import { useMutation } from "react-apollo";
import { DELETE_ROOM } from "../utils/topologyQueries";
import { TopologyContext } from "contexts/Topology";
import { useContext } from "react";

export const useDeleteRoom = () => {
    const { setLoadingRefresh } = useContext(TopologyContext);
    const [deleteRoom] = useMutation(DELETE_ROOM);

    const deleteRoomMutation = (id) => {
        deleteRoom({
            variables: {
                id,
            },
        }).then((response) => {
            if (response.data.deleteRoom.ok) {
                if (setLoadingRefresh) {
                    setLoadingRefresh(true);
                }
            }
            return response;
        });
    };

    return { deleteRoomMutation };
};
