import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import { setWarningsMinimized } from "../../actions/uiActions";
import { Session } from "hooks/Utils/Session";

import Button from "../Button";
import Icon from "../Icon";

import { GlobalContext } from "contexts/Global";

const Warning = ({
    id,
    children: message,
    title,
    icon = "warning",
    readMore,
    collapsible,
    closeable,
    collapsed: initialCollapsed,
    className,
    design,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { highlightComponent } = useContext(GlobalContext);

    const [closed, setClosed] = useState(false);
    const [collapsed, setCollapsed] = useState(initialCollapsed);

    const close = () => {
        setClosed(true);
        Session.setWarningClosed(id, true);
    };

    const collapseToggle = () => {
        setCollapsed(!collapsed);
        Session.setWarningCollapsed(id, !collapsed);
        dispatch(setWarningsMinimized(!collapsed));
    };

    const isCollapsed = collapsible && collapsed;

    const warningClass = classNames({
        "z-100 flex items-center justify-between": true,
        "text-gray-900": !design,
        "bg-blue-100 text-white": design === "blue",
        "bg-gray-900 text-gray-100": design === "black",
        "transition-all duration-500 ease-in-out overflow-hidden": closeable || collapsible,
        "py-8": isCollapsed,
        "opacity-0 h-0": closed,
        "highlight-component": highlightComponent,
        [className]: className,
    });

    const infoClass = classNames({
        "flex flex-1 justify-items-start gap-2": true,
        "items-center": collapsed,
        "items-start": !collapsed,
        "py-5": !collapsed && design,
        "px-10": !collapsible && design,
    });

    const titleClass = classNames({
        "text-base": isCollapsed,
        "text-lg font-bold mb-2": !isCollapsed,
    });

    const messageClass = classNames({
        "transition-all duration-1000 overflow-hidden": collapsible,
        "h-0 opacity-0": isCollapsed,
    });

    const toggleClass = classNames({
        "px-5": true,
        "h-full": isCollapsed,
        "self-start mt-6": !isCollapsed,
    });

    if (!id) {
        throw new Error("Warning component must have an id");
    }

    return (
        <div id="warnings" className={warningClass} style={isCollapsed ? { height: "2rem" } : { minHeight: "4rem" }}>
            {collapsible ? (
                <Button id="warning-toggle" onClick={collapseToggle} className={toggleClass}>
                    <Icon
                        type={`arrow-${!isCollapsed ? "up" : "down"}`}
                        size="xl"
                        className={`font-bold ${isCollapsed ? "pt-1" : ""}`}
                    />
                </Button>
            ) : null}
            <div className={infoClass}>
                <Icon type={icon} size={isCollapsed ? "xl" : "3xl"} />

                <div>
                    {title ? <div className={titleClass}>{title}</div> : null}
                    <div className={messageClass}>
                        <span>{message}</span>
                        {readMore ? (
                            <span className="pl-4 link clickable underline text-white" onClick={readMore}>
                                {t("read more")}
                            </span>
                        ) : null}
                    </div>
                </div>
            </div>
            {closeable ? (
                <Button id="warning-toggle" onClick={close} className="px-10 h-full">
                    <Icon type="close" size="xl" />
                </Button>
            ) : null}
        </div>
    );
};

export default Warning;
