import React from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";
import { useMutation } from "react-apollo";
import Button from "components/Button";

export const Duplicate = ({ designName, id, refetch }) => {
    const { close } = useModal();
    const { t } = useTranslation();
    const { duplicateDesign } = useDuplicateDesign();

    return (
        <Modal
            title={`${designName} - ${t("duplicate-design")}`}
            className="p-10 w-1/4"
            footer={
                <div className="flex justify-end gap-4">
                    <Button id="cancel" design="white" onClick={close} className="font-bold">
                        {t("cancel")}
                    </Button>
                    <Button
                        id="duplicate-design"
                        design="blue"
                        onClick={() => {
                            duplicateDesign({
                                variables: { designID: id },
                            }).then((res) => {
                                if (res.data.duplicateDesign.ok) {
                                    setTimeout(() => refetch(), 500);
                                }
                            });
                            close();
                        }}
                    >
                        {t("duplicate-design")}
                    </Button>
                </div>
            }
        >
            <p>{t("duplicate-design-confirm")}</p>
        </Modal>
    );
};

const useDuplicateDesign = () => {
    const DUPLICATE_DESIGN = gql`
        mutation DuplicateDesign($designID: Int64!) {
            duplicateDesign(designID: $designID) {
                error
                ok
            }
        }
    `;
    const [duplicateDesign] = useMutation(DUPLICATE_DESIGN);

    return { duplicateDesign };
};
